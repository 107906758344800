.faq-section .faq-section__grid {
    display: flex;
    flex-direction: column;
}

.faq-section .faq-section__grid h2 {
    margin: 0 0 8px 0;
    font-size: 24px;
    color: #333333;
}

.faq-section .faq-section__grid-item {
    margin-bottom: 48px;
}

.faq-section .faq-section__expansion-panel {
    display: flex;
    flex-direction: column;
    margin-left: 0;
}

.faq-section
    .faq-section__expansion-panel
    .expansion-panel-summary__content-eventsplatform {
    font-weight: 800;
    margin: 12px 0 14px 0;
    line-height: 1.57;
}

.faq-section .faq-section__expansion-panel .expansion-panel__details {
    padding: 0;
    margin-bottom: 24px;
    line-height: 1.29;
}

.faq-section .faq-section__expansion-panel-details {
    box-shadow: none;
    background-color: transparent;
}
