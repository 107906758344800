.entryEditInfo__container {
    border-radius: 3px;
    border: solid 1px #eaeaea;
    background-color: rgba(245, 245, 245, 0.5);
    padding: 32px;
    font-size: 15px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.entryEditInfo__title {
    font-weight: bold;
    padding-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
}

.entryEditInfo__subject {
    color: #999999;
    padding-bottom: 16px;
    font-weight: bold;
}

.entryEditInfo__value {
    color: #666666;
    font-weight: normal;
}

.entryEditInfo__editor {
    padding: 0;
    width: 156px;
    font-size: 16px;
    margin-top: 8px;
}

.entryEditInfo__clear-reject-link {
    margin-left: 12px;
    font-weight: normal;
}
