.entry-list__title {
    font-size: 48px;
    margin-bottom: 32px;
    font-weight: 900;
}

.event-channel-list__list {
    margin-top: 32px;
}

@media (max-width: 979px) {
    .entry-list__title.entry-list__title.entry-list__title {
        font-size: 32px;
        margin-bottom: 16px;
    }
}

@media (max-width: 480px) {
    .entry-list__item {
        margin-bottom: 24px;
    }
}
