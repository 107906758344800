.classroom__resource-text {
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    color: #4a4a4a;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource-status-icon {
    font-size: 16px;
    float: left;
}

.classroom__resource-circle-icon {
    font-size: 12px;
    float: left;
}

.classroom__resource-circle-icon-green {
    color: #009344;
}

.classroom__resource-circle-icon-gray {
    color: #666666;
}

.classroom__resource-circle-icon-red {
    color: #da1f26;
}

.classroom__resource-circle-icon-yellow {
    color: #f8a61a;
}

.classrooom__resource-favorite-button {
    outline: none;
    border: none;
    background: transparent;
    float: left;
}

.ResourceCellComponent {
    float: left;
}

.connection-text {
    padding-left: 17px;
    line-height: 1.15;
}

.recording-text {
    padding-left: 17px;
    line-height: 1;
}

.status-text {
    padding-left: 22px;
}

.classroom__scheduledEventTextContainer {
    float: left;
}

.classroom__scheduledEventLink {
    text-decoration: none !important;
    height: 16px;
    width: 16px;
    color: #bbbbbb;
}

.classroom__nextScheduledEvent {
    float: right;
}

.classroom__currentScheduledEvent {
    margin-left: 10px;
    font-size: 13px;
}

.classroom__is-live {
    height: 20px;
    width: 20px;
    text-align: center;
    float: left;
}

.classroom__is-live-streaming {
    color: #da1f26;
}

.classroom__is-live-offline {
    color: #666666;
}
