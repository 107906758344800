.media-carousel__media-item {
    border-radius: 4px;
    overflow: hidden;
}

.media-carousel__media-item__link {
    display: block;
    height: 100%;
    width: 100%;
}

.media-carousel__media-item__name {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
}

.media-carousel__media-item__live-data {
    margin-top: 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.media-carousel__media-item--2 .media-carousel__media-item__live-data {
    margin-top: 4px;
}

.media-carousel__media-item__scheduling-data {
    font-size: 11px;
    font-weight: bold;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media-carousel__media-item__live-status-tag {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    background-color: #fa374b;
    border-radius: 4px;
    display: inline-block;
    line-height: normal;
    padding: 2px 3px;
}

.media-carousel__media-item__description {
    margin-top: 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: normal;
}

.media-carousel__media-item__details-wrapper {
    padding: 8px 16px 16px 16px;
}

.media-carousel__media-item--2 .media-carousel__media-item__details-wrapper {
    padding: 16px;
}

/* card size for each number of cards in slide */
.media-carousel__media-item--4 {
    width: 276px;
    height: 222px;
}
.media-carousel__media-item__image--4 {
    width: inherit;
    height: 154px;
}
.media-carousel__media-item--3 {
    width: 379px;
    height: 281px;
}
.media-carousel__media-item__image--3 {
    width: inherit;
    height: 213px;
}
.media-carousel__media-item--2 {
    width: 583px;
    height: 427px;
}
.media-carousel__media-item__image--2 {
    width: inherit;
    height: 327px;
}

@media (max-width: 1335px) {
    /* card size for each number of cards in slide */
    .media-carousel__media-item--4 {
        width: 261px;
        height: 215px;
    }
    .media-carousel__media-item__image--4 {
        width: inherit;
        height: 147px;
    }
    .media-carousel__media-item--3 {
        width: 359px;
        height: 270px;
    }
    .media-carousel__media-item__image--3 {
        width: inherit;
        height: 202px;
    }
    .media-carousel__media-item--2 {
        width: 554px;
        height: 404px;
    }
    .media-carousel__media-item__image--2 {
        width: inherit;
        height: 312px;
    }
}

@media (max-width: 1199px) {
    .media-carousel__media-item--4 {
        width: 228px;
        height: 196px;
    }
    .media-carousel__media-item__image--4 {
        width: inherit;
        height: 128px;
    }
    .media-carousel__media-item--3 {
        width: 309px;
        height: 242px;
    }
    .media-carousel__media-item__image--3 {
        width: inherit;
        height: 174px;
    }
    .media-carousel__media-item--2 {
        width: 472px;
        height: 358px;
    }
    .media-carousel__media-item__image--2 {
        width: inherit;
        height: 266px;
    }
}

@media (max-width: 979px) {
    .media-carousel__media-item--4,
    .media-carousel__media-item--3 {
        width: 228px;
        height: 196px;
    }
    .media-carousel__media-item__image--4,
    .media-carousel__media-item__image--3,
    .media-carousel__media-item__image--2 {
        width: inherit;
        height: 128px;
    }
    .media-carousel__media-item--2 {
        width: 228px;
        height: 261px;
    }
}
