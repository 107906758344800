.user-details {
    width: 100%;
}
.user-details__container {
    display: flex;
}
@media (min-width: 980px) {
    h1.user-details__name {
        flex-grow: 1;
    }
    .user-details__actions {
        margin-top: 89px;
        margin-left: auto;
    }
}
h1.user-details__name {
    font-size: 48px;
    line-height: 57px;
    display: inline;
    color: #333333;
    font-weight: bold;
    word-break: break-word;
    word-wrap: break-word;
}
.user-details__linkedinButton {
    display: inline;
}

.user-details__title {
    font-size: 16px;
    line-height: 19px;
    min-height: 19px;
    color: #333333;
    font-weight: bold;
}
.user-details__bio {
    color: #666666;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 16px;
    width: 75%;
}

.user-details__actions button {
    display: inline-flex;
    align-items: center;
    height: 32px;
}

.user-details__actions button i {
    font-size: 24px;
}

.user-details__user-img {
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
    width: 173px;
    height: 173px;
}

.user-details__user-img .user-thumbnail__img {
    height: 100%;
}

.user-details__name-and-title {
    align-self: flex-end;
}

.UserDetailsActionsMenu {
    right: 0;
    left: auto;
}

@media (max-width: 980px) {
    .user-details__container {
        display: block;
    }
    .user-details__actions {
        margin-top: 8px;
        margin-bottom: 7px;
    }
    .user-details__bio {
        width: 100%;
    }
}
@media (max-width: 480px) {
    .user-details__user-img.user-details__user-img {
        margin-right: 16px;
        width: 104px;
        height: 104px;
        min-width: 0;
        min-width: initial;
    }
    .h1.user-details__name {
        font-size: 32px;
    }
    .user-page-head__bio {
        top: 72px;
        position: relative;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 16px;
    }
    .user-details__user-img {
        min-width: 133px;
    }

    .UserDetailsActionsMenu {
        right: auto;
    }
}
