.settings__items-container {
    margin-top: 24px;
}

.registration-page-component__checkbox + .checkbox-custom-label:before {
    font-size: 16px;
    position: relative;
    top: 2px;
}

.registration-page-component-item {
    margin-top: 14px;
}

.registration-page-component-item:first-child {
    margin-top: 0;
}

.registration-help-icon {
    font-size: 16px;
}
