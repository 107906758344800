.ad-images {
    display: flex;
}

.ad-images__image-container {
    width: 100%;
}

.ad-images__image-wrapper--aspect-ratio-size {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.ad-images__image-link {
    display: block;
    padding-bottom: 56.25%;
    margin-right: -4px;
    border: 2px solid transparent;
}

.ad-images__image-link:focus {
    border-color: #006efa;
}

.ad-images__image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ad-images__image-link:focus {
    outline: none;
}

@media (max-width: 480px) {
    .ad-images {
        flex-direction: column;
    }
}
