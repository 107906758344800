/**
 * css for the SingleEntryId metadata component
 */

.metadata_entryId__popup {
    background-color: #222222;
    color: #ffffff;
    font-size: 12px;
    border-radius: 4px;
    opacity: 0.9;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
}

.metadata_entryId__popup {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 40px;
}

.metadata_entryId__popup--thumbnail {
    padding: 8px;
}

.metadata_entryId__popup--thumbnail--no_access,
.metadata_entryId__popup--thumbnail--not_available {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 17px;
    padding-right: 17px;
}

.metadata_entryId__image {
    width: 90px;
    height: 50px;
    border: 1px solid #ffffff;
}
