.date-input input::-ms-clear {
    display: none;
}

.date-input__icon {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    font-size: 18px;
}

.date-input__input[readonly] {
    background: transparent;
    cursor: auto;
}

.react-datepicker__input-container input {
    padding-top: 6px;
    padding-bottom: 6px;
}

.filter-datepicker__datepicker {
    margin-top: 14px;
}

.filter-datepicker__native-date {
    width: 170px;
}

.react-datepicker-wrapper input {
    max-width: 130px;
}

/* override the default z-index of react date picker */
.react-datepicker-popper {
    z-index: 2000;
}
