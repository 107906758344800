.rc-tree-switcher {
    visibility: hidden;
    float: right;
}
.rc-tree .parent > span.rc-tree-switcher:after {
    font-size: 14px;
    visibility: visible;
}
.rc-tree-checkbox {
    visibility: hidden;
}
.rc-tree-checkbox:before {
    visibility: visible;
}
.rc-tree-title {
    margin-left: 11px;
}
.rc-tree {
    padding: 0 !important;
}
.rc-tree-node-content-wrapper {
    vertical-align: middle;
}
.rc-tree li .rc-tree-node-content-wrapper {
    padding-bottom: 16px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 1336px) and (max-width: 1600px) {
    .rc-tree li .rc-tree-node-content-wrapper {
        max-width: 105px;
    }
}

span.rc-tree-checkbox:not(.rc-tree-checkbox-checked):before {
    font-family: "v2ui";
}
.rc-tree .parent > span.rc-tree-switcher_open:after {
    font-family: "FontAwesome";
}
.rc-tree .parent > span.rc-tree-switcher_close:after {
    font-family: "FontAwesome";
}
span.rc-tree-checkbox-checked:before {
    font-family: "v2ui";
}
