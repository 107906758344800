.tv-entry-hero-section {
    height: 100%;
    position: relative;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: rgba(0, 0, 0, 0.7);
}

.tv-entry-hero-section__background-image--wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.tv-entry-hero-section__background-image,
.tv-entry-hero-section__background-image--svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.tv-entry-hero-section__background-image {
    filter: blur(16px);
    /* scale the image to get rid of blur borders */
    transform: scale(1.2);
}

.tv-entry-hero-section__background-image--svg-image {
    height: 100%;
    width: 100%;
}

.tv-entry-hero-section__content {
    height: 100%;
}

.tv-entry-hero-section__thumbnail {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}
