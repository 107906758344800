.group-change__menu-item {
    width: 100%;
}

.group-change__menu-item .kms-react-icon.eventplatform-check {
    margin-right: -8px;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
}

.dropdown-submenu.group-change__sub-menu > a:after {
    margin-top: 24px;
    margin-right: -10px;
}
.group-change__lang-menu.group-change__lang-menu.group-change__lang-menu {
    margin: 0;
    width: 100%;
}
