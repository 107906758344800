/**
 *  Group Users table css
 */

.groups-management-table__users .rt-td .user-avatar {
    margin-right: 8px;
}

.no-users {
    padding-top: 24px;
    margin-bottom: 20px;
    border-top: 1px solid #eaeaea;
}

.no-users-image {
    margin-top: 56px;
}

.no-users-text {
    margin-top: 32px;
    font-size: 22px;
}

.no-users-subtext {
    margin-top: 16px;
    font-size: 16px;
}

.no-users-add-new-button {
    margin-top: 24px;
}
