.event-channel-list-item__img img {
    max-width: 142px;
    min-width: 142px;
    max-height: 80px;
    min-height: 80px;
}

.event-channel-list-item__container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
}

.event-channel-list-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding: 16px 20px 16px 16px;
}

.event-channel-list-item {
    width: 100%;
}

.event-channel-list-item__name {
    font-size: 20px;
    font-weight: bold;
}

.event-channel-list-item__details {
    max-width: 800px;
    width: auto;
}

.event-channel-list-item__description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: normal;
}

.event-channel-list-item__link:hover {
    text-decoration: none;
}

.event-channel-list-item__arrow {
    font-size: 24px;
    align-self: center;
}
.event-channel-list-item--focus {
    outline: solid 2px #006efa;
    outline-offset: -2px;
}

@media (max-width: 480px) {
    .event-channel-list-item__container {
        height: 72px;
    }

    .event-channel-list-item__content {
        padding: 14px 12px 14px 16px;
    }

    .event-channel-list-item__img img {
        max-width: 128px;
        min-width: 128px;
        max-height: 72px;
        min-height: 72px;
    }

    .event-channel-list-item__name {
        font-size: 18px;
    }
}
