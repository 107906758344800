.entry-thumbnail-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.entry-thumbnail-background--blurred {
    filter: blur(20px);
    left: -20px;
    right: -20px;
    bottom: -20px;
}
