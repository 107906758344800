.timer {
    height: 38px;
    color: white;
    font-size: 18px;
    letter-spacing: 0.24px;
    line-height: 24px;
    font-weight: bold;
}

.timer__data {
    font-size: 32px;
    padding-left: 20px;
    display: inline-block;
}

.timer__title {
    font-size: 22px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 6px;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    display: inline-block;
}

.timer__data--first {
    padding-left: 0px;
}

.timer__title.timer__title--last {
    padding-right: 0;
    border: none;
}

@media (max-width: 768px) {
    .timer {
        height: 34px;
    }

    .timer__data {
        font-size: 28px;
    }

    .timer__title {
        font-size: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 4px;
    }
}

@media (max-width: 480px) {
    .timer--long-days > .timer__data {
        font-size: 26px;
    }

    .timer--long-days > .timer__title {
        font-size: 18px;
    }
}
