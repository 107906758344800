.headerContainer {
    width: 100%;
}

.headerBackLink-container {
    margin-bottom: 16px;
}

.headerBackLink-link {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
}

.entryName {
    font-size: 28px;
    font-weight: bold;
    color: black;
    line-height: 36px;
}

.headerBackLink-link-icon {
    margin-right: 4px;
    font-size: 11px;
    text-decoration: none;
}
