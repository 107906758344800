/* override browsers default, which is "buttontext" */
.groups-management-table .rt-td i {
    color: #666;
}

.groups-management-table .ReactTable .rt-thead .rt-th.-sort-desc,
.groups-management-table .ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: none;
}

.groups-management-table .ReactTable.-striped .rt-tr.-even {
    background-color: #f1f1f1;
}
