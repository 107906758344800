form.edit--email--notification--form .notification--name--label {
    font-weight: bold;
}

form.edit--email--notification--form
    .edit--email--notification--form--sender--email
    input {
    color: #008297;
    text-decoration: underline;
}

form.edit--email--notification--form input[type="text"],
form.edit--email--notification--form textarea {
    padding-top: 7px;
    padding-bottom: 7px;
    height: auto;
}

form.edit--email--notification--form textarea {
    resize: none;
    width: 100%;
}
