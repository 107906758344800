/**
 *  SearchForm css
 */

.searchForm {
    margin-bottom: 6px;
}

input.searchForm__text::-ms-clear {
    display: none;
}

input.searchForm__text[type="text"] {
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    border: none;
    box-shadow: none;
    border-left: 1px solid;
    padding-left: 10px;
    padding-top: 0;
    padding-bottom: 0;
    width: 90%;
    height: 20px;
}

.searchForm__prepend {
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    padding-bottom: 11px;
}

.searchForm.noBorder .searchForm__prepend {
    border-bottom: none;
}

.searchForm .input-append .add-on,
.searchForm .input-prepend .add-on {
    font-size: 16px;
}

.searchForm_icon {
    text-decoration: none;
    margin-left: 16px;
    bottom: 1px;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
}

.searchForm_icon [class^="v2ui-"],
.searchForm_icon .icon-search {
    display: inline-block !important;
}

.searchForm_icon:hover {
    text-decoration: none;
}
