.news-item-modal {
    position: absolute;
    border-radius: 8px;
    transform: translate(-50%, 0);
    padding-bottom: 30px;
}

.news-item-modal.news-item-modal {
    width: 995px;
    margin: auto;
}

.news-item-modal.modal.fade.in {
    top: 49px;
    border-bottom: 49px solid transparent;
    border-left: none;
    border-right: none;
    box-shadow: none;
}

.news-item-modal__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 2000;
    max-width: 100vw;
    overflow-x: hidden;
}

body.news-modal-open {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.news-item-modal__body {
    max-height: none;
    word-break: break-word;
}

.modal .modal-body.news-item-modal__body {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
}

.news-item-modal__close-button {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 35px;
    height: 35px;
    z-index: 2000;
    padding: 0 !important;
}

.news-item-modal__content-wrapper {
    padding: 20px 40px 26px 40px;
}

.news-item-modal__image {
    float: left;
    margin-right: 32px;
    margin-bottom: 16px;
    min-width: 320px;
    max-width: 320px;
    height: auto;
    border-radius: 8px;
}

.news-item-modal__updated-at {
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.38;
    margin-bottom: 8px;
}

.news-item-modal__name {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    line-height: normal;
    display: table;
}

.news-item-modal__description {
    display: table;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .news-item-modal {
        width: 945px;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .news-item-modal {
        width: 797px;
    }

    .news-item-modal__content-wrapper {
        padding: 20px 40px 26px 32px;
    }

    .news-item-modal__image {
        min-width: 280px;
        max-width: 280px;
        height: auto;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .news-item-modal__content-wrapper {
        padding: 24px 24px 26px 24px;
    }

    .news-item-modal__image {
        min-width: 280px;
        max-width: 280px;
        height: auto;
    }
}

@media (max-width: 979px) {
    .news-item-modal.news-item-modal.news-item-modal {
        width: 100%;
        top: 0;
    }
    .news-item-modal.modal.fade.in {
        top: 0;
        border-bottom: none;
    }
}

@media (max-width: 480px) {
    .news-item-modal__content-wrapper {
        padding: 24px 16px 26px 16px;
    }

    .news-item-modal__name {
        font-size: 24px;
        margin-bottom: 0;
    }

    .news-item-modal__description {
        padding-top: 16px;
        display: inline-block;
    }

    .news-item-modal__image {
        margin-right: 16px;
        min-width: 133px;
        max-width: 133px;
        height: auto;
    }
}
