.password__field-input {
    width: 100%;
}

.setPassword__submit-button {
    width: 100%;
}

.sßetPassword-complete-text {
    font-size: 16px;
}
