/**
 * cognito login css
 */

.webhookregistration-cognito-login-wrapper {
    width: 100%;
}

.webhookregistration-cognito-login-box-wrapper {
    padding-top: 153px;
    position: relative;
}

.webhookregistration-cognito-login--title {
    margin: 0 0 24px 4px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}
.webhookregistration-cognito-login--sub-title {
    text-align: center;
}

.webhookregistration-cognito-login-form-wrapper {
    margin: 0 auto;
    max-width: 480px;
    min-height: 480px;
    color: #000;
    background-color: #f4f4f4;
    padding: 65px 80px 80px 80px;
    border: solid 1px #5a5a5a;
}

.webhookregistration-cognito-login-form-header {
    margin: 0 auto;
    max-width: 640px;
}

.webhookregistration-cognito-login-form-header
    .react-dropdown
    .btn.dropdown-toggle.language-menu--button {
    padding-left: 8px;
}

.webhookregistration-cognito-login--success-wrapper {
    text-align: center;
    margin-top: 140px;
}
.webhookregistration-cognito-login--success--text {
    margin: 16px 0 0;
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
}

/* phone variant */
@media (max-width: 480px) {
    .webhookregistration-cognito-login-form-wrapper {
        padding: 100px 16px 0 16px;
        border: none;
    }

    .webhookregistration-cognito-login-box-wrapper {
        padding-top: 0;
    }
}
