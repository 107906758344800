/**
 * css for MachineTranslationForm component
 */
.order-form__translation {
    margin-top: 10px;
}

.order-form__translation__header {
    display: table;
    height: 36px;
    margin-bottom: 24px;
}

.form__translation__label {
    display: table-cell;
    vertical-align: middle;
}

.form__translation__upload-button {
    float: right;
    padding: 7px 20px;
    font-size: 16px;
    margin-left: 16px;
}

.btn.form__translation__upload-button {
    color: #666;
    border-color: #666 !important;
}
