.user-thumbnail-upload {
    display: flex;
    align-items: flex-end;
}

.user-thumbnail-upload {
    font-size: 13px;
    padding: 0 8px;
    vertical-align: bottom;
}

.user-thumbnail-upload .error {
    color: #e12437;
}
