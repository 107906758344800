/**
 *  Stream Comfiguration component css
 */

.streamConfiguration {
    margin-top: 14px;
    margin-bottom: 12px;
}

.streamConfiguration-row {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
    line-height: 20px;
}

.streamConfiguration-cell {
    float: left;
    width: 22.5%;
    min-height: 30px;
}
