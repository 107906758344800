.eventsplatform-channel-item {
    display: flex;
    font-size: 14px;
    border-radius: 4px;
    max-height: 100%;
    margin-bottom: 24px;
    background-color: #f4f4f4;
}

.eventsplatform-channel-item__container {
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
    min-width: 0;
    padding: 16px 24px 0 24px;
}

.eventsplatform-channel-item__thumbnail {
    width: 277px;
    height: 156px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.eventsplatform-channel-item__title {
    margin: 0 0 8px 0;
    font-weight: 900;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eventsplatform-channel-item__speakers {
    font-weight: bold;
    line-height: 1.43;
    font-size: 14px;
}

.eventsplatform-channel-item__details {
    width: 100%;
}

.eventsplatform-channel-item__actions .button.btn.btn-borderless-eventplatform {
    font-size: 24px;
}

.eventsplatform-channel-item__actions {
    position: relative;
    top: -4px;
}
button.btn.eventsplatform-channel-item__actions_button {
    width: 32px;
    height: 32px;
    padding-top: 2px;
    padding-bottom: 6px;
    padding-right: 5px;
    padding-left: 3px;
}

@media (max-width: 479px) {
    .eventsplatform-channel-item {
        display: flex;
        flex-direction: column;
        margin: 16px 0 0 0;
        border-radius: 4px;
        max-height: 100%;
    }
    .eventsplatform-channel-item__thumbnail {
        width: 100%;
        height: auto;
    }
    .eventsplatform-channel-item__container {
        padding: 0 16px 16px;
    }
    .eventsplatform-channel-item__details h4 {
        font-size: 18px;
        margin: 8px 0;
    }
}

@media (min-width: 479px) and (max-width: 979px) {
    .eventsplatform-channel-item__details h4 {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .eventsplatform-channel-item__container {
        display: block;
    }
}
