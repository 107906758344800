/**
 *  EntrySearchSummary component css
 */

.summary__wrapper {
    width: 100%;
    position: relative;
}

.results-summary__wrapper__icons {
    display: inline-block;
    max-width: 80%;
}

.results-summary-label {
    margin-right: 8px;
    font-size: 13px;
}
@media (max-width: 480px) {
    .results-summary-label {
        margin-right: 23px;
    }
}

.results-summary-item {
    line-height: 22px;
}

.results-summary-item:before {
    content: "|";
    margin: 0 6px;
    opacity: 0.5;
}

@media (max-width: 767px) {
    .results-summary-item:before {
        content: "";
        margin: 0 0 0 8px;
    }
}

.results-summary-item:first-of-type:before {
    content: "";
    margin: 0;
}

.results-summary-item__a {
    cursor: pointer;
    text-decoration: none;
}

.results-summary-item__a:hover {
    text-decoration: none;
}

.results-summary-item__text {
    white-space: nowrap;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
}

.results-summary-item__text i {
    position: relative;
    bottom: 1px;
    margin-right: 2px;
}

/**
 *  phone and desktop - 'show more' aligned right
 */
.results-summary__show-more-container {
    float: right;
    padding-left: 5px;
}

@media (min-width: 480px) and (max-width: 767px) {
    .results-summary__show-more-container {
        float: none;
    }
}

/**
 *  in tablet, align the 'show more' left to the icons
 */
@media (min-width: 480px) and (max-width: 767px) {
    .results-summary__show-more-container {
        display: inline-block;
        position: static;
    }
}

.results-summary__show-more--text {
    white-space: nowrap;
    text-decoration: underline;
    position: relative;
    top: 3px;
}

.results-summary__show-more--dots {
    font-size: 20px;
    letter-spacing: 4px;
    text-decoration: none;
    margin-left: 8px;
    line-height: 4px;
    vertical-align: text-top;
}
