.field-description {
    font-size: 12px;
}
.select-file {
    min-width: 135px;
    min-height: 38px;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 16px;
}
.upload-form-field {
    width: 95%;
}
.field-label {
    margin-top: 20px;
}
