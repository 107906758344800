.hostnames-table-wrapper {
    font-weight: bold;
    margin-bottom: 20px;
}

.hostnames-table-wrapper .rt-resizable-header-content {
    font-size: 13px;
    line-height: 16px;
    color: #888888;
}

.hostnames-table-wrapper .rt-resizable-header-content {
    text-transform: capitalize;
}

.hostnames-table-wrapper .rt-resizable-header:nth-child(2) .rt-resizable-header-content {
    text-transform: uppercase;
}


.hostnames-table-wrapper .rt-tr > .rt-td:nth-child(1) {
    color: #333333;
}

.hostnames-table-wrapper .rt-tr > .rt-td:nth-child(2) {
    color: #888888;
}

.hostnames-table-wrapper .rt-tr > .rt-td:nth-child(3) {
    text-transform: capitalize;
}

.hostnames-table-wrapper .rt-thead.-header {
    border-bottom: 1px solid #D9D9D9;
}

.hostnames-table-wrapper .rt-tbody .rt-tr-group,
.hostnames-table-wrapper .rt-tbody .rt-tr-group:last-child {
    border-bottom: 1px solid #CCCCCC;
}

.hostnames-table-wrapper .hostnames-status-ready {
    color: #00A078;
}

.hostnames-table-wrapper .hostnames-status-ready .hostnames-status__bullet {
    background-color: #00A078;
}

.hostnames-table-wrapper .hostnames-status-pending {
    color: #FFAA00;
}

.hostnames-table-wrapper .hostnames-status-error {
    color: #E12437;
}

.hostnames-table-wrapper .hostnames-status-error .hostnames-status__bullet {
    background-color: #E12437;
}

.hostnames-table-wrapper .hostnames-status-pending .hostnames-status__bullet {
    background-color: #FFAA00;
}

.hostnames-table-wrapper .hostnames-status__bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
}
