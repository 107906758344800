.modal.hostnames-modal .modal-header {
    background-color: transparent;
    border-bottom: none;
}

.modal.hostnames-modal .modal-header h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
}

.modal.hostnames-modal .modal-body {
    padding-top: 7px;
    font-size: 14px;
}

.modal.hostnames-modal .hostnames-modal-body input {
    width: calc(100% - 6px);
    margin-top: 6px;
    line-height: 32px;
    border-radius: 4px;
    padding: 2px;
    border-width: 1px;
}

.modal.hostnames-modal input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    text-transform: capitalize;
}

.modal.hostnames-modal input::-moz-placeholder {
    text-transform: capitalize;
}

.modal.hostnames-modal input::placeholder {
    text-transform: capitalize;
}

.modal.hostnames-modal .hostnames-modal-body {
    margin-bottom: 14px;
}

.modal.hostnames-modal .hostnames-modal-body a {
    color: #006EFA;
    font-weight: bold;
    margin-left: 5px;
    text-transform: capitalize;
}

.hostnames-modal-body a:hover {
    color: #006EFA;
}

.modal.hostnames-modal .hostnames-modal-body-hostname {
    text-decoration: underline;
    font-weight: bold;
    display: block;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-result {
    display: flex;
    align-items: center;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-available,
.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-errors {
    background: #F4F4F4;
    border-radius: 4px;
    margin-top: 8px;
    font-size: 14px;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-errors {
    color: #E12437;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-available svg,
.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-errors svg {
    margin: auto 7px auto 10px;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-available {
    color: #217A37;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn {
    font-size: 14px;
}

.modal.hostnames-modal .hostnames-modal-footer .button.btn.btn-cancel {
    color: #006EFA;
    text-transform: capitalize;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn.btn-cancel:hover:not(.btn-link, button.btn .button--transparent) {
    color: #4798FF;
}

.modal.hostnames-modal .hostnames-modal-footer {
    background-color: transparent;
    border-top-color: #CCCCCC;
    position: relative;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn-action.btn:not(.btn-danger):not(.btn-primary) {
    background-color: #006EFA;
    color: #FFFFFF;
    text-transform: capitalize;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn-action.btn:not(.btn-danger):not(.btn-primary):hover {
    background-color: #4798FF;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn-action.btn:disabled:not(.btn-danger):not(.btn-primary) {
    color: #CCCCCC;
    border: 1px solid #CCCCCC;
    background-color: transparent;
}

.modal.hostnames-modal .hostnames-modal-footer .hostnames-modal-loader-wrapper {
    position: absolute;
    right: 30px;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
}
