.edit-playlist-entry-list {
    width: 100%;
    transition: all 0.4s ease-out;
    display: inline-block;
    animation-name: fadeIn;
    opacity: 0;
    animation-delay: 1s;
    animation-timing-function: ease-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    border-left: solid 1px #eaeaea;
    padding-left: 25px;
}
.edit-playlist-entry-list--open {
    width: calc(50% - 25px);
}
.edit-playlist-entry-list--disabled {
    border-left: none;
}
.edit-playlist__container {
    display: flex;
}
.playlist-search-dropdown {
    position: relative;
    bottom: 2px;
    width: calc(50% - 25px);
    display: inline-block;
    max-width: 200px;
}
.edit-playlist-header__title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    color: #008297;
}

.edit-playlist-header {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
}

.edit-playlist__entries-list {
    max-height: 850px;
    min-height: 650px;
    overflow: auto;
    padding-right: 1px;
}

.edit-playlist-header__entries-count {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: right;
    color: #666666;
    margin-left: auto;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.edit-playlist__entries-list-empty {
    text-align: center;
    margin-top: 80px;
}
.edit-playlist__entries-list-empty-title {
    font-size: 22px;
    margin-top: 27px;
    margin-bottom: 16px;
    font-weight: bold;
}
.edit-playlist__entries-list-empty-text {
    font-size: 16px;
}
