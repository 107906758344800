.news-item {
    flex-direction: column;
    position: relative;
    width: 377px;
    height: 465px;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    text-align: left;
}

/** override button styles **/
.news-item.news-item.news-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 24px;
    border: none;
}

.news-item__opac-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

.news-item__text-box {
    z-index: 1;
}

.news-item__text-box__name {
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    line-height: normal;
}

.news-item__text-box__description {
    margin-top: 8px;
    font-size: 16px;
    color: #ffffff;
    line-height: 1.31;
}

.news-item__text-box__read-more {
    margin-top: 16px;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .news-item {
        width: 359px;
        height: 443px;
    }
}

@media (min-width: 481px) and (max-width: 1199px) {
    .news-item {
        width: 309px;
        height: 381px;
    }
}

@media (max-width: 480px) {
    .news-item {
        width: 283px;
        height: 349px;
    }
}
