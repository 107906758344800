div.kms-react-carousel {
    position: relative;
}

div.kms-react-carousel .carousel .slide {
    overflow: hidden;
}

div.kms-react-carousel .carousel.carousel-slider .control-arrow,
div.kms-react-carousel .carousel.carousel-slider .control-arrow:hover {
    background: none;
}

div.kms-react-carousel .carousel.carousel-slider .control-arrow::before {
    border: none;
    font-family: "FontAwesome";
    font-size: 60px;
}

div.kms-react-carousel
    .carousel.carousel-slider
    .control-arrow.control-prev::before {
    content: "\f104";
}

div.kms-react-carousel
    .carousel.carousel-slider
    .control-arrow.control-next::before {
    content: "\f105";
}

div.kms-react-carousel .carousel .control-dots {
    margin: 0 0 16px;
}

div.kms-react-carousel .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
    margin: 0 8px;
    outline: none;
    box-shadow: none;
}

/* disable blinking on tap in mobiles (https://stackoverflow.com/questions/3516173/ipad-safari-how-to-disable-the-quick-blinking-effect-when-a-link-has-been-hit) */
div.kms-react-carousel .carousel.carousel-slider .control-arrow,
div.kms-react-carousel .carousel .control-dots .dot {
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
}

/* tablet portrait / landscape */
div.kms-react-carousel .carousel .control-dots {
    margin-bottom: 16px;
}

.kms-react-carousel-bottom {
    bottom: 44px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    body.mobile
        div.kms-react-carousel
        .carousel.carousel-slider
        .control-arrow {
        display: none;
    }

    div.kms-react-carousel .carousel .control-dots {
        margin-bottom: 10px;
    }

    .kms-react-carousel-bottom {
        bottom: 38px;
    }
}

/* desktop / desktop large */
@media (min-width: 1200px) {
    div.kms-react-carousel .carousel .control-dots {
        margin-bottom: 16px;
    }
}
