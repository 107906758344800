/**
 *  channel subscription component css
 */

button.btn.btn-link.channelSubscription__share,
button.btn.btn-link.channelSubscription__share:active {
    color: #666666;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
}

button.btn.btn-link.channelSubscription__share:hover,
button.btn.btn-link.channelSubscription__share:focus {
    color: black;
}

.channelSubscription__buttons {
    display: inline-block;
}

.channelSubscription__button {
    font-size: 16px;
    height: 36px;
    min-width: 176px;
    display: inline-block;
}

.channelSubscription__button .channelSubscription__button__icon {
    margin-right: 10px;
    font-size: 14px;
}

button.btn.channelSubscription__subscribed {
    color: #666666;
    border-color: #cccccc !important;
}

/*
 *  hide and show the appropriate subscribed/unsubscribe button
 */
.channelSubscription__buttons:hover .channelSubscription__subscribed {
    display: none;
}

.channelSubscription__subscribed--unsubscribe {
    display: none;
}
.channelSubscription__buttons:hover
    .channelSubscription__subscribed--unsubscribe {
    display: inline-block;
    background-color: white;
}

@media (max-width: 979px) {
    button.btn.channelSubscription__button {
        font-size: 14px;
        min-width: 140px;
        padding-right: 10px;
        padding-left: 10px;
    }
}
@media (max-width: 480px) {
    button.btn.channelSubscription__button {
        width: 100%;
    }
    button.btn.btn-link.channelSubscription__share {
        float: left;
        padding-left: 0;
    }
    .channelSubscription {
        margin-bottom: 16px;
    }
    .channelSubscription__buttons {
        width: 100%;
    }
}
