/**
 * css for watch later tab on personal profile page
 */

.watchLaterTab__empty {
    display: flex;
    min-height: 80vh;
}

.watchLaterTab__empty__message {
    margin: auto;
}

.watchLaterTab__removed__item {
    background-color: #f4f4f4;
    margin-bottom: 16px;
    padding-right: 24px;
    display: flex;
}

.watchLaterTab__removed__thumbnail {
    width: 110px;
    height: 62px;
}

.watchLaterTab__removed__data {
    display: flex;
    margin-left: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 0; /* a trick from https://css-tricks.com/flexbox-truncated-text/ */
}

.watchLaterTab__removed__data__name {
    display: flex;
    min-width: 0; /* a trick from https://css-tricks.com/flexbox-truncated-text/ */
}

.watchLaterTab__removed__data__name--bold {
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 3.67px; /* replace an actual space with margin */
}

.watchLaterTab__removed__data__was-removed-text {
    white-space: nowrap;
}

.watchLaterTab__removed__undo {
    margin-right: 4px;
    font-weight: bold;
    text-transform: capitalize;
    white-space: pre;
}

@media (max-width: 480px) {
    .watchLaterTab__removed__item {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
    }

    .watchLaterTab__removed__thumbnail {
        display: none;
    }

    .watchLaterTab__removed__data {
        display: block;
    }
    .watchLaterTab__removed__undo {
        display: flex;
    }
}
