.entry-player-preview,
.entry-player-preview__thumbnail,
.entry-player-preview__player .kaltura-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.entry-player-preview__thumbnail {
    transition: opacity 500ms ease-out;
    will-change: opacity;
}

.entry-player-preview__thumbnail--hidden {
    opacity: 0;
}

.entry-player-preview__player {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
}

.entry-player-preview__player .playkit-player-gui,
.entry-player-preview__player .playkit-loading-backdrop,
.entry-player-preview__player .playkit-pre-playback-play-overlay {
    display: none;
}

.entry-player-preview__player .playkit-engine-html5 {
    -o-object-fit: cover;
       object-fit: cover;
}

/* IE10+ object-fit: cover alternative */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .entry-player-preview__player .playkit-engine-html5 {
        top: 50%;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
    }
}

/* Edge 12+ object-fit: cover alternative */
@supports (-ms-ime-align: auto) {
    .entry-player-preview__player .playkit-engine-html5 {
        top: 50%;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
    }
}

.entry-player-preview__player .playkit-poster {
    background-size: cover;
}
