.expansion-panel {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.expansion-panel:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.expansion-panel:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.expansion-panel__container {
    min-height: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
}

.expansion-panel__container--expanded {
    overflow: visible;
    visibility: visible;
    animation-name: fade-in;
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    height: auto;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
