.event-list-single-item {
    height: 191px;
    padding: 12px 0;
}

.event-list__date-divider {
    padding-top: 20px;
    padding-bottom: 4px;
}

.event-list__filter-button {
    margin-bottom: 12px;
    color: #006efa;
}

button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button,
button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button:hover,
button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button:active,
button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button:focus {
    font-weight: bold;
    line-height: normal;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

.event-list__filter-button:hover,
.event-list__filter-button:active,
.event-list__filter-button:focus {
    color: #0055cc;
}

@media (max-width: 979px) {
    .event-list__filter-button {
        margin-bottom: 8px;
    }

    .event-list-single-item {
        padding: 8px 0;
    }

    .event-list__date-divider {
        padding-top: 8px;
        padding-bottom: 0;
    }
}

@media (max-width: 768px) {
    .event-list-single-item {
        height: 100%;
    }
}
