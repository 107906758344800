.event-presenter-no-image {
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
}

.event-presenter-no-image__name {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
}
.event-presenter-no-image__title {
    color: #666666;
    font-size: 13px;
    line-height: 21px;
    margin: 0;
}

.event-presenter-no-image__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 767px) {
    .event-presenter-no-image {
        max-width: 100%;
    }
}
