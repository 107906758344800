.expansion-panel-summary {
    display: flex;
    padding: 0 24px 0 24px;
    min-height: 48px;
    justify-content: space-between;
    align-items: center;
}

.expansion-panel-summary__content {
    margin: 12px 0;
    display: flex;
    width: 100%;
}

.expansion-panel-summary__btn {
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
}

.expansion-panel-summary__btn--expanded {
    transform: rotate(90deg);
}

@media (max-width: 480px) {
    .expansion-panel-summary {
        padding: 0 16px;
    }
}
