.event-channel-card-item {
    min-width: 378px;
    max-width: 378px;
    height: 243px;
    background-color: #f4f4f4;
    border-radius: 4px;
}

.event-channel-card-item__header {
    position: relative;
    height: 155px;
    overflow: hidden;
}

.event-channel-card-item__header-image,
.event-channel-card-item__header-no-image {
    height: 100%;
}

.event-channel-card-item__header-no-image {
    background-color: black;
}

.event-channel-card-item__header-logo {
    width: 62px;
    height: 62px;
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 33px;
    border: solid 2px #ffffff;
}

.event-channel-card-item__details {
    padding-top: 10px;
    padding-left: 16px;
}

.event-channel-card-item__details > div:first-child {
    display: flex;
    height: 24px;
    margin-bottom: 10px;
    align-items: center;
}

.event-channel-card-item__name {
    display: inline-block;
    line-height: normal;
    line-height: initial;
    margin: 0px 8px 0px 0px;
    max-width: 61%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.event-channel-card-item__links {
    display: flex;
}

.event-channel-card-item__link {
    display: flex;
    align-items: center;
    padding-top: 7px;
}

a.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link:hover,
a.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link:focus,
a.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link:active {
    height: auto;
}

.event-channel-card-item__booth-link {
    margin-right: 27px;
}

.event-channel-card-item__link-icon {
    margin-right: 4px;
    font-size: 24px;
}

@media (max-width: 767px) {
    .event-channel-card-item {
        min-width: 0px;
        max-width: inherit;
        width: 100%;
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    .event-channel-card-item {
        min-width: 351px;
        max-width: 351px;
    }
}

@media (max-width: 1199px) and (min-width: 980px) {
    .event-channel-card-item {
        min-width: 309px;
        max-width: 309px;
    }
}

@media (max-width: 1335px) and (min-width: 1200px) {
    .event-channel-card-item {
        min-width: 358px;
        max-width: 358px;
    }
}
