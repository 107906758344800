.title-line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

/* override system-width's margin: auto */
.title-line.title-line {
    margin-bottom: 16px;
}

.title-line__title {
    margin: 0;
    line-height: normal;
}
