.sub-section {
    display: flex;
    flex-wrap: wrap;
}

.sub-section__item {
    display: flex;
    flex-direction: column;
    width: 420px;
    margin: 22px 5% 26px 0;
}

.sub-section__item-title {
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;
}

.sub-section__item-title span {
    height: 22px;
    font-size: 16px;
    font-weight: 900;
}

.sub-section__item-title a {
    font-size: 14px;
    font-weight: 900;
    margin: 0 0 0 16px;
}

.btn-visit-page {
    height: 32px;
    line-height: 32px !important;
}

.sub-section__item-description-html {
    font-size: 16px;
    margin: 10px 0;
    max-width: 400px;
}

@media (max-width: 979px) {
    .sub-section__item-description-html {
        color: #b2b2b2;
        margin: 8px 0 8px 0;
    }

    .sub-section__item {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 24px 0 13px 0;
    }

    .sub-section__item-title {
        margin: 0 0 8px 0;
    }
}
