.send-email__wrapper {
    width: 85%;
    margin: 0 auto;
}
.send-email__header {
    margin: 0 auto 1rem;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
}
.send-email__error {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}
.send-email__email-field-input.send-email__email-field-input--error {
    border-color: #dc430d;
    outline-color: transparent;
}
.email-form-spacer {
    min-height: 20px;
}
.email-error {
    font-size: 14px;
    color: #dc430d;
}
.btn.send-email__email-submit {
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
}
.send-email__item {
    margin-top: 20px;
}
.send-email__form-container {
    display: flex;
    flex-direction: column;
}
.send-email__form .form-label {
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: bold;
    color: #999999;
}
.send-email__form-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.send-email__form .send-email__form-container .send-email__email-field-input {
    max-width: 100%;
    width: calc(100% - 24px);
    padding: 13px 12px;
}
.send-email__buttons-container {
    text-align: center;
}
.send-email__button.btn {
    margin: 0 10px;
    font-size: 20px;
    height: 48px;
    line-height: 2.3em;
}
