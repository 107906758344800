.entry-name-and-description {
    display: inline-block;
}
.entry-name-and-description {
    vertical-align: top;
}
.playlist-result--detailed .entry-name-and-description {
    padding-left: 32px;
}
.results__entry-name {
    font-weight: bold;
}
.playlist-result--grid .results__entry-name {
    margin-top: 12px;
}
.results__entry-time.results__entry-time--playlist {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #999999;
}
.playlist-result {
    padding: 24px 0;
}
.playlist-result--detailed:not(:first-child) {
    padding-top: 24px;
}
.playlist-result--grid {
    padding-bottom: 22px;
    padding-top: 10px;
}
.playlist-result--detailed:first-child {
    border-top: solid 1px #eaeaea;
}
.playlist-result--detailed {
    width: 100%;
}
.playlist-result--detailed:not(:last-child) {
    border-bottom: solid 1px #eaeaea;
    display: flex;
}

.playlist-result--detailed .playlist__thumbnail {
    width: 300px;
    display: inline-block;
    flex-shrink: 0;
}
.playlist-result--grid {
    width: 32%;
}
.user-playlists__button {
    padding: 5px;
}

.playlist-dot-separator {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
}
.playlist-entries-count__text {
    padding: 0 10px;
}
.playlist-entries-count {
    z-index: 10;
    line-height: 1.5em;
    font-size: 16px;
    display: flex;
    position: absolute;
    padding-left: 1rem;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    top: 24px;
    left: 0;
    min-width: 80px;
    color: white;
    background-color: #222222;
    max-height: 30px;
    box-sizing: border-box;
}

.playlist-icon__container {
    font-size: 24px;
    display: inline-block;
}
.playlist__buttons.details--detailed-view {
    display: inline-block;
}
.playlist__buttons.details--grid-view {
    display: inline-block;
    margin-top: 5px;
}
.playlist-result--detailed {
    position: relative;
}
.playlist-result--detailed .user-playlists__buttons {
    position: absolute;
    right: 0;
}

.playlist__buttons-details {
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
}

@media (max-width: 979px) {
    .playlist-result--grid {
        width: 48%;
    }
}

@media (max-width: 480px) {
    .playlist-search-results--grid .playlist__thumbnail {
        width: 100%;
    }
    .playlist-result {
        width: 100%;
        height: 100%;
    }
}
