/* not BEM, but needed to override bootstrap/kms css */
.nav li.dropdown button.dropdown-toggle.DropdownFilter__toggle {
    font-weight: 300 !important;
}

@media (max-width: 480px) {
    .nav.filter-bar__filters
        .dropdown.DropdownFilter
        button.dropdown-toggle.DropdownFilter__toggle {
        font-size: 15px;
    }

    li.dropdown.DropdownFilter button.dropdown-toggle.DropdownFilter__toggle {
        font-size: 15px;
    }

    .DropdownFilter .dropdown-menu > li > a {
        font-size: 13px;
    }
}

.nav > li > button.DropdownFilter__toggle:hover,
.nav > li > button.DropdownFilter__toggle:focus {
    background-color: transparent;
}

#wrap #filterBarWrap:not(.navbar) .nav > li > button.DropdownFilter__toggle {
    padding: 7px 25px 10px 5px;
}

.DropdownFilter__toggle {
    font-size: 17px;
    letter-spacing: 1px;
    padding: 7px 25px 10px 5px;
}
