@import "./../../assets/kmsreactfont.css";

[class^="kmsr-"],
[class*=" kmsr-"] {
    font-size: 16px;
}

.reach__header {
    padding-bottom: 32px;
}

.page-title {
    color: #000000;
    font-size: 28px;
}

.reach__title {
    color: #008297;
    font-size: 20px;
    line-height: 36px;
    font-weight: normal;
}

button.btn.order-btn {
    font-size: 16px;
    padding: 8px 16px;
}

.info-sign {
    color: #999999;
}

/* dashboard */
.dept-filter {
    display: inline;
}

.dept-filter__title {
    display: inline-block;
    line-height: 36px;
}

.dept-filter__control {
    display: inline-block;
    min-width: 220px;
}

.channel-rules-link {
    display: inline-block;
    line-height: 36px;
}
