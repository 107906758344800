/**
 * css for CaptionUploadForm component
 */
.order-form__caption-upload-form__modal {
    max-width: 500px;
}

.order-form__caption-upload-form__select-file-label,
.order-form__caption-upload-form__caption-label {
    display: inline;
    vertical-align: top;
    line-height: 22px;
}

.order-form__caption-upload-form__select-file-label {
    margin-right: 54px;
}

.order-form__caption-upload-form__caption-label {
    margin-right: 90px;
}

.order-form__caption-upload-form__upload-ui,
.order-form__caption-upload-form__description {
    display: inline;
}

.order-form__caption-upload-form__upload-ui-button {
    margin: 0;
}

.order-form__caption-upload-form__description {
    font-size: 12px;
}

.order-form__caption-upload-form__label-input {
    max-width: 240px;
    width: 100%;
}

.order-form__caption-upload-form__field {
    margin-bottom: 24px;
}
