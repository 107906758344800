.pie-chart-with-legend {
    float: left;
    width: auto;
    box-sizing: border-box;
}
.legends-with-pie-chart {
    float: left;
    padding-top: 30px;
    min-width: 250px;
    margin-left: 85px;
    box-sizing: border-box;
}
.pie-chart-with-legend-container {
    padding: 0 30px;
    float: left;
    min-width: 100%;
    box-sizing: border-box;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .legends-with-pie-chart {
        min-width: 200px;
        margin-left: 35px;
    }
    .pie-chart-with-legend-container {
        padding: 0 24px;
    }
}
@media (max-width: 991px) {
    .pie-chart-with-legend {
        float: left;
        max-width: 50%;
    }
    .pie-chart-with-legend-container {
        padding: 0;
    }
    .legends-with-pie-chart {
        max-width: 50%;
        margin-left: 0;
    }
}
