.create-new-playlist-modal {
    max-width: 600px;
}

.create-new-playlist-form__label,
.create-new-playlist-form .playlist-tags-label {
    margin-top: 24px;
    color: #999999;
    cursor: default;
    font-weight: bold;
}

.create-new-playlist-form__label:first-of-type {
    margin-top: 0;
}

.create-new-playlist-form__input {
    box-sizing: border-box;
    width: 100%;
    resize: none;
}

.create-new-playlist-form__input.create-new-playlist-form__input {
    margin-bottom: 0;
}

.create-new-playlist-form__input,
.create-new-playlist-form-tags-input-container {
    margin-top: 12px;
}

.create-new-playlist-modal__add-button {
    min-width: 104px;
    min-height: 36px;
    font-size: 16px;
    margin-left: 12px;
}

.modal-footer .btn.create-new-playlist-modal__add-button {
    margin-left: 16px;
}

@media (max-width: 767px) {
    .create-new-playlist-modal .modal-footer.modal-footer {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .create-new-playlist-modal__cancel-button {
        order: 1;
        margin-top: 12px;
    }

    .modal-footer .btn.create-new-playlist-modal__add-button {
        margin-left: 0;
    }
}
