.password__field-input {
    width: 100%;
}

.resetPassword__submit-button {
    width: 100%;
}

.resetPassword-complete-text {
    font-size: 16px;
}
