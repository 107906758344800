.thumbnail-player-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    padding-top: 56.25%;
}

.kaltura-thumbnail-player {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
