.source-type__pip {
    float: left;
}
.sip-source-type-modal-content {
    font-size: 14px;
}
.modal .modal-body.sip-source-type-modal-content {
    min-height: 260px;
    padding-top: 40px;
}
.sip-source-type-modal {
    width: 600px;
}
.sip-source-type-modal__table {
    text-align: center;
}
.source-type__talking-heads {
    padding-left: 30px;
}
.source-type__talking-heads-img {
    padding-left: 20px;
    padding-top: 14px;
}
.source-type__pip-img {
    padding-top: 14px;
}
.sip-source-type__message {
    margin-top: 35px;
}
.sip-source-option {
    padding: 1px;
}
@media (min-width: 1200px) {
    .modal.sip-source-type-modal {
        width: 600px;
    }
}
