.media-thumbnail {
    display: block;
    position: relative;
    text-decoration: none;
}

.media-thumbnail.no-link {
    pointer-events: none;
    cursor: default;
}

.media-thumbnail::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.2) 53%,
        rgba(0, 0, 0, 0.15) 71%,
        rgba(0, 0, 0, 0.05) 100%
    );
}

.media-thumbnail__img {
    width: 100%;
    overflow: hidden;
}

.media-thumbnail__duration {
    font-size: 12px;
    line-height: normal;
    left: 8px;
    bottom: 8px;
    position: absolute;
    color: #fff;
}

.media-thumbnail--error .media-thumbnail__img {
    display: none;
}

.media-thumbnail__type-container {
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    padding: 5px 10px;
}

.media-thumbnail__webcast {
    background-color: #dc430d;
}
