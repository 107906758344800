.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column,
.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    /* the checkbox component has a right margin - add the same from the left */
    margin-left: 10px;
    /* cancel "resizable" and "column line" table styles for the checkbox column */
    padding: 0px;
    margin: auto;
}

.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    /* cancel "column line" table styles for the checkbox column */
    border-right: none;
    text-align: center;
}

.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column
    + .rt-td,
.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th,
.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th
    .rt-resizable-header-content {
    /* cancel "column line" table styles for the second column */
    padding-left: 0;
}

.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    padding: 18px 0 !important;
    margin: 4px 0 auto 5px !important;
}

.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    padding: 0 0 2px 1px;
    margin: auto 0 auto 10px;
}
