.free-text-filter {
    padding-left: 2px;
}

.free-text-filter__input {
    max-width: 130px;
    height: 36px;
    margin-top: 16px;
    box-shadow: none;
}
