/**
 *  OrderForm Component css
 */

.order-form__department {
    padding: 0 0 0 0px;
    margin-bottom: 33px;
}

.order-form_row {
    margin-left: 0 !important;
}
.order-form {
    text-align: left;
    font-size: 14px;
}

.order-form__service-and-source,
.order-form__instructions-container,
.order-form__feature-and-turnaround,
.order-form__submit-box {
    background-color: #f8f8f8;
}

.order-form__instructions-container {
    margin-left: 0 !important;
    max-height: 200px;
    overflow-y: auto;
}

.order-form__welcome {
    padding: 8px 8px 8px 8px;
    margin: 32px 24px 32px 24px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    border-radius: 3.5px;
}

.order-form__welcome a {
    text-decoration: underline;
}
.order-form__welcome a:hover {
    opacity: 0.7;
}

.order-form__service-and-source,
.order-form__instructions {
    border-top: 1px solid #eaeaea;
}

.order-form__submit-box,
.order-form__feature-and-turnaround,
.order-form__service-and-source {
    margin-left: 0 !important;
    padding: 24px 56px 24px 24px;
}

.order-form__instructions {
    margin-left: 0;
    padding: 10px 0 0 0;
    border-top: 0;
}

.order-form__add-speaker-box {
    margin-left: 0 !important;
    display: block;
}

.order-form__instructions {
    margin-bottom: 20px;
}

.order-form__submit-box {
    margin-left: 0 !important;
    overflow: auto;
    border-bottom: 1px solid #eaeaea;
}

.order-form__instructions-textarea {
    width: 100%;
    height: 105px;
    display: block;
    box-sizing: border-box;
    resize: none;
    margin: 0;
}

.order-form__drop-down {
    margin-left: 0 !important;
}

.order-form__department__dropDown {
    padding-right: 16px;
    margin-left: 0 !important;
}

.order-form__submit-button {
    padding: 7px 20px;
    font-size: 16px;
    margin-left: 16px;
}

.order-form__feature-and-turnaround {
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
}

.order-form__target-translation {
    padding-top: 20px;
}

.order-form .Select-menu {
    max-height: 120px;
}

.reach-order-form__turnAroundTime__message {
    display: inline-block;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .order-form__department__dropDown {
        padding-right: 56px;
    }
}
@media (max-width: 480px) {
    .order-form__department__dropDown {
        padding-right: 20px;
    }

    .order-form__feature-and-turnaround,
    .order-form__service-and-source {
        padding: 25px 20px;
        float: left !important;
    }
    .order-form__instructions {
        padding: 25px 0;
    }
    .order-form__languages,
    .order-form__turnaround-time {
        padding-top: 25px;
    }
    .order-form__instructions {
        margin-bottom: 0;
    }
    .order-form__instructions,
    .order-form__submit-button {
        width: 100%;
    }
    .order-form__submit-box {
        padding: 24px;
    }
}
