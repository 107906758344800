.my-playlist-details-page {
    padding: 32px 0;
}
.my-playlist-details-page--new-playlist {
    padding: 24px 0;
}

.my-playlist-details-page .my-playlist-details {
    margin-bottom: 48px;
}

@media (max-width: 767px) {
    .my-playlist-details-page {
        padding: 24px 0;
    }
}
