.event-channel-list__title {
    font-size: 48px;
    line-height: normal;
    margin-bottom: 32px;
}

.event-channel-list__sorter .eventplatform-check:before {
    position: absolute;
    right: 5px;
}

.event-channel-list__description {
    margin-bottom: 32px;
    width: 690px;
    line-height: 1.31;
    font-size: 16px;
}

.event-channel-list__list {
    margin-top: 32px;
}

.event-channel-item {
    margin-bottom: 16px;
}

.event-channel-collection__filters .dropdown-option__text {
    text-transform: capitalize;
}

@media (max-width: 1200px) {
    .event-channel-list__description {
        width: 770px;
    }
}

@media (max-width: 979px) {
    .event-channel-list__title.event-channel-list__title.event-channel-list__title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .event-channel-list__description {
        margin-bottom: 24px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .event-channel-list__search {
        min-width: 193px;
        max-width: 193px;
    }

    .event-channel-item {
        margin-bottom: 24px;
    }
}
