.virtual-event-entry-hero-section {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
}

.virtual-event-entry-hero-section .hero-section__buttons {
    display: flex;
}

.virtual-event-entry-hero-section__background-image--wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

.virtual-event-entry-hero-section__background-image,
.virtual-event-entry-hero-section__background-image--svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.virtual-event-entry-hero-section__background-image {
    filter: blur(16px);
    /* scale the image to get rid of blur borders */
    transform: scale(1.2);
    -o-object-fit: cover;
       object-fit: cover;
}

.virtual-event-entry-hero-section__background-image--svg-image {
    height: 100%;
    width: 100%;
}

.virtual-event-entry-hero-section__content {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    z-index: 0;
}
@media (min-width: 1200px) {
    .virtual-event-entry-hero-section__content {
        justify-content: space-between;
    }
}
.virtual-event-entry-hero-section__details__timer {
    margin-bottom: 24px;
}
.countdown-timer-timerDigit.timerDigit-days {
    background-image: none;
}

.virtual-event-entry-hero-section__details .countdown-timer-timerDigit {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.virtual-event-entry-hero-section__details__timer__title {
    font-size: 18px;
    padding-right: 16px;
}

.virtual-event-entry-hero-section__details__timer__data {
    font-size: 29px;
    padding-left: 16px;
}
.scheduling-data.virtual-event-entry-hero-section__details__scheduling-data
    .scheduling-data__date,
.scheduling-data.virtual-event-entry-hero-section__details__scheduling-data
    .scheduling-data__time {
    margin: 8px 0;
}
.scheduling-data.virtual-event-entry-hero-section__details__scheduling-data
    .scheduling-data__date {
    margin-top: 0;
}

.virtual-event-entry-hero-section__details__timer__data--first {
    padding-left: 0 !important;
}

.virtual-event-entry-hero-section__details__timer__title {
    font-size: 18px;
    padding-right: 16px;
}

.virtual-event-entry-hero-section__details__timer__title--last {
    padding-right: 0 !important;
}

.virtual-event-entry-hero-section__details__scheduling-data.scheduling-data {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.88 !important;
    letter-spacing: 0.21px;
    font-weight: bold;
}

.virtual-event-entry-hero-section__details__scheduling-data__icon {
    font-size: 20px;
}

button.btn.dropdown-toggle.virtual-event-entry-hero-section__details__add-to-calendar__button.virtual-event-entry-hero-section__details__add-to-calendar__button {
    border: none !important;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
}
button.btn.dropdown-toggle.virtual-event-entry-hero-section__details__add-to-calendar__button
    .react-dropdown-toggle__button,
button.btn.dropdown-toggle.virtual-event-entry-hero-section__details__add-to-calendar__button
    .eventplatform-calendar {
    font-size: 14px !important;
    font-weight: 700;
}

.virtual-event-entry-hero-section__details__add-to-calendar__arrow-icon {
    border: solid 1px rgba(255, 255, 255, 0.24);
}

.virtual-event-entry-hero-section__thumbnail {
    width: 686px;
    height: 386px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.virtual-event-entry-hero-section__thumbnail > img {
    display: block;
    border-radius: 8px;
}

.virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    line-height: 20px;
}

.virtual-event-entry-hero-section__details {
    width: 342px;
}

@media (max-width: 767px) {
    .virtual-event-entry-hero-section .hero-section__buttons {
        display: block;
    }
}

/* mobile portrait */
@media (max-width: 666px) {
    .virtual-event-entry-hero-section__content {
        justify-content: center;
        padding-right: 20px;
    }

    .virtual-event-entry-hero-section__details {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: inherit;
    }
    .virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
        width: 300px;
    }
    .virtual-event-entry-hero-section__details__timer {
        margin-bottom: 12px;
    }

    .virtual-event-entry-hero-section__details__timer__title {
        padding-right: 12px;
    }

    .virtual-event-entry-hero-section__details__timer__data {
        padding-left: 12px;
    }

    .virtual-event-entry-hero-section__details__scheduling-data {
        margin-bottom: 32px;
    }

    .virtual-event-entry-hero-section__details__scheduling-data__icon {
        display: none !important;
    }

    .virtual-event-entry-hero-section__thumbnail {
        width: 236px;
        height: 129px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.4);
    }
}

/* mobile landscape */
@media (min-width: 667px) and (max-width: 767px) {
    .virtual-event-entry-hero-section__details__timer {
        margin-bottom: 16px;
    }

    .virtual-event-entry-hero-section__details__scheduling-data {
        margin-bottom: 16px;
    }

    .virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
        width: 375px;
    }

    .virtual-event-entry-hero-section__content.system-width {
        width: 100%;
    }

    .virtual-event-entry-hero-section__thumbnail {
        width: 236px;
        height: 129px;
    }
}

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
    .virtual-event-entry-hero-section__details__scheduling-data {
        margin-bottom: 32px;
    }

    .virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
        width: 100%;
    }

    .virtual-event-entry-hero-section__thumbnail {
        width: 352px;
        height: 198px;
    }
}

/* tablet landscape */
@media (min-width: 980px) and (max-width: 1199px) {
    .virtual-event-entry-hero-section__thumbnail {
        width: 554px;
        height: 312px;
    }
}

/* add to calendar dropdown adjustments */
.virtual-event-entry-hero-section__details__add-to-calendar__arrow-icon.react-dropdown-toggle__arrow {
    display: none;
}
.virtual-event-entry-hero-section__details__add-to-calendar li a i {
    display: none;
}

.virtual-event-entry-hero-section__agenda-button.btn.btn-borderless-eventplatform {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e553ec;
}

/* broadcast will start soon */
.virtual-event-entry-hero-section-counter {
    line-height: 0px;
}

.virtual-event-entry-hero-section-counter-title {
    font-size: 24px;
    color: #ffffff;
    display: inline-block;
    font-weight: 900;
    margin-bottom: 32px;
    line-height: 24px;
}

.virtual-event-entry-hero-section-counter-title.no-content {
    margin-bottom: 0px;
    display: inline;
}

@media (max-width: 767px) {
    .virtual-event-entry-hero-section__details__scheduling-data.scheduling-data {
        font-size: 13px;
    }
}
