.contact-info-item {
    padding: 12px 0;
    display: flex;
    font-size: 16px;
}

.contact-info__icon {
    padding: 0 8px 0 0;
    font-size: 24px;
}

.contact-info__property {
    color: #666666;
    width: 20%;
    align-items: center;
}

.contact-info__value {
    margin-left: 24px;
    color: #333333;
    flex: 1;
}
.contact-info__url {
    font-size: 16px;
}
