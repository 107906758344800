.cnc-form {
}
.cnc-form textarea {
    padding: 7px 8px !important;
    max-width: 568px;
}
.cnc-form__field {
    margin-bottom: 32px;
    max-width: 584px;
}
.cnc-form__field-label {
    font-size: 14px;
    font-weight: bold;
}
.cnc-form__field-description {
    font-size: 13px;
    margin-bottom: 8px;
}
.cnc-form__autoReply__msg {
    margin-top: 16px;
}
.cnc-form__separate-line {
    border: solid 1px;
    width: 100%;
}
.cnc-form__btns__object-url {
    margin-left: 24px;
}
.cnc-form__pre-broadcast--error,
.cnc-form__post-broadcast--error {
    font-size: 14px;
    font-weight: bold;
    color: #ff5050;
}
.cnc-form__pre-broadcast__input--error,
.cnc-form__post-broadcast__input--error {
    border: 1px solid #ff5050 !important;
}
