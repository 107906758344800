.search-modal-body .modal-body {
    max-height: none;
}

.search-modal-body .modal-body.filters__modal {
    overflow: auto;
    max-height: 70vh;
    padding: 0;
}

.search-filter-group:first-of-type {
    margin-left: 0;
}

.search-filters-wrapper {
    padding-left: 0px;
    padding-right: 0px;
}

.search-filter-group__filter-items-container {
    padding-top: 10px;
}

.search-filter-group .accordion-body {
    padding-left: 1px;
}
.carousel-mode .search-filter-group:first-of-type {
    margin-left: 0;
}

.filter-modal__accordion-header {
    font-weight: bold;
    letter-spacing: 0.5px;
}

.filter-modal__accordion-body {
    margin-top: 8px;
}
