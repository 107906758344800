.entry-cta {
    display: flex;
    border-radius: unset;
    padding: 0 16px 0 8px;
    height: 34px;
    align-items: center;
    background-color: rgb(224, 237, 254);
    line-height: normal;
    margin-bottom: 24px;
}

.entry-cta:hover {
    background-color: rgb(224, 237, 254, 0.7);
}

.entry-cta__icon {
    font-size: 32px;
    margin-right: 16px;
}

.entry-cta__header {
    display: inline;
    font-size: 14px;
    font-weight: bold;
}

.entry-cta__description {
    display: inline;
    font-size: 13px;
    font-weight: normal;
    margin-left: 8px;
}
