/**
 * add users form
 */

.Groups__actions__status {
    text-align: right;
}

.Groups__actions__status__text {
    font-size: 14px;
    color: #666666;
    display: inline-block;
}
