.add-to-playlist-modal {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    height: 80%;
    max-height: 638px;
}

.modal-body.add-to-playlist-modal__body {
    overflow-y: hidden;
    max-height: 2000px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.add-to-playlist__list-wrapper {
    overflow-y: auto;
    position: relative;
    margin-top: 10px;
}
