.entry-list-item__container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
}

.entry-list-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    padding: 16px 28px 16px 16px;
}

.entry-list-item {
    width: 100%;
    margin-bottom: 16px;
}

.entry-list-item__name {
    font-size: 20px;
    line-height: normal;
    font-weight: bold;
}

.entry-list-item__details {
    max-width: 800px;
    width: auto;
}

.entry-list-item__description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: normal;
}

.entry-list-item__link:hover {
    text-decoration: none;
}
.event-list-item__scheduling {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
}
.event-list-item__scheduling span {
    margin-right: 8px;
}
.entry-list-item__link {
    font-size: 14px;
    display: flex;
    align-items: center;
}
.entry-list-item__link .eventplatform-go-to {
    font-size: 24px;
}
.entry-list-item__link-text {
    vertical-align: super;
}

@media (max-width: 979px) {
    .entry-list-item__img {
        display: none;
    }
    .entry-list-item__content {
        flex-direction: column;
    }
    .entry-list-item__container {
        height: 126px;
    }
    .entry-list-item__details,
    .entry-list-item__link {
        margin-right: auto;
    }
}

@media (max-width: 480px) {
    .entry-list-item__name {
        font-size: 18px;
    }
}
