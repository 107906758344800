.eventsplatform .contactus-section .contactus-button {
    height: 32px;
    margin: 18px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventsplatform
    .contactus-section
    .contactus-button.btn-cta-eventplatform:hover,
.eventsplatform
    .contactus-section
    .contactus-button.btn-cta-eventplatform:active,
.eventsplatform
    .contactus-section
    .contactus-button.btn-cta-eventplatform:focus {
    background-color: #0055cc;
    color: #ffffff;
}

.eventsplatform .contactus-section .connect {
    background-color: #282828;
    padding: 40px;
    margin: 0;
    text-align: center;
    font-weight: 400;
}

.amazon-connect__modal .modal-body {
    padding: 0 !important;
}

.amazon-connect__modal .modal-body h3 {
    font-size: 32px;
    font-weight: 400;
    color: #ffffff;
    background-color: #282828;
    padding: 40px;
    margin: 0;
    text-align: center;
}

.amazon-connect__modal.bootbox {
    height: 620px;
    background: #000;
    color: #fff;
    text-align: center;
}

.amazon-connect__modal.bootbox .connect-customer-interface {
    width: 100%;
    height: 620px;
    margin: 0;
}

.amazon-connect__modal.bootbox
    .btn.button.btn-link.contactus-button__close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 999;
    padding: 0;
    color: #fff;
    width: 32px;
    height: 32px;
    outline: none;
    text-decoration: none;
}

.amazon-connect__modal.bootbox
    .btn.button.btn-link.contactus-button__close-button
    i {
    font-size: 32px;
}

.amazon-connect__modal.bootbox .connect-action-button {
    background: #282828;
}

.eventsplatform .contactus-section .connect-chat-wrapper .connect-actions {
    height: 40px;
}

.eventsplatform
    .contactus-section
    .connect-chat-wrapper
    .connect-button-wrapper {
    display: none;
}
