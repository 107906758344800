@keyframes webcast-homepage-live-entry-join-button {
    0% {
        background-color: #fc3536;
        border-color: #fc3536;
    }
    100% {
        background-color: #7e1a1b;
        border-color: #7e1a1b;
    }
}

span.btn.webcast-homepage-live-entry-join-button {
    color: #fff;
    opacity: 0.8;
    padding: 9px 16px;
    font-size: 16px;

    animation-name: webcast-homepage-live-entry-join-button;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}
