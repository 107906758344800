.user-thumbnail {
    box-sizing: border-box;
    border: 2px solid #ffffff;
    border-radius: 8px;
}
.user-thumbnail__img-wrapper {
    aspect-ratio: 1;
}

.user-thumbnail__img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.user-thumbnail__intro-container {
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -56px;
    padding: 5px 10px;
}

.user-thumbnail__edit-container {
    margin-top: -48px;
    padding: 8px 8px;
}

.user-thumbnail--error .user-thumbnail__edit-container {
    margin-top: 184px;
}

.user-thumbnail--empty .user-thumbnail__img {
    display: none;
}

.user-thumbnail--empty {
    background-color: #666666;
}

.user-thumbnail__actions {
    float: right;
}

/* processing state */
.user-thumbnail__processing {
    text-align: center;
    margin-top: 70%;
    color: #ffffff;
    font-size: 20px;
}

.user-thumbnail__processing .v2ui-Spinner-icon .path1:before,
.user-thumbnail__processing .v2ui-Spinner-icon .path2:before {
    color: white;
}

/* processing state end */
