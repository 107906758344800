.main-sidebar-dark-theme .main-sidebar {
    background-color: #222222;
}

.main-sidebar-dark-theme.main-sidebar__container
    button.main-sidebar__menu-button--open[type="button"]
    .main-sidebar__menu-icon {
    font-size: 24px;
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar a,
.main-sidebar a :hover,
.main-sidebar a :active {
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar__menu button {
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar__menu :hover {
    background-color: #333333;
}

.main-sidebar-dark-theme .main-sidebar__menu .main-sidebar__back-btn:hover {
    background-color: #333333;
}

.main-sidebar-dark-theme
    .main-sidebar
    .main-sidebar__menu
    .main-sidebar__menu-icon {
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar__menu-item--active {
    background-color: #333333;
}
