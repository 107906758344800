.login-email__container .login-email__email-field-input {
    width: 100%;
    max-width: 100%;
    height: 48px;
    padding: 0 10px;
    margin: 6px 0px;
    box-sizing: border-box;
}
.login-email__buttons-container {
    text-align: center;
}
.login-email__login-button {
    margin-top: 5px;
    text-align: center;
    color: #006efa;
    padding: 20px;
    font-weight: bold;
    box-sizing: border-box;
    display: inline-block;
}
.login-email-error {
    font-size: 14px;
    color: #dc430d;
}
.error__header {
    /*text-align: center;*/
    /*font-size: 24px;*/
}
.login-email__label {
    display: inline-block;
    color: #888888;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
}
.email-login__header {
    opacity: 0;
    color: #666666;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 40px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}
.login-email-success .base-form__logo {
    padding-bottom: 0;
}
.base-form__logo {
    padding-bottom: 24px;
}
.btn.login-email__send-button {
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    background-color: #006efa;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}

.login-email-success {
    text-align: center;
}
.login-email-success__header {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
}
.login-email-success__body {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}
.login-email-success > * {
    margin: 20px;
}
.login__welcome-text {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
}
.login-email__form-container {
    text-align: justify;
}
.login-email__container {
    text-align: center;
}
