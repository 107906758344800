h3.video-item__name {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    margin: 0;
}
.video-item__name-wrapper {
    padding: 0 16px;
}
.video-item__img-wrapper {
    height: 213px;
}
.video-item__img--no-image {
    background-color: black;
}

.media-thumbnail__duration {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 5px;
}
@media (max-width: 1199px) {
    .video-item__img-wrapper {
        height: 185px;
    }
}

@media (max-width: 979px) {
    .video-item__img-wrapper {
        height: 140px;
    }
}

@media (max-width: 480px) {
    .video-item__img-wrapper {
        height: 204px;
        width: 100%;
    }
}
