.comingSoon-container {
    height: 100%;
    position: relative;
    background-color: #006efa;
    min-height: 732px;
}

.comingSoon-videofile {
    display: block;
    position: absolute;
    right: 0;
    width: 45%;
}

.comingSoon-videofile video {
    height: auto;
    width: 100%;
}

.comingSoon-content {
    position: relative;
}

.comingSoon-logo,
.comingSoon-logo--timerIsDone {
    padding-top: 120px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 260px;
    max-height: 80px;
}

.comingSoon-logo--timerIsDone {
    padding-top: 318px;
}

.comingSoon-header,
.comingSoon-timerMainDoneMsg {
    text-align: center;
    font-size: 64px;
    line-height: normal;
    font-weight: 900;
    color: #ffffff;
    margin-top: 40px;
}

.comingSoon-description,
.comingSoon-timerSecondaryDoneMsg {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    line-height: 1.6;
    max-width: 997px;
    margin: 40px auto 0 auto;
    font-weight: normal;
}

.comingSoon-targetDate {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1.08px;
    margin-top: 32px;
}

.comingSoon-timerContainer {
    text-align: center;
    color: #ffffff;
    margin-top: 56px;
}

.comingSoon-timerDigit {
    width: 180px;
    height: 160px;
    font-size: 55px;
    font-weight: 900;
    display: inline-block;
    line-height: 100px;
    border-left: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
    text-align: center;
}
.comingSoon-timerDigit-valueType {
    font-size: 20px;
    line-height: 55px;
    font-weight: normal;
}

.comingSoon-footer {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    margin-top: 56px;
    line-height: 1.6;
}

.comingSoon-footer a:link {
    color: #ffffff;
    font-weight: bold;
}
.comingSoon-footer a:link:hover {
    text-decoration: none;
}

.timerDigit-days {
    border-left: none;
}
.timerDigit-seconds {
    border-right: none;
}

.comingSoon-locales {
    float: right;
}

@media (min-width: 768px) and (max-width: 979px) {
    .comingSoon-header {
        font-size: 32px;
    }
}

@media (max-width: 767px) {
    
    .comingSoon-logo {
        padding-top: 74px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
        max-height: 45px;
    }

    .comingSoon-header {
        font-size: 32px;
        font-weight: bold;
    }

    .comingSoon-description {
        font-size: 16px;
        line-height: 1.5;
        margin-top: 16px;
        margin-right: 5%;
        margin-left: 5%;
    }

    .comingSoon-targetDate {
        margin-top: 24px;
    }

    .comingSoon-timerContainer {
        margin-top: 40px;
    }

    .comingSoon-timerDigit {
        width: 89px;
        height: 86px;
        font-size: 32px;
        line-height: 69px;
    }
    .comingSoon-timerDigit-valueType {
        font-size: 16px;
        line-height: 0;
    }

    .comingSoon-footer {
        margin-top: 40px;
    }
}
