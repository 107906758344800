/**
 *  My media buttons css
 */

.my-media__actions {
    display: inline-flex;
    justify-content: space-around;
    align-self: flex-start;
}

.my-media__actions-link {
    text-decoration: none;
    border: none;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-media__actions-link:hover,
.my-media__actions-link:focus {
    color: #666666;
    text-decoration: none;
}

.my-media__actions-link.my-media__actions-link--disabled,
.my-media__actions-link.my-media__actions-link--disabled:hover,
.my-media__actions-link.my-media__actions-link--disabled:focus {
    color: #cccccc;
}
