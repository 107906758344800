.news-playlist {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
}

.news-playlist__carousel .slick-slide div {
    margin-right: 32px;
    width: 100%;
}

.news-playlist__carousel .slick-list {
    margin-right: -32px;
    overflow: hidden;
}

.news-playlist__carousel .slick-next:before {
    content: none;
}

.news-playlist__carousel .slick-prev:before {
    content: none;
}

.news-playlist__carousel .slick-next {
    right: -41px;
}

.news-playlist__carousel .slick-prev {
    left: -66px;
}

.media-carousel__arrow {
    font-size: 48px;
}

@media (max-width: 1199px) {
    .news-playlist__carousel .slick-list {
        margin-right: -16px;
        min-width: 958px;
    }

    .news-playlist__carousel .slick-slide div {
        margin-right: 16px;
        width: 100%;
    }

    .media-carousel__arrow {
        font-size: 32px;
    }

    .news-playlist__carousel .slick-next {
        right: -25px;
    }

    .news-playlist__carousel .slick-prev {
        left: -32px;
    }
}

@media (max-width: 979px) {
    .news-playlist {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
