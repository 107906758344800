/**
 * My Media Entry Results (List - details, collapsed) css
 */

.ReactTable.-view-type-detailed.-selection-table .rt-tbody .rt-td {
    display: flex;
}

.ReactTable.-view-type-detailed,
.ReactTable.-view-type-collapsed {
    border-top: 0;
}

/**
 * align the list views - details and collapsed, with the
 * table view.
 */
.ReactTable.-selection-table.-view-type-detailed
    .rt-thead
    .rt-th.selection--table--checkbox--column,
.ReactTable.-selection-table.-view-type-collapsed
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/**
 * these cause additional spacing, and are invisible in list views.
 */
.ReactTable.-selection-table.-view-type-detailed
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th,
.ReactTable.-selection-table.-view-type-collapsed
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th {
    display: none;
}

/**
 *  adjust to my media cells size
 */
.ReactTable.-view-type-detailed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    margin-top: 18px;
}

.ReactTable.-view-type-collapsed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    margin-top: 22px;
}

/* search results */
.ReactTable.search-detailed.-view-type-detailed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column,
.ReactTable.search-detailed.-view-type-collapsed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    margin-top: 10px;
}
