/**
 *  Truncate Manager css
 */

.truncateManager {
    white-space: normal;
}

.truncateManager__more,
.truncateManager__less-link {
    cursor: pointer;
    font-size: 12px;
}

.truncateManager__less {
    width: 100%;
}

.truncateManager__less-link {
    float: right;
    text-decoration: underline;
}
