.eventsplatform .agenda__title {
    margin-bottom: 32px;
}

.eventsplatform .agenda__filters {
    width: 100%;
    display: flex;
    margin: 24px 0;
}

.eventsplatform .agenda__search {
    width: 196px;
    height: 28px;
    margin-right: 16px;
}

.eventsplatform .agenda__sorter {
    display: flex;
    margin-left: auto;
}

.eventsplatform .agenda__sorter-dropdown {
    min-width: 100px;
    margin-right: 8px;
}

.checkbox-custom-icon.eventplatform-check {
    box-sizing: border-box;
    line-height: 18px;
    vertical-align: auto;
}

.eventplatform-checkbox {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    text-align: center;
}

.eventplatform-checkbox--checked {
    border: none;
}

.eventsplatform .agenda__tags-filter {
    margin: 24px 0;
}

.eventsplatform .agenda-page .tab-pane {
    margin-bottom: 16px;
}

.eventsplatform .agenda-page .agenda-tabs__nav li a {
    padding: 16px 0 14px 0;
    margin-right: 25px;
}

.eventsplatform .agenda-page .agenda-tabs__nav li a:hover {
    padding: 16px 0 14px 0;
    margin-right: 25px;
}

.agenda__tags-filter button.btn-borderless-eventplatform {
    text-transform: capitalize;
}

/* style btn-view-earlier, btn-view-later and btn-view-more as links instead of buttons */
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later,
.eventsplatform button.btn-view-more.btn-view-more.btn-view-more.btn-view-more,
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier:hover,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later:hover,
.eventsplatform
    button.btn-view-more.btn-view-more.btn-view-more.btn-view-more:hover,
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier:focus,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later:focus,
.eventsplatform
    button.btn-view-more.btn-view-more.btn-view-more.btn-view-more:focus,
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier:active,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later:active,
.eventsplatform
    button.btn-view-more.btn-view-more.btn-view-more.btn-view-more:active {
    font-weight: bold;
    line-height: normal;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

.eventsplatform button.btn-view-earlier {
    margin-bottom: 12px;
}

.eventsplatform button.btn-view-later,
.eventsplatform button.btn-view-more {
    margin-top: 12px;
}

.eventsplatform button.btn-view-earlier,
.eventsplatform button.btn-view-later,
.eventsplatform button.btn-view-more {
    color: #006efa;
}

.eventsplatform button.btn-view-earlier:hover,
.eventsplatform button.btn-view-later:hover,
.eventsplatform button.btn-view-more:hover,
.eventsplatform button.btn-view-earlier:focus,
.eventsplatform button.btn-view-later:focus,
.eventsplatform button.btn-view-more:focus,
.eventsplatform button.btn-view-earlier:active,
.eventsplatform button.btn-view-later:active,
.eventsplatform button.btn-view-more:active {
    color: #0055cc;
}

.eventsplatform .my-agenda__no-results {
    text-align: center;
}

.eventsplatform .my-agenda__image-box {
    display: inline-block;
    margin-top: 40px;
    /*background-image: url("../assets/myagenda_light@2x.png");*/
    height: 150px;
    width: 320px;
    background-size: contain;
    background-repeat: no-repeat;
}

.eventsplatform .my-agenda__title {
    text-align: center;
    font-size: 24px;
    margin: 40px 20px 20px 20px;
}

.eventsplatform .my-agenda__sub-title {
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #939393;
}
/* ie adjustments */
.agenda__sorter-dropdown .dropdown-eventplatform__control {
    min-width: 128px;
    white-space: nowrap;
}
.agenda__sorter-dropdown .dropdown-eventplatform__control .value-container {
    display: inline-block;
    text-overflow: ellipsis;
}
/* end of ie adjustments */

.eventsplatform .agenda-page .tab-content {
    padding-bottom: 25px;
}

.agenda__description {
    margin-bottom: 24px;
    width: 757px;
    max-width: 100%;
    font-size: 16px;
}

@media (max-width: 979px) {
    .eventsplatform .agenda__title.agenda__title.agenda__title {
        font-size: 32px;
        margin-bottom: 16px;
    }
}

@media (max-width: 768px) {
    .eventsplatform label:nth-child(2) .dropdown-eventplatform__menu {
        right: 50%;
        transform: translateX(50%);
    }
    .eventsplatform .agenda__filters {
        flex-direction: column;
    }

    .eventsplatform .agenda__search {
        min-width: 100%;
    }

    .eventsplatform .agenda__sorter {
        flex-wrap: wrap;
        margin-left: 0;
    }

    .eventsplatform .agenda__sorter-dropdown {
        margin: 16px 8px 0 0;
    }

    .eventsplatform .agenda__filters {
        margin: 16px 0;
    }
}

@media (max-width: 480px) {
    .eventsplatform .my-agenda__image-box {
        height: 136px;
        width: 290px;
    }

    .eventsplatform .my-agenda__title {
        margin: 20px;
    }
}
