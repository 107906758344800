.header-logo__img {
    max-width: 140px;
    max-height: 36px;
}
a.header-logo__link {
    font-size: 36px;
    color: #ffffff;
}
a.header-logo__link:hover {
    font-size: 36px;
    color: #cccccc;
}
a.header-logo__link:focus {
    outline: 2px solid #006efa;
}

.header-logo__altText {
    font-size: 18px;
    font-weight: bold;
    line-height: 34px;
}
