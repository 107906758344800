.channel-rules-summary {
    font-size: 16px;
    margin-top: 20px;
    display: inline-block;
}
.channel-rules-link {
    text-decoration: underline;
    margin-top: 10px;
    color: #00b4d1;
}
.channel-rules__status-line {
    height: 80px;
    vertical-align: center;
}
