/*
 * A copy of react-responsive-carousel/lib/styles/carousel.css,
 * but all rules are prefixed with ".kms-react-carousel"
 */
.kms-react-carousel .carousel .control-arrow,
.kms-react-carousel .carousel.carousel-slider .control-arrow {
    transition: all 0.25s ease-in;
    opacity: 0.4;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
}
.kms-react-carousel .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.kms-react-carousel .carousel .control-arrow:before,
.kms-react-carousel .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: "";
}
.kms-react-carousel .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none;
}
.kms-react-carousel .carousel .control-prev.control-arrow {
    left: 0;
}
.kms-react-carousel .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
}
.kms-react-carousel .carousel .control-next.control-arrow {
    right: 0;
}
.kms-react-carousel .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff;
}

.kms-react-carousel .carousel {
    position: relative;
    width: 100%;
}
.kms-react-carousel .carousel * {
    box-sizing: border-box;
}
.kms-react-carousel .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
}
.kms-react-carousel .carousel .carousel {
    position: relative;
}
.kms-react-carousel .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}
.kms-react-carousel .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
}
.kms-react-carousel .carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
}
.kms-react-carousel .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
}
.kms-react-carousel .carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
}
.kms-react-carousel .carousel .thumb.selected,
.kms-react-carousel .carousel .thumb:hover {
    border: 3px solid #333;
}
.kms-react-carousel .carousel .thumb img {
    vertical-align: top;
}
.kms-react-carousel .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
}
.kms-react-carousel .carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}
.kms-react-carousel .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}
.kms-react-carousel .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in;
}
.kms-react-carousel .carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.kms-react-carousel .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}
.kms-react-carousel .carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.kms-react-carousel .carousel .slider-wrapper.axis-vertical .slider {
    flex-direction: column;
}
.kms-react-carousel .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}
.kms-react-carousel .carousel .slider.animated {
    transition: all 0.35s ease-in-out;
}
.kms-react-carousel .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: #000;
}
.kms-react-carousel .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}
.kms-react-carousel .carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}
.kms-react-carousel .carousel .slide .legend {
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    transition: opacity 0.35s ease-in-out;
}
.kms-react-carousel .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%;
}
@media (min-width: 960px) {
    .kms-react-carousel .carousel .control-dots {
        bottom: 0;
    }
}
.kms-react-carousel .carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}
.kms-react-carousel .carousel .control-dots .dot.selected,
.kms-react-carousel .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.kms-react-carousel .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
}
.kms-react-carousel .carousel:hover .slide .legend {
    opacity: 1;
}
