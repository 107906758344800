.btn-icon .searchForm_icon {
    margin-left: 0;
}

.searchForm_icon--info {
    position: relative;
    top: 1px;
}

.btn-icon {
    background: none;
    border: none;
    padding: 0;
    margin: 0 16px;
}
.btn-icon:visited {
    text-decoration: none;
}
