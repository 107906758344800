.stat_data.stat_data--webcast-home-page,
.stat_data.stat_data--webcast-home-page i {
    color: inherit;
    font-size: 13px;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
}

.stat_data.stat_data--webcast-home-page i {
    margin-right: 6px;
}

.webcast-homepage-recorded-stat-item {
    margin-right: 14px;
}

.stat_data:last-child .webcast-homepage-recorded-stat-item {
    margin-right: 0;
}
