.main-sidebar__container .main-sidebar__menu-button {
    display: flex;
    align-items: center;
}

.main-sidebar .main-sidebar__menu-button--close {
    margin-left: -36px;
    left: 50px;
    height: 28px;
    top: 12px;
}

.main-sidebar__container
    button.main-sidebar__menu-button--open[type="button"]
    .main-sidebar__menu-icon {
    font-size: 24px;
    color: #000000;
    padding: 0;
    margin: 0;
}

.main-sidebar__container
    button.main-sidebar__menu-button--close[type="button"]
    .main-sidebar__menu-icon {
    font-size: 24px;
    color: #ffffff;
    padding: 0;
    margin: 0;
}
