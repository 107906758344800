.autocomplete-dropdown-container input {
    margin-bottom: 0;
}

.dropdown-option--selected {
    margin-right: 8px;
}

.autocomplete-dropdown-container .value-container {
    padding-left: 16px;
    padding-right: 16px;
}

.autocomplete-dropdown-container .dropdown-option {
    padding-left: 16px;
    padding-right: 16px;
    border: none;
}
.autocomplete-dropdown__input input {
    box-shadow: none;
}
.autocomplete-dropdown__input input:focus {
    box-shadow: none;
}

.value-container__icon {
    position: absolute;
    right: 0;
}

.autocomplete-dropdown-indicator__icon,
.dropdown-option__icon {
    font-size: 16px;
}

.autocomplete-dropdown__menu .dropdown-option__text {
    white-space: nowrap;
    display: inline-block;
}

.autocomplete-dropdown__menu.dropdown-menu--auto-width {
    width: auto !important;
    min-width: 220px;
}

.dropdown-menu--multiline-items .dropdown-option__text {
    white-space: normal;
}

/* Align the drop-down item text to the left when the item has a checkbox */
.dropdown-menu--multiline-items
    .dropdown-option--with-checkbox
    .dropdown-option__text {
    position: relative;
    padding-left: 30px;
}
.dropdown-menu--multiline-items .checkbox-custom-wrapper {
    position: absolute;
    left: 0;
}

.eventplatform-checkbox {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    text-align: center;
    display: inline-block;
}

.eventplatform-checkbox--checked {
    border: none;
}

/* hover/focus state */
.dropdown-option.autocomplete-dropdown__option:hover,
.dropdown-option.autocomplete-dropdown__option:focus {
    background-color: #f4f4f4;
}
