.eventsplatform .tag-chip {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 13px;
    font-weight: bold;
    padding: 0 6px;
    margin-right: 8px;
    margin-top: 5px;
}

.eventsplatform .tag-chip i {
    position: relative;
    top: 1px;
    margin-left: 4px;
    color: black;
}
