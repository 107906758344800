.event-placeholder {
    display: flex;
    font-size: 13px;
    border-radius: 4px;
    margin-bottom: 24px;
}

.event-placeholder__details {
    flex-grow: 1;
}

.event-placeholder__container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    padding: 16px 24px 0 24px;
    background-color: #f4f4f4;
}

.event-placeholder__thumbnail {
    width: 291px;
    height: 164px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.event-placeholder__title {
    font-size: 18px;
    margin: 0;
    font-weight: 900;
}

.event-placeholder__description {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 1.43;
    padding-right: 100px;
}

.event-placeholder__action {
    display: flex;
    margin: 8px 0 16px;
    align-items: center;
}
.event-placeholder__action :hover,
.event-placeholder__action :focus {
    text-decoration: none;
}

.event-placeholder__link {
    display: flex;
    justify-content: center;
    font-size: 14px;
    max-height: 32px;
    padding: 4px 20px 4px 8px;
    color: #006efa;
}

.event-placeholder__link:hover {
    color: #006efa;
}

.event-placeholder__link span {
    max-height: 17px;
    margin: 3px 0 4px 4px;
    font-size: 14px;
    font-weight: 900;
}

.event-placeholder__link-icon {
    font-size: 24px;
}

@media (max-width: 767px) {
    .event-placeholder {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 13px;
        border-radius: 4px;
        max-height: 100%;
    }
    .event-placeholder__thumbnail {
        margin-top: 16px;
        width: 100%;
        height: auto;
        text-align: center;
    }
    .event-placeholder__title {
        margin-top: 16px;
    }
    .event-placeholder__container {
        padding: 8px 24px;
    }
    .event-placeholder__description {
        padding-right: 0;
    }
    .event-placeholder__action {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0 0 0;
        align-items: center;
        max-width: 343px;
    }
    .event-placeholder__link {
        display: flex;
        justify-content: center;
        max-width: 100%;
        max-height: 32px;
        margin: 0 8px 8px 0;
        color: #2d9bd6;
    }
}

/*@media (min-width: 480px) and (max-width: 767px) {*/
/*    .event-placeholder__container {*/
/*        padding: 24px 16px 0 24px;*/
/*    }*/
/*}*/

@media (min-width: 768px) and (max-width: 979px) {
    .event-placeholder__thumbnail {
        width: 222px;
    }
}
