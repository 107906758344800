.progressBar-container {
    height: 4px;
    background-color: #cccccc;
    margin-bottom: 4px;
}

.progressBar__inner {
    float: left;
    height: 4px;
}

.progressBar--complete {
    background-color: #8dc63f;
}

.progressBar--uploading {
    background-color: #008297;
}
