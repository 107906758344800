.main-sidebar__menu-item {
    display: flex;
    justify-content: space-between;
    max-width: 319px;
}

.main-sidebar__menu-item--active {
    background-color: #f4f4f4;
}

.main-sidebar__menu-item--active .main-sidebar__menu-item-title {
    padding-left: 17px;
}

.main-sidebar__menu-item a {
    text-decoration: none;
}

.main-sidebar__container
    .main-sidebar
    .main-sidebar__menu
    .main-sidebar__menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: #333333;
}

.main-sidebar__menu-item-title {
    display: flex;
    align-items: center;
    min-height: 56px;
    max-height: 100%;
    padding-left: 24px;
    width: 100%;
}
