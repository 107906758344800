/**
 * EntrySearchResultDetails css
 */

.results-details-container {
    width: 100%;
    display: inline-block;
}

.results-details-container__group {
    margin-top: 14px;
}

.results-details-container__group:first-child {
    margin-top: 0;
}
