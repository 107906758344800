.playlist-edit-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: solid 1px #d9d9d9;
    opacity: 0;
    animation-delay: 0.5s;
    animation-name: slidemenu;
    animation-timing-function: ease-out;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
}

.playlist-edit-navbar__info {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: bold;
}

.playlist-edit-navbar__info i {
    margin-right: 8px;
    font-size: 24px;
    color: #999999;
}

.playlist-edit-navbar__info--secondary {
    display: flex;
    align-items: center;
    color: #999999;
}

.playlist-edit-navbar__info .divider-vertical {
    height: 36px;
    border: 1px solid #999999;
    margin: 0 16px;
}

.playlist-edit-navbar__info--primary {
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}

.playlist-edit-navbar__actions {
    display: flex;
}

@keyframes slidemenu {
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 83px;
    }
}
