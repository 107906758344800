.qr-code-wrapper {
    clear: both;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
}

.qr-code-wrapper > p {
    margin: 24px auto;
    width: 412px;
}

.qr-code-stores-wrapper > a:first-child {
    margin-right: 32px;
}
