.counter {
    border-radius: 8px;
    background-color: rgba(0, 110, 250, 0.2);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #006efa;
    padding: 8px;
    display: inline;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
}
