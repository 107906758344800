.my-media-action-bar-controls {
    width: 100%;
}
@media (min-width: 768px) {
    .my-media-action-bar-controls__container > div {
        min-width: 125px;
        padding: 0 5px;
    }
}
@media (max-width: 767px) {
    .react-action-dropdown.my-media-action-bar-controls__add .dropdown-menu {
        left: initial;
        right: 0;
    }
}

.my-media-action-bar-controls__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.my-media-action-bar-controls__add {
    margin-left: 10px;
}
button.btn.my-media-action-bar-controls__add__btn {
    padding-right: 8px;
}

.my-media-action-bar-controls .radio-button {
    margin-left: 10px;
}

.my-media-action-bar-controls__container .btn-group > .dropdown-menu {
    right: 0;
    left: auto;
}

@media (max-width: 1200px) {
    .my-media-action-bar-controls__container > div {
        padding: 5px 0;
    }
}

.my-media-action-bar-controls__container .radio-button {
    color: black;
}

.my-media-action-bar-controls__container .radio-button.active {
    color: #008297;
}

.my-media-action-bar-controls__container .radio-button:focus,
.my-media-action-bar-controls__container .radio-button:hover {
    color: #005664;
}
