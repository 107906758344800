.span6.embed-settings__right {
    margin-left: 0;
    min-height: 305px;
}

.span6.embed-settings__left {
    border-right: 1px solid #d9d9d9;
    padding-right: 14px;
    margin-right: 16px;
}

.embed-settings__left .h4 {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}

.embed-settings__player {
    position: relative;
    margin: auto;
    float: right;
}
.embed-settings__player.player-horizontal {
    width: 95%;
    height: 220px;
}
.embed-settings__player.player-vertical {
    width: 80%;
    height: 305px;
}

.form-control {
    margin-top: 25px;
}

.form-label {
    padding-top: 7px;
}

.fancy-ios-like-switch {
    margin-top: 7px;
}

.toggleLabel {
    margin-left: 16px;
    position: relative;
    top: 4px;
}

input.embedSettings__timeInput.embedSettings__timeInput {
    margin-bottom: 0;
    max-width: 50px;
    text-align: center;
    margin-right: 10px;
    padding-left: 6px;
}

.embedSettings__timeFromPlayerButton {
    padding: 3px 0 0 0;
}

.embedSettings__timeFromPlayerButton--start {
    margin-right: 25px;
}

.embed-code-text {
    padding-bottom: 20px;
}

div.autocomplete-dropdown__control {
    background-color: white;
}
