.upload__progress {
    height: 36px;
    width: 100%;
    overflow: hidden;
    background-color: #f7f7f7;
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
}
.progress__bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #92ca3f;
    background-image: -moz-linear-gradient(top, #92ca3f, #92ca3f);
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(#92ca3f),
        to(#92ca3f)
    );
    background-image: -webkit-linear-gradient(top, #92ca3f, #92ca3f);
    background-image: -o-linear-gradient(top, #92ca3f, #92ca3f);
    background-image: linear-gradient(to bottom, #92ca3f, #92ca3f);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf',endColorstr='#ff0480be',GradientType=0);
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: width 0.6s ease;
    -moz-transition: width 0.6s ease;
    -o-transition: width 0.6s ease;
    transition: width 0.6s ease;
}
.progress__upload-status {
    height: 0;
    color: black;
    font-size: 17px;
    text-align: center;
    line-height: 35px;
}
