.collage__image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.collage__container:not(.collage__container--one-image) .collage__image--0 {
    width: 50%;
}
.collage__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.35s;
    z-index: 10;
}
.collage__overlay:hover {
    transition: opacity 0.35s;
    background-color: black;
    opacity: 0.3;
}
.collage__container:not(.collage__container--one-image) .collage__image--1 {
    width: 49%;
    height: 49%;
    position: absolute;
    top: 0;
    left: 51%;
}
.collage__container:not(.collage__container--one-image) .collage__image--2,
.collage__container:not(.collage__container--one-image)
    .collage__image--2.collage__image--empty {
    position: absolute;
    top: 51%;
    width: 49%;
    height: 49%;
    left: 51%;
}

.collage__image__picture {
    height: 100%;
    width: 100%;
    max-width: none;
    max-width: initial;
    position: relative;
}

.collage {
    padding-top: 56.25%;
    width: 100%;
    position: relative;
}
.collage__wrapper {
    width: 100%;
    display: inline-block;
}
.collage__container {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.collage__container--one-image {
    display: inline-block;
}

.collage__image--empty,
.collage__container--empty {
    display: inline-block;
    background-color: #eaeaea;
}
.collage__image--empty .icon-container {
    font-size: 1.9em;
}
.collage__container--empty .icon-container,
.collage__container--one-image .icon-container {
    font-size: 4em;
}
.icon-container {
    position: absolute;
    color: #bebebe;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
}
