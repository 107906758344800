.playlist-entry-item {
    display: flex;
    align-items: center;
    width: calc(100% - 35px);
}
.playlist-entry-item__icon--right {
    margin-left: auto;
}
.playlist-entry-item__index {
    font-size: 16px;
    font-weight: bold;
    margin-right: 25px;
    margin-left: 16px;
    color: #000;
}

.playlist-entry-item__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;
}

.playlist-entry-item__content--header {
    display: flex;
}

.playlist-entry__name {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.playlist-entry__icon {
    margin-right: 8px;
    color: #666666;
    margin-top: 2px;
}

.playlist-entry__header {
    color: #008297;
    font-size: 16px;
    margin: 0;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.playlist-entry-item__content--header i {
    font-size: 16px;
}

.playlist-entry-item__content--description {
    margin: 8px 0;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 480px) {
    .playlist-entry-item__index {
        margin-right: 16px;
    }

    .playlist-entry-item__content {
        padding: 0 12px;
    }
}
