.single-video {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
}

.single-video__text-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 32px;

    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 1;
}

.single-video__text-box__header {
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.single-video__text-box__description {
    overflow: hidden;
    line-height: 21px;
    font-size: 16px;
}

.single-video__player-container {
    max-width: 750px;
    min-width: 750px;

    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 0;
}

.single-video__right-player {
    order: 0;
}

.single-video__left-player {
    order: -1;
}

.single-video__player {
    height: 0;
    position: relative;
    padding-top: 56.25%;
}

@media (max-width: 1199px) {
    .single-video__text-box__title {
        font-size: 32px !important;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .single-video__text-box__description {
        line-height: 19px;
    }

    .single-video__player-container {
        max-width: 634px;
        min-width: 634px;
    }
}

@media (max-width: 979px) {
    .single-video__player-container {
        order: 0 !important;
        width: 100%;
        max-width: initial;
        min-width: initial;
    }

    .single-video {
        flex-direction: column-reverse;
        max-height: initial;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .single-video__text-box {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0;
    }

    .single-video__text-box__headers,
    .single-video__text-box__description {
        flex: 1;
        padding: 16px 16px 24px 16px;
    }

    .single-video__text-box__headers {
        align-self: flex-start;
    }

    .single-video__text-box__description {
        align-self: flex-end;
    }
}

@media (max-width: 480px) {
    .single-video__text-box {
        padding: 16px;
    }
}
