.board-message__close-button {
    top: 0;
    right: 0;
    font-size: 18px;
    font-weight: bold;
    color: inherit;
}
.board-message {
    display: flex;
    width: 100%;
    font-size: 13px;
    box-sizing: border-box;
    padding: 5px 3px 5px 15px;
    margin: 12px 0;
    background-color: #e0edfe;
    color: #006efa;
    border-radius: 2px;
    align-items: center;
}
.board-message__text {
    display: inline-block;
}
.board-message__icon-info {
    font-size: 18px;
    margin-right: 11px;
}
.board-message__close {
    margin-left: auto;
}
