/**
 *  Registration Preview Banner css
 */

.registrationPreviewBanner {
    background-color: #222222;
    height: 50px;
    position: relative;
    top: 0;
    z-index: 2000;
    border-bottom: 1px solid white;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    padding-left: 24px;
    padding-right: 24px;
}

.registrationPreviewBanner-text {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 12px;
    border-right: 2px solid rgba(255, 255, 255, 0.5);
}

.registrationPreviewBanner-title {
    padding-left: 12px;
    color: white;
}

.registrationPreviewBanner-checkbox {
    color: white;
    margin-top: 16px;
    float: right;
}

/**
 *  tablet adjustment
 */
@media (max-width: 1024px) {
    .registrationPreviewBanner {
        height: 82px;
        display: flex;
        flex-wrap: wrap;
    }

    .registrationPreviewBanner-title {
        margin-top: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1;
    }

    .registrationPreviewBanner-checkbox {
        margin-top: 0;
        width: 100%;
        float: none;
    }
}
