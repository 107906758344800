.filterbar-bubble {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 13px;
    font-weight: bold;
    padding: 0 6px;
    margin-right: 8px;
}

.filterbar-bubble i {
    position: relative;
    top: 1px;
    margin-left: 4px;
    color: black;
}
.filterbar-bubble__btn {
    background: white none;
    border: none;
    padding: 0;
    font-size: 13px;
    vertical-align: baseline;
}
.filterbar-bubble__btn:hover {
    background-color: transparent;
}
