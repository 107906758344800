.credit-wrapper {
    width: 100%;
    padding: 24px 32px;
    float: left;
    border-radius: 4px;
    background: white;
    box-sizing: border-box;
}
.credit-status {
    float: left;
    font-family: Lato;
    box-sizing: border-box;
}
.credit-status__title {
    float: left;
    color: #4a4a4a;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.38px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.credit-status-info {
    float: left;
    width: 100%;
    font-size: 17px;
}
.credit-status-info__date {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 19px;
    font-weight: bold;
    color: black;
}
.credit-bar-chart-wrapper {
    float: left;
    box-sizing: border-box;
}
.credit-status-info__date-value {
    display: inline-block;
}
@media (max-width: 1199px) {
    .credit-status__title {
        width: 50%;
    }
    .credit-status-info {
        width: 50%;
        text-align: right;
    }
    .credit-wrapper .credit-status {
        width: 100%;
        margin-bottom: 35px;
    }
    .credit-wrapper .credit-bar-chart-wrapper {
        width: 100%;
        margin: 0;
    }
}
@media (max-width: 991px) {
    .credit-status-info {
        font-size: 14px;
    }
    .credit-status-info__date {
        font-size: 14px;
    }
}
