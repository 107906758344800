/**
 *  EntrySearchForm component css
 */

.entrySearchForm__toggle {
    font-size: 16px;
    padding-top: 1px;
}

.entrySearchForm__toggle a {
    text-decoration: none;
}
