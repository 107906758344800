/**
 *  Initial Password Form Component css
 */

.repeat-password-error {
    color: #dc430d;
    min-height: 20px;
}
.initialPassword-form {
    margin-bottom: 0;
}
.initialPassword-main-container {
    display: flex;
    flex-wrap: wrap;
}

.initialPassword-form-container {
    flex-basis: 45%;
}
.error-messages__container {
    margin-top: 32px;
    margin-left: 72px;
    flex-basis: 40%;
}

.initialPassword-form__title {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 56px;
}

form.initialPassword-form .initialPassword-form-field-label {
    float: left;
}

form.initialPassword-form
    .form-field
    input[type="password"].initialPassword-form-field__input {
    height: 48px;
    max-width: 100%;
    width: 100%;
}
.form-spacer {
    min-height: 20px;
}

.initialPassword-form-field__input--error {
    border-color: #dc430d;
    outline-color: transparent;
}

form.initialPassword-form
    input[type="password"].initialPassword-form-field__input.initialPassword-form-field__input--error {
    border-color: #dc430d;
    outline-color: transparent;
}

.initialPassword-form__btns {
    margin-top: 10px;
    text-align: center;
    flex-basis: 45%;
}

.initialPassword-form__btns--submit.btn {
    transition: background-color 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67),
        border-color 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
}

.initialPassword-form__btns--mobile {
    display: none;
}

@media (max-width: 768px) {
    .error-messages__container {
        margin: 12px auto 0;
        flex-basis: 80%;
    }
    .initialPassword-form-container {
        flex-basis: 80%;
        margin: 0 auto;
    }
    .initialPassword-form__btns--mobile {
        display: block;
        text-align: center;
        flex-basis: 80%;
        margin: 32px auto 0;
    }
    .initialPassword-form__btns--desktop {
        display: none;
    }
}

@media (max-width: 480px) {
    .error-messages__container {
        flex-basis: 90%;
    }
    .initialPassword-form-container {
        flex-basis: 90%;
    }
    .initialPassword-form__btns--mobile {
        flex-basis: 90%;
    }
}
