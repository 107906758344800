/**
 * css for the Text Select List Metadata component
 */

.textSelect__items__item {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    border-right: 1px solid #666666;
}

.textSelect__items__item:last-child {
    border-right: none;
}
