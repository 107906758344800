.MobileCategorySearchResults__wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
}

.MobileCategorySearchResults__border {
    font-size: x-large;
    font-weight: 100;
    vertical-align: middle;
    width: 10%;
    display: inline-block;
    text-align: center;
}

.MobileCategorySearchResults__galleryResults {
    vertical-align: middle;
    display: inline-block;
}

.MobileCategorySearchResults__channelResults {
    vertical-align: middle;
    display: inline-block;
}
