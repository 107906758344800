.results-entry_bottom-bar {
    text-align: right;
}

.results-entry_bottom-bar .results-entry_bottom-bar__show-all,
.results-entry_bottom-bar .results-entry_bottom-bar__show-less {
    margin-left: 30px;
    cursor: pointer;
    text-decoration: underline;
}
