.scheduling-data {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.24px;
    color: white;
}

.scheduling-data__date,
.scheduling-data__time {
    font-weight: bold;
}

.scheduling-data__icon {
    font-size: 22px;
    margin-right: 16px;
    position: relative;
    top: 2px;
}
