.load-next-page-button {
    padding: 4px 20px;
    border-color: black;
    display: block;
    border-radius: 4px;
    text-align: center;
    margin: 20px auto 0;
    background-color: #fff;
    border-width: 1px;
    box-sizing: content-box;
    box-sizing: initial;
    width: 10%;
    transition: background-color 0.2s;
}

.load-next-page-button:focus,
.load-next-page-button:hover {
    color: #333;
    text-decoration: none;
    background-color: #e6e6e6;
}
