span.btn.webcast-homepage-live-entry-label-button {
    color: #fff;
    opacity: 0.8;
    padding: 9px 16px;
    font-size: 16px;

    background-color: #000;
    margin-left: 8px;
}

.webcast-homepage-live-entry-label-button i {
    font-size: 13px;
}
