.user-page-head__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 240px;
    background-color: #000000;
}

.user-page-head__details-wrap {
    position: relative;
    top: 200px;
}

.user-page-head__details {
    display: inline-block;
}
.user-page-head__details.user-page-head__details--no-thumb {
    width: 100%;
}
.user-page-head__details .user-details__bio {
    padding-right: 32px;
}

@media (max-width: 1199px) {
    .user-page-head__img {
        height: 200px;
    }
    .user-page-head__details-wrap {
        top: 156px;
    }
}

@media (min-width: 481px) and (max-width: 980px) {
    .user-page-head__details-wrap {
        top: 200px;
    }
}
@media (max-width: 480px) {
    .user-page-head__img {
        height: 130px;
    }
    .user-page-head__details-wrap {
        top: 94px;
        margin-left: 4px;
        margin-right: 4px;
    }
}
