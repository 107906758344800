/**
 * Event Platform Playlist as a channel component css
 */

.playlistChannel__title {
    margin-bottom: 32px;
}

.playlistChannel__description {
    margin-bottom: 32px;
    margin-top: -16px;
    width: 690px;
    line-height: 1.31;
    font-size: 16px;
}

@media (max-width: 1200px) {
    .playlistChannel__description {
        width: 770px;
    }
}

@media (max-width: 979px) {
    .playlistChannel__description {
        margin-bottom: 24px;
        width: 100%;
    }
}
