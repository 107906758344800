.filter-checkbox__icon {
    text-decoration: none;
}

.filter-checkbox {
    height: 36px;
}

.filter-checkbox__label {
    text-decoration: none;
    margin-left: 11px;
    margin-bottom: 1px;
    top: -3px;
    line-height: 20px;
}

a.a__checkbox-wrapper:hover,
a.a__checkbox-wrapper:active,
a.a__checkbox-wrapper:focus {
    text-decoration: none;
}

.search-filter__items .filter-checkbox__icon--disabled .filter-checkbox__icon,
.search-filter__items .filter-checkbox__icon--disabled .filter-checkbox__label {
    color: #cccccc;
}
