h1.hostnames-main-title {
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bold;
    text-transform: capitalize;
}

.hostnames-alert {
    display: flex;
    align-items: center;
    color: #006EFA;
    background: #F4F4F4;
    border-radius: 4px;
    font-size: 14px;
    text-transform: capitalize;
}

.hostnames-alert svg  {
    margin: auto 7px auto 10px;
}

.hostnames-button-add {
    display: flex;
    align-items: center;
    background-color: #006EFA;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    padding: 4px 8px;
    text-transform: capitalize;
}

.hostnames-button-add:hover,
.hostnames-button-add:disabled {
    background-color: #4798FF;
}

.hostnames-button-add > svg {
    margin-right: 8px;
}
