.pie-chart {
    position: relative;
    float: left;
    padding: 44px 0;
}
.pie-chart__total {
    position: absolute;
    color: #666666;
    font-family: Lato;
    font-size: 36px;
    font-weight: bold;
    line-height: 44px;
}
.pie-chart__total.above {
    top: 0;
    left: 50%;
    transform: translate(-50%);
}
.pie-chart__total.below {
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}
.pie-chart__total.inside {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
    .pie-chart {
        float: none;
        float: initial;
        margin: 0 auto;
    }
}
