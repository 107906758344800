.kccs-frame {
    width: 100%;
    margin-top: 20px;
    min-height: 672px;
    /*
      * mobile make sure we simulate landscape mode
      * to get proper error in KEA.
      */
    margin-left: -15px;
    margin-right: -15px;
}
@media (min-width: 768px) and (max-width: 1200px) {
    .kccs-frame {
        min-height: 768px;
    }
}
@media (max-width: 767px) {
    .kccs-frame {
        width: 400px;
        height: 401px;
    }
}
