.description-container__entry-description,
.description-container__entry-name {
    box-sizing: border-box;
}

.description-container .description-container__entry-name {
    height: 40px;
    resize: none;
    color: black;
}
.description-container__entry-name {
    display: flex;
    justify-content: space-between;
}

.description-container .description-container__entry-description {
    min-height: 35px;
    max-height: 400px;
    height: 124px;
    resize: vertical;
    margin-top: 12px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.69;
    color: #666666;
}

.description-container .description-container__entry-description::-moz-placeholder, .description-container .tags-input-field::-moz-placeholder {
    color: #cccccc;
}

.description-container .description-container__entry-description:-ms-input-placeholder, .description-container .tags-input-field:-ms-input-placeholder {
    color: #cccccc;
}

.description-container .description-container__entry-description::placeholder,
.description-container .tags-input-field::placeholder {
    color: #cccccc;
}

.description-container .editable-text {
    width: 100%;
    padding: 8px 16px;
}

.my-playlist-details__description--edit-mode {
    flex: 1 1;
}
.my-playlist-details--edit-mode {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 48px;
    margin-bottom: 24px;
}
