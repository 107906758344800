.upcoming-webcast-card {
    position: relative;
    border-radius: 4px;
    border: solid 1px #cccccc;
    color: #666666;
    transition: background-color 0.5s ease;
    font-size: 13px;
}

.upcoming-webcast-card:hover {
    background-color: #ebebeb;
}

.upcoming-webcast-card__link,
.upcoming-webcast-card__link:hover,
.upcoming-webcast-card__link:focus,
.upcoming-webcast-card__link:active {
    text-decoration: none;
}

.upcoming-webcast-card__cell {
    vertical-align: top;
}

.upcoming-webcast__date {
    padding: 16px;
    width: 58px;
    color: #9e9e9e;
}

.upcoming-webcast__date-day-number {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: bold;
}

.upcoming-webcast__date-month {
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    font-weight: bold;
    color: #ababab;
}

.upcoming-webcast__details {
    padding: 16px;
}

.upcoming-webcast__details-time {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    color: black;
    margin: 0;
}

.upcoming-webcast__details-name {
    font-size: 18px;
    line-height: 22px;
    color: black;
    margin: 5px 0 0;
}

.upcoming-webcast__details-description {
    line-height: 21px;
    margin: 4px 0 0;
}

.upcoming-webcast__details-presenters {
    margin: 18px 0 0;
}

.upcoming-webcast__thumbnail {
    width: 270px;
    height: 159px;
}

/* tablet portrait / phone */
@media (max-width: 979px) {
    .upcoming-webcast__thumbnail {
        /* !important - to override inline style of FlexItem */
        display: none !important;
    }
}

/* desktop / tablet landscape */
@media (min-width: 980px) {
    .upcoming-webcast__details-presenters {
        position: absolute;
        bottom: 16px;
    }
}

/* phone portrait / landscape */
@media (max-width: 667px) {
    .upcoming-webcast-card {
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-color: #979797;
    }

    .upcoming-webcast-card-container
        + .upcoming-webcast-card-container
        .upcoming-webcast-card {
        border-top: 0;
    }

    .upcoming-webcast__date {
        width: 50px;
    }

    .upcoming-webcast__date,
    .upcoming-webcast__details {
        padding-left: 0;
        padding-right: 0;
    }

    .upcoming-webcast__date-day-number {
        font-size: 24px;
        letter-spacing: 0.8px;
    }

    .upcoming-webcast__date-month {
        font-size: 13px;
        line-height: 16px;
    }
}
