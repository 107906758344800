.kms-icon {
    cursor: default;
}

/* not BEM, but needed to override bootstrap/kms css */
.nav li.dropdown button.dropdown-toggle.DropdownFilter__toggle {
    font-weight: 300 !important;
}

@media (max-width: 480px) {
    .nav.filter-bar__filters
        .dropdown.DropdownFilter
        button.dropdown-toggle.DropdownFilter__toggle {
        font-size: 15px;
    }

    li.dropdown.DropdownFilter button.dropdown-toggle.DropdownFilter__toggle {
        font-size: 15px;
    }

    .DropdownFilter .dropdown-menu > li > a {
        font-size: 13px;
    }
}

.nav > li > button.DropdownFilter__toggle:hover,
.nav > li > button.DropdownFilter__toggle:focus {
    background-color: transparent;
}

#wrap #filterBarWrap:not(.navbar) .nav > li > button.DropdownFilter__toggle {
    padding: 7px 25px 10px 5px;
}

.DropdownFilter__toggle {
    font-size: 17px;
    letter-spacing: 1px;
    padding: 7px 25px 10px 5px;
}

.btn-icon .searchForm_icon {
    margin-left: 0;
}

.searchForm_icon--info {
    position: relative;
    top: 1px;
}

.btn-icon {
    background: none;
    border: none;
    padding: 0;
    margin: 0 16px;
}
.btn-icon:visited {
    text-decoration: none;
}

/**
 * Button component css
 */

.button {
    position: relative;
}

.button--transparent {
    background: none;
    border: none;
}

/**
 *  primary processing button - change icon color to white
 */
.btn-primary .v2ui-Spinner-icon .path1:before,
.btn-primary .v2ui-Spinner-icon .path2:before {
    color: white;
}

.btn i.button__spinner {
    position: absolute;
    right: 50%;
    margin-right: -12px;
    font-size: 24px;
    top: 50%;
    margin-top: -12px;
}

.btn.btn-large i.button__spinner {
    margin-top: -12px;
}

.button__content--proecessing {
    display: none;
}

.button--reset-styles {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    border-spacing: 0;
    list-style: none outside none;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
}
.button--reset-styles::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/**
 *  SearchForm css
 */

.searchForm {
    margin-bottom: 6px;
}

input.searchForm__text::-ms-clear {
    display: none;
}

input.searchForm__text[type="text"] {
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    border: none;
    box-shadow: none;
    border-left: 1px solid;
    padding-left: 10px;
    padding-top: 0;
    padding-bottom: 0;
    width: 90%;
    height: 20px;
}

.searchForm__prepend {
    border-bottom: 1px solid #ebebeb;
    width: 100%;
    padding-bottom: 11px;
}

.searchForm.noBorder .searchForm__prepend {
    border-bottom: none;
}

.searchForm .input-append .add-on,
.searchForm .input-prepend .add-on {
    font-size: 16px;
}

.searchForm_icon {
    text-decoration: none;
    margin-left: 16px;
    bottom: 1px;
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
}

.searchForm_icon [class^="v2ui-"],
.searchForm_icon .icon-search {
    display: inline-block !important;
}

.searchForm_icon:hover {
    text-decoration: none;
}

/**
 * RecentSearch component css
 */

.recentSearch {
    outline: none;
}

.recentSeatch__icon,
.recentSeatch__removed {
    vertical-align: text-top;
    margin-right: 18px;
    margin-left: 18px;
    color: #cccccc;
    font-size: 16px;
}

.recentSearch__remove .btn.btn-link {
    padding: 0;
    font-size: 12px;
    margin-right: 16px;
    text-decoration: none;
    outline: none;
    border: none !important;
    color: #cccccc;
    text-transform: none;
}

.recentSearch__remove .btn.btn-link:active,
.recentSearch__remove .btn.btn-link:hover,
.recentSearch__remove .btn.btn-link:focus {
    border: none !important;
    color: #999999;
}

/**
 * Search Suggestions component css
 */

.searchSuggestions {
    position: relative;
    top: -10px;
    margin-left: 38px;
    background-color: white;
}

.searchSuggestions__list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 4px;
    padding-bottom: 4px;

    position: absolute;
    width: 100%;
    z-index: 1000;

    border: 1px solid #cccccc;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.searchSuggestions__suggestion {
    padding-bottom: 8px;
    padding-top: 8px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    outline: none;
    background-color: white;
    font-size: 14px;
    font-weight: normal;
}

.searchSuggestions__suggestion.active {
    background-color: #f9f9f9;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}

/**
 * 	FilterBar component css
 */

.filterBar__controls {
    width: 100%;
}

.filterBar__pre {
    margin-right: 24px;
}

.filterBar__controls-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.filterBar__filters {
    flex: 1 1;
}

.filterBar {
    margin-top: 8px;
    margin-bottom: 10px;
}
.filterBar__description {
    padding-top: 8px;
    font-size: 16px;
    height: 36px;
}
@media (max-width: 767px) {
    .filterBar {
        margin-top: 0;
    }
}

.filterBar .DropdownFilter {
    float: left;
}

.filterBar__dropdowns {
    margin-bottom: 0;
    float: left;
}

@media (max-width: 480px) {
    .filterBar__dropdowns {
        width: 100%;
        margin-top: 13px;
    }
}
/* adjust for kms css - untill we stop using the shrink wrapper classes */
#wrap #filterBarWrap .filterBar .toggleButton {
    margin-left: 0;
}

#wrap #filterBarWrap:not(.navbar) .filterBar .btn {
    margin-top: 0;
}

#wrap #filterBarWrap .filterBar .shrink-container__button.btn {
    margin-left: 0;
}

.bubble {
    font-size: 12px;
    margin-right: 14px;
    display: inline-block;
}

.bubble__a {
    font-size: 10px;
    vertical-align: 0;
}

.bubble_label {
    margin-left: 5px;
    font-size: 12px;
    padding-right: 3px;
}
.bubble__icon {
    padding-left: 4px;
    font-size: 10px;
    vertical-align: middle;
}

.bubble__a,
.bubble__icon {
    text-decoration: none;
    display: inline-block;
}

.bubbles {
    overflow: hidden;
    min-height: 31px;
    display: flex;
    align-items: center;
    padding: 0;
    transition: min-height 0.2s 0.1s;
}

@media (max-width: 767px) {
    .bubbles:empty {
        min-height: 0;
    }
}

.toggleButton.toggleButton--disabled {
    color: #e1e1e1;
    transition: opacity 0.3ms;
}
.toggleButton--disabled .toggleButton__icon {
    cursor: default;
}
.toggleButton {
    opacity: 1;
}
.toggleButton__icon {
    cursor: pointer;
}

.search-filters__more-filters {
    display: inline-block;
    border-radius: 5px;
    padding: 10px;
    float: right;
}

.filters__clear-all {
    cursor: pointer;
}

.search-filters__more-filters__button-container {
    width: 20px;
    display: inline-block;
}
.filters__button-container__right {
    text-align: left;
}

.search-filters__arrow {
    cursor: pointer;
    text-align: center;
    border: 0;
    padding: 0;
    background: none;
    font-size: 16px;
    margin-top: -2px;
}
.search-filters__arrow--previous {
    padding-right: 10px;
    padding-left: 4px;
}

.search-filters__arrow--next {
    padding: 0 10px;
}

.search-filter-group {
    height: auto;
    transition: max-height 0.3s;
    display: inline-block;
    vertical-align: top;
}

.modal .row-fluid [class*="span"] .search-filter-group {
    display: block;
}

@media (max-width: 767px) {
    .modal .modal-mode.search-filter-group {
        transition: max-height 0.7s;
        height: auto;
        padding: 15px;
        margin-left: 1px;
        border-bottom: solid 2px;
        /**
        Two times the padding + 2px for the border.
         */
        width: calc(100% - 40px);
        line-height: normal;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.filter-modal__accordion-toggle.modal-mode {
    margin-bottom: 20px;
    margin-top: 19px;
    background-color: transparent;
    border: none;
}

.search-filters-group__title--desktop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
}

.search-filters__modal--spacer {
    padding-top: 10px;
}

.modal .search-filters-group__title--desktop {
    display: none;
}

.filter-modal__accordion-toggle {
    width: 100%;
    padding: 0;
}

.filter-checkbox__icon {
    text-decoration: none;
}

.filter-checkbox {
    height: 36px;
}

.filter-checkbox__label {
    text-decoration: none;
    margin-left: 11px;
    margin-bottom: 1px;
    top: -3px;
    line-height: 20px;
}

a.a__checkbox-wrapper:hover,
a.a__checkbox-wrapper:active,
a.a__checkbox-wrapper:focus {
    text-decoration: none;
}

.search-filter__items .filter-checkbox__icon--disabled .filter-checkbox__icon,
.search-filter__items .filter-checkbox__icon--disabled .filter-checkbox__label {
    color: #cccccc;
}

.filter-rangepicker__label {
    margin-left: 11px;
    margin-bottom: 1px;
    top: -3px;
    cursor: pointer;
    float: left;
    position: relative;
}

.filter-rangepicker {
    width: 90%;
    display: table;
    height: 100px;
}

.filter-rangepicker .form .input-range {
    width: 90%;
    margin-left: auto;
    top: -5px;
}

.input-range__track.input-range__track--background .input-range__track {
    height: 2px;
    margin-bottom: 2px;
}

.input-range__track.input-range__track--background {
    height: 2px;
}

.rangepicker-checkbox {
    margin-bottom: 45px;
}

.filter-checkbox__icon {
    cursor: pointer;
    float: left;
    text-decoration: none;
}

.filter-rangepicker.selected .form .input-range__slider {
    border: 0px;
}

div.input-range__slider {
    border-radius: 100%;
    border: 0px !important;
    display: block;
    height: 9px;
    margin-left: -0.52rem;
    margin-top: -0.45rem;
    outline: none;
    position: absolute;
    top: 50%;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    width: 9px;
}

.input-range__slider-container
    .input-range__label
    .input-range__label-container {
    padding: 3px;
    border-radius: 3px;
    bottom: 7px;
    font-size: 10px;
}

.input-range__slider-container .input-range__label--value {
    top: 46px;
}

/* LEFT tooltip */
.input-range__slider-container:first-of-type
    .input-range__label
    .input-range__label-container {
    margin-right: 20px;
}
.input-range__slider-container:first-of-type
    .input-range__label
    .input-range__label-container:after {
    left: 85%;
}
/* RIGHT tooltip */
.input-range__slider-container:last-of-type .input-range__label {
    margin-left: 4px;
}
.input-range__slider-container:last-of-type
    .input-range__label
    .input-range__label-container:after {
    left: 20%;
}
.filter-rangepicker .form {
}
.filter-rangepicker.selected .form {
    display: table;
    width: 85%;
    margin-left: 10px;
}
.filter-rangepicker .form {
    display: none;
}

.filter-rangepicker.selected:hover .input-range__label-container {
    opacity: 1;
}
.input-range__label-container {
    opacity: 0;
    transition: 0.5s;
    top: -40px;
}

.form .input-range__label--min,
.form .input-range__label--max {
    bottom: -1.5rem;
}

.date-input input::-ms-clear {
    display: none;
}

.date-input__icon {
    position: absolute;
    right: 10px;
    top: 8px;
    cursor: pointer;
    font-size: 18px;
}

.date-input__input[readonly] {
    background: transparent;
    cursor: auto;
}

.react-datepicker__input-container input {
    padding-top: 6px;
    padding-bottom: 6px;
}

.filter-datepicker__datepicker {
    margin-top: 14px;
}

.filter-datepicker__native-date {
    width: 170px;
}

.react-datepicker-wrapper input {
    max-width: 130px;
}

/* override the default z-index of react date picker */
.react-datepicker-popper {
    z-index: 2000;
}

.free-text-filter {
    padding-left: 2px;
}

.free-text-filter__input {
    max-width: 130px;
    height: 36px;
    margin-top: 16px;
    box-shadow: none;
}

.rc-tree-switcher {
    visibility: hidden;
    float: right;
}
.rc-tree .parent > span.rc-tree-switcher:after {
    font-size: 14px;
    visibility: visible;
}
.rc-tree-checkbox {
    visibility: hidden;
}
.rc-tree-checkbox:before {
    visibility: visible;
}
.rc-tree-title {
    margin-left: 11px;
}
.rc-tree {
    padding: 0 !important;
}
.rc-tree-node-content-wrapper {
    vertical-align: middle;
}
.rc-tree li .rc-tree-node-content-wrapper {
    padding-bottom: 16px;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 1336px) and (max-width: 1600px) {
    .rc-tree li .rc-tree-node-content-wrapper {
        max-width: 105px;
    }
}

span.rc-tree-checkbox:not(.rc-tree-checkbox-checked):before {
    font-family: "v2ui";
}
.rc-tree .parent > span.rc-tree-switcher_open:after {
    font-family: "FontAwesome";
}
.rc-tree .parent > span.rc-tree-switcher_close:after {
    font-family: "FontAwesome";
}
span.rc-tree-checkbox-checked:before {
    font-family: "v2ui";
}

.filter-dropdown-container {
    height: 370px;
    max-width: 150px;
}

.autocomplete-dropdown-container input {
    margin-bottom: 0;
}

.dropdown-option--selected {
    margin-right: 8px;
}

.autocomplete-dropdown-container .value-container {
    padding-left: 16px;
    padding-right: 16px;
}

.autocomplete-dropdown-container .dropdown-option {
    padding-left: 16px;
    padding-right: 16px;
}

.autocomplete-dropdown-container .dropdown-option > i {
    float: right;
}

.autocomplete-dropdown__input input {
    box-shadow: none;
}
.autocomplete-dropdown__input input:focus {
    box-shadow: none;
}

.value-container__icon {
    position: absolute;
    right: 0;
}

.search-modal-body .modal-body {
    max-height: none;
}

.search-modal-body .modal-body.filters__modal {
    overflow: auto;
    max-height: 70vh;
    padding: 0;
}

.search-filter-group:first-of-type {
    margin-left: 0;
}

.search-filters-wrapper {
    padding-left: 0px;
    padding-right: 0px;
}

.search-filter-group__filter-items-container {
    padding-top: 10px;
}

.search-filter-group .accordion-body {
    padding-left: 1px;
}
.carousel-mode .search-filter-group:first-of-type {
    margin-left: 0;
}

.filter-modal__accordion-header {
    font-weight: bold;
    letter-spacing: 0.5px;
}

.filter-modal__accordion-body {
    margin-top: 8px;
}

.SearchFormWrapper {
    position: relative;
}

/* this removes a blank line on FF */
.SearchFormWrapper--text-search-container.row-fluid::before {
    content: none;
}

.SearchFormWrapper .accordion-body.collapse .modal-body {
    padding-top: 0;
    padding-bottom: 0;
}

.search-modal-body .modal-body {
    padding-left: 1px;
}

.results-preview__category {
    margin-bottom: 8px;
    display: block;
}

.results-preview__category:first-child {
    display: inline-block;
}

.entryStatistics .entryStatistics__stat__icon {
    margin-left: 0;
    font-size: 14px;
    margin-right: 12px;
    position: relative;
    top: 2px;
}

.entryStatistics__stat {
    margin-right: 20px;
    font-size: 12px;
    position: relative;
    line-height: normal;
    display: inline-block; /* keep stat icon and number together */
    color: #999999;
}

.entryStatistics__link:hover {
    color: #666666;
}

/**
 * Checkbox Component css
 */

.checkbox-custom-wrapper {
    display: inline-block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 20px;
}

.checkbox-custom {
    opacity: 0;
    position: absolute;
}
.checkbox-custom,
.checkbox-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 5px 5px 0;
    cursor: pointer;
    line-height: 14px;
}
.checkbox-custom-label {
    position: relative;
    padding-left: 1px;
}

.checkbox-custom-icon {
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: middle;
}

.checkbox-custom-icon--active {
    color: #008297;
}

.checkbox-custom-icon--disabled {
    color: #9e9d9d;
    cursor: not-allowed;
}

.checkbox-custom-label--disabled {
    color: #9e9d9d;
    cursor: not-allowed;
}

/*
 * Fhe following modificator classes are supported in ReactTable and SelectionTable components:
 * - "-resizable-header":
 *      Aligns non-resizable column styles with the resizable column styles.
 *      Should be applied to the component:
 *      <ReactTable className={"... -resizable-header"} ...
 * - "-column-separators":
 *      Adds separator lines between the columns, add padding between the columns.
 *      Should be applied to the component:
 *      <ReactTable className={"... -column-separators"} ...
 * - "rt-column-center":
 *      Aligns cell contents to the center (horizontally) in the column.
 *      Should be applied both to the header and body cells in the column:
 *      const column = {
 *        ...,
 *        headerClassName: "rt-column-center",
 *        className: "rt-column-center"
 *      }
 */

.ReactTable {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
}

.ReactTable .rt-tbody {
    overflow: hidden;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-top: 1px solid #eaeaea;
    border-bottom: none;
}

.ReactTable.-striped .rt-tr.-odd {
    background: #f8f8f8;
}
.ReactTable.-striped .rt-tr.-even {
    background: #ffffff;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td {
    border-right: none;
}

.ReactTable.-resizable-header .rt-th:not(.rt-resizable-header),
.rt-resizable-header-content {
    text-transform: uppercase;
    text-align: left;
    padding: 19px 11px;
    font-size: 13px;
    font-weight: bold;
}

.ReactTable.-resizable-header .rt-th:not(.rt-resizable-header) {
    padding: 26px 21px 26px 0;
}

.ReactTable .rt-td {
    padding: 20px 11px;
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: hidden;
}

.ReactTable.-column-separators .rt-thead .rt-th {
    border-right: 1px solid #eaeaea;
}

.ReactTable.-column-separators .rt-thead .rt-th:last-child {
    border-right: none;
}

.ReactTable .rt-tbody .rt-td.rt-column-center,
.ReactTable .rt-thead .rt-th.rt-column-center {
    text-align: center;
}

.rt-th {
    position: relative;
}
.sort-arrow {
    position: absolute;
    top: 24px;
    right: 12px;
}

.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column,
.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    /* the checkbox component has a right margin - add the same from the left */
    margin-left: 10px;
    /* cancel "resizable" and "column line" table styles for the checkbox column */
    padding: 0px;
    margin: auto;
}

.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    /* cancel "column line" table styles for the checkbox column */
    border-right: none;
    text-align: center;
}

.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column
    + .rt-td,
.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th,
.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th
    .rt-resizable-header-content {
    /* cancel "column line" table styles for the second column */
    padding-left: 0;
}

.ReactTable.-selection-table
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    padding: 18px 0 !important;
    margin: 4px 0 auto 5px !important;
}

.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    padding: 0 0 2px 1px;
    margin: auto 0 auto 10px;
}

/**
 * DropdownWithCheckbox css
 */

.dropdownWithCheckbox .checkbox-custom-wrapper {
    display: inline-block;
    margin-top: 16px;
}

.user-selection-icon-container {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    padding: 8px 6px 8px 8px;
}

.user-selection-icon-container--group {
    background-color: #b9df90;
}

.user-selection-icon-container--user {
    background-color: #ebebeb;
    color: #666666;
}

.user-selection-option__label {
    display: inline-block;
    margin-left: 10px;
}

.user-selection-help-icon-container {
    width: 1%;
}

.user-selection-box {
    width: 99%;
}

.user-selection-multi-value--new {
    border: 1px solid #dc430d;
    border-radius: 18px;
}

.user-selection-multi-value {
    margin-top: 8px;
}

.registration__title {
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 2px;
}

.registration-settings__header {
    padding: 0 24px;
}

.registration__subtext {
    margin-top: 8px;
}

.registration-settings-accordion {
    padding-top: 40px;
}

.registration__accordion-toggle {
    height: 68px;
    width: 100%;
    padding: 0 24px;
    background-color: #ffffff;
    border-color: #eaeaea;
    border-style: solid none;
    border-width: 1px 0;
    color: #666666;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.43px;
    line-height: 26px;
}

.registration-form__section {
    background-color: #f1f1f1;
    padding: 40px 24px;
}

.registration__help-text {
    font-size: 12px;
    line-height: 17px;
    color: #666666;
    margin-top: 8px;
}

.registration__help-text.invalid {
    color: #dc430d;
}

.registration__form-element-with-label {
    margin-top: 28px;
    max-width: 816px;
}

.registration__form-element-with-label:first-child {
    margin-top: 0;
}

.registration__control-label {
    color: #666666;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
}

.registration__actions {
    margin-top: 40px;
    padding: 0 24px;
}

.registration__actions .btn {
    height: 36px;
    font-size: 16px;
}

.registration__btn-preview {
    margin: 0 16px;
}

.registration__btn-enable {
    float: right;
}

.registration-settings-accordion .accordion__container:last-child {
    margin-bottom: 40px;
}

.registration-section-title {
    margin-left: 12px;
}

.registration-settings-accordion .toggleButton__icon {
    font-size: 16px;
    position: relative;
    top: 2px;
}

.registration-section-icon {
    font-size: 16px;
    position: relative;
    top: 2px;
}

.registration-section-icon.registration-section-icon--bold {
    font-weight: bold;
}

input[type="text"].registration_entry {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: 0;
    width: 100%;
    height: 36px;
}

input[type="text"].registration_entry.invalid {
    border: 1px solid #dc430d;
}

.share-page__copy-wrap {
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
}

.share-page__copy-text {
    width: 800px;
}

.copy-text__input.copy-text__input {
    background-color: white;
}

.copy-text {
    display: table;
}

.copy-text__input-container {
    margin-bottom: 40px;
    width: 100%;
    display: table-cell;
}

.copy-text__input {
    width: 100%;
    border-radius: 3px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
    color: #666666;
    padding: 1px 16px;
    overflow-y: scroll;
    resize: none;
    box-sizing: border-box;
    height: 36px;
}
.copy-text__input::-moz-selection {
    color: #666666;
    background: rgba(0, 130, 151, 0.2);
}
.copy-text__input::selection {
    color: #666666;
    background: rgba(0, 130, 151, 0.2);
}

.copy-text__button-container {
    text-align: right;
    display: table-cell;
    padding-left: 16px;
    vertical-align: top;
}

button.btn-link.copy-text__button {
    white-space: nowrap;
    padding-right: 0;
    padding-left: 0;
}

.settings__items-container {
    margin-top: 24px;
}

.registration-page-component__checkbox + .checkbox-custom-label:before {
    font-size: 16px;
    position: relative;
    top: 2px;
}

.registration-page-component-item {
    margin-top: 14px;
}

.registration-page-component-item:first-child {
    margin-top: 0;
}

.registration-help-icon {
    font-size: 16px;
}

.page-components-item__popover-body {
    background-color: #333333;
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 4px;
    max-width: 250px;
}

.registration-help-icon__button {
    padding: 0;
    margin-left: 6px;
    line-height: 1;
}

.progressBar-container {
    height: 4px;
    background-color: #cccccc;
    margin-bottom: 4px;
}

.progressBar__inner {
    float: left;
    height: 4px;
}

.progressBar--complete {
    background-color: #8dc63f;
}

.progressBar--uploading {
    background-color: #008297;
}

.csv-upload-button {
    padding-left: 0;
}

.csv-upload-button--replace {
    margin-left: 9px;
}

.csv-upload-button--retry {
    margin-left: 4px;
    padding: 0 0 2px 0;
}

.uploaded-file-name {
    font-size: 14px;
    padding: 5px 8px;
    font-weight: 300;
}

.action-button-icon {
    color: #666;
    margin-left: 9px;
}

.manual-emails__errors {
    color: #dc430d;
}

.manual-emails-status-bar {
    margin-top: 8px;
    font-size: 12px;
}

.manual-emails__count--limit-reached {
    color: #dc430d;
}

.registration__form-email-validation-row {
    margin-top: 20px;
}

.message {
    position: relative;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
}

.message__title {
    display: inline-block;
    margin-right: 16px;
    font-weight: bold;
    vertical-align: top;
}
.message__text {
    display: inline-block;
    max-width: 90%;
}
.message__close-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    font-weight: bold;
    padding: 13px 24px;
    color: inherit; /* override user-agent (browser) default button color (black) */
}
@media (max-width: 767px) {
    .message__close-button {
        padding-right: 12px;
    }
}

.message.alert {
    line-height: inherit;
}

.no-registration-container {
    text-align: center;
}

h1.no-registration_title {
    color: #666666;
    font-size: 22px;
    padding-bottom: 12px;
}

.no-registration_text {
    padding-bottom: 30px;
    color: #999999;
    max-width: 722px;
    display: inline-block;
}

.no-registration_image {
    padding-bottom: 30px;
}

.no-registration_btn {
    font-size: 16px;
    height: 36px;
}

.onboarding {
    width: 384px;
    height: 150px;
    -o-object-fit: contain;
       object-fit: contain;
}

.no-results__container {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: 0;
}

.no-results__no-results-image {
    text-align: center;
    background-color: white;
    border-radius: 100%;
    min-width: 418px;
    padding: 50px;
    margin-top: -50px;
}

.no-results__image {
    margin-bottom: 24px;
}

.no-results__title {
    font-size: 24px;
    line-height: 27px;
    margin: 16px 0;
}

.no-results__description {
    font-size: 16px;
    line-height: 24px;
}

.no-results__body {
    margin-top: 24px;
}

@media (max-width: 768px) {
    .no-results__container {
        margin-top: 47px;
    }
}

@media (max-width: 667px) {
    .no-results__container {
        margin-top: 31px;
    }
    .no-results__image {
        width: 80%;
        max-width: 319px;
    }
    .no-results__title {
        font-size: 18px;
    }
    .no-results__description {
        font-size: 13px;
    }
}

.hero-unit-component {
    display: block;
    position: relative;
    width: 100%;
    height: 620px;
    color: #fff !important;
}

@media (max-width: 1199px) {
    .hero-unit-component {
        height: 580px;
    }
}

@media (max-width: 979px) {
    .hero-unit-component {
        height: 480px;
    }
}

.hero-unit_title-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 88px;
}

.eventsplatform .hero-unit_title,
.hero-unit_title {
    color: #ffffff;
}
@media (max-width: 480px) {
    .eventsplatform .hero-unit_title,
    .hero-unit_title {
        font-size: 32px;
    }
}

.hero-unit-component__backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 0%, #000);
}

.hero-unit-component__content {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
}

.btn.btn-borderless-eventplatform.hero-unit-component__content__live-label {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    color: inherit;
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    letter-spacing: 1px;
}

.hero-unit-component__content__title,
.hero-unit-component__content__description,
.hero-unit-component__content__presenters {
    max-width: 600px;
}

.hero-unit-component__content__title.hero-unit-component__content__title.hero-unit-component__content__title {
    line-height: normal;
    margin-top: 19px;
    color: #fff !important;
}

.hero-unit-component__content__description {
    margin-top: 16px;
    font-size: 16px;
}

.hero-unit-component__content__presenters {
    margin-top: 16px;
}

.hero-unit-component__content__presenters .event-presenter__name {
    color: #fff;
}

.hero-unit-component__content__presenters .event-presenter__title {
    color: #ccc;
}

button.btn.hero-unit-component__content__join-button,
button.btn.hero-unit-component__content__toggle-play-button {
    margin-top: 24px;
}

button.btn.hero-unit-component__content__join-button {
    min-width: 209px;
    font-size: 16px;
}

.eventsplatform
    button.btn.hero-unit-component__content__join-button[type="button"]:hover {
    color: #ffffff;
}

button.btn.hero-unit-component__content__toggle-play-button {
    background: none !important;
    border: none !important;
    float: right;
    color: #fff;
    font-size: 14px;
}

.hero-unit-component__lines {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    min-width: 220px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Duplicate the class to override the global re-invent styles */
button.hero-unit-component__lines__line.hero-unit-component__lines__line.hero-unit-component__lines__line.hero-unit-component__lines__line {
    position: relative;
    width: 100%;
    border-radius: 2px;
    margin: 0;
    padding: 4px 5px;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.hero-unit-component__lines__line__inner {
    position: relative;
    height: 4px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.3);
}

.hero-unit-component__lines__line:hover
    .hero-unit-component__lines__line__inner,
.hero-unit-component__lines__line:active
    .hero-unit-component__lines__line__inner,
.hero-unit-component__lines__line:focus
    .hero-unit-component__lines__line__inner {
    background: rgba(255, 255, 255, 0.5);
}

.hero-unit-component__lines__line__progress {
    height: 100%;
    border-radius: inherit;
    background: #fff;
    transition: width 500ms linear;
}

@media (max-width: 979px) {
    .hero-unit-component__content__title.hero-unit-component__content__title.hero-unit-component__content__title {
        font-size: 32px;
    }
}

@media (max-width: 480px) {
    button.btn.hero-unit-component__content__join-button {
        min-width: 171px;
    }
}

.event-presenters-list {
    display: flex;
}
.event-presenters-list__popup {
    background-color: white;
    width: 260px;
    padding: 16px 16px 8px;
    border-radius: 4px;
}
.event-presenters-list__popup .in-popover {
    max-width: none;
    max-width: initial;
    padding: 0 0 8px;
}

.event-presenter {
    display: flex;
    padding-right: 16px;
}
@media (min-width: 481px) {
    .event-presenter {
        max-width: 40%;
    }
}
.event-presenter__details {
    padding-left: 8px;
}
.event-presenter__thumbnail {
    border-radius: 8px;
    width: 36px;
    height: 36px;
}
.event-presenter__name {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    margin: 0;
}
.event-presenter__title {
    color: #666666;
    font-size: 13px;
    line-height: 21px;
    margin: 0;
}

/**
 *  Truncate Manager css
 */

.truncateManager {
    white-space: normal;
}

.truncateManager__more,
.truncateManager__less-link {
    cursor: pointer;
    font-size: 12px;
}

.truncateManager__less {
    width: 100%;
}

.truncateManager__less-link {
    float: right;
    text-decoration: underline;
}

.counter {
    border-radius: 8px;
    background-color: rgba(0, 110, 250, 0.2);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    color: #006efa;
    padding: 8px;
    display: inline;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
}

.entry-player-preview,
.entry-player-preview__thumbnail,
.entry-player-preview__player .kaltura-player {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.entry-player-preview__thumbnail {
    transition: opacity 500ms ease-out;
    will-change: opacity;
}

.entry-player-preview__thumbnail--hidden {
    opacity: 0;
}

.entry-player-preview__player {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    transform: translateZ(0);
}

.entry-player-preview__player .playkit-player-gui,
.entry-player-preview__player .playkit-loading-backdrop,
.entry-player-preview__player .playkit-pre-playback-play-overlay {
    display: none;
}

.entry-player-preview__player .playkit-engine-html5 {
    -o-object-fit: cover;
       object-fit: cover;
}

/* IE10+ object-fit: cover alternative */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .entry-player-preview__player .playkit-engine-html5 {
        top: 50%;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
    }
}

/* Edge 12+ object-fit: cover alternative */
@supports (-ms-ime-align: auto) {
    .entry-player-preview__player .playkit-engine-html5 {
        top: 50%;
        width: 100%;
        height: auto;
        transform: translateY(-50%);
    }
}

.entry-player-preview__player .playkit-poster {
    background-size: cover;
}

.entry-thumbnail-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: #000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.entry-thumbnail-background--blurred {
    filter: blur(20px);
    left: -20px;
    right: -20px;
    bottom: -20px;
}

.text-only__wrapper {
    display: flex;
    justify-content: center;
}

.text-only {
    display: inline-block;
    font-size: 24px;
    line-height: 1.42;
    text-align: center;
}

.text-only a {
    font-size: 24px;
}

.html-only__wrapper {
    display: flex;
    justify-content: center;
}

.html-only {
    display: inline-block;
    overflow-y: auto;
}

/**
 *  kaltura player (v2 + v7) css
 */

.kaltura-player {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.single-video {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
}

.single-video__text-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 32px;

    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 1;
}

.single-video__text-box__header {
    letter-spacing: 2px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
}

.single-video__text-box__description {
    overflow: hidden;
    line-height: 21px;
    font-size: 16px;
}

.single-video__player-container {
    max-width: 750px;
    min-width: 750px;

    flex-basis: 1px;
    flex-grow: 1;
    flex-shrink: 0;
}

.single-video__right-player {
    order: 0;
}

.single-video__left-player {
    order: -1;
}

.single-video__player {
    height: 0;
    position: relative;
    padding-top: 56.25%;
}

@media (max-width: 1199px) {
    .single-video__text-box__title {
        font-size: 32px !important;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .single-video__text-box__description {
        line-height: 19px;
    }

    .single-video__player-container {
        max-width: 634px;
        min-width: 634px;
    }
}

@media (max-width: 979px) {
    .single-video__player-container {
        order: 0 !important;
        width: 100%;
        max-width: none;
        max-width: initial;
        min-width: 0;
        min-width: initial;
    }

    .single-video {
        flex-direction: column-reverse;
        max-height: none;
        max-height: initial;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .single-video__text-box {
        flex-direction: row;
        justify-content: flex-end;
        padding: 0;
    }

    .single-video__text-box__headers,
    .single-video__text-box__description {
        flex: 1 1;
        padding: 16px 16px 24px 16px;
    }

    .single-video__text-box__headers {
        align-self: flex-start;
    }

    .single-video__text-box__description {
        align-self: flex-end;
    }
}

@media (max-width: 480px) {
    .single-video__text-box {
        padding: 16px;
    }
}

.ad-images {
    display: flex;
}

.ad-images__image-container {
    width: 100%;
}

.ad-images__image-wrapper--aspect-ratio-size {
    width: 100%;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.ad-images__image-link {
    display: block;
    padding-bottom: 56.25%;
    margin-right: -4px;
    border: 2px solid transparent;
}

.ad-images__image-link:focus {
    border-color: #006efa;
}

.ad-images__image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.ad-images__image-link:focus {
    outline: none;
}

@media (max-width: 480px) {
    .ad-images {
        flex-direction: column;
    }
}

.title-line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

/* override system-width's margin: auto */
.title-line.title-line {
    margin-bottom: 16px;
}

.title-line__title {
    margin: 0;
    line-height: normal;
}

.event-list-item-tag {
    margin: 0 4px;
    max-width: 50%;
    display: inline-block;
}
.event-list-item-tag .tag {
    padding: 0 6px 0 6px;
    border: solid 1px #cccccc;
    color: #666666;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
}
@media (max-width: 767px) {
    .event-list-item-tag:first-child {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 979px) {
    .event-list-item-tag .tag {
        max-width: 120px;
    }
}

@media (min-width: 980px) {
    .event-list-item-tag .tag {
        max-width: 148px;
    }
}

.tag {
    font-size: 12px;
    display: inline-block;
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
    border: 1px solid #666666;
    color: #666666;
}

.tag:hover {
    color: #666666;
}

.event-list-item {
    display: flex;
    font-size: 14px;
    border-radius: 4px;
}

.event-list-item .dropdown-menu.pull-right {
    left: 0;
}

.event-list-item__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
    background-color: #f4f4f4;
    padding: 16px;
    min-width: 0;
}

.event-list-item__details {
    max-width: 90%;
}

.event-list-item__thumbnail {
    width: 330px;
    height: 100%;
}

.event-list-item__name {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-list-item__description {
    color: #666666;
    font-size: 14px;
    line-height: 1.29;
    margin: -4px 0 -16px;
}
.eventsplatform .event-list-item__description .truncateManager__more {
    font-size: inherit;
}
.event-list-item__join-button {
    float: right;
}
.event-list-item__status--offline {
    color: #999999;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border: 1px solid #999999;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
    padding: 0 2px;
}
.event-list-item__status--live {
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border-radius: 4px;
    display: inline-block;
    padding: 1px 3px;
    background-color: #fa374b;
}

/* region Make sure that the "actions wrapper" element will not prevent neighbor elements from mouse clicking */
.event-list-item__actions-wrapper {
    pointer-events: none;
}

.event-list-item__tags,
.event-list-item__action > * {
    pointer-events: all;
}
/* endregion */

.event-list-item__action {
    display: flex;
    padding-top: 5px;
    align-items: center;
}

.event-list-item__action button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-list-item__action button i {
    font-size: 24px;
}

.event-list-item__action .event-list-item__join-button {
    float: none;
    margin-right: 8px;
}

.event-list-item__agenda-button {
    margin-right: 8px;
}

.event-list-item
    .event-list-item__container
    button.btn.btn-danger-eventplatform[type="button"]:hover {
    background-color: #c82c3c;
    color: #ffffff;
}

.event-list-item
    .event-list-item__container
    button.event-list-item__join-button[type="button"]:hover {
    background-color: #0058c8;
    color: #ffffff;
}

.event-list-item
    .event-list-item__container
    button.btn.btn-borderless-eventplatform[type="button"]:hover {
    background-color: #cccccc;
    color: #006efa;
}

@media (max-width: 767px) {
    .event-list-item__action {
        padding-top: 16px;
    }

    .event-list-item__tags {
        padding: 16px 0 0;
    }

    .event-list-item__description {
        margin: -4px 0 0;
    }
}

@media (min-width: 768px) {
    .event-list-item__tags {
        flex-grow: 1;
        padding: 0;
        align-items: center;
    }
    .event-list-item__tags .event-list-item-tag {
        float: right;
    }
}

.event-presenters-list-no-image {
    display: flex;
    margin-bottom: 12px;
}
.event-presenter-no-image__popover {
    background-color: white;
    width: 260px;
    padding: 16px 16px 8px;
    border-radius: 4px;
    border: solid 1px #d9d9d9;
}
.event-presenter-no-image__popover .in-popover {
    max-width: none;
    max-width: initial;
    padding: 0 0 8px;
}
.event-presenter-no-image__popover .Popover-tip {
    display: none;
}
.event-presenters-list__more {
    font-weight: bold;
    max-width: 20%;
    cursor: pointer;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .event-presenters-list-no-image {
        display: block;
    }
}

.event-presenter-no-image {
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
}

.event-presenter-no-image__name {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    cursor: pointer;
}
.event-presenter-no-image__title {
    color: #666666;
    font-size: 13px;
    line-height: 21px;
    margin: 0;
}

.event-presenter-no-image__title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media (max-width: 767px) {
    .event-presenter-no-image {
        max-width: 100%;
    }
}

.event-presenters-list-no-count {
    display: flex;
    flex-direction: column;
}

.event-presenters-list-no-count .event-presenter {
    margin-bottom: 8px;
    padding-right: 0;
}

.event-presenters-list-no-count .event-presenter__details {
    width: 100%;
}

@media (min-width: 481px) {
    .event-presenter {
        max-width: 100%;
    }
}

.event-list-item-modal {
    position: absolute;
    border-radius: 8px;
    transform: translate(-50%, 0);
    max-width: 583px;
}

.event-list-item-modal.event-list-item-modal {
    width: 995px;
    margin: auto;
}

.event-list-item-modal.modal.fade.in {
    top: 80px;
    border-bottom: 155px solid transparent;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 8px;
}

.event-list-item-modal__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 2000;
    max-width: 100vw;
    overflow-x: hidden;
}

body.event-list-modal-open {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.eventsplatform .event-list-item-modal .modal-body {
    max-height: 100%;
}

.event-list-item-modal__body {
    max-height: none;
    word-break: break-word;
}

.modal .modal-body.event-list-item-modal__body {
    padding: 0;
}

.event-list-item-modal__close-button.btn.btn-link {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    z-index: 2000;
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0.4);
}

.event-list-item-modal__close-button.btn.btn-link:focus,
.event-list-item-modal__close-button.btn.btn-link:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.event-list-item-modal__close-button.btn.btn-link i {
    margin: 0;
    font-size: 24px;
    color: #ffffff;
}

.event-list-item-modal
    .event-list-item-modal__content-wrapper
    button.btn.btn-danger-eventplatform[type="button"]:hover {
    background-color: #c82c3c;
    color: #ffffff;
}
.event-list-item-modal
    .event-list-item-modal__content-wrapper
    button.event-list-item__join-button[type="button"]:hover {
    background-color: #0058c8;
    color: #ffffff;
}
.event-list-item-modal
    .event-list-item-modal__content-wrapper
    button.btn.btn-borderless-eventplatform[type="button"]:hover {
    background-color: #cccccc;
    color: #006efa;
}

.event-list-item-modal__content-wrapper {
    padding: 0;
}

.event-list-item-modal__image {
    width: 100%;
    height: 328px;
    border-radius: 8px;
    border-bottom: 8px solid transparent;
}

.event-list-item-modal__info {
    padding: 14px 32px 32px 32px;
}

.event-list-item-modal__info .event-list-item__action {
    margin-bottom: 16px;
    padding: 0;
    max-height: 30px;
}
.event-list-item-modal__info .event-list-item__action button {
    max-height: 30px;
}

.event-list-item-modal__updated-at {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 900;
}

.event-list-item-modal__name {
    font-size: 20px;
    font-weight: 900;
    margin: 8px 0 16px;
    line-height: normal;
}

.event-list-item-modal__description {
    font-size: 14px;
    line-height: 1.29;
    margin-top: 16px;
}

.event-list-item-modal__presenters {
    margin-bottom: 8px;
}

.event-list-item-modal__tags {
    margin-left: -4px;
}

.event-list-item-modal__tags .tag {
    font-size: 15px;
    line-height: 20px;
    height: 24px;
    color: #333333;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .event-list-item-modal {
        width: 945px;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .event-list-item-modal {
        width: 797px;
    }

    .event-list-item-modal__info {
        padding: 14px 32px 32px 32px;
    }

    .event-list-item-modal__image {
        height: 328px;
        width: 100%;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .event-list-item-modal.modal.fade.in {
        top: 60px;
    }
}

@media (max-width: 979px) {
    .event-list-item-modal.event-list-item-modal.event-list-item-modal {
        width: 100%;
        top: 0;
    }
    .event-list-item-modal.modal.fade.in {
        border-bottom: 59px solid transparent;
    }
}

@media (max-width: 480px) {
    .event-list-item-modal.modal.fade.in {
        top: 0;
        border-bottom: none;
        border-radius: 0;
    }

    .event-list-item-modal__description {
        margin-top: 16px;
    }

    .event-list-item-modal__image {
        max-height: 211px;
        border-radius: 0;
        border-bottom: none;
    }

    .event-list-item-modal__info {
        padding: 16px 16px 0 16px;
    }
}

.upcoming-session:not(:last-child) {
    margin-bottom: 24px;
}

@media (max-width: 979px) {
    .upcoming-session:not(:last-child) {
        margin-bottom: 16px;
    }
}

.news-playlist {
    width: 100%;
    padding-top: 60px;
    padding-bottom: 60px;
}

.news-playlist__carousel .slick-slide div {
    margin-right: 32px;
    width: 100%;
}

.news-playlist__carousel .slick-list {
    margin-right: -32px;
    overflow: hidden;
}

.news-playlist__carousel .slick-next:before {
    content: none;
}

.news-playlist__carousel .slick-prev:before {
    content: none;
}

.news-playlist__carousel .slick-next {
    right: -41px;
}

.news-playlist__carousel .slick-prev {
    left: -66px;
}

.media-carousel__arrow {
    font-size: 48px;
}

@media (max-width: 1199px) {
    .news-playlist__carousel .slick-list {
        margin-right: -16px;
        min-width: 958px;
    }

    .news-playlist__carousel .slick-slide div {
        margin-right: 16px;
        width: 100%;
    }

    .media-carousel__arrow {
        font-size: 32px;
    }

    .news-playlist__carousel .slick-next {
        right: -25px;
    }

    .news-playlist__carousel .slick-prev {
        left: -32px;
    }
}

@media (max-width: 979px) {
    .news-playlist {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}

h3.media-item__name {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    margin: 0;
}
.media-item__name {
    max-height: 44px;
    overflow: hidden;
}
.media-item__name-wrapper {
    padding: 0px 16px;
}
.media-item__img--no-image {
    background-color: black;
}
.media-carousel__arrow {
    font-size: 48px;
}

/* override carousel style to match design */
.media-carousel__item-container {
    position: relative;
    margin-right: 32px;
}
.media-carousel .slick-slide {
    width: 276px;
}
.media-carousel .slick-list {
    margin-right: -32px;
}
.media-carousel .slick-arrow:before {
    content: none;
}

.media-carousel .slick-arrow a.tabbed-arrow {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.media-carousel .slick-next {
    right: -41px;
}
.media-carousel .slick-prev {
    left: -66px;
}
.media-carousel--one-slide .slick-track {
    margin-left: 0;
}

/* Styles for "unslick" for mobiles - navigate by scrolling, but don't show the scrollbar */
.media-carousel.regular {
    white-space: nowrap;
    overflow-x: auto;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.media-carousel.regular::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.media-carousel.regular .media-carousel__item-container {
    display: inline-block;
    vertical-align: top;
}

.media-carousel.regular .media-carousel__item-container:last-child {
    margin-right: 0;
}

@media (max-width: 1199px) {
    .media-item__img-wrapper {
        height: 174px;
    }
    .media-carousel .slick-next {
        right: -25px;
    }
    .media-carousel .slick-prev {
        left: -35px;
    }
    .media-carousel__arrow {
        font-size: 32px;
    }
    .media-carousel .slick-list {
        margin-right: -16px;
        min-width: 958px;
    }
    .media-carousel__item-container {
        margin-right: 16px;
    }
}

.playlist-item__head {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 24px;
}

.playlist-item__head > div:nth-child(2) {
    white-space: nowrap;
}

.playlist-item__title {
    margin: 0;
    line-height: normal;
}
.playlist-item {
    margin-bottom: 32px;
}
/* make sure arrows are shown outside tab content*/
#wrap .event-channel__tab-content.tab-content {
    overflow: visible !important;
}
@media (max-width: 979px) {
    #wrap .event-channel__tab-content.tab-content {
        overflow: hidden !important;
    }
}

.media-thumbnail {
    display: block;
    position: relative;
    text-decoration: none;
}

.media-thumbnail.no-link {
    pointer-events: none;
    cursor: default;
}

.media-thumbnail::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.4) 30%,
        rgba(0, 0, 0, 0.2) 53%,
        rgba(0, 0, 0, 0.15) 71%,
        rgba(0, 0, 0, 0.05) 100%
    );
}

.media-thumbnail__img {
    width: 100%;
    overflow: hidden;
}

.media-thumbnail__duration {
    font-size: 12px;
    line-height: normal;
    left: 8px;
    bottom: 8px;
    position: absolute;
    color: #fff;
}

.media-thumbnail--error .media-thumbnail__img {
    display: none;
}

.media-thumbnail__type-container {
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    padding: 5px 10px;
}

.media-thumbnail__webcast {
    background-color: #dc430d;
}

.media-carousel__media-item {
    border-radius: 4px;
    overflow: hidden;
}

.media-carousel__media-item__link {
    display: block;
    height: 100%;
    width: 100%;
}

.media-carousel__media-item__name {
    font-size: 16px;
    font-weight: bold;
    line-height: 22px;
}

.media-carousel__media-item__live-data {
    margin-top: 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.media-carousel__media-item--2 .media-carousel__media-item__live-data {
    margin-top: 4px;
}

.media-carousel__media-item__scheduling-data {
    font-size: 11px;
    font-weight: bold;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media-carousel__media-item__live-status-tag {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #ffffff;
    background-color: #fa374b;
    border-radius: 4px;
    display: inline-block;
    line-height: normal;
    padding: 2px 3px;
}

.media-carousel__media-item__description {
    margin-top: 4px;
    font-size: 14px;
    line-height: 19px;
    font-weight: normal;
}

.media-carousel__media-item__details-wrapper {
    padding: 8px 16px 16px 16px;
}

.media-carousel__media-item--2 .media-carousel__media-item__details-wrapper {
    padding: 16px;
}

/* card size for each number of cards in slide */
.media-carousel__media-item--4 {
    width: 276px;
    height: 222px;
}
.media-carousel__media-item__image--4 {
    width: inherit;
    height: 154px;
}
.media-carousel__media-item--3 {
    width: 379px;
    height: 281px;
}
.media-carousel__media-item__image--3 {
    width: inherit;
    height: 213px;
}
.media-carousel__media-item--2 {
    width: 583px;
    height: 427px;
}
.media-carousel__media-item__image--2 {
    width: inherit;
    height: 327px;
}

@media (max-width: 1335px) {
    /* card size for each number of cards in slide */
    .media-carousel__media-item--4 {
        width: 261px;
        height: 215px;
    }
    .media-carousel__media-item__image--4 {
        width: inherit;
        height: 147px;
    }
    .media-carousel__media-item--3 {
        width: 359px;
        height: 270px;
    }
    .media-carousel__media-item__image--3 {
        width: inherit;
        height: 202px;
    }
    .media-carousel__media-item--2 {
        width: 554px;
        height: 404px;
    }
    .media-carousel__media-item__image--2 {
        width: inherit;
        height: 312px;
    }
}

@media (max-width: 1199px) {
    .media-carousel__media-item--4 {
        width: 228px;
        height: 196px;
    }
    .media-carousel__media-item__image--4 {
        width: inherit;
        height: 128px;
    }
    .media-carousel__media-item--3 {
        width: 309px;
        height: 242px;
    }
    .media-carousel__media-item__image--3 {
        width: inherit;
        height: 174px;
    }
    .media-carousel__media-item--2 {
        width: 472px;
        height: 358px;
    }
    .media-carousel__media-item__image--2 {
        width: inherit;
        height: 266px;
    }
}

@media (max-width: 979px) {
    .media-carousel__media-item--4,
    .media-carousel__media-item--3 {
        width: 228px;
        height: 196px;
    }
    .media-carousel__media-item__image--4,
    .media-carousel__media-item__image--3,
    .media-carousel__media-item__image--2 {
        width: inherit;
        height: 128px;
    }
    .media-carousel__media-item--2 {
        width: 228px;
        height: 261px;
    }
}

.news-item-modal {
    position: absolute;
    border-radius: 8px;
    transform: translate(-50%, 0);
    padding-bottom: 30px;
}

.news-item-modal.news-item-modal {
    width: 995px;
    margin: auto;
}

.news-item-modal.modal.fade.in {
    top: 49px;
    border-bottom: 49px solid transparent;
    border-left: none;
    border-right: none;
    box-shadow: none;
}

.news-item-modal__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 2000;
    max-width: 100vw;
    overflow-x: hidden;
}

body.news-modal-open {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.news-item-modal__body {
    max-height: none;
    word-break: break-word;
}

.modal .modal-body.news-item-modal__body {
    padding-left: 0;
    padding-right: 0;
    overflow-x: hidden;
}

.news-item-modal__close-button {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 35px;
    height: 35px;
    z-index: 2000;
    padding: 0 !important;
}

.news-item-modal__content-wrapper {
    padding: 20px 40px 26px 40px;
}

.news-item-modal__image {
    float: left;
    margin-right: 32px;
    margin-bottom: 16px;
    min-width: 320px;
    max-width: 320px;
    height: auto;
    border-radius: 8px;
}

.news-item-modal__updated-at {
    font-size: 13px;
    text-transform: uppercase;
    line-height: 1.38;
    margin-bottom: 8px;
}

.news-item-modal__name {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 24px;
    line-height: normal;
    display: table;
}

.news-item-modal__description {
    display: table;
    font-weight: bold;
    font-size: 16px;
    line-height: 21px;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .news-item-modal {
        width: 945px;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .news-item-modal {
        width: 797px;
    }

    .news-item-modal__content-wrapper {
        padding: 20px 40px 26px 32px;
    }

    .news-item-modal__image {
        min-width: 280px;
        max-width: 280px;
        height: auto;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .news-item-modal__content-wrapper {
        padding: 24px 24px 26px 24px;
    }

    .news-item-modal__image {
        min-width: 280px;
        max-width: 280px;
        height: auto;
    }
}

@media (max-width: 979px) {
    .news-item-modal.news-item-modal.news-item-modal {
        width: 100%;
        top: 0;
    }
    .news-item-modal.modal.fade.in {
        top: 0;
        border-bottom: none;
    }
}

@media (max-width: 480px) {
    .news-item-modal__content-wrapper {
        padding: 24px 16px 26px 16px;
    }

    .news-item-modal__name {
        font-size: 24px;
        margin-bottom: 0;
    }

    .news-item-modal__description {
        padding-top: 16px;
        display: inline-block;
    }

    .news-item-modal__image {
        margin-right: 16px;
        min-width: 133px;
        max-width: 133px;
        height: auto;
    }
}

.news-item {
    flex-direction: column;
    position: relative;
    width: 377px;
    height: 465px;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    text-align: left;
}

/** override button styles **/
.news-item.news-item.news-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 24px;
    border: none;
}

.news-item__opac-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 8px;
}

.news-item__text-box {
    z-index: 1;
}

.news-item__text-box__name {
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    line-height: normal;
}

.news-item__text-box__description {
    margin-top: 8px;
    font-size: 16px;
    color: #ffffff;
    line-height: 1.31;
}

.news-item__text-box__read-more {
    margin-top: 16px;
    color: #ffffff;
    font-weight: bold;
    font-size: 16px;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .news-item {
        width: 359px;
        height: 443px;
    }
}

@media (min-width: 481px) and (max-width: 1199px) {
    .news-item {
        width: 309px;
        height: 381px;
    }
}

@media (max-width: 480px) {
    .news-item {
        width: 283px;
        height: 349px;
    }
}

.connect-cookies__message p {
    margin: 20px;
}

.eventsplatform .contactus-section .contactus-button {
    height: 32px;
    margin: 18px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.eventsplatform
    .contactus-section
    .contactus-button.btn-cta-eventplatform:hover,
.eventsplatform
    .contactus-section
    .contactus-button.btn-cta-eventplatform:active,
.eventsplatform
    .contactus-section
    .contactus-button.btn-cta-eventplatform:focus {
    background-color: #0055cc;
    color: #ffffff;
}

.eventsplatform .contactus-section .connect {
    background-color: #282828;
    padding: 40px;
    margin: 0;
    text-align: center;
    font-weight: 400;
}

.amazon-connect__modal .modal-body {
    padding: 0 !important;
}

.amazon-connect__modal .modal-body h3 {
    font-size: 32px;
    font-weight: 400;
    color: #ffffff;
    background-color: #282828;
    padding: 40px;
    margin: 0;
    text-align: center;
}

.amazon-connect__modal.bootbox {
    height: 620px;
    background: #000;
    color: #fff;
    text-align: center;
}

.amazon-connect__modal.bootbox .connect-customer-interface {
    width: 100%;
    height: 620px;
    margin: 0;
}

.amazon-connect__modal.bootbox
    .btn.button.btn-link.contactus-button__close-button {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 999;
    padding: 0;
    color: #fff;
    width: 32px;
    height: 32px;
    outline: none;
    text-decoration: none;
}

.amazon-connect__modal.bootbox
    .btn.button.btn-link.contactus-button__close-button
    i {
    font-size: 32px;
}

.amazon-connect__modal.bootbox .connect-action-button {
    background: #282828;
}

.eventsplatform .contactus-section .connect-chat-wrapper .connect-actions {
    height: 40px;
}

.eventsplatform
    .contactus-section
    .connect-chat-wrapper
    .connect-button-wrapper {
    display: none;
}

.contactus-section {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    text-align: center;
    padding: 24px;
}

.faq-section__line-separator {
    color: #cccccc;
    background-color: #cccccc;
    height: 1px;
    border: none;
    margin: 0;
}

.contactus-section__title {
    width: 100%;
    color: #333333;
}

.contactus-section__additional-info {
    width: 100%;
}

.contactus-section__browser-not-supported {
    margin-top: 20px;
    margin-bottom: 20px;
}

.expansion-panel {
    position: relative;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
}

.expansion-panel:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.expansion-panel:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.expansion-panel__container {
    min-height: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
}

.expansion-panel__container--expanded {
    overflow: visible;
    visibility: visible;
    -webkit-animation-name: fade-in;
            animation-name: fade-in;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    height: auto;
}

@-webkit-keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.eventsplatform .expansion-panel-summary-eventsplatform {
    display: flex;
    min-height: 48px;
    justify-content: space-between;
    align-items: center;
    box-shadow: none;
}

.eventsplatform .expansion-panel-summary__content-eventsplatform {
    margin: 12px 0;
    display: flex;
    width: 100%;
    overflow: hidden;
    color: #333333;
}

.eventsplatform .btn.expansion-panel-summary__icon-button,
.eventsplatform .btn.expansion-panel-summary__icon-button :focus {
    outline: none;
}

.eventsplatform .expansion-panel-summary__icon-button i,
.eventsplatform .expansion-panel-summary__icon-button i :hover {
    margin: 0;
    color: #006efa;
}

/* hide label for core theme */
.eventsplatform .expansion-panel-summary__close-icon-label {
    display: none;
}

/* hide label for core theme */
.eventsplatform .expansion-panel-summary__expand-icon-label {
    display: none;
}

.eventsplatform.virtual-event .expansion-panel-summary__close-icon-label {
    font-size: 14px;
    font-weight: bold;
    margin: 8px;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.eventsplatform.virtual-event .expansion-panel-summary__expand-icon-label {
    font-size: 14px;
    font-weight: bold;
    margin: 8px;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.eventsplatform.virtual-event .expansion-panel-summary__icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    height: 32px;
}

.expansion-panel__details {
    display: flex;
    align-items: center;
    padding: 16px 24px 32px;
}

.faq-section .faq-section__grid {
    display: flex;
    flex-direction: column;
}

.faq-section .faq-section__grid h2 {
    margin: 0 0 8px 0;
    font-size: 24px;
    color: #333333;
}

.faq-section .faq-section__grid-item {
    margin-bottom: 48px;
}

.faq-section .faq-section__expansion-panel {
    display: flex;
    flex-direction: column;
    margin-left: 0;
}

.faq-section
    .faq-section__expansion-panel
    .expansion-panel-summary__content-eventsplatform {
    font-weight: 800;
    margin: 12px 0 14px 0;
    line-height: 1.57;
}

.faq-section .faq-section__expansion-panel .expansion-panel__details {
    padding: 0;
    margin-bottom: 24px;
    line-height: 1.29;
}

.faq-section .faq-section__expansion-panel-details {
    box-shadow: none;
    background-color: transparent;
}

.scheduling-section {
}

.scheduling-section__title {
    margin: 74px 0 24px 0;
    font-size: 32px;
    font-weight: 900;
}

.scheduling-section__description {
    font-size: 16px;
    margin: 24px 0 38px 0;
    font-weight: 400;
    width: 60%;
}

.scheduling-section__title-text {
    display: flex;
    align-items: center;
    margin-top: 28px;
}

.scheduling-section__title-text h3 {
    font-size: 24px;
    font-weight: 300;
    height: 28px;
    display: flex;
    align-items: center;
}

@media (max-width: 979px) {
    .scheduling-section__title-text {
        margin-top: 24px;
    }

    .scheduling-section__title-text h3 {
        font-size: 20px;
        margin: 0;
    }

    .scheduling-section__description {
        width: 60%;
    }

    .scheduling-section__title p {
        margin: 20px 0 0 0;
    }

    .scheduling-section__items {
        margin-bottom: 54px;
    }

    .scheduling-section__item-description-html {
        color: #b2b2b2;
        margin: 8px 0 8px 0;
    }

    .scheduling-section__items {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .scheduling-section__description {
        width: 80%;
    }
}

.sub-section-item {
    display: flex;
    margin: 5px 0 3px 0;
}

.sub-section-item__icon-country {
    display: flex;
}

.sub-section-item__icon {
    font-size: 20px;
    color: #b2b2b2;
}

.sub-section-item__country {
    margin: 0 10px;
    width: 80px;
    font-size: 14px;
    color: #b2b2b2;
}

.sub-section-item__date {
    display: flex;
}

.sub-section-item__date-text {
    margin: 0 10px;
}

@media (max-width: 979px) {
    .sub-section-item {
        margin-top: 8px;
    }

    .sub-section-item-date {
        display: flex;
        flex-direction: column;
    }

    .sub-section__date-text {
        margin: 0;
    }
}

@media (max-width: 479px) {
    .sub-section-item__icon-country {
        display: flex;
    }

    .sub-section-item__date {
        display: flex;
        flex-direction: column;
    }

    .sub-section-item__date-text {
        margin: 0;
        width: 150px;
    }
}

.sub-section {
    display: flex;
    flex-wrap: wrap;
}

.sub-section__item {
    display: flex;
    flex-direction: column;
    width: 420px;
    margin: 22px 5% 26px 0;
}

.sub-section__item-title {
    display: flex;
    align-items: center;
    margin: 0 0 5px 0;
}

.sub-section__item-title span {
    height: 22px;
    font-size: 16px;
    font-weight: 900;
}

.sub-section__item-title a {
    font-size: 14px;
    font-weight: 900;
    margin: 0 0 0 16px;
}

.btn-visit-page {
    height: 32px;
    line-height: 32px !important;
}

.sub-section__item-description-html {
    font-size: 16px;
    margin: 10px 0;
    max-width: 400px;
}

@media (max-width: 979px) {
    .sub-section__item-description-html {
        color: #b2b2b2;
        margin: 8px 0 8px 0;
    }

    .sub-section__item {
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 24px 0 13px 0;
    }

    .sub-section__item-title {
        margin: 0 0 8px 0;
    }
}

/** animation */
@-webkit-keyframes show-search-input {
    0% {
        margin-top: -200%;
    }
    100% {
        margin-top: 0;
    }
}
@keyframes show-search-input {
    0% {
        margin-top: -200%;
    }
    100% {
        margin-top: 0;
    }
}

@-webkit-keyframes hide-logo {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}

@keyframes hide-logo {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}

.header-menu__mobile-search.show-search-input {
    -webkit-animation-name: show-search-input;
            animation-name: show-search-input;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
}
.hide-logo {
    -webkit-animation-name: hide-logo;
            animation-name: hide-logo;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.header-menu__mobile-search {
    margin-top: -200%;
    flex-grow: 1;
}

/** animation end */
.site-header__container.header-menu__navbar-inverse
    .header-menu__navbar--inner {
    background-image: inherit;
    background-color: #000000;
}

.site-header__container.header-menu__navbar-inverse.gradient
    .header-menu__navbar--inner {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
    );
    background-color: transparent;
    background-color: initial;
}

.site-header__container .header-menu__navbar--inner {
    padding: 8px 8px;
}

.site-header__container .main-sidebar__menu-button {
    margin-right: 10px;
}

.site-header__container .main-sidebar__menu-button i {
    color: #ffffff;
}
.site-header__container .user-menu-toggle-btn {
    display: flex;
    padding: 0 8px;
}

.site-header__container .user-menu-toggle-btn i {
    color: #ffffff;
    font-size: 24px;
    line-height: 32px;
}

.site-header__container .logoImg.brand {
    padding: 0;
    margin: 0;
}

.site-header__container .main-sidebar__menu-button:focus {
    outline: 2px solid #006efa;
}

.header-menu__logo {
    flex-grow: 0;
}

.header-menu__search-wrap {
    padding: 0 8px;
    flex-grow: 1;
}
.header-menu__search.search-input {
    width: 210px;
    float: right;
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(256, 256, 256, 0.4);
}
.header-menu__search.search-input:hover {
    border-color: rgba(256, 256, 256, 0.6);
}
.header-menu__search.search-input__focused,
.header-menu__search.search-input__focused:hover {
    border-color: rgba(256, 256, 256, 0.6);
}

.header-menu__search .search-input__icon {
    color: rgba(256, 256, 256, 0.7);
}

.site-header__container .header-menu__search input[type="text"] {
    background-color: transparent;
    background-color: initial;
    color: #ffffff;
    opacity: 0.7;
}

.site-header__container .header-menu__search .search-input__input::-moz-placeholder {
    color: #ffffff;
    opacity: 0.7;
}

.site-header__container .header-menu__search .search-input__input:-ms-input-placeholder {
    color: #ffffff;
    opacity: 0.7;
}

.site-header__container .header-menu__search .search-input__input::placeholder {
    color: #ffffff;
    opacity: 0.7;
}

.header-menu__navbar {
    display: flex;
    align-items: center;
}
.skip-to-content-link {
    flex-grow: 0;
}

button.btn.btn-borderless-eventplatform.header-menu__search-btn,
button.btn.btn-borderless-eventplatform.header-menu__search-btn:hover {
    color: #ffffff;
}

@media (max-width: 480px) {
    .site-header__container .btn.header-menu__search-btn {
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
        float: right;
        padding: 0;
    }
    .btn.header-menu__search-btn i {
        margin-right: 0;
    }
    .header-menu__search-wrap {
        padding: 0;
    }
    .site-header__container .btn.header-menu__search-btn,
    .site-header__container .btn.header-menu__search-btn:active,
    .site-header__container .btn.header-menu__search-btn:focus {
        padding: 0;
    }
}
@media (max-width: 767px) {
    /* override kms.css */
    .site-header__container .header-menu__navbar--inner {
        margin-left: 0;
    }
}

.header-logo__img {
    max-width: 140px;
    max-height: 36px;
}
a.header-logo__link {
    font-size: 36px;
    color: #ffffff;
}
a.header-logo__link:hover {
    font-size: 36px;
    color: #cccccc;
}
a.header-logo__link:focus {
    outline: 2px solid #006efa;
}

.header-logo__altText {
    font-size: 18px;
    font-weight: bold;
    line-height: 34px;
}

.search-input {
    display: flex;
    align-items: center;
    border: solid 1px #cccccc;
    border-radius: 4px;
}

.search-input:hover {
    border: solid 1px #888888;
}

.search-input--focused,
.search-input--focused:hover {
    border: solid 2px #006efa;
}

.search-input--disabled {
    background-color: white;
}

.search-input__input {
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    color: #333333 !important;
    width: 100%;
    /* For IE11 */
    flex-basis: 1px;
    flex-grow: 1;
    /* For Firefox */
    overflow: hidden;
}

.search-input__input::-moz-placeholder {
    color: #888888 !important;
}

.search-input__input:-ms-input-placeholder {
    color: #888888 !important;
}

.search-input__input::placeholder {
    color: #888888 !important;
}

.search-input__input:disabled::-moz-placeholder {
    color: #cccccc !important;
    background-color: transparent;
}

.search-input__input:disabled:-ms-input-placeholder {
    color: #cccccc !important;
    background-color: transparent;
}

.search-input__input:disabled,
.search-input__input:disabled::placeholder {
    color: #cccccc !important;
    background-color: transparent;
}

.search-input__icon {
    color: #999999;
    font-size: 24px;
    padding: 3px 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 7px;
    padding-right: 2px;
}

.search-input__icon-small {
    color: #999999;
    font-size: 20px;
}

.search-input--disabled .search-input__icon {
    color: #cccccc !important;
}

button.search-input__button {
    padding-top: 2px;
}

.main-sidebar__container .main-sidebar__menu-button {
    display: flex;
    align-items: center;
}

.main-sidebar .main-sidebar__menu-button--close {
    margin-left: -36px;
    left: 50px;
    height: 28px;
    top: 12px;
}

.main-sidebar__container
    button.main-sidebar__menu-button--open[type="button"]
    .main-sidebar__menu-icon {
    font-size: 24px;
    color: #000000;
    padding: 0;
    margin: 0;
}

.main-sidebar__container
    button.main-sidebar__menu-button--close[type="button"]
    .main-sidebar__menu-icon {
    font-size: 24px;
    color: #ffffff;
    padding: 0;
    margin: 0;
}

.main-sidebar__menu-item {
    display: flex;
    justify-content: space-between;
    max-width: 319px;
}

.main-sidebar__menu-item--active {
    background-color: #f4f4f4;
}

.main-sidebar__menu-item--active .main-sidebar__menu-item-title {
    padding-left: 17px;
}

.main-sidebar__menu-item a {
    text-decoration: none;
}

.main-sidebar__container
    .main-sidebar
    .main-sidebar__menu
    .main-sidebar__menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    color: #333333;
}

.main-sidebar__menu-item-title {
    display: flex;
    align-items: center;
    min-height: 56px;
    max-height: 100%;
    padding-left: 24px;
    width: 100%;
}

.main-sidebar__menu {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 319px;
    font-size: 16px;
    overflow-y: auto;
}

.main-sidebar__menu :hover {
    background-color: #f4f4f4;
}

.main-sidebar__back-btn {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
}

.main-sidebar__back-btn span {
    display: inline-block;
    font-size: 16px;
    font-weight: 900;
    margin-left: 8px;
}

.main-sidebar__back-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.main-sidebar__menu-wrapper {
    display: flex;
}

@media (max-width: 479px) {
    .main-sidebar__menu {
        width: 80vw;
    }
}

.main-sidebar {
    position: fixed;
    display: flex;
    left: 0;
    align-content: center;
    z-index: 999;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    top: 0;
    width: 319px;
}

.main-sidebar__container-overlay {
    position: fixed;
    width: 100%;
    min-height: 100%;
    background: transparent;
    z-index: 998;
    top: 0;
    left: 0;
}

.main-sidebar a,
.main-sidebar a :hover,
.main-sidebar a :active {
    color: #333333;
}

.main-sidebar ul {
    margin: 0;
}

@media (max-width: 479px) {
    .main-sidebar {
        width: 80vw;
        max-width: 319px;
    }
}

.main-sidebar-dark-theme .main-sidebar {
    background-color: #222222;
}

.main-sidebar-dark-theme.main-sidebar__container
    button.main-sidebar__menu-button--open[type="button"]
    .main-sidebar__menu-icon {
    font-size: 24px;
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar a,
.main-sidebar a :hover,
.main-sidebar a :active {
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar__menu button {
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar__menu :hover {
    background-color: #333333;
}

.main-sidebar-dark-theme .main-sidebar__menu .main-sidebar__back-btn:hover {
    background-color: #333333;
}

.main-sidebar-dark-theme
    .main-sidebar
    .main-sidebar__menu
    .main-sidebar__menu-icon {
    color: #ffffff;
}

.main-sidebar-dark-theme .main-sidebar__menu-item--active {
    background-color: #333333;
}

button.btn.btn-borderless-eventplatform.user-menu-toggle-btn {
    color: #ffffff;
}
button.btn.btn-borderless-eventplatform.user-menu-toggle-btn:hover {
    background-color: rgba(256, 256, 256, 0.2);
    color: #ffffff;
}

.eventsplatform
    button.btn.btn-borderless-eventplatform.user-menu-toggle-btn.user-menu-toggle-btn:hover,
.eventsplatform
    button.btn.btn-borderless-eventplatform.user-menu-toggle-btn.user-menu-toggle-btn:focus,
.eventsplatform
    button.btn.btn-borderless-eventplatform.user-menu-toggle-btn.user-menu-toggle-btn:active {
    height: auto;
    line-height: normal;
}

button.btn.btn-borderless-eventplatform.user-menu-toggle-btn:focus,
button.btn.btn-borderless-eventplatform.user-menu-toggle-btn:active {
    outline: 2px solid #006efa;
}

.eventsplatform #header.navbar #userMenuToggle.btn-group {
    margin: 0;
}

.eventsplatform #header .btn.user-menu-toggle-btn,
.eventsplatform #header .btn.user-menu-toggle-btn:active,
.eventsplatform #header .btn.user-menu-toggle-btn:focus {
    padding: 0 8px;
}

.eventsplatform #header.navbar .btn-group i {
    margin-right: 0;
    position: static;
    position: initial;
    top: 0;
    font-size: 24px;
    line-height: 32px;
}

.react-dropdown button.btn.dropdown-toggle {
    outline: none;
    color: #fff !important;
    font-size: 16px !important;
    line-height: 36px;
    padding: 0;
}

.react-dropdown.disabled button.btn.dropdown-toggle {
    background-color: #ccc !important;
}

.react-dropdown button.btn.dropdown-toggle,
.react-dropdown button.btn.dropdown-toggle:hover,
.react-dropdown.disabled button.btn.dropdown-toggle,
.react-dropdown.disabled button.btn.dropdown-toggle:hover {
    border: none !important;
}

.react-dropdown-toggle__button {
    padding: 0 15px;
}

.left-icon + .react-dropdown-toggle__button {
    padding-left: 10px;
}

.react-dropdown-toggle__arrow {
    display: inline-block;
    width: 36px;
    margin-left: 0;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
}

.left-icon {
    margin-left: 15px;
    margin-right: 0 !important;
}

.react-dropdown-toggle__arrow .kmsr-arrow-down {
    margin: 0;
    font-size: inherit;
}

button.btn.disabled .react-dropdown-toggle__arrow .kmsr-arrow-down {
    color: inherit !important;
}

.language-menu {
    margin: 0;
}
.language-menu .react-dropdown-toggle__button {
    padding: 0;
}
.react-dropdown .btn.dropdown-toggle.language-menu--button,
.react-dropdown .btn.dropdown-toggle.language-menu--button:active,
.react-dropdown .btn.dropdown-toggle.language-menu--button:focus {
    padding-left: 0;
    padding-right: 8px;
    border-color: transparent !important;
    background-color: transparent;
    box-shadow: none;
    font-weight: normal;
}
.language-menu--arrow {
    width: 20px;
    vertical-align: middle;
    border-left: none;
}
.navbar .language-menu--arrow i {
    font-size: 16px;
}
.language-menu .dropdown-menu {
    right: 0;
    left: auto;
    top: 42px;
}
.language-menu .dropdown-menu .language-menu--item:focus,
.language-menu .dropdown-menu .language-menu--item:hover {
    border-top: solid 1px #f4f4f4;
    border-bottom: solid 1px #f4f4f4;
    font-weight: normal;
}

.virtual-event-entry-hero-section {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
}

.virtual-event-entry-hero-section .hero-section__buttons {
    display: flex;
}

.virtual-event-entry-hero-section__background-image--wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
}

.virtual-event-entry-hero-section__background-image,
.virtual-event-entry-hero-section__background-image--svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.virtual-event-entry-hero-section__background-image {
    filter: blur(16px);
    /* scale the image to get rid of blur borders */
    transform: scale(1.2);
    -o-object-fit: cover;
       object-fit: cover;
}

.virtual-event-entry-hero-section__background-image--svg-image {
    height: 100%;
    width: 100%;
}

.virtual-event-entry-hero-section__content {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    z-index: 0;
}
@media (min-width: 1200px) {
    .virtual-event-entry-hero-section__content {
        justify-content: space-between;
    }
}
.virtual-event-entry-hero-section__details__timer {
    margin-bottom: 24px;
}
.countdown-timer-timerDigit.timerDigit-days {
    background-image: none;
}

.virtual-event-entry-hero-section__details .countdown-timer-timerDigit {
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.virtual-event-entry-hero-section__details__timer__title {
    font-size: 18px;
    padding-right: 16px;
}

.virtual-event-entry-hero-section__details__timer__data {
    font-size: 29px;
    padding-left: 16px;
}
.scheduling-data.virtual-event-entry-hero-section__details__scheduling-data
    .scheduling-data__date,
.scheduling-data.virtual-event-entry-hero-section__details__scheduling-data
    .scheduling-data__time {
    margin: 8px 0;
}
.scheduling-data.virtual-event-entry-hero-section__details__scheduling-data
    .scheduling-data__date {
    margin-top: 0;
}

.virtual-event-entry-hero-section__details__timer__data--first {
    padding-left: 0 !important;
}

.virtual-event-entry-hero-section__details__timer__title {
    font-size: 18px;
    padding-right: 16px;
}

.virtual-event-entry-hero-section__details__timer__title--last {
    padding-right: 0 !important;
}

.virtual-event-entry-hero-section__details__scheduling-data.scheduling-data {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 1.88 !important;
    letter-spacing: 0.21px;
    font-weight: bold;
}

.virtual-event-entry-hero-section__details__scheduling-data__icon {
    font-size: 20px;
}

button.btn.dropdown-toggle.virtual-event-entry-hero-section__details__add-to-calendar__button.virtual-event-entry-hero-section__details__add-to-calendar__button {
    border: none !important;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
}
button.btn.dropdown-toggle.virtual-event-entry-hero-section__details__add-to-calendar__button
    .react-dropdown-toggle__button,
button.btn.dropdown-toggle.virtual-event-entry-hero-section__details__add-to-calendar__button
    .eventplatform-calendar {
    font-size: 14px !important;
    font-weight: 700;
}

.virtual-event-entry-hero-section__details__add-to-calendar__arrow-icon {
    border: solid 1px rgba(255, 255, 255, 0.24);
}

.virtual-event-entry-hero-section__thumbnail {
    width: 686px;
    height: 386px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.4);
}

.virtual-event-entry-hero-section__thumbnail > img {
    display: block;
    border-radius: 8px;
}

.virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    line-height: 20px;
}

.virtual-event-entry-hero-section__details {
    width: 342px;
}

@media (max-width: 767px) {
    .virtual-event-entry-hero-section .hero-section__buttons {
        display: block;
    }
}

/* mobile portrait */
@media (max-width: 666px) {
    .virtual-event-entry-hero-section__content {
        justify-content: center;
        padding-right: 20px;
    }

    .virtual-event-entry-hero-section__details {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: inherit;
    }
    .virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
        width: 300px;
    }
    .virtual-event-entry-hero-section__details__timer {
        margin-bottom: 12px;
    }

    .virtual-event-entry-hero-section__details__timer__title {
        padding-right: 12px;
    }

    .virtual-event-entry-hero-section__details__timer__data {
        padding-left: 12px;
    }

    .virtual-event-entry-hero-section__details__scheduling-data {
        margin-bottom: 32px;
    }

    .virtual-event-entry-hero-section__details__scheduling-data__icon {
        display: none !important;
    }

    .virtual-event-entry-hero-section__thumbnail {
        width: 236px;
        height: 129px;
        border-radius: 4px;
        border: 1px solid rgba(255, 255, 255, 0.4);
    }
}

/* mobile landscape */
@media (min-width: 667px) and (max-width: 767px) {
    .virtual-event-entry-hero-section__details__timer {
        margin-bottom: 16px;
    }

    .virtual-event-entry-hero-section__details__scheduling-data {
        margin-bottom: 16px;
    }

    .virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
        width: 375px;
    }

    .virtual-event-entry-hero-section__content.system-width {
        width: 100%;
    }

    .virtual-event-entry-hero-section__thumbnail {
        width: 236px;
        height: 129px;
    }
}

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
    .virtual-event-entry-hero-section__details__scheduling-data {
        margin-bottom: 32px;
    }

    .virtual-event-entry-hero-section__details .countdown-timer-timerContainer {
        width: 100%;
    }

    .virtual-event-entry-hero-section__thumbnail {
        width: 352px;
        height: 198px;
    }
}

/* tablet landscape */
@media (min-width: 980px) and (max-width: 1199px) {
    .virtual-event-entry-hero-section__thumbnail {
        width: 554px;
        height: 312px;
    }
}

/* add to calendar dropdown adjustments */
.virtual-event-entry-hero-section__details__add-to-calendar__arrow-icon.react-dropdown-toggle__arrow {
    display: none;
}
.virtual-event-entry-hero-section__details__add-to-calendar li a i {
    display: none;
}

.virtual-event-entry-hero-section__agenda-button.btn.btn-borderless-eventplatform {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #e553ec;
}

/* broadcast will start soon */
.virtual-event-entry-hero-section-counter {
    line-height: 0px;
}

.virtual-event-entry-hero-section-counter-title {
    font-size: 24px;
    color: #ffffff;
    display: inline-block;
    font-weight: 900;
    margin-bottom: 32px;
    line-height: 24px;
}

.virtual-event-entry-hero-section-counter-title.no-content {
    margin-bottom: 0px;
    display: inline;
}

@media (max-width: 767px) {
    .virtual-event-entry-hero-section__details__scheduling-data.scheduling-data {
        font-size: 13px;
    }
}

.scheduling-data {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.24px;
    color: white;
}

.scheduling-data__date,
.scheduling-data__time {
    font-weight: bold;
}

.scheduling-data__icon {
    font-size: 22px;
    margin-right: 16px;
    position: relative;
    top: 2px;
}

.countdown-timer-timerContainer {
    color: #ffffff;
}

.countdown-timer-timerDigit {
    width: 84px;
    height: 64px;
    padding: 5px;
    font-size: 32px;
    font-weight: bold;
    line-height: 26px;
    display: inline-block;
    border-left: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
    text-align: center;
}
.countdown-timer-timerDigit-digit {
    margin: 8px;
}
.countdown-timer-timerDigit-valueType {
    font-size: 16px;
    margin: 4px;
    font-weight: normal;
}

.fancy-ios-like-switch {
    position: relative;
    -webkit-appearance: none;
    background-color: #fff;
    border: 2px solid #d9dadc;
    border-radius: 12px;
    box-shadow: inset -15px 0 0 0 #e0e0e0;
}

input.fancy-ios-like-switch {
    height: 20px;
    width: 36px;
    outline-offset: 2px;
}

.fancy-ios-like-switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.fancy-ios-like-switch:checked {
    box-shadow: inset 16px 0 0 0 #008297;
    border-color: #008297;
}

.fancy-ios-like-switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

.fancy-ios-like-switch:disabled {
    box-shadow: inset -17px 0 0 0 #f4f4f4;
    border-color: #e0e0e0;
}

.fancy-ios-like-switch:checked:disabled {
    box-shadow: inset 16px 0 0 0 #f4f4f4;
    border-color: #e0e0e0;
}

.dropdown-indicator__icon,
.dropdown-option__icon {
    font-size: 16px;
}

.dropdown-eventplatform__menu .dropdown-option__text {
    white-space: nowrap;
}

.dropdown-eventplatform__menu.dropdown-menu--auto-width {
    width: auto !important;
    min-width: 220px;
}

.dropdown-menu--multiline-items .dropdown-option__text {
    white-space: normal;
}

/* Align the drop-down item text to the left when the item has a checkbox */
.dropdown-menu--multiline-items
    .dropdown-option--with-checkbox
    .dropdown-option__text {
    position: relative;
    padding-left: 30px;
}
.dropdown-menu--multiline-items .checkbox-custom-wrapper {
    position: absolute;
    left: 0;
}

/* react-select dropdown styles, for multiple select in eventsplatform */
.value-container__custom-placeholder
    + .dropdown-eventplatform__indicators
    .dropdown-eventplatform__clear-indicator {
    display: none;
}

/*!* react-select dropdown styles, for multiple select in eventsplatform *!*/
.value-container__custom-placeholder .dropdown-eventplatform__multi-value {
    display: none;
}

/*!* react-select dropdown styles, for multiple select in eventsplatform *!*/
.value-container__custom-placeholder .dropdown-eventplatform__single-value {
    display: none;
}

/*!* react-select dropdown styles, for multiple select in eventsplatform *!*/
.value-container__custom-placeholder .dropdown-eventplatform__placeholder {
    display: none;
}

.eventplatform-checkbox {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    text-align: center;
    display: inline-block;
}

.eventplatform-checkbox--checked {
    border: none;
}

/* hover/focus state */
.dropdown-option.dropdown-eventplatform__option:hover,
.dropdown-option.dropdown-eventplatform__option:focus {
    background-color: #f4f4f4;
}

.textarea {
    resize: none;
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc;
    padding: 10px 15px;
}
.textarea__char-count {
    float: right;
    margin-top: -10px;
}

.cnc-form {
}
.cnc-form textarea {
    padding: 7px 8px !important;
    max-width: 568px;
}
.cnc-form__field {
    margin-bottom: 32px;
    max-width: 584px;
}
.cnc-form__field-label {
    font-size: 14px;
    font-weight: bold;
}
.cnc-form__field-description {
    font-size: 13px;
    margin-bottom: 8px;
}
.cnc-form__autoReply__msg {
    margin-top: 16px;
}
.cnc-form__separate-line {
    border: solid 1px;
    width: 100%;
}
.cnc-form__btns__object-url {
    margin-left: 24px;
}
.cnc-form__pre-broadcast--error,
.cnc-form__post-broadcast--error {
    font-size: 14px;
    font-weight: bold;
    color: #ff5050;
}
.cnc-form__pre-broadcast__input--error,
.cnc-form__post-broadcast__input--error {
    border: 1px solid #ff5050 !important;
}

.entry-cta {
    display: flex;
    border-radius: unset;
    padding: 0 16px 0 8px;
    height: 34px;
    align-items: center;
    background-color: rgb(224, 237, 254);
    line-height: normal;
    margin-bottom: 24px;
}

.entry-cta:hover {
    background-color: rgb(224, 237, 254, 0.7);
}

.entry-cta__icon {
    font-size: 32px;
    margin-right: 16px;
}

.entry-cta__header {
    display: inline;
    font-size: 14px;
    font-weight: bold;
}

.entry-cta__description {
    display: inline;
    font-size: 13px;
    font-weight: normal;
    margin-left: 8px;
}

.media-details {
    font-size: 14px;
    color: #ffffff;
    font-weight: normal;
}

.event-channel-list-item__img img {
    max-width: 142px;
    min-width: 142px;
    max-height: 80px;
    min-height: 80px;
}

.event-channel-list-item__container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
}

.event-channel-list-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    padding: 16px 20px 16px 16px;
}

.event-channel-list-item {
    width: 100%;
}

.event-channel-list-item__name {
    font-size: 20px;
    font-weight: bold;
}

.event-channel-list-item__details {
    max-width: 800px;
    width: auto;
}

.event-channel-list-item__description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: normal;
}

.event-channel-list-item__link:hover {
    text-decoration: none;
}

.event-channel-list-item__arrow {
    font-size: 24px;
    align-self: center;
}
.event-channel-list-item--focus {
    outline: solid 2px #006efa;
    outline-offset: -2px;
}

@media (max-width: 480px) {
    .event-channel-list-item__container {
        height: 72px;
    }

    .event-channel-list-item__content {
        padding: 14px 12px 14px 16px;
    }

    .event-channel-list-item__img img {
        max-width: 128px;
        min-width: 128px;
        max-height: 72px;
        min-height: 72px;
    }

    .event-channel-list-item__name {
        font-size: 18px;
    }
}

.eventsplatform .search-result__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #4c4c4c;
    height: 500px;
}

@media (max-width: 479px) {
    .eventsplatform .search-result__empty {
        height: 400px;
    }
}

.event-channel-list__title {
    font-size: 48px;
    line-height: normal;
    margin-bottom: 32px;
}

.event-channel-list__sorter .eventplatform-check:before {
    position: absolute;
    right: 5px;
}

.event-channel-list__description {
    margin-bottom: 32px;
    width: 690px;
    line-height: 1.31;
    font-size: 16px;
}

.event-channel-list__list {
    margin-top: 32px;
}

.event-channel-item {
    margin-bottom: 16px;
}

.event-channel-collection__filters .dropdown-option__text {
    text-transform: capitalize;
}

@media (max-width: 1200px) {
    .event-channel-list__description {
        width: 770px;
    }
}

@media (max-width: 979px) {
    .event-channel-list__title.event-channel-list__title.event-channel-list__title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .event-channel-list__description {
        margin-bottom: 24px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .event-channel-list__search {
        min-width: 193px;
        max-width: 193px;
    }

    .event-channel-item {
        margin-bottom: 24px;
    }
}

/* CollectionSearchFormWrapper css */

.collectionSearchFormFilterBar {
    width: 100%;
}

.event-channel-collection__filters {
    display: flex;
}

.event-channel-collection__sorter .dropdown-eventplatform__menu {
    min-width: 100px;
    right: 0;
}

.event-channel-collection-search {
    height: 30px;
    min-width: 300px;
    max-width: 300px;
    margin-right: 16px;
}

.event-channel-collection__sorter {
    min-width: 160px;
}

.event-channel-card-item {
    min-width: 378px;
    max-width: 378px;
    height: 243px;
    background-color: #f4f4f4;
    border-radius: 4px;
}

.event-channel-card-item__header {
    position: relative;
    height: 155px;
    overflow: hidden;
}

.event-channel-card-item__header-image,
.event-channel-card-item__header-no-image {
    height: 100%;
}

.event-channel-card-item__header-no-image {
    background-color: black;
}

.event-channel-card-item__header-logo {
    width: 62px;
    height: 62px;
    position: absolute;
    left: 16px;
    bottom: 16px;
    border-radius: 33px;
    border: solid 2px #ffffff;
}

.event-channel-card-item__details {
    padding-top: 10px;
    padding-left: 16px;
}

.event-channel-card-item__details > div:first-child {
    display: flex;
    height: 24px;
    margin-bottom: 10px;
    align-items: center;
}

.event-channel-card-item__name {
    display: inline-block;
    line-height: normal;
    line-height: initial;
    margin: 0px 8px 0px 0px;
    max-width: 61%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.event-channel-card-item__links {
    display: flex;
}

.event-channel-card-item__link {
    display: flex;
    align-items: center;
    padding-top: 7px;
}

a.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link:hover,
a.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link:focus,
a.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link.event-channel-card-item__link:active {
    height: auto;
}

.event-channel-card-item__booth-link {
    margin-right: 27px;
}

.event-channel-card-item__link-icon {
    margin-right: 4px;
    font-size: 24px;
}

@media (max-width: 767px) {
    .event-channel-card-item {
        min-width: 0px;
        max-width: inherit;
        width: 100%;
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    .event-channel-card-item {
        min-width: 351px;
        max-width: 351px;
    }
}

@media (max-width: 1199px) and (min-width: 980px) {
    .event-channel-card-item {
        min-width: 309px;
        max-width: 309px;
    }
}

@media (max-width: 1335px) and (min-width: 1200px) {
    .event-channel-card-item {
        min-width: 358px;
        max-width: 358px;
    }
}

.channel-grid__title {
    margin-bottom: 32px;
}

.channel-grid__description {
    width: 690px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.31;
    color: #666666;
}

.channel-grid__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
}

@media (max-width: 1200px) {
    .channel-grid__description {
        width: 770px;
    }
}

@media (max-width: 979px) {
    .channel-grid__title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .channel-grid__description {
        margin-bottom: 24px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .channel-grid__search {
        min-width: 193px;
        max-width: 193px;
    }

    .channel-grid__tiers-filter {
        min-width: 104px;
        max-width: 104px;
    }
}

.edit-registration-form__block {
    display: block;
}

.registration-form-editor__section {
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
    background-color: #F4F4F4;
}

.registration-form-editor-section__field {
    border: solid 1px #cccccc;
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
}

.field-defaults-wrap {
    border: solid 1px #cccccc;
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
}

.field-defaults-wrap input {
    width: 100%;
}

.field-options-wrap {
    border: solid 1px #cccccc;
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
}

.field-options-wrap input {
    width: 100%;
}

.registration-form-editor__add-item {
    margin: 6px;
}
.registration-form-editor .siteRegistration-error {
    color: #dc430d;
}

button.btn.registration-form-editor__remove-item  {
    color: #666666;
}

.registration-form-editor__section .toggleButton {
    width: 100%;
    text-align: left;
}

hr {
    border-top: 1px solid #cccccc;
    border-bottom:0;
}

.autocomplete-dropdown-container input {
    margin-bottom: 0;
}

.dropdown-option--selected {
    margin-right: 8px;
}

.autocomplete-dropdown-container .value-container {
    padding-left: 16px;
    padding-right: 16px;
}

.autocomplete-dropdown-container .dropdown-option {
    padding-left: 16px;
    padding-right: 16px;
    border: none;
}
.autocomplete-dropdown__input input {
    box-shadow: none;
}
.autocomplete-dropdown__input input:focus {
    box-shadow: none;
}

.value-container__icon {
    position: absolute;
    right: 0;
}

.autocomplete-dropdown-indicator__icon,
.dropdown-option__icon {
    font-size: 16px;
}

.autocomplete-dropdown__menu .dropdown-option__text {
    white-space: nowrap;
    display: inline-block;
}

.autocomplete-dropdown__menu.dropdown-menu--auto-width {
    width: auto !important;
    min-width: 220px;
}

.dropdown-menu--multiline-items .dropdown-option__text {
    white-space: normal;
}

/* Align the drop-down item text to the left when the item has a checkbox */
.dropdown-menu--multiline-items
    .dropdown-option--with-checkbox
    .dropdown-option__text {
    position: relative;
    padding-left: 30px;
}
.dropdown-menu--multiline-items .checkbox-custom-wrapper {
    position: absolute;
    left: 0;
}

.eventplatform-checkbox {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    text-align: center;
    display: inline-block;
}

.eventplatform-checkbox--checked {
    border: none;
}

/* hover/focus state */
.dropdown-option.autocomplete-dropdown__option:hover,
.dropdown-option.autocomplete-dropdown__option:focus {
    background-color: #f4f4f4;
}

.language-menu {
    margin: 0;
}
.language-menu .react-dropdown-toggle__button {
    padding: 0;
}
.react-dropdown .btn.dropdown-toggle.language-menu--button,
.react-dropdown .btn.dropdown-toggle.language-menu--button:active,
.react-dropdown .btn.dropdown-toggle.language-menu--button:focus {
    padding-left: 0;
    padding-right: 8px;
    border-color: transparent !important;
    background-color: transparent;
    box-shadow: none;
    font-weight: normal;
}
.language-menu--arrow {
    width: 20px;
    vertical-align: middle;
    border-left: none;
}
.navbar .language-menu--arrow i {
    font-size: 16px;
}
.language-menu .dropdown-menu {
    right: 0;
    left: auto;
    top: 42px;
}
.language-menu .dropdown-menu .language-menu--item:focus,
.language-menu .dropdown-menu .language-menu--item:hover {
    border-top: solid 1px #f4f4f4;
    border-bottom: solid 1px #f4f4f4;
    font-weight: normal;
}
.language-menu .dropdown-menu .language-menu--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.language-menu
    .dropdown-menu
    .language-menu--item
    .language-menu--item--check-icon {
    margin-right: 0;
    margin-left: 8px;
}

.virtual-event-entry-hero-section .hero-section__browser-not-supported {
    margin: 16px 0;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
}

.hero-section__browser-not-supported-logos {
    display: flex;
}

.hero-section__browser-not-supported-logos i {
    font-size: 24px;
}

.meeting-entry__join-meeting {
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: table;
    text-align: center;
}
.meeting-entry__join-meeting-content {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    color: #ffffff;
}
@media (min-width: 667px){
    button.btn.meeting-entry__join-meeting-btn {
        margin-top: 24px;
    }
}
button.btn.meeting-entry__join-meeting-btn {
    background-color: #e12437;
    color: #ffffff;
    border: none;
}

button.btn.meeting-entry__join-meeting-btn:hover:not(.btn-link, button.btn
        .button--transparent) {
    color: #ffffff;
    background-color: #b41c2c;
}

button.btn.meeting-entry__start-meeting-btn {
    margin: 32px 0;
}

.meeting-entry__newrowIframe {
    width: 100%;
    height: calc(100vh - 60px);
    border: none;
}

.meeting-entry-hero-section__lobby {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    justify-content: space-between;
}

.meeting-entry__hero-section__no-schedule {
    margin-bottom: 32px;
}

.meeting-entry__hero-section__no-schedule > p {
    color: #ffffff;
}

.meeting-entry__hero-section__primary-message {
    font-size: 24px;
    font-weight: 700;
}

.meeting-entry__hero-section__secondary-message {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
}

.meeting-entry-hero-section__lobby button.btn.meeting-entry__start-meeting-btn {
    margin: 0px;
    background-color: #006efa;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.meeting-entry-hero-section__lobby
    button.btn.meeting-entry__start-meeting-btn:hover:not(.btn-link, button.btn
        .button--transparent) {
    color: #ffffff;
    background-color: #4798ff;
}

.meeting-entry-hero-section__lobby
    button.btn.meeting-entry__start-meeting-btn
    > i {
    font-size: 24px;
}

.meeting-entry-hero-section__thumbnail {
    width: 686px;
    height: 386px;
    border-radius: 8px;
}

.meeting-entry-hero-section__thumbnail > img {
    display: block;
    border-radius: 8px;
}

/* mobile portrait */
@media (max-width: 666px) {
    .meeting-entry-hero-section__lobby {
        justify-content: center;
    }
    .meeting-entry-hero-section__thumbnail {
        display: none;
    }
    .meeting-entry__hero-section__no-schedule {
        text-align: center;
    }
    .meeting-entry-hero-section__lobby
    button.btn.meeting-entry__start-meeting-btn {
        margin-left: auto;
        margin-right: auto;
    }
    .meeting-entry__join-meeting-content{
        font-size: inherit;
    }
}

/* mobile landscape */
@media (min-width: 667px) and (max-width: 767px) {
    .meeting-entry-hero-section__lobby {
        width: 100%;
    }
    .meeting-entry-hero-section__thumbnail {
        width: 236px;
        height: 129px;
    }
}

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
    .meeting-entry-hero-section__thumbnail {
        width: 352px;
        height: 198px;
    }
}

/* tablet landscape */
@media (min-width: 980px) and (max-width: 1199px) {
    .meeting-entry-hero-section__thumbnail {
        width: 554px;
        height: 312px;
    }
}

.tv-entry-hero-section {
    height: 100%;
    position: relative;
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    background-color: rgba(0, 0, 0, 0.7);
}

.tv-entry-hero-section__background-image--wrap {
    height: 100%;
    width: 100%;
    position: absolute;
    overflow: hidden;
}

.tv-entry-hero-section__background-image,
.tv-entry-hero-section__background-image--svg {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.tv-entry-hero-section__background-image {
    filter: blur(16px);
    /* scale the image to get rid of blur borders */
    transform: scale(1.2);
}

.tv-entry-hero-section__background-image--svg-image {
    height: 100%;
    width: 100%;
}

.tv-entry-hero-section__content {
    height: 100%;
}

.tv-entry-hero-section__thumbnail {
    width: 100%;
    height: auto;
    margin: 0 auto;
    display: block;
}

.lean-entry-page__player-preview {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.history-container.progress {
    margin: 0;
    width: 100%;
    height: 6px;
    border-radius: 0;
    box-shadow: none;
    /* will be overridden by config */
    background-color: #bbbbbb;
}
.history__bar.complete {
    /* will be overridden by config */
    background: #bbbbbb none;
}

.history__bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background: #0e90d2 linear-gradient(to bottom, #149bdf, #0480be);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf',endColorstr='#ff0480be',GradientType=0);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transition: width 0.6s ease;
}

.load-next-page-button {
    padding: 4px 20px;
    border-color: black;
    display: block;
    border-radius: 4px;
    text-align: center;
    margin: 20px auto 0;
    background-color: #fff;
    border-width: 1px;
    box-sizing: content-box;
    box-sizing: initial;
    width: 10%;
    transition: background-color 0.2s;
}

.load-next-page-button:focus,
.load-next-page-button:hover {
    color: #333;
    text-decoration: none;
    background-color: #e6e6e6;
}

.blink {
    -webkit-animation-name: blink;
    animation-name: blink;
    -webktit-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

/** 
 *  EntryData component css
 */

.entry-data {
    position: relative;
    line-height: normal;
}

.entry-data__details {
    margin-top: 16px;
}

/**
 *  EntryThumbnail component css
 */

.entryThumbnail {
    display: block;
    position: relative;
    text-decoration: none;
}

.entryThumbnail__type-overlay {
    position: absolute;
    top: 12px;
    left: 12px;
}
.entryThumbnail__icon {
    font-size: 16px;
}

.entryThumbnail__textWrapper {
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.entryThumbnail__text {
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: white;
    background-color: red;
    text-transform: uppercase;
    font-size: 12px;
}

.entryThumbnail__img {
    width: 100%;
    overflow: hidden;
}

.entryThumbnail__duration {
    font-size: 12px;
    line-height: normal;
    left: 12px;
    bottom: 15px;
    position: absolute;
}

.entryThumbnail--status .entryThumbnail__duration {
    bottom: 22px;
}

.entryThumbnail__status {
    line-height: 18px;
}

@media (max-width: 767px) {
    .entryThumbnail__icon {
        font-size: 12px;
        top: 8px;
        left: 8px;
    }

    .entryThumbnail__duration {
        font-size: 10px;
        left: 8px;
        bottom: 11px;
    }

    .entryThumbnail--status .entryThumbnail__duration {
        bottom: 22px;
    }
}

.entryThumbnail--error::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
}

.entryThumbnail--error::after {
    content: "";
    display: table;
    clear: both;
}

.entryThumbnail--error .entryThumbnail__img {
    display: none;
}

.entryThumbnail__overlay-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 53%,
        rgba(0, 0, 0, 0.15) 73%,
        rgba(0, 0, 0, 0.79) 93%,
        #000000
    );
    position: absolute;
    top: 0;
    right: 0 !important;
    left: 0 !important;
    height: 100%;
}

.thumbnail-player-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;
    padding-top: 56.25%;
}

.kaltura-thumbnail-player {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.small-media-thumbnail {
    display: block;
    position: relative;
    text-decoration: none;
}

.small-media-thumbnail::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15) 33%,
        rgba(0, 0, 0, 0.79) 79%,
        #000000
    );
}

.small-media-thumbnail__img {
    width: 100%;
    overflow: hidden;
}

.small-media-thumbnail__duration {
    line-height: normal;
    left: 12px;
    position: absolute;
    font-size: 10px;
    bottom: 8px;
    color: #fff;
}

.small-media-thumbnail--error .small-media-thumbnail__img {
    display: none;
}

.small-media-thumbnail__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    background-color: #dc430d;
    width: 28px;
    height: 16px;
}

.small-media-thumbnail__icon {
    font-size: 12px;
    color: #fff;
}

.collage__image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.collage__container:not(.collage__container--one-image) .collage__image--0 {
    width: 50%;
}
.collage__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.35s;
    z-index: 10;
}
.collage__overlay:hover {
    transition: opacity 0.35s;
    background-color: black;
    opacity: 0.3;
}
.collage__container:not(.collage__container--one-image) .collage__image--1 {
    width: 49%;
    height: 49%;
    position: absolute;
    top: 0;
    left: 51%;
}
.collage__container:not(.collage__container--one-image) .collage__image--2,
.collage__container:not(.collage__container--one-image)
    .collage__image--2.collage__image--empty {
    position: absolute;
    top: 51%;
    width: 49%;
    height: 49%;
    left: 51%;
}

.collage__image__picture {
    height: 100%;
    width: 100%;
    max-width: none;
    max-width: initial;
    position: relative;
}

.collage {
    padding-top: 56.25%;
    width: 100%;
    position: relative;
}
.collage__wrapper {
    width: 100%;
    display: inline-block;
}
.collage__container {
    display: inline-block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}
.collage__container--one-image {
    display: inline-block;
}

.collage__image--empty,
.collage__container--empty {
    display: inline-block;
    background-color: #eaeaea;
}
.collage__image--empty .icon-container {
    font-size: 1.9em;
}
.collage__container--empty .icon-container,
.collage__container--one-image .icon-container {
    font-size: 4em;
}
.icon-container {
    position: absolute;
    color: #bebebe;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
}

.results-entry__action:hover {
    text-decoration: none;
}

/**
 *  EntryNameAndDescription css
 */

.results-entry__icon {
    font-size: 20px;
    margin-right: 10px;
}

.results-entry__name {
    font-size: 20px;
    line-height: 22px;
    word-break: inherit;
    word-break: break-word;
    word-wrap: break-word;
    display: inline-block;
}

.entry-statistics--mobile {
    margin-left: 16px;
}
.results-entry__basic-scheduling {
    padding-top: 8px;
}

.results-entry__status {
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
    padding: 1px 3px;
}
.results-entry__status--live {
    color: #ffffff;
    background-color: #fa374b;
}
.results-entry__status--scheduled {
    color: #999999;
    border-radius: 4px;
    border: solid 1px #999999;
}

.entry-name-and-description {
    display: inline-block;
}
.entry-name-and-description {
    vertical-align: top;
}
.playlist-result--detailed .entry-name-and-description {
    padding-left: 32px;
}
.results__entry-name {
    font-weight: bold;
}
.playlist-result--grid .results__entry-name {
    margin-top: 12px;
}
.results__entry-time.results__entry-time--playlist {
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #999999;
}
.playlist-result {
    padding: 24px 0;
}
.playlist-result--detailed:not(:first-child) {
    padding-top: 24px;
}
.playlist-result--grid {
    padding-bottom: 22px;
    padding-top: 10px;
}
.playlist-result--detailed:first-child {
    border-top: solid 1px #eaeaea;
}
.playlist-result--detailed {
    width: 100%;
}
.playlist-result--detailed:not(:last-child) {
    border-bottom: solid 1px #eaeaea;
    display: flex;
}

.playlist-result--detailed .playlist__thumbnail {
    width: 300px;
    display: inline-block;
    flex-shrink: 0;
}
.playlist-result--grid {
    width: 32%;
}
.user-playlists__button {
    padding: 5px;
}

.playlist-dot-separator {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
}
.playlist-entries-count__text {
    padding: 0 10px;
}
.playlist-entries-count {
    z-index: 10;
    line-height: 1.5em;
    font-size: 16px;
    display: flex;
    position: absolute;
    padding-left: 1rem;
    padding-top: 2.5px;
    padding-bottom: 2.5px;
    top: 24px;
    left: 0;
    min-width: 80px;
    color: white;
    background-color: #222222;
    max-height: 30px;
    box-sizing: border-box;
}

.playlist-icon__container {
    font-size: 24px;
    display: inline-block;
}
.playlist__buttons.details--detailed-view {
    display: inline-block;
}
.playlist__buttons.details--grid-view {
    display: inline-block;
    margin-top: 5px;
}
.playlist-result--detailed {
    position: relative;
}
.playlist-result--detailed .user-playlists__buttons {
    position: absolute;
    right: 0;
}

.playlist__buttons-details {
    text-decoration: underline;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
}

@media (max-width: 979px) {
    .playlist-result--grid {
        width: 48%;
    }
}

@media (max-width: 480px) {
    .playlist-search-results--grid .playlist__thumbnail {
        width: 100%;
    }
    .playlist-result {
        width: 100%;
        height: 100%;
    }
}

/**
 * Tags in Entry Page
 */

.entry-tags {
    font-size: 12px;
    line-height: 20px;
}

.entry-tag {
    margin-top: 12px;
    margin-right: 16px;
    display: inline-block;
}

.playlist-search-results {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.playlist-search-results::after {
    content: "";
    width: 32%;
}
.playlist-search-results--no-playlists:not(.playlist-search-results--no-extra-playlits) {
    border-top: 1px solid #ebebeb;
    padding-top: 16px;
}
.playlist-search-results--grid {
    justify-content: space-between;
    flex-flow: row wrap;
}
.playlist-search-results--detailed {
    flex-direction: column;
}
.playlist-result--grid .collage {
    display: block;
}

.btn.grid-view,
.btn.longView {
    padding-left: 0;
    padding-right: 0;
    border-color: transparent !important;
    background-color: transparent !important;
    box-shadow: none;
    font-size: 32px;
    margin-top: 0;
    margin-left: 10px;
    transition: color 0.2s;
}
.btn.grid-view.active,
.btn.longView.active {
    color: #008297;
}
.buttons__list {
    list-style: none;
}

.tags-input-container {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    cursor: text;
    flex-flow: row wrap;
}

.tags-input-container .tags-input-field {
    margin: 0;
    outline: none;
    border: none;
    box-shadow: none;
    flex: 1 1;
    flex-wrap: wrap;
    padding: 0;
}

.tags-input__tag-item {
    background-color: #ebebeb;
    color: #666666;
    padding: 0 8px;
    margin-right: 12px;
}

.tags-input__tag-item i {
    position: relative;
    top: 2px;
    margin-left: 4px;
}

.create-new-playlist-modal {
    max-width: 600px;
}

.create-new-playlist-form__label,
.create-new-playlist-form .playlist-tags-label {
    margin-top: 24px;
    color: #999999;
    cursor: default;
    font-weight: bold;
}

.create-new-playlist-form__label:first-of-type {
    margin-top: 0;
}

.create-new-playlist-form__input {
    box-sizing: border-box;
    width: 100%;
    resize: none;
}

.create-new-playlist-form__input.create-new-playlist-form__input {
    margin-bottom: 0;
}

.create-new-playlist-form__input,
.create-new-playlist-form-tags-input-container {
    margin-top: 12px;
}

.create-new-playlist-modal__add-button {
    min-width: 104px;
    min-height: 36px;
    font-size: 16px;
    margin-left: 12px;
}

.modal-footer .btn.create-new-playlist-modal__add-button {
    margin-left: 16px;
}

@media (max-width: 767px) {
    .create-new-playlist-modal .modal-footer.modal-footer {
        display: flex;
        flex-direction: column;
        padding-left: 20px;
        padding-right: 20px;
    }

    .create-new-playlist-modal__cancel-button {
        order: 1;
        margin-top: 12px;
    }

    .modal-footer .btn.create-new-playlist-modal__add-button {
        margin-left: 0;
    }
}

.btn.create-playlist-button {
    height: 36px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.create-playlist-button__icon {
    position: relative;
    top: 1px;
}

.create-playlist-button__text {
    margin-left: 5px;
}

.btn.create-playlist-button {
    margin-left: 8px;
}

.my-playlists-view-buttons {
    display: inline-block;
}

.playlist-entry-expandable-item {
    margin: 8px 0;
}

.playlist-entry-expandable-item .summary-field {
    align-items: center;
    margin: 17px 0;
}

.playlist-entry-expandable-item .playlist-entry-expandable-item__details {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 59px 32px;
}

.playlist-entry-expandable-item__details-item {
    margin-bottom: 24px;
    max-width: 800px;
}

.playlist-entry-expandable-item__statistics {
    margin-top: 0;
}

.playlist-entry-item {
    display: flex;
    align-items: center;
    width: calc(100% - 35px);
}
.playlist-entry-item__icon--right {
    margin-left: auto;
}
.playlist-entry-item__index {
    font-size: 16px;
    font-weight: bold;
    margin-right: 25px;
    margin-left: 16px;
    color: #000;
}

.playlist-entry-item__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;
}

.playlist-entry-item__content--header {
    display: flex;
}

.playlist-entry__name {
    display: flex;
    align-items: center;
    overflow: hidden;
}

.playlist-entry__icon {
    margin-right: 8px;
    color: #666666;
    margin-top: 2px;
}

.playlist-entry__header {
    color: #008297;
    font-size: 16px;
    margin: 0;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.playlist-entry-item__content--header i {
    font-size: 16px;
}

.playlist-entry-item__content--description {
    margin: 8px 0;
    overflow: hidden;
    min-width: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media (max-width: 480px) {
    .playlist-entry-item__index {
        margin-right: 16px;
    }

    .playlist-entry-item__content {
        padding: 0 12px;
    }
}

.expansion-panel-summary {
    display: flex;
    padding: 0 24px 0 24px;
    min-height: 48px;
    justify-content: space-between;
    align-items: center;
}

.expansion-panel-summary__content {
    margin: 12px 0;
    display: flex;
    width: 100%;
}

.expansion-panel-summary__btn {
    font-size: 20px;
    transition: transform 0.3s ease-in-out;
}

.expansion-panel-summary__btn--expanded {
    transform: rotate(90deg);
}

@media (max-width: 480px) {
    .expansion-panel-summary {
        padding: 0 16px;
    }
}

.action-icon__button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-icon__button:hover,
.action-icon__button:focus {
    color: #666666;
}

.my-playlist-details {
    display: flex;
}

.my-playlist-details__thumbnail {
    flex: 0 0 425px;
}

.my-playlist-details__description {
    margin: 0 0 0 40px;
    width: 100%;
}

.playlist-details__back-to-channel-button {
    font-size: 1rem;
    font-weight: normal;
    text-decoration: underline;
}

.description-container {
    font-size: 16px;
    overflow: hidden;
}

.description-container .description-container__entry-name {
    font-size: 28px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.description-container__entry-field {
    margin-top: 16px;
}

.description-container__entry-time .dot-separator {
    margin: 0 5px;
}

.description-container .description-container__action-buttons {
    margin-top: 24px;
}

.description-container__action-buttons .btn {
    margin-right: 12px;
}

.description-container__action-buttons .btn i {
    position: relative;
    top: 1px;
}

@media (max-width: 979px) {
    .my-playlist-details__thumbnail {
        display: none;
    }

    .my-playlist-details__description {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .description-container .description-container__entry-name {
        font-size: 22px;
    }

    .description-container .search-results__tag {
        margin-right: 12px;
        line-height: 18px;
    }
}

@media (max-width: 480px) {
    .description-container .description-container__action-buttons .btn-primary {
        width: calc(100% - 30px);
        margin-right: 0;
    }
}

.my-playlist-details-page {
    padding: 32px 0;
}
.my-playlist-details-page--new-playlist {
    padding: 24px 0;
}

.my-playlist-details-page .my-playlist-details {
    margin-bottom: 48px;
}

@media (max-width: 767px) {
    .my-playlist-details-page {
        padding: 24px 0;
    }
}

.description-container__entry-description,
.description-container__entry-name {
    box-sizing: border-box;
}

.description-container .description-container__entry-name {
    height: 40px;
    resize: none;
    color: black;
}
.description-container__entry-name {
    display: flex;
    justify-content: space-between;
}

.description-container .description-container__entry-description {
    min-height: 35px;
    max-height: 400px;
    height: 124px;
    resize: vertical;
    margin-top: 12px;
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 1.69;
    color: #666666;
}

.description-container .description-container__entry-description::-moz-placeholder, .description-container .tags-input-field::-moz-placeholder {
    color: #cccccc;
}

.description-container .description-container__entry-description:-ms-input-placeholder, .description-container .tags-input-field:-ms-input-placeholder {
    color: #cccccc;
}

.description-container .description-container__entry-description::placeholder,
.description-container .tags-input-field::placeholder {
    color: #cccccc;
}

.description-container .editable-text {
    width: 100%;
    padding: 8px 16px;
}

.my-playlist-details__description--edit-mode {
    flex: 1 1;
}
.my-playlist-details--edit-mode {
    border-bottom: 1px solid #eaeaea;
    padding-bottom: 48px;
    margin-bottom: 24px;
}

.draggable-playlist-entry-container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin: 8px 0 8px 1px;
}
.draggable-playlist-entry-container__entry-item {
    padding: 17px 24px 17px 8px;
}

.draggable-playlist-entry-container.dragging {
    background-color: #f1f1f1;
}

.draggable-playlist-entry-container--handle {
    font-size: 24px;
}

.react-action-dropdown .dropdown-toggle {
    cursor: pointer;
}

.react-action-dropdown .dropdown-menu.left-dropdown {
    transform: translate3d(-200px, 0px, 0px);
    left: 75px;
    will-change: transform;
}

.playlist__side-bar {
    width: calc(50% - 25px);
    display: inline-block;
    padding-right: 25px;
}

.playlist-icon {
    font-size: 20px;
    color: #008297;
    top: 2px;
    cursor: pointer;
    position: relative;
}
.sidebar__playlist-search-results {
    max-height: 800px;
    overflow: auto;
}

.playlist__side-bar-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: 0.57px;
    color: #008297;
}
.playlist-entry-container__entry-item {
    border-bottom: solid 1px #eaeaea;
    padding: 17px 24px 17px 0;
}

.sidebar__playlist-no-results-message {
    max-width: calc(100% - 12px);
}

.playlist-side-bar-title__container {
    display: flex;
    justify-content: space-between;
}
.playlist__search-container {
    padding-bottom: 10px;
    max-height: 33px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}
.playlist-context-search-form {
    display: inline-block;
    width: 50%;
    max-width: 240px;
}

.sidebar__playlist-loading {
    font-size: 50px;
    overflow: hidden;
    margin-top: 122px;
}
.sidebar__playlist-loading__spinner {
    margin-left: 45%;
}
.sidebar__playlist-loading__spinner.v2ui-Spinner-icon .path2::before {
    color: #008297;
    opacity: 0.9;
}
.playlist-entry-item__icon--right--sidebar {
    margin-top: -28px;
}
.playlist-entry-item__icon__add-remove-button {
    padding: 2px 4px;
}

.edit-playlist-entry-list {
    width: 100%;
    transition: all 0.4s ease-out;
    display: inline-block;
    -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
    opacity: 0;
    -webkit-animation-delay: 1s;
            animation-delay: 1s;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    border-left: solid 1px #eaeaea;
    padding-left: 25px;
}
.edit-playlist-entry-list--open {
    width: calc(50% - 25px);
}
.edit-playlist-entry-list--disabled {
    border-left: none;
}
.edit-playlist__container {
    display: flex;
}
.playlist-search-dropdown {
    position: relative;
    bottom: 2px;
    width: calc(50% - 25px);
    display: inline-block;
    max-width: 200px;
}
.edit-playlist-header__title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    color: #008297;
}

.edit-playlist-header {
    display: flex;
    margin-bottom: 15px;
    justify-content: space-between;
    align-items: center;
}

.edit-playlist__entries-list {
    max-height: 850px;
    min-height: 650px;
    overflow: auto;
    padding-right: 1px;
}

.edit-playlist-header__entries-count {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: right;
    color: #666666;
    margin-left: auto;
}
@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.edit-playlist__entries-list-empty {
    text-align: center;
    margin-top: 80px;
}
.edit-playlist__entries-list-empty-title {
    font-size: 22px;
    margin-top: 27px;
    margin-bottom: 16px;
    font-weight: bold;
}
.edit-playlist__entries-list-empty-text {
    font-size: 16px;
}

.playlist-edit-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: solid 1px #d9d9d9;
    opacity: 0;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-name: slidemenu;
            animation-name: slidemenu;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.playlist-edit-navbar__info {
    display: flex;
    align-items: center;
    font-size: 19px;
    font-weight: bold;
}

.playlist-edit-navbar__info i {
    margin-right: 8px;
    font-size: 24px;
    color: #999999;
}

.playlist-edit-navbar__info--secondary {
    display: flex;
    align-items: center;
    color: #999999;
}

.playlist-edit-navbar__info .divider-vertical {
    height: 36px;
    border: 1px solid #999999;
    margin: 0 16px;
}

.playlist-edit-navbar__info--primary {
    overflow: hidden;
    text-overflow: ellipsis;
    color: black;
}

.playlist-edit-navbar__actions {
    display: flex;
}

@-webkit-keyframes slidemenu {
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 83px;
    }
}

@keyframes slidemenu {
    0% {
        opacity: 0;
        height: 0;
    }
    100% {
        opacity: 1;
        height: 83px;
    }
}

.add-to-playlist__create-form {
    height: 35px;
}

.add-to-playlist-modal {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    overflow: hidden !important;
    height: 80%;
    max-height: 638px;
}

.modal-body.add-to-playlist-modal__body {
    overflow-y: hidden;
    max-height: 2000px;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.add-to-playlist__list-wrapper {
    overflow-y: auto;
    position: relative;
    margin-top: 10px;
}

.search-results__tags-container {
    padding-left: 8px;

    box-sizing: content-box;
    border-collapse: separate;
    line-height: normal;
}

.search-results__tag {
    margin-right: 16px;
    margin-top: 3px;
}

/**
 *  ResultDetailsItem css
 */

.results__result-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/**
 * EntrySearchResultDetails css
 */

.results-details-container {
    width: 100%;
    display: inline-block;
}

.results-details-container__group {
    margin-top: 14px;
}

.results-details-container__group:first-child {
    margin-top: 0;
}

/**
 *  EntrySearchSummary component css
 */

.summary__wrapper {
    width: 100%;
    position: relative;
}

.results-summary__wrapper__icons {
    display: inline-block;
    max-width: 80%;
}

.results-summary-label {
    margin-right: 8px;
    font-size: 13px;
}
@media (max-width: 480px) {
    .results-summary-label {
        margin-right: 23px;
    }
}

.results-summary-item {
    line-height: 22px;
}

.results-summary-item:before {
    content: "|";
    margin: 0 6px;
    opacity: 0.5;
}

@media (max-width: 767px) {
    .results-summary-item:before {
        content: "";
        margin: 0 0 0 8px;
    }
}

.results-summary-item:first-of-type:before {
    content: "";
    margin: 0;
}

.results-summary-item__a {
    cursor: pointer;
    text-decoration: none;
}

.results-summary-item__a:hover {
    text-decoration: none;
}

.results-summary-item__text {
    white-space: nowrap;
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
}

.results-summary-item__text i {
    position: relative;
    bottom: 1px;
    margin-right: 2px;
}

/**
 *  phone and desktop - 'show more' aligned right
 */
.results-summary__show-more-container {
    float: right;
    padding-left: 5px;
}

@media (min-width: 480px) and (max-width: 767px) {
    .results-summary__show-more-container {
        float: none;
    }
}

/**
 *  in tablet, align the 'show more' left to the icons
 */
@media (min-width: 480px) and (max-width: 767px) {
    .results-summary__show-more-container {
        display: inline-block;
        position: static;
    }
}

.results-summary__show-more--text {
    white-space: nowrap;
    text-decoration: underline;
    position: relative;
    top: 3px;
}

.results-summary__show-more--dots {
    font-size: 20px;
    letter-spacing: 4px;
    text-decoration: none;
    margin-left: 8px;
    line-height: 4px;
    vertical-align: text-top;
}

.results-entry_bottom-bar {
    text-align: right;
}

.results-entry_bottom-bar .results-entry_bottom-bar__show-all,
.results-entry_bottom-bar .results-entry_bottom-bar__show-less {
    margin-left: 30px;
    cursor: pointer;
    text-decoration: underline;
}

/**
 * Single Entry Search Results
 */

.icon.icon--vertical-align-bottom {
    vertical-align: text-bottom;
}

.icon.icon--vertical-align-sub {
    vertical-align: sub;
}

.icon.icon--vertical-align-middle {
    vertical-align: middle;
}

.results-preview__details {
    font-size: 12px;
}

.results-entry {
    white-space: normal;
}

.results-entry__summary {
    margin-bottom: 15px;
}

/**
 *  apply only to entry search results inside the react wrapper
 */
.entry-search-results .results-entry__container {
    padding-top: 32px;
    padding-bottom: 32px;
    min-height: 10px;
    border-bottom: 1px solid;
    line-height: normal;
    line-height: initial;
}

.results-entry__container {
    flex: 1 1 auto;
}

.results-entry__description {
    display: block;
    overflow: hidden;
    margin-bottom: 16px;
    margin-top: 8px;
    flex: 1 1 auto;
}

.search-results-icon {
    font-size: 20px;
}

.results__result-item {
    margin-top: 5px;
}
.results__result-item--time,
.results__result-item--text {
    margin-left: 5px;
}

.results-items {
    margin-top: 24px;
}

.results-entry__container .from-now {
    font-size: 12px;
    margin-top: 8px;
}

/** 
 * alignments
 */

.results-preview {
    float: left;
    width: 204px;
    margin-right: 30px;
}

.offset-left {
    margin-left: 234px;
}

/**
 * tablet alignment
 */
@media (max-width: 767px) {
    .results-preview {
        width: 120px;
        margin-right: 12px;
    }

    .results-details {
        margin-left: 132px;
        min-height: 50px;
    }

    .results-items,
    .results-icons {
        margin-left: 0;
    }

    .results-items {
        width: 100%;
    }
}

/**
 *  mobile alignment
 */
@media (max-width: 480px) {
    .results-details {
        min-height: 69px;
    }

    .results-icons {
        width: 100%;
    }
}

/**
 * limit entry search result width
 */

@media (min-width: 980px) and (max-width: 1024px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 630px;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 405px;
    }
}

@media (min-width: 1200px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 560px;
    }
}

@media (min-width: 1336px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 582px;
    }
}

@media (min-width: 1600px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 765px;
    }
}

/**
 * actions modifier
 */

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
    .entry-search-results .results-entry__container {
        padding: 16px 0;
    }
    .results-entry__description {
        margin: 8px 0;
    }
    .results-entry.results-entry--hasActions
        .results-details
        .results-entry__name {
        max-width: 420px;
        display: inline-block;
    }
}

/* tablet landscape and up */
@media (min-width: 979px) and (max-width: 1024px) {
    .results-entry.results-entry--hasActions
        .results-details
        .results-entry__name {
        max-width: 600px;
        display: inline-block;
    }
}

/************************/
/**** Media Queries *****/
/************************/

@media (max-width: 480px) {
    .results-icons {
        margin-top: 8px;
    }
}

@media (max-width: 767px) {
    .results-entry__name {
        font-size: 16px;
    }

    .summary__wrapper {
        font-size: 13px;
    }

    .results-preview {
        vertical-align: top;
    }
    .entry-search-results .results-entry__container {
        padding: 16px 0;
    }
    .results-entry__description {
        margin: 8px 0;
    }
    .results-items {
        margin-top: 16px;
    }
}

@media (max-width: 540px) {
    .row-fluid .results-summary__show-more--span {
        display: none;
    }
}

/**
 *  css for the Entry Metadata Item component
 */

dd.metadata__item {
    margin-left: 144px;
    margin-bottom: 24px;
    float: none;
    font-size: 12px;
    line-height: 18px;
}

@media (max-width: 480px) {
    dd.metadata__item {
        margin-left: 0;
    }

    .dl-horizontal dd.metadata__item {
        margin-left: 0;
    }
}

/**
 *  css for the PResenter Component
 */

.metadata__item__presenters {
    margin-bottom: 16px;
}

.metadata__item__presenters:last-child {
    margin-bottom: 0;
}

.metadata__item__presenters__image {
    min-width: 50px;
}

.metadata__item__presenters__image__container {
    position: relative;
    overflow: hidden;
    width: 60px;
    height: 60px;
    border-radius: 4px;
}

.metadata__item__presenters__image__img {
    max-width: none;
    max-height: 60px;
    min-width: 100%;
    min-height: 100%;
    height: auto;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.metadata__item__presenters__details {
    padding-left: 14px;
    vertical-align: top;
    line-height: 20px;
}

.metadata__item__presenters__name {
    color: #333333;
    margin-top: 0;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 18px;
}

.metadata__item__presenters__title {
    color: #666666;
    margin-top: 0;
    margin-bottom: 4px;
    font-weight: normal;
    font-size: 16px;
}

.metadata__item__presenters__bio {
    font-size: 14px;
    line-height: 18px;
    white-space: pre-wrap;
}

.metadata__item__presenters__bio > .metadata__item__presenters__show-bio-link {
    font-size: 14px;
    line-height: 18px;
    padding: 0;
    margin-top: 4px;
    display: block;
    cursor: pointer;
}

.btn.btn-link.metadata__item__presenters__remove-link {
    line-height: 10px;
}
.metadata__item__presenters__processing {
    margin-left: 18px;
    display: inline-block;
}

dd.presenters__items {
    margin-left: 0;
}

.presenters__add-new-btn {
    float: right;
}
.presenters__deleted-names {
    width: 50%;
}

/**
 *  css for the Entry Metadata Label component
 */

dt.metadata__label {
    font-size: 14px;
    font-weight: normal;
    width: 120px;
    float: left;
}

@media (max-width: 480px) {
    dt.metadata__label {
        width: 100%;
        float: none;
        margin-bottom: 6px;
        font-weight: bold;
    }
}

/**
 *  Categories in entry Metadata css
 */

.metadata__item__categories__category {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    border-right: 1px solid #666666;
}

.metadata__item__categories__category:last-child {
    border-right: none;
}

/**
 *  css for the TextSingle entry metadata component
 */

.textSingle__item {
    line-height: 18px;
}

/**
 *  css for the TextMulti entry metadata component
 */

.metadata__item__textMulti {
    line-height: 18px;
    margin-bottom: 18px;
    padding-left: 12px;
    border-left: 2px solid #cccccc;
}

.metadata__item__textMulti:last-child {
    margin-bottom: 0;
}

.textMulti__more {
    min-width: 70px;
    text-align: right;
    font-size: 12px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .textMulti__more {
        vertical-align: top;
        padding-top: 20px;
    }
}

/**
 * css for the Text Select List Metadata component
 */

.textSelect__items__item {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    border-right: 1px solid #666666;
}

.textSelect__items__item:last-child {
    border-right: none;
}

/**
 * css for the SingleEntryId metadata component
 */

.metadata_entryId__popup {
    background-color: #222222;
    color: #ffffff;
    font-size: 12px;
    border-radius: 4px;
    opacity: 0.9;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
}

.metadata_entryId__popup {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 12px;
    padding-right: 40px;
}

.metadata_entryId__popup--thumbnail {
    padding: 8px;
}

.metadata_entryId__popup--thumbnail--no_access,
.metadata_entryId__popup--thumbnail--not_available {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 17px;
    padding-right: 17px;
}

.metadata_entryId__image {
    width: 90px;
    height: 50px;
    border: 1px solid #ffffff;
}

/**
 * css for the entry metadata Entry Id component
 */

.metadata_entryId__item {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    border-right: 1px solid #666666;
    cursor: pointer;
}

.metadata_entryId__item:last-child {
    border-right: none;
}

.rating-button {
    padding: 0;
    margin-right: 6px;
}

.rating-button:last-child {
    margin-right: 0;
}

.entryEditInfo__container {
    border-radius: 3px;
    border: solid 1px #eaeaea;
    background-color: rgba(245, 245, 245, 0.5);
    padding: 32px;
    font-size: 15px;
    position: relative;
    display: inline-block;
    width: 100%;
}

.entryEditInfo__title {
    font-weight: bold;
    padding-bottom: 16px;
    color: rgba(0, 0, 0, 0.85);
}

.entryEditInfo__subject {
    color: #999999;
    padding-bottom: 16px;
    font-weight: bold;
}

.entryEditInfo__value {
    color: #666666;
    font-weight: normal;
}

.entryEditInfo__editor {
    padding: 0;
    width: 156px;
    font-size: 16px;
    margin-top: 8px;
}

.entryEditInfo__clear-reject-link {
    margin-left: 12px;
    font-weight: normal;
}

.addMeetingInvite span.react-dropdown-toggle__arrow {
    border-left: none;
}
.addMeetingInvite,
.addMeetingInvite button,
.addMeetingInvite button span {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.webcastMeetingsDropdown,
.webcastDnl-link {
    font-size: 14px;
    padding-bottom: 12px;
    display: inline-block;
}

.webcastDnl-link {
    white-space: nowrap;
}

.webcastEditInfo-launchWebcast {
    padding-top: 12px;
    padding-bottom: 12px;
}

.webcastEditInfo-launchWebcast button {
    height: 36px;
    font-size: 16px;
    white-space: nowrap;
}

.webcastMeetingsDropdown-link {
    padding: 0 !important;
}

.webcastMeetingsDropdown-link .react-dropdown-toggle__arrow {
    width: 20px;
}

.headerContainer {
    width: 100%;
}

.headerBackLink-container {
    margin-bottom: 16px;
}

.headerBackLink-link {
    color: #999999;
    font-size: 13px;
    font-weight: bold;
}

.entryName {
    font-size: 28px;
    font-weight: bold;
    color: black;
    line-height: 36px;
}

.headerBackLink-link-icon {
    margin-right: 4px;
    font-size: 11px;
    text-decoration: none;
}

/**
 * CopyButton Component css
 */

.copyButton-icon {
    font-size: 18px;
    position: relative;
    top: 3px;
}

/**
 *  channel subscription component css
 */

button.btn.btn-link.channelSubscription__share,
button.btn.btn-link.channelSubscription__share:active {
    color: #666666;
    text-decoration: none;
    padding-right: 10px;
    padding-left: 10px;
}

button.btn.btn-link.channelSubscription__share:hover,
button.btn.btn-link.channelSubscription__share:focus {
    color: black;
}

.channelSubscription__buttons {
    display: inline-block;
}

.channelSubscription__button {
    font-size: 16px;
    height: 36px;
    min-width: 176px;
    display: inline-block;
}

.channelSubscription__button .channelSubscription__button__icon {
    margin-right: 10px;
    font-size: 14px;
}

button.btn.channelSubscription__subscribed {
    color: #666666;
    border-color: #cccccc !important;
}

/*
 *  hide and show the appropriate subscribed/unsubscribe button
 */
.channelSubscription__buttons:hover .channelSubscription__subscribed {
    display: none;
}

.channelSubscription__subscribed--unsubscribe {
    display: none;
}
.channelSubscription__buttons:hover
    .channelSubscription__subscribed--unsubscribe {
    display: inline-block;
    background-color: white;
}

@media (max-width: 979px) {
    button.btn.channelSubscription__button {
        font-size: 14px;
        min-width: 140px;
        padding-right: 10px;
        padding-left: 10px;
    }
}
@media (max-width: 480px) {
    button.btn.channelSubscription__button {
        width: 100%;
    }
    button.btn.btn-link.channelSubscription__share {
        float: left;
        padding-left: 0;
    }
    .channelSubscription {
        margin-bottom: 16px;
    }
    .channelSubscription__buttons {
        width: 100%;
    }
}

.edit-board__add-button {
    float: right;
    margin-bottom: 20px;
}
.edit-board__action-icon {
    float: left;
}

.edit-board-form__message {
    width: 98%;
    overflow: hidden;
    margin-bottom: 20px;
}
.edit-board-form__field-description {
    font-size: 12px;
    margin-bottom: 7px;
}

.importUsers__textArea {
    width: 100%;
    min-height: 150px;
    resize: vertical;
    margin-top: 16px;
    box-sizing: border-box;
}

/**
 * add users form
 */

.Groups__actions__status {
    text-align: right;
}

.Groups__actions__status__text {
    font-size: 14px;
    color: #666666;
    display: inline-block;
}

/**
 *  Groups table css
 */

.no-groups {
    padding-top: 24px;
    margin-bottom: 20px;
    border-top: 1px solid #eaeaea;
}

.no-groups-image {
    margin-top: 56px;
}

.no-groups-text {
    margin-top: 32px;
    font-size: 22px;
}

.no-groups-subtext {
    margin-top: 16px;
    font-size: 16px;
}

.no-groups-add-new-button {
    margin-top: 24px;
}

.group-action__link {
    padding-left: 7px;
    padding-right: 7px;
}
.group-action__link:hover {
    text-decoration: none;
}

.groups-management-table--processing {
    color: #008297;
}

/**
 *  Group Manager Bulk Actions css
 */

.GroupManagment--bulkActions > .btn {
    margin-right: 16px;
}

.GroupManagment--bulkActions .btn {
    line-height: 30px;
    font-size: 16px;
}

.GroupManagment--bulkActions .btn i {
    font-size: 18px;
    margin-right: 10px;
    position: relative;
    top: 1px;
}

.modal__add-group {
    width: 50%;
    margin-left: 25%;
}

/**
 * Groups managment css
 */

.Groups__actions__input[type="text"] {
    box-sizing: border-box;
    width: 100%;
    height: 36px;
}

/**
 *  Group Users table css
 */

.groups-management-table__users .rt-td .user-avatar {
    margin-right: 8px;
}

.no-users {
    padding-top: 24px;
    margin-bottom: 20px;
    border-top: 1px solid #eaeaea;
}

.no-users-image {
    margin-top: 56px;
}

.no-users-text {
    margin-top: 32px;
    font-size: 22px;
}

.no-users-subtext {
    margin-top: 16px;
    font-size: 16px;
}

.no-users-add-new-button {
    margin-top: 24px;
}

/* override browsers default, which is "buttontext" */
.groups-management-table .rt-td i {
    color: #666;
}

.groups-management-table .ReactTable .rt-thead .rt-th.-sort-desc,
.groups-management-table .ReactTable .rt-thead .rt-th.-sort-asc {
    box-shadow: none;
}

.groups-management-table .ReactTable.-striped .rt-tr.-even {
    background-color: #f1f1f1;
}

.user-avatar {
    display: inline-block;
    line-height: 24px;
    text-align: center;
    width: 24px;
}

.user-avatar--initials {
    border: 1px solid black;
    background-color: #008297;
    color: white;
    border-radius: 50%;
    font-size: 11px;
    height: 24px;
    text-transform: uppercase;
}

/**
 *  Group Users Manager Bulk Actions css
 */

.GroupManagment--bulkUserActions > .btn:last-child {
    margin-right: 0;
}

.editable {
    position: relative;
    display: inline-block;
}

.editable .edit-icon {
    font-size: 14px;
    margin-left: 16px;
    max-height: 0;
    position: relative;
    top: -3px;
}

.editable-label {
    padding: 5px 0;
}

.edit-box {
    margin-left: 0;
}

.edit-actions {
    position: absolute;
    top: 12%;
    right: 2px;
}

input.edit-box__input {
    width: inherit;
    height: inherit;
    font-weight: bold;
    padding-left: 32px;
    box-sizing: border-box;
    margin-bottom: 0;
    padding-right: 56px;
}

.edit-box__form {
    margin: 0;
}

.group-page-header {
    margin-bottom: 32px;
}

.back-to-groups-link {
    line-height: 54px;
}

.group-page-header .editable-group-name {
    display: block;
    margin-left: 0;
}

.editable-group-name .edit-icon,
.group-name-edit-actions i {
    font-size: 16px;
}

.group-name-editable-label {
    padding-right: 50px;
}

.group-name-edit-actions {
    right: 16px;
    top: 30%;
}

input.group-name-edit-box__input {
    width: 100%;
    height: 58px;
    font-size: 28px;
    padding-right: 10%;
}

.group-header-nav {
    padding: 2px 0;
}

.groupInfo__content {
    background-color: white;
    opacity: 1;
    font-size: 14px;
    font-weight: normal;
    min-height: 72px;
    line-height: 72px;
    padding: 0 16px;
}

.groupInfo__container {
    background-color: #f8f8f8;
    padding: 32px;
}

.groupInfo__content .stat-item {
    margin-left: 16px;
}

.groupInfo__content .stat-item::after {
    content: "|";
    margin-left: 16px;
}

.groupInfo__content .stat-item:last-child::after {
    content: "";
    margin-left: 16px;
}

.stat__label {
    letter-spacing: 0.3px;
}

.stat__value {
    font-weight: bold;
}

.email-editable-label,
input.email-edit-box__input {
    color: #008297;
    font-weight: bold;
}

.email-editable-label.editable-label--empty {
    color: inherit;
}

.email-editable-label .edit-icon {
    font-size: 14px;
}

.email-edit-actions {
    top: 0;
}

.ReactTable.email--notifications--table .rt-thead .rt-th {
    font-weight: bold;
    letter-spacing: 0.43px;
}

.ReactTable.email--notifications--table .rt-tbody .rt-tr {
    height: 100px;
}

.ReactTable.email--notifications--table .rt-tbody .rt-td {
    /*padding-top: 32px;*/
    /*padding-bottom: 32px;*/
    align-self: center;
    white-space: normal;
    line-height: 16px;
}

.email--notifications--table .email--notification--cell--line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
}

.email--notifications--table .email--notification--name {
    font-size: 15px;
    font-weight: bold;
}

.email--notifications--table .email--notification--subject,
.email--notifications--table .email--notification--sender--name {
    font-weight: bold;
}

.email--notifications--table .email--notification--sender--email a {
    text-decoration: underline;
    cursor: default;
}

form.edit--email--notification--form .notification--name--label {
    font-weight: bold;
}

form.edit--email--notification--form
    .edit--email--notification--form--sender--email
    input {
    color: #008297;
    text-decoration: underline;
}

form.edit--email--notification--form input[type="text"],
form.edit--email--notification--form textarea {
    padding-top: 7px;
    padding-bottom: 7px;
    height: auto;
}

form.edit--email--notification--form textarea {
    resize: none;
    width: 100%;
}

.modal.edit-email-notifications-form-modal {
    left: 50%;
    width: 640px;
    margin-left: -320px;
}

.modal.edit-email-notifications-form-modal .modal-body {
    overflow-y: auto;
}

.email--notifications--form--header--stat {
    margin-right: 10px;
}

.email--notifications--form--header {
    font-size: 16px;
    line-height: 36px;
    letter-spacing: 0.57px;
    margin-bottom: 24px;
}

/*
 * A copy of react-responsive-carousel/lib/styles/carousel.css,
 * but all rules are prefixed with ".kms-react-carousel"
 */
.kms-react-carousel .carousel .control-arrow,
.kms-react-carousel .carousel.carousel-slider .control-arrow {
    transition: all 0.25s ease-in;
    opacity: 0.4;
    filter: alpha(opacity=40);
    position: absolute;
    z-index: 2;
    top: 20px;
    background: none;
    border: 0;
    font-size: 32px;
    cursor: pointer;
}
.kms-react-carousel .carousel .control-arrow:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.kms-react-carousel .carousel .control-arrow:before,
.kms-react-carousel .carousel.carousel-slider .control-arrow:before {
    margin: 0 5px;
    display: inline-block;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    content: "";
}
.kms-react-carousel .carousel .control-disabled.control-arrow {
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: inherit;
    display: none;
}
.kms-react-carousel .carousel .control-prev.control-arrow {
    left: 0;
}
.kms-react-carousel .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #fff;
}
.kms-react-carousel .carousel .control-next.control-arrow {
    right: 0;
}
.kms-react-carousel .carousel .control-next.control-arrow:before {
    border-left: 8px solid #fff;
}

.kms-react-carousel .carousel {
    position: relative;
    width: 100%;
}
.kms-react-carousel .carousel * {
    box-sizing: border-box;
}
.kms-react-carousel .carousel img {
    width: 100%;
    display: inline-block;
    pointer-events: none;
}
.kms-react-carousel .carousel .carousel {
    position: relative;
}
.kms-react-carousel .carousel .control-arrow {
    outline: 0;
    border: 0;
    background: none;
    top: 50%;
    margin-top: -13px;
    font-size: 18px;
}
.kms-react-carousel .carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
}
.kms-react-carousel .carousel .thumbs {
    transition: all 0.15s ease-in;
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
}
.kms-react-carousel .carousel .thumb {
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
}
.kms-react-carousel .carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
}
.kms-react-carousel .carousel .thumb.selected,
.kms-react-carousel .carousel .thumb:hover {
    border: 3px solid #333;
}
.kms-react-carousel .carousel .thumb img {
    vertical-align: top;
}
.kms-react-carousel .carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: hidden;
}
.kms-react-carousel .carousel.carousel-slider .control-arrow {
    top: 0;
    color: #fff;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}
.kms-react-carousel .carousel.carousel-slider .control-arrow:hover {
    background: rgba(0, 0, 0, 0.2);
}
.kms-react-carousel .carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    transition: height 0.15s ease-in;
}
.kms-react-carousel .carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.kms-react-carousel .carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}
.kms-react-carousel .carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.kms-react-carousel .carousel .slider-wrapper.axis-vertical .slider {
    flex-direction: column;
}
.kms-react-carousel .carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}
.kms-react-carousel .carousel .slider.animated {
    transition: all 0.35s ease-in-out;
}
.kms-react-carousel .carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: #000;
}
.kms-react-carousel .carousel .slide img {
    width: 100%;
    vertical-align: top;
    border: 0;
}
.kms-react-carousel .carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}
.kms-react-carousel .carousel .slide .legend {
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    transition: opacity 0.35s ease-in-out;
}
.kms-react-carousel .carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%;
}
@media (min-width: 960px) {
    .kms-react-carousel .carousel .control-dots {
        bottom: 0;
    }
}
.kms-react-carousel .carousel .control-dots .dot {
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}
.kms-react-carousel .carousel .control-dots .dot.selected,
.kms-react-carousel .carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.kms-react-carousel .carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
}
.kms-react-carousel .carousel:hover .slide .legend {
    opacity: 1;
}

div.kms-react-carousel {
    position: relative;
}

div.kms-react-carousel .carousel .slide {
    overflow: hidden;
}

div.kms-react-carousel .carousel.carousel-slider .control-arrow,
div.kms-react-carousel .carousel.carousel-slider .control-arrow:hover {
    background: none;
}

div.kms-react-carousel .carousel.carousel-slider .control-arrow::before {
    border: none;
    font-family: "FontAwesome";
    font-size: 60px;
}

div.kms-react-carousel
    .carousel.carousel-slider
    .control-arrow.control-prev::before {
    content: "\f104";
}

div.kms-react-carousel
    .carousel.carousel-slider
    .control-arrow.control-next::before {
    content: "\f105";
}

div.kms-react-carousel .carousel .control-dots {
    margin: 0 0 16px;
}

div.kms-react-carousel .carousel .control-dots .dot {
    width: 12px;
    height: 12px;
    margin: 0 8px;
    outline: none;
    box-shadow: none;
}

/* disable blinking on tap in mobiles (https://stackoverflow.com/questions/3516173/ipad-safari-how-to-disable-the-quick-blinking-effect-when-a-link-has-been-hit) */
div.kms-react-carousel .carousel.carousel-slider .control-arrow,
div.kms-react-carousel .carousel .control-dots .dot {
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
}

/* tablet portrait / landscape */
div.kms-react-carousel .carousel .control-dots {
    margin-bottom: 16px;
}

.kms-react-carousel-bottom {
    bottom: 44px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    body.mobile
        div.kms-react-carousel
        .carousel.carousel-slider
        .control-arrow {
        display: none;
    }

    div.kms-react-carousel .carousel .control-dots {
        margin-bottom: 10px;
    }

    .kms-react-carousel-bottom {
        bottom: 38px;
    }
}

/* desktop / desktop large */
@media (min-width: 1200px) {
    div.kms-react-carousel .carousel .control-dots {
        margin-bottom: 16px;
    }
}

.webcast-homepage-live-entry-details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;

    text-align: left;
}

.webcast-homepage-live-entry-buttons {
    margin-top: 16px;
}

.webcast-homepage-presenter,
.webcast-homepage-presenter__image,
.webcast-homepage-presenter__name {
    display: inline-block;
    vertical-align: top;
    direction: ltr;
}

.webcast-homepage-presenter__image {
    position: relative;
    margin: 0;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
}

/* override carousel vendor styles */
.webcast-homepage-presenter__image,
.kms-react-carousel .carousel .slide img.webcast-homepage-presenter__image {
    border: 1px solid transparent;
    /* the real width will be set by the responsive "min-width" rule later in this file */
    width: 1px;
}

.webcast-homepage-presenter__name {
    padding-left: 14px;
}

.webcast-homepage-presenter--collapsed .webcast-homepage-presenter__image {
    border-color: white;
    background-color: white;
}

.webcast-homepage-presenter--small {
    height: 24px;
    line-height: 24px;
}

.webcast-homepage-presenter--small .webcast-homepage-presenter__image {
    height: 24px;
    min-width: 24px;
    /* 2px less is for borders */
    line-height: 22px;
}

/* tablet portrait / landscape / desktop / desktop large */
.webcast-homepage-presenter--large {
    height: 44px;
    line-height: 44px;
}

.webcast-homepage-presenter--large .webcast-homepage-presenter__image {
    height: 44px;
    min-width: 44px;
    /* 2px less is for borders */
    line-height: 42px;
}

.webcast-homepage-presenter:last-child {
    margin-right: 0;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-presenter--large {
        height: 32px;
        line-height: 32px;
    }

    .webcast-homepage-presenter--large .webcast-homepage-presenter__image {
        height: 32px;
        min-width: 32px;
        /* 2px less is for borders */
        line-height: 30px;
    }
}

.webcast-homepage-presenters--rtl {
    direction: rtl;
}

.webcast-homepage-presenters--ltr
    .webcast-homepage-presenter--collapsed
    + .webcast-homepage-presenter--collapsed {
    margin-left: -7px;
}
.webcast-homepage-presenters--rtl
    .webcast-homepage-presenter--collapsed
    + .webcast-homepage-presenter--collapsed {
    margin-right: -7px;
}

/* tablet / desktop */
.webcast-homepage-presenters--ltr
    .webcast-homepage-presenter--expanded
    + .webcast-homepage-presenter--expanded {
    margin-left: 43px;
}
.webcast-homepage-presenters--rtl
    .webcast-homepage-presenter--expanded
    + .webcast-homepage-presenter--expanded {
    margin-right: 43px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-presenters--ltr
        .webcast-homepage-presenter--expanded
        + .webcast-homepage-presenter--expanded {
        margin-left: 35px;
    }
    .webcast-homepage-presenters--rtl
        .webcast-homepage-presenter--expanded
        + .webcast-homepage-presenter--expanded {
        margin-right: 35px;
    }
}

.webcast-homepage-presenter--more .webcast-homepage-presenter__image {
    background-color: #595959;
    border-color: #595959;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0.81px;
    font-weight: bold;
    color: #ffffff;
}

.webcast-homepage-carousel-entry-info {
    line-height: normal;
}

.webcast-homepage-carousel-status-text {
    font-weight: bold;
    text-transform: uppercase;
}

.webcast-homepage-carousel-entry-name {
    font-weight: bold;
}

/* tablet portrait / landscape / desktop / desktop large */
.webcast-homepage-carousel-entry-info {
    font-size: 16px;
}

.webcast-homepage-carousel-entry-name {
    font-size: 48px;
}

.webcast-homepage-carousel-entry-name,
.webcast-homepage-carousel-presenters {
    margin-top: 16px;
}

.webcast-homepage-carousel-entry-description {
    line-height: 21px;
    margin-top: 12px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-carousel-entry-info {
        font-size: 13px;
    }

    .webcast-homepage-carousel-entry-name {
        font-size: 32px;
    }

    .webcast-homepage-carousel-entry-name,
    .webcast-homepage-carousel-presenters {
        margin-top: 8px;
    }
}

@-webkit-keyframes webcast-homepage-live-entry-join-button {
    0% {
        background-color: #fc3536;
        border-color: #fc3536;
    }
    100% {
        background-color: #7e1a1b;
        border-color: #7e1a1b;
    }
}

@keyframes webcast-homepage-live-entry-join-button {
    0% {
        background-color: #fc3536;
        border-color: #fc3536;
    }
    100% {
        background-color: #7e1a1b;
        border-color: #7e1a1b;
    }
}

span.btn.webcast-homepage-live-entry-join-button {
    color: #fff;
    opacity: 0.8;
    padding: 9px 16px;
    font-size: 16px;

    -webkit-animation-name: webcast-homepage-live-entry-join-button;

            animation-name: webcast-homepage-live-entry-join-button;
    -webkit-animation-duration: 1s;
            animation-duration: 1s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-direction: alternate;
            animation-direction: alternate;
}

span.btn.webcast-homepage-live-entry-label-button {
    color: #fff;
    opacity: 0.8;
    padding: 9px 16px;
    font-size: 16px;

    background-color: #000;
    margin-left: 8px;
}

.webcast-homepage-live-entry-label-button i {
    font-size: 13px;
}

.webcast-homepage-upcoming-entry-details-wrapper,
.webcast-homepage-upcoming-entry-details-wrapper > div {
    height: 100%;
}

.webcast-homepage-upcoming-entry-details-inner {
    text-align: center;
    vertical-align: middle;
}

.webcast-homepage-upcoming-entry-details-inner
    .webcast-homepage-carousel-item-inner {
    display: inline-block;
}

.webcast-homepage-upcoming-entry-details-inner
    .webcast-homepage-carousel-entry-description {
    font-weight: normal;
    opacity: 0.8;
}

.webcast-homepage-recorded-entry-details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;

    text-align: left;
}

.webcast-homepage-carousel-item-wrapper {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    color: #fff;
}

.webcast-homepage-carousel-item-inner {
    width: 100%;
    max-width: 600px;
}

.webcast-homepage-carousel-ready-item-backdrop,
.webcast-homepage-carousel-upcoming-item-backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.webcast-homepage-carousel-ready-item-backdrop {
    background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.73)
    );
}

.webcast-homepage-carousel-upcoming-item-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

/* TODO: use .system-width-mixin() when LESS will be supported */

.webcast-homepage-carousel-item-wrapper {
    width: 940px;
}

@media (max-width: 767px) {
    .webcast-homepage-carousel-item-wrapper {
        width: auto;
        margin-right: 12px;
        margin-left: 12px;
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 724px;
    }
}

@media (min-width: 1200px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 1170px;
    }
}

@media (min-width: 1336px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 1200px;
    }
}

@media (min-width: 1600px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 1470px;
    }
}

.kms-react-carousel.webcast-homepage-carousel {
    position: absolute;
    left: 0;
    right: 0;
}

.webcast-homepage-carousel-item {
    position: relative;
}

/* tablet portrait / landscape / desktop / desktop large */
.webcast-homepage-carousel-height {
    height: 600px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-carousel-height {
        height: 375px;
    }
}

.upcoming-webcast-card {
    position: relative;
    border-radius: 4px;
    border: solid 1px #cccccc;
    color: #666666;
    transition: background-color 0.5s ease;
    font-size: 13px;
}

.upcoming-webcast-card:hover {
    background-color: #ebebeb;
}

.upcoming-webcast-card__link,
.upcoming-webcast-card__link:hover,
.upcoming-webcast-card__link:focus,
.upcoming-webcast-card__link:active {
    text-decoration: none;
}

.upcoming-webcast-card__cell {
    vertical-align: top;
}

.upcoming-webcast__date {
    padding: 16px;
    width: 58px;
    color: #9e9e9e;
}

.upcoming-webcast__date-day-number {
    font-size: 32px;
    letter-spacing: 1px;
    line-height: 1;
    font-weight: bold;
}

.upcoming-webcast__date-month {
    font-size: 18px;
    line-height: 22px;
    margin-top: 2px;
    font-weight: bold;
    color: #ababab;
}

.upcoming-webcast__details {
    padding: 16px;
}

.upcoming-webcast__details-time {
    font-size: 12px;
    line-height: 13px;
    font-weight: bold;
    color: black;
    margin: 0;
}

.upcoming-webcast__details-name {
    font-size: 18px;
    line-height: 22px;
    color: black;
    margin: 5px 0 0;
}

.upcoming-webcast__details-description {
    line-height: 21px;
    margin: 4px 0 0;
}

.upcoming-webcast__details-presenters {
    margin: 18px 0 0;
}

.upcoming-webcast__thumbnail {
    width: 270px;
    height: 159px;
}

/* tablet portrait / phone */
@media (max-width: 979px) {
    .upcoming-webcast__thumbnail {
        /* !important - to override inline style of FlexItem */
        display: none !important;
    }
}

/* desktop / tablet landscape */
@media (min-width: 980px) {
    .upcoming-webcast__details-presenters {
        position: absolute;
        bottom: 16px;
    }
}

/* phone portrait / landscape */
@media (max-width: 667px) {
    .upcoming-webcast-card {
        border-left: none;
        border-right: none;
        border-radius: 0;
        border-color: #979797;
    }

    .upcoming-webcast-card-container
        + .upcoming-webcast-card-container
        .upcoming-webcast-card {
        border-top: 0;
    }

    .upcoming-webcast__date {
        width: 50px;
    }

    .upcoming-webcast__date,
    .upcoming-webcast__details {
        padding-left: 0;
        padding-right: 0;
    }

    .upcoming-webcast__date-day-number {
        font-size: 24px;
        letter-spacing: 0.8px;
    }

    .upcoming-webcast__date-month {
        font-size: 13px;
        line-height: 16px;
    }
}

.upcoming-webcast-month-name {
    font-size: 14px;
    line-height: 21px;
    color: black;
}

.upcoming-webcast-month-name + .upcoming-webcast-card-container {
    margin-top: 8px;
}

.upcoming-webcast-card-container + .upcoming-webcast-card-container {
    margin-top: 16px;
}

.upcoming-webcast-card-container + .upcoming-webcast-month-name {
    margin-top: 24px;
}

@media (max-width: 767px) {
    .upcoming-webcast-card-container + .upcoming-webcast-card-container {
        margin-top: 0;
    }

    .upcoming-webcast-card-container + .upcoming-webcast-month-name {
        margin-top: 15px;
    }
}

.webcast-section-title {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 39px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .webcast-section-title {
        margin-top: 24px;
        font-size: 24px;
        line-height: 29px;
    }
}

.webcast-homepage-recorded-item {
    width: 32%;
    margin-right: 2%;
    float: left;
    margin-bottom: 32px;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0;
    font-weight: bold;
    color: #999999;
}

.webcast-homepage-recorded-item-link,
.webcast-homepage-recorded-item-link:hover,
.webcast-homepage-recorded-item-link:focus,
.webcast-homepage-recorded-item-link:active {
    text-decoration: none;
    color: inherit;
}

.webcast-homepage-recorded-item-details__line2 {
    margin-top: 8px;
}

.webcast-homepage-recorded-item-details__cell {
    vertical-align: middle;
}

.webcast-homepage-recorded-item:nth-child(3n) {
    margin-right: 0;
}

.webcast-homepage-recorded-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #222222;
}

.webcast-homepage-carousel-item-time-ago {
    text-align: right;
}

/* tablet portrait / landscape */
@media (max-width: 1336px) {
    .webcast-homepage-recorded-item {
        margin-bottom: 24px;
    }
}

/* phone landscape / tablet portrait */
@media (min-width: 667px) and (max-width: 1023px) {
    .webcast-homepage-recorded-item {
        width: 49%;
        margin-right: 2%;
    }

    .webcast-homepage-recorded-item:nth-child(3n) {
        margin-right: 2%;
    }

    .webcast-homepage-recorded-item:nth-child(2n) {
        margin-right: 0;
    }
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-recorded-item {
        margin-bottom: 16px;
    }
}

/* phone portrait */
@media (max-width: 666px) {
    .webcast-homepage-recorded-item {
        width: 100%;
    }
}

.stat_data.stat_data--webcast-home-page,
.stat_data.stat_data--webcast-home-page i {
    color: inherit;
    font-size: 13px;
    line-height: inherit;
    font-weight: inherit;
    margin: 0;
}

.stat_data.stat_data--webcast-home-page i {
    margin-right: 6px;
}

.webcast-homepage-recorded-stat-item {
    margin-right: 14px;
}

.stat_data:last-child .webcast-homepage-recorded-stat-item {
    margin-right: 0;
}

.webcast-homepage-recorded-stat i {
    margin-right: 6px;
    margin-left: 0;
}

.webcast-homepage-recorded-presenters {
    float: right;
}

.entry-thumbnail-container {
    position: relative;
    width: 100%;
}

.webcast-homepage-recorded-item-thumbnail-container {
    margin-bottom: 5px;
    border-radius: 4px;
    overflow: hidden;
}

.webcast-homepage-recorded-item-thumbnail-container
    .webcast-homepage-recorded-item-thumbnail-duration {
    background-color: #000000;
    padding: 7px 12px 7px 16px;
    opacity: 0.7;
    font-size: 16px;
}

.webcast-homepage-recorded-item-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.webcast-homepage-recorded {
    position: relative;
}

.homepage-no-webcasts {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 370px;
    margin: 40px auto 0;
    text-align: center;
}

.homepage-no-webcasts__header {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 22px;
    line-height: normal;
    letter-spacing: 0.7px;
}

.homepage-no-webcasts__description {
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.7px;
}

.homepage-no-webcasts__button a {
    margin-top: 16px;
}

.comingSoon-container {
    height: 100%;
    position: relative;
    background-color: #006efa;
    min-height: 732px;
}

.comingSoon-videofile {
    display: block;
    position: absolute;
    right: 0;
    width: 45%;
}

.comingSoon-videofile video {
    height: auto;
    width: 100%;
}

.comingSoon-content {
    position: relative;
}

.comingSoon-logo,
.comingSoon-logo--timerIsDone {
    padding-top: 120px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 260px;
    max-height: 80px;
}

.comingSoon-logo--timerIsDone {
    padding-top: 318px;
}

.comingSoon-header,
.comingSoon-timerMainDoneMsg {
    text-align: center;
    font-size: 64px;
    line-height: normal;
    font-weight: 900;
    color: #ffffff;
    margin-top: 40px;
}

.comingSoon-description,
.comingSoon-timerSecondaryDoneMsg {
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    line-height: 1.6;
    max-width: 997px;
    margin: 40px auto 0 auto;
    font-weight: normal;
}

.comingSoon-targetDate {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 1.08px;
    margin-top: 32px;
}

.comingSoon-timerContainer {
    text-align: center;
    color: #ffffff;
    margin-top: 56px;
}

.comingSoon-timerDigit {
    width: 180px;
    height: 160px;
    font-size: 55px;
    font-weight: 900;
    display: inline-block;
    line-height: 100px;
    border-left: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
    text-align: center;
}
.comingSoon-timerDigit-valueType {
    font-size: 20px;
    line-height: 55px;
    font-weight: normal;
}

.comingSoon-footer {
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    margin-top: 56px;
    line-height: 1.6;
}

.comingSoon-footer a:link {
    color: #ffffff;
    font-weight: bold;
}
.comingSoon-footer a:link:hover {
    text-decoration: none;
}

.timerDigit-days {
    border-left: none;
}
.timerDigit-seconds {
    border-right: none;
}

.comingSoon-locales {
    float: right;
}

@media (min-width: 768px) and (max-width: 979px) {
    .comingSoon-header {
        font-size: 32px;
    }
}

@media (max-width: 767px) {
    
    .comingSoon-logo {
        padding-top: 74px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        max-width: 200px;
        max-height: 45px;
    }

    .comingSoon-header {
        font-size: 32px;
        font-weight: bold;
    }

    .comingSoon-description {
        font-size: 16px;
        line-height: 1.5;
        margin-top: 16px;
        margin-right: 5%;
        margin-left: 5%;
    }

    .comingSoon-targetDate {
        margin-top: 24px;
    }

    .comingSoon-timerContainer {
        margin-top: 40px;
    }

    .comingSoon-timerDigit {
        width: 89px;
        height: 86px;
        font-size: 32px;
        line-height: 69px;
    }
    .comingSoon-timerDigit-valueType {
        font-size: 16px;
        line-height: 0;
    }

    .comingSoon-footer {
        margin-top: 40px;
    }
}

.user-profile__page-content {
    margin-top: 184px;
    padding-top: 32px;
}

#wrap .user-profile__page-content .nav.nav-tabs {
    margin-bottom: 32px;
}
@media (max-width: 1199px) {
    .user-profile__page-content {
        margin-top: 143px;
    }
}
@media (max-width: 980px) {
    .user-profile__page-content {
        margin-top: 190px;
    }
}
@media (max-width: 480px) {
    .user-profile__page-content {
        padding-top: 0;
        margin-top: 107px;
    }
}

.user-profile__tab-content {
    display: contents;
}
#wrap .tab-content.user-profile__tab-content {
    overflow: visible !important;
}
@media (max-width: 979px) {
    .user-profile__tab-content {
        padding-top: 8px;
        clear: both;
    }
}

.user-profile__btn-update {
    margin-right: 8px;
}
.user-profile__btn-unregister {
    margin-left: 8px;
}

.user-page-head__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 240px;
    background-color: #000000;
}

.user-page-head__details-wrap {
    position: relative;
    top: 200px;
}

.user-page-head__details {
    display: inline-block;
}
.user-page-head__details.user-page-head__details--no-thumb {
    width: 100%;
}
.user-page-head__details .user-details__bio {
    padding-right: 32px;
}

@media (max-width: 1199px) {
    .user-page-head__img {
        height: 200px;
    }
    .user-page-head__details-wrap {
        top: 156px;
    }
}

@media (min-width: 481px) and (max-width: 980px) {
    .user-page-head__details-wrap {
        top: 200px;
    }
}
@media (max-width: 480px) {
    .user-page-head__img {
        height: 130px;
    }
    .user-page-head__details-wrap {
        top: 94px;
        margin-left: 4px;
        margin-right: 4px;
    }
}

.user-details {
    width: 100%;
}
.user-details__container {
    display: flex;
}
@media (min-width: 980px) {
    h1.user-details__name {
        flex-grow: 1;
    }
    .user-details__actions {
        margin-top: 89px;
        margin-left: auto;
    }
}
h1.user-details__name {
    font-size: 48px;
    line-height: 57px;
    display: inline;
    color: #333333;
    font-weight: bold;
    word-break: break-word;
    word-wrap: break-word;
}
.user-details__linkedinButton {
    display: inline;
}

.user-details__title {
    font-size: 16px;
    line-height: 19px;
    min-height: 19px;
    color: #333333;
    font-weight: bold;
}
.user-details__bio {
    color: #666666;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 16px;
    width: 75%;
}

.user-details__actions button {
    display: inline-flex;
    align-items: center;
    height: 32px;
}

.user-details__actions button i {
    font-size: 24px;
}

.user-details__user-img {
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
    width: 173px;
    height: 173px;
}

.user-details__user-img .user-thumbnail__img {
    height: 100%;
}

.user-details__name-and-title {
    align-self: flex-end;
}

.UserDetailsActionsMenu {
    right: 0;
    left: auto;
}

@media (max-width: 980px) {
    .user-details__container {
        display: block;
    }
    .user-details__actions {
        margin-top: 8px;
        margin-bottom: 7px;
    }
    .user-details__bio {
        width: 100%;
    }
}
@media (max-width: 480px) {
    .user-details__user-img.user-details__user-img {
        margin-right: 16px;
        width: 104px;
        height: 104px;
        min-width: 0;
        min-width: initial;
    }
    .h1.user-details__name {
        font-size: 32px;
    }
    .user-page-head__bio {
        top: 72px;
        position: relative;
        font-size: 16px;
        line-height: 22px;
        padding-bottom: 16px;
    }
    .user-details__user-img {
        min-width: 133px;
    }

    .UserDetailsActionsMenu {
        right: auto;
    }
}

.group-change__menu-item {
    width: 100%;
}

.group-change__menu-item .kms-react-icon.eventplatform-check {
    margin-right: -8px;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
}

.dropdown-submenu.group-change__sub-menu > a:after {
    margin-top: 24px;
    margin-right: -10px;
}
.group-change__lang-menu.group-change__lang-menu.group-change__lang-menu {
    margin: 0;
    width: 100%;
}

.modal .modal-body.user-edit-modal__body {
    padding: 0;
    min-height: 367px;
}

.modal .modal-footer .user-edit-modal__button.user-edit-modal__button {
    border: none;
    padding: 8px;
    margin-left: 8px;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform {
    background-color: #006efa;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform:hover {
    background-color: #0055cc;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform:active,
.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform:focus {
    background-color: #006bff;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-borderless-eventplatform:hover {
    background-color: #cccccc;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-borderless-eventplatform:active,
.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-borderless-eventplatform:focus {
    background-color: #ebebeb;
}

.user-edit-modal__content-wrapper {
    padding: 20px 40px 26px 32px;
    margin-top: -87px;
}

.user-edit-head__img {
    background-size: cover;
    height: 160px;
}

.user-edit-head__user-img {
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
}

.user-thumbnail.user-edit-head__user-img {
    width: 133px;
    height: 133px;
}

.user-thumbnail.user-edit-head__user-img .user-thumbnail__img {
    height: 100%;
}

.user-edit-modal__text {
    box-sizing: border-box;
    width: 100%;
}

.textarea__char-count.user-edit-modal__char-count {
    margin-top: 0;
    font-size: 13px;
}

.user-edit-modal__description {
    margin-bottom: 0;
    font-size: 13px;
    color: #666666;
}

.user-edit-modal__form_dropdown {
    margin-top: 8px;
}

.user-edit-modal__error {
    margin: 24px 0 0;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .user-edit-modal {
        width: 945px;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .user-edit-modal {
        width: 797px;
    }

    .user-edit-modal__content-wrapper {
        padding: 20px 40px 26px 32px;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .user-edit-modal__content-wrapper {
        padding: 24px 24px 26px 24px;
    }
}

@media (max-width: 480px) {
    .user-edit-modal__content-wrapper {
        padding: 24px 16px 26px 16px;
    }
}

.user-thumbnail-upload {
    display: flex;
    align-items: flex-end;
}

.user-thumbnail-upload {
    font-size: 13px;
    padding: 0 8px;
    vertical-align: bottom;
}

.user-thumbnail-upload .error {
    color: #e12437;
}

.user-thumbnail {
    box-sizing: border-box;
    border: 2px solid #ffffff;
    border-radius: 8px;
}
.user-thumbnail__img-wrapper {
    aspect-ratio: 1;
}

.user-thumbnail__img {
    -o-object-fit: cover;
       object-fit: cover;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
}

.user-thumbnail__intro-container {
    font-size: 15px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: -56px;
    padding: 5px 10px;
}

.user-thumbnail__edit-container {
    margin-top: -48px;
    padding: 8px 8px;
}

.user-thumbnail--error .user-thumbnail__edit-container {
    margin-top: 184px;
}

.user-thumbnail--empty .user-thumbnail__img {
    display: none;
}

.user-thumbnail--empty {
    background-color: #666666;
}

.user-thumbnail__actions {
    float: right;
}

/* processing state */
.user-thumbnail__processing {
    text-align: center;
    margin-top: 70%;
    color: #ffffff;
    font-size: 20px;
}

.user-thumbnail__processing .v2ui-Spinner-icon .path1:before,
.user-thumbnail__processing .v2ui-Spinner-icon .path2:before {
    color: white;
}

/* processing state end */

.event-list-single-item {
    height: 191px;
    padding: 12px 0;
}

.event-list__date-divider {
    padding-top: 20px;
    padding-bottom: 4px;
}

.event-list__filter-button {
    margin-bottom: 12px;
    color: #006efa;
}

button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button,
button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button:hover,
button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button:active,
button.event-list__filter-button.event-list__filter-button.event-list__filter-button.event-list__filter-button:focus {
    font-weight: bold;
    line-height: normal;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

.event-list__filter-button:hover,
.event-list__filter-button:active,
.event-list__filter-button:focus {
    color: #0055cc;
}

@media (max-width: 979px) {
    .event-list__filter-button {
        margin-bottom: 8px;
    }

    .event-list-single-item {
        padding: 8px 0;
    }

    .event-list__date-divider {
        padding-top: 8px;
        padding-bottom: 0;
    }
}

@media (max-width: 768px) {
    .event-list-single-item {
        height: 100%;
    }
}

.contact-info-item {
    padding: 12px 0;
    display: flex;
    font-size: 16px;
}

.contact-info__icon {
    padding: 0 8px 0 0;
    font-size: 24px;
}

.contact-info__property {
    color: #666666;
    width: 20%;
    align-items: center;
}

.contact-info__value {
    margin-left: 24px;
    color: #333333;
    flex: 1 1;
}
.contact-info__url {
    font-size: 16px;
}

.user-badge__image {
    transform: translateX(-8px);
    opacity: 0.5;
}
.user-badge__image img {
    width: 100px;
    height: 100px;
}
.user-badge__image--in-progress {
    opacity: 1;
}
.user-badge__info {
    flex: 1 1;
}
.user-badge *,
.user-badge *:before,
.user-badge *:after {
    box-sizing: border-box;
}
.badge__label {
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    border: solid 1px #333333;
    margin-left: 8px;
    padding: 2px 2px;
}
.user-badge__name-and-label {
    display: flex;
    align-items: center;
}
.user-badge__name {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
}
.user-badge__how-to {
    font-weight: bold;
}
.user-badge__level-image img {
    /*
    works better images than directly setting width and height
    */
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
}
.user-badge__level-image {
    background-color: transparent;
    position: relative;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-badge__level-points {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
}
.user-badge__level-image--disabled {
    cursor: auto;
}
.user-badge__level-popover .Popover-tip {
    display: none;
}
.user-badge__level-image--completed {
    opacity: 1;
}
.user-badge__current-points {
    position: relative;
}
.user-badge__current-points span {
    background-color: #ffaa00;
    padding: 3px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
}
.level-tooltip__container {
    display: flex;
    background-color: #ffffff;
    padding: 8px;
    box-shadow: 0 4px 30px -8px rgba(0, 0, 0, 0.2);
    border: solid 1px #d9d9d9;
    border-radius: 4px;
}
.level-tooltip__image {
    flex-shrink: 0;
}
.level-tooltip__image img {
    width: 72px;
    height: 72px;
}
.level-tooltip__content {
    padding-left: 8px;
}
.level-tooltip__text-container {
    max-width: 352px;
}
.level-tooltip__header {
    font-size: 16px;
    font-weight: bold;
}
.level-tooltip__description {
    padding-bottom: 1em;
}
.user-badge__how-to {
    padding-left: 0;
    text-decoration: none;
}
.user-badge__how-to:hover {
    text-decoration: none;
}
.user-badge {
    width: 100%;
    display: flex;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}
.user-badge.user-badge__progress {
    width: 100%;
}
.user-badge__progress-line-container {
    background-color: #cccccc;
    height: 8px;
    width: 10%;
    flex-basis: 40%;
    position: relative;
}
.user-badge__progress-line {
    position: absolute;
}

.user-badge__progress-line--completed {
    position: absolute;
    height: 8px;
    left: 0;
    top: 0;
    background-color: #ffaa00;
}
.user-badge__levels {
    display: flex;
    margin-left: auto;
    align-items: center;
    padding-bottom: 20px;
}
/* tablet */
@media (min-width: 766px) {
    .user-badge {
        width: calc(50% - 64px);
    }
    .user-badge__info {
        flex: 2 1;
    }
    .user-badge__levels {
        width: 100%;
    }
}
/* desktop */
@media (min-width: 1024px) {
    .user-badge__levels {
        flex: 3 1;
        padding-bottom: 0;
    }
}

.badges__container,
.badges__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.badges__list-header {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
}
.badges__list-sub-header {
    font-weight: normal;
    font-size: 16px;
}

.eventsplatform-channel-item {
    display: flex;
    font-size: 14px;
    border-radius: 4px;
    max-height: 100%;
    margin-bottom: 24px;
    background-color: #f4f4f4;
}

.eventsplatform-channel-item__container {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    min-width: 0;
    padding: 16px 24px 0 24px;
}

.eventsplatform-channel-item__thumbnail {
    width: 277px;
    height: 156px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.eventsplatform-channel-item__title {
    margin: 0 0 8px 0;
    font-weight: 900;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eventsplatform-channel-item__speakers {
    font-weight: bold;
    line-height: 1.43;
    font-size: 14px;
}

.eventsplatform-channel-item__details {
    width: 100%;
}

.eventsplatform-channel-item__actions .button.btn.btn-borderless-eventplatform {
    font-size: 24px;
}

.eventsplatform-channel-item__actions {
    position: relative;
    top: -4px;
}
button.btn.eventsplatform-channel-item__actions_button {
    width: 32px;
    height: 32px;
    padding-top: 2px;
    padding-bottom: 6px;
    padding-right: 5px;
    padding-left: 3px;
}

@media (max-width: 479px) {
    .eventsplatform-channel-item {
        display: flex;
        flex-direction: column;
        margin: 16px 0 0 0;
        border-radius: 4px;
        max-height: 100%;
    }
    .eventsplatform-channel-item__thumbnail {
        width: 100%;
        height: auto;
    }
    .eventsplatform-channel-item__container {
        padding: 0 16px 16px;
    }
    .eventsplatform-channel-item__details h4 {
        font-size: 18px;
        margin: 8px 0;
    }
}

@media (min-width: 479px) and (max-width: 979px) {
    .eventsplatform-channel-item__details h4 {
        font-size: 18px;
    }
}

@media (max-width: 480px) {
    .eventsplatform-channel-item__container {
        display: block;
    }
}

/**
 * css for watch later tab on personal profile page
 */

.watchLaterTab__empty {
    display: flex;
    min-height: 80vh;
}

.watchLaterTab__empty__message {
    margin: auto;
}

.watchLaterTab__removed__item {
    background-color: #f4f4f4;
    margin-bottom: 16px;
    padding-right: 24px;
    display: flex;
}

.watchLaterTab__removed__thumbnail {
    width: 110px;
    height: 62px;
}

.watchLaterTab__removed__data {
    display: flex;
    margin-left: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-width: 0; /* a trick from https://css-tricks.com/flexbox-truncated-text/ */
}

.watchLaterTab__removed__data__name {
    display: flex;
    min-width: 0; /* a trick from https://css-tricks.com/flexbox-truncated-text/ */
}

.watchLaterTab__removed__data__name--bold {
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 3.67px; /* replace an actual space with margin */
}

.watchLaterTab__removed__data__was-removed-text {
    white-space: nowrap;
}

.watchLaterTab__removed__undo {
    margin-right: 4px;
    font-weight: bold;
    text-transform: capitalize;
    white-space: pre;
}

@media (max-width: 480px) {
    .watchLaterTab__removed__item {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-right: 16px;
    }

    .watchLaterTab__removed__thumbnail {
        display: none;
    }

    .watchLaterTab__removed__data {
        display: block;
    }
    .watchLaterTab__removed__undo {
        display: flex;
    }
}

.eventsplatform .tag-chip {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 13px;
    font-weight: bold;
    padding: 0 6px;
    margin-right: 8px;
    margin-top: 5px;
}

.eventsplatform .tag-chip i {
    position: relative;
    top: 1px;
    margin-left: 4px;
    color: black;
}

.eventsplatform .agenda__title {
    margin-bottom: 32px;
}

.eventsplatform .agenda__filters {
    width: 100%;
    display: flex;
    margin: 24px 0;
}

.eventsplatform .agenda__search {
    width: 196px;
    height: 28px;
    margin-right: 16px;
}

.eventsplatform .agenda__sorter {
    display: flex;
    margin-left: auto;
}

.eventsplatform .agenda__sorter-dropdown {
    min-width: 100px;
    margin-right: 8px;
}

.checkbox-custom-icon.eventplatform-check {
    box-sizing: border-box;
    line-height: 18px;
    vertical-align: auto;
}

.eventplatform-checkbox {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    text-align: center;
}

.eventplatform-checkbox--checked {
    border: none;
}

.eventsplatform .agenda__tags-filter {
    margin: 24px 0;
}

.eventsplatform .agenda-page .tab-pane {
    margin-bottom: 16px;
}

.eventsplatform .agenda-page .agenda-tabs__nav li a {
    padding: 16px 0 14px 0;
    margin-right: 25px;
}

.eventsplatform .agenda-page .agenda-tabs__nav li a:hover {
    padding: 16px 0 14px 0;
    margin-right: 25px;
}

.agenda__tags-filter button.btn-borderless-eventplatform {
    text-transform: capitalize;
}

/* style btn-view-earlier, btn-view-later and btn-view-more as links instead of buttons */
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later,
.eventsplatform button.btn-view-more.btn-view-more.btn-view-more.btn-view-more,
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier:hover,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later:hover,
.eventsplatform
    button.btn-view-more.btn-view-more.btn-view-more.btn-view-more:hover,
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier:focus,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later:focus,
.eventsplatform
    button.btn-view-more.btn-view-more.btn-view-more.btn-view-more:focus,
.eventsplatform
    button.btn-view-earlier.btn-view-earlier.btn-view-earlier.btn-view-earlier:active,
.eventsplatform
    button.btn-view-later.btn-view-later.btn-view-later.btn-view-later:active,
.eventsplatform
    button.btn-view-more.btn-view-more.btn-view-more.btn-view-more:active {
    font-weight: bold;
    line-height: normal;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;
}

.eventsplatform button.btn-view-earlier {
    margin-bottom: 12px;
}

.eventsplatform button.btn-view-later,
.eventsplatform button.btn-view-more {
    margin-top: 12px;
}

.eventsplatform button.btn-view-earlier,
.eventsplatform button.btn-view-later,
.eventsplatform button.btn-view-more {
    color: #006efa;
}

.eventsplatform button.btn-view-earlier:hover,
.eventsplatform button.btn-view-later:hover,
.eventsplatform button.btn-view-more:hover,
.eventsplatform button.btn-view-earlier:focus,
.eventsplatform button.btn-view-later:focus,
.eventsplatform button.btn-view-more:focus,
.eventsplatform button.btn-view-earlier:active,
.eventsplatform button.btn-view-later:active,
.eventsplatform button.btn-view-more:active {
    color: #0055cc;
}

.eventsplatform .my-agenda__no-results {
    text-align: center;
}

.eventsplatform .my-agenda__image-box {
    display: inline-block;
    margin-top: 40px;
    /*background-image: url("../assets/myagenda_light@2x.png");*/
    height: 150px;
    width: 320px;
    background-size: contain;
    background-repeat: no-repeat;
}

.eventsplatform .my-agenda__title {
    text-align: center;
    font-size: 24px;
    margin: 40px 20px 20px 20px;
}

.eventsplatform .my-agenda__sub-title {
    font-size: 16px;
    line-height: 1.25;
    text-align: center;
    color: #939393;
}
/* ie adjustments */
.agenda__sorter-dropdown .dropdown-eventplatform__control {
    min-width: 128px;
    white-space: nowrap;
}
.agenda__sorter-dropdown .dropdown-eventplatform__control .value-container {
    display: inline-block;
    text-overflow: ellipsis;
}
/* end of ie adjustments */

.eventsplatform .agenda-page .tab-content {
    padding-bottom: 25px;
}

.agenda__description {
    margin-bottom: 24px;
    width: 757px;
    max-width: 100%;
    font-size: 16px;
}

@media (max-width: 979px) {
    .eventsplatform .agenda__title.agenda__title.agenda__title {
        font-size: 32px;
        margin-bottom: 16px;
    }
}

@media (max-width: 768px) {
    .eventsplatform label:nth-child(2) .dropdown-eventplatform__menu {
        right: 50%;
        transform: translateX(50%);
    }
    .eventsplatform .agenda__filters {
        flex-direction: column;
    }

    .eventsplatform .agenda__search {
        min-width: 100%;
    }

    .eventsplatform .agenda__sorter {
        flex-wrap: wrap;
        margin-left: 0;
    }

    .eventsplatform .agenda__sorter-dropdown {
        margin: 16px 8px 0 0;
    }

    .eventsplatform .agenda__filters {
        margin: 16px 0;
    }
}

@media (max-width: 480px) {
    .eventsplatform .my-agenda__image-box {
        height: 136px;
        width: 290px;
    }

    .eventsplatform .my-agenda__title {
        margin: 20px;
    }
}

/* Margin between sections */
* + .homepage-eventplatform__component {
    margin-top: 60px;
}

@media (max-width: 979px) {
    * + .homepage-eventplatform__component {
        margin-top: 40px;
    }
}

.channel-header {
    height: 285px;
    color: #ffffff;
    display: flex;
    line-height: normal;
    padding-top: 112px;
}

.btn.channel-header__link.channel-header__banner__newrow-link.btn-cta-eventplatform {
    display: inline-flex;
}

.channel-header__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    max-height: 397px;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.4);
}
.channel-header__content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.channel-header__details {
    display: flex;
    overflow: hidden;
}
.channel-header__text-and-links-wrapper {
    overflow: hidden;
}
.channel-header__logo {
    max-width: 76px;
    min-width: 76px;
    max-height: 76px;
    min-height: 76px;
    border-radius: 44px;
    border: solid 2px #ffffff;
}
.channel-header__logo-wrapper {
    margin-right: 40px;
}
.channel-header__tier {
    font-size: 11px;
    font-weight: bold;
    border-radius: 4px;
    border: solid 1px #ffffff;
    height: 13px;
    padding: 1px 4px;
    display: table;
}
.channel-header__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 48px;
    font-weight: bold;
    display: inline-block;
    max-width: 100%;
}
.channel-header__description {
    max-width: 490px;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: normal;
    margin-top: 5px;
}
.channel-header__description .truncateManager__more,
.channel-header__description .truncateManager__less-link,
.channel-header__description .truncateManager__less-link:hover,
.channel-header__description .truncateManager__more:hover {
    color: #ffffff;
    font-weight: normal;
    text-decoration: underline;
}
.channel-header__description .truncateManager__less {
    display: flex;
}
.channel-header__links {
    margin-top: 24px;
}
a.channel-header__link.channel-header__banner__newrow-link:hover {
    color: #ffffff !important;
    background-color: #0055cc !important;
}
a.channel-header__link {
    margin-right: 8px;
    padding: 0 16px 0 8px;
    color: #ffffff;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
}
a.channel-header__banner__lets-chat-link:hover,
a.channel-header__banner__external-link:hover {
    color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
}
.channel-header__link-icon {
    font-size: 24px;
    vertical-align: sub;
}
.channel-header__link--secondary {
    color: #ffffff;
}
.channel-header__media {
    margin-left: 24px;
    min-width: 407px;
    height: 229px;
    overflow: hidden;
}
.channel-header__img {
    border-radius: 8px;
}
.channel-header__video {
    width: 100%;
    height: 100%;
    position: relative;
}

@media (max-width: 1199px) {
    .channel-header__description {
        max-width: 397px;
    }
    .channel-header__logo-wrapper {
        margin-right: 16px;
    }
    .channel-header__links {
        margin-top: 16px;
    }
    .channel-header__media {
        width: 391px;
        height: 220px;
    }
}

@media (max-width: 979px) {
    .channel-header__details {
        flex-direction: column;
    }

    .channel-header__logo {
        max-width: 44px;
        min-width: 44px;
        max-height: 44px;
        min-height: 44px;
    }
    .channel-header__logo-wrapper {
        margin-right: 24px;
    }
    .channel-header {
        height: 220px;
    }
    .channel-header__media {
        max-width: 291px;
        min-width: 291px;
        max-height: 164px;
        min-height: 164px;
    }
    .channel-header__banner {
        max-height: 332px;
    }
    .channel-header__title {
        font-size: 32px;
        margin-top: 4px;
    }
    .channel-header__tier {
        margin-top: 9px;
    }
    .channel-header__links {
        margin-top: 13px;
    }
}

@media (max-width: 767px) {
    .channel-header__logo-wrapper {
        margin-right: 8px;
    }
    .channel-header__content-wrapper {
        flex-direction: column;
    }
    .channel-header__logo {
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
    }
    .channel-header {
        position: relative;
        height: 100%;
        flex-direction: column;
        padding-top: 92px;
        padding-bottom: 25px;
    }
    .channel-header__banner {
        max-height: 100%;
        width: calc(100% + 56px);
        margin-left: -28px;
        margin-top: -22px;
        padding-bottom: 24px;
    }
    .channel-header__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 193px;
        max-height: 193px;
        flex-basis: 100%;
        text-align: center;
        margin: 16px auto auto 0;
    }
    @media (max-width: 320px) {
        .channel-header__links .btn {
            font-size: 12px;
        }
        .btn.channel-header__link.channel-header__banner__newrow-link.btn-cta-eventplatform {
            font-size: 12px;
        }
    }
}

.event-channel__sections {
    margin-top: 20px;
}
.event-channel__sections__nav {
    margin-bottom: 12px;
    display: flex;
}
.event-channel__section-content {
    overflow: inherit;
    margin-top: 24px;
}
.channel-sections__section {
    white-space: nowrap;
}

#event-channel__section-content.event-channel__section-content.tab-content {
    overflow: visible !important;
}

h3.video-item__name {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    margin: 0;
}
.video-item__name-wrapper {
    padding: 0 16px;
}
.video-item__img-wrapper {
    height: 213px;
}
.video-item__img--no-image {
    background-color: black;
}

.media-thumbnail__duration {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 4px;
    font-size: 14px;
    padding: 4px 5px;
}
@media (max-width: 1199px) {
    .video-item__img-wrapper {
        height: 185px;
    }
}

@media (max-width: 979px) {
    .video-item__img-wrapper {
        height: 140px;
    }
}

@media (max-width: 480px) {
    .video-item__img-wrapper {
        height: 204px;
        width: 100%;
    }
}

.event-videos__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.event-videos__item {
    height: 281px;
    width: 378px;
    border-radius: 4px;
    overflow: hidden;
}
/* system width 1140 */
@media (max-width: 1335px) {
    .event-videos__item {
        width: 358px;
        height: 281px;
    }
}
/* system width 960 */
@media (max-width: 1199px) {
    .event-videos__item {
        width: 309px;
        height: 242px;
    }
}
/* system width 720 */
@media (max-width: 979px) {
    .event-videos__item {
        width: 229px;
        height: 208px;
    }
}
/* system width auto */
@media (max-width: 480px) {
    .event-videos__item {
        width: auto;
        height: 272px;
    }
}

.channelActionsBtn__wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.btn-group {
    margin-top: auto;
}

.channelActionsBtn__wrapper button.btn.btn-borderless-eventplatform {
    display: flex;
    align-items: center;
    color: #006bff;
}

.channelActionsBtn__wrapper .btn-group.open .btn.dropdown-toggle {
    background-color: #e6e6e6;
}

.channelActionsBtn__wrapper .dropdown-menu {
    border-radius: 6px;
}

.channelActionsBtn__wrapper li {
    height: auto;
}

.channelActionsBtn__wrapper a:hover {
    font-weight: inherit;
}

.board-message__close-button {
    top: 0;
    right: 0;
    font-size: 18px;
    font-weight: bold;
    color: inherit;
}
.board-message {
    display: flex;
    width: 100%;
    font-size: 13px;
    box-sizing: border-box;
    padding: 5px 3px 5px 15px;
    margin: 12px 0;
    background-color: #e0edfe;
    color: #006efa;
    border-radius: 2px;
    align-items: center;
}
.board-message__text {
    display: inline-block;
}
.board-message__icon-info {
    font-size: 18px;
    margin-right: 11px;
}
.board-message__close {
    margin-left: auto;
}

/**
 * Event Platform Playlist as a channel component css
 */

.playlistChannel__title {
    margin-bottom: 32px;
}

.playlistChannel__description {
    margin-bottom: 32px;
    margin-top: -16px;
    width: 690px;
    line-height: 1.31;
    font-size: 16px;
}

@media (max-width: 1200px) {
    .playlistChannel__description {
        width: 770px;
    }
}

@media (max-width: 979px) {
    .playlistChannel__description {
        margin-bottom: 24px;
        width: 100%;
    }
}

/**
 * event playform cognito login form css
 */

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-cognito__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-cognito__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-cognito__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-cognito__field-input {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: 50px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 8px;
}

/* reverse chrome autofill style */
input.login-cognito__field-input:-internal-autofill-selected,
input.login-cognito__field-input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

.login-cognito__label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 12px;
    padding-top: 0;
    display: inline-block;
}

.login-cognito__error-message {
    margin-top: 40px;
    margin-bottom: 24px;
}

.login-cognito-info {
    font-size: 16px;
    text-align: center;
}

.login-cognito-error {
    font-size: 14px;
    font-weight: bold;
}

.form-control.login-cognito__form-container {
    margin-top: 16px;
}

.login-cognito__buttons-container {
    margin-top: 30px;
}

/* make sure the button is always 100% width */
.eventsplatform.reinvent
    .login-cognito__buttons-container
    button.loading.login-cognito__submit-button,
button.btn.login-cognito__submit-button {
    width: 100%;
    box-sizing: border-box;
}

.login-cognito-password-validation__icon {
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 8px;
}

.form-control-tnc {
    font-size: 13px;
    text-align: center;
}

.form-control-tnc a {
    font-weight: 900;
}

/**
 * event playform cognito login css
 */

.event-platform-cognito-login-wrapper {
    width: 100%;
}

.event-platform-cognito-videofile {
    display: block;
    position: absolute;
    right: 0;
    width: 64%;
}

.event-platform-cognito-videofile video {
    height: auto;
    width: 100%;
}

.event-platform-cognito-login-box-wrapper {
    padding-top: 153px;
    position: relative;
}

.event-platform-cognito-login--logo {
    text-align: center;
    margin-bottom: 24px;
}
.event-platform-cognito-login--title {
    text-align: center;
    font-weight: 300;
    font-size: 24px;
}

.event-platform-cognito-login-form-wrapper {
    margin: 0 auto;
    max-width: 480px;
    padding: 48px 80px 80px 80px;
    background-color: #f4f4f4;
    border: 1px solid #e3e3e3;
}

.event-platform-cognito-login--link-wrapper {
    text-align: center;
}

a.event-platform-cognito-login--link {
    font-weight: bold;
    font-size: 14px;
    margin-top: 4px;
    padding-right: 14px;
    padding-left: 14px;
}

a.event-platform-cognito-login--link-signup {
    border-right-style: solid;
    border-right-width: 1px;
}

.event-platform-cognito-login--top-text {
    text-align: center;
    margin: 16px 0 32px;
    font-size: 14px;
}

/* phone variant */
@media (max-width: 480px) {
    .event-platform-cognito-videofile {
        display: none;
    }

    .event-platform-cognito-login-form-wrapper {
        padding: 100px 16px 0 16px;
        border: none;
    }

    .event-platform-cognito-login-box-wrapper {
        padding-top: 0;
    }

    .event-platform-cognito-login--title {
        margin-bottom: 40px;
    }
}

.cognito-admin-user-search-form {
    text-align: center;
}

.cognito-admin-user-search-form input,
.cognito-admin-user-search-form .add-on {
    height: 36px;
    font-size: 16px;
}

.cognito-admin-user-search-form input {
    width: 70%;
}

.cognito-admin-user-search-form .add-on i {
    margin-left: 0;
}

.cognito-admin-status-wrapper .alert {
    width: 77%;
    margin-left: auto;
    margin-right: auto;
}

.cognito-admin-status .table:not(.mediaTable) td {
    font-size: 16px;
}

.cognito-admin-status .hero-unit {
    font-weight: normal;
    padding: 40px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.cognito-admin-status-content {
    background-color: white;
}

/**
 *  cognito login form css
 */

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-webhookregistration__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-webhookregistration__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-webhookregistration__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-webhookregistration__field-input {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: 50px;
    background-color: #fff;
    color: #000;
    border: solid 1px grey;
    border-radius: 0px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 8px;
    opacity: 0.8;
}

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-webhookregistration__field-input.login-webhookregistration__field-input--error,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-webhookregistration__field-input.login-webhookregistration__field-input--error,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-webhookregistration__field-input.login-webhookregistration__field-input--error,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-webhookregistration__field-input.login-webhookregistration__field-input--error {
    border: solid 1px #d20156;
}

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-webhookregistration__field-input:focus,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-webhookregistration__field-input:focus,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-webhookregistration__field-input:focus,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-webhookregistration__field-input:focus {
    outline: none;
    border: solid 1px #000;
    background-color: var(--black-30);
}

/* reverse chrome autofill style */
input.login-webhookregistration__field-input:-internal-autofill-selected,
input.login-webhookregistration__field-input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

.login-webhookregistration__label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 12px;
    padding-top: 0;
    display: inline-block;
}

.login-webhookregistration__error-message {
    margin-top: 40px;
    margin-bottom: 24px;
    color: #ff5050;
}

.login-webhookregistration-info {
    font-size: 16px;
    text-align: center;
}

.login-webhookregistration-error {
    font-size: 14px;
    font-weight: bold;
    color: #ff5050;
}

.form-control.login-webhookregistration__form-container {
    margin-top: 16px;
}

.login-webhookregistration__buttons-container {
    margin-top: 30px;
}

/* make sure the button is always 100% width */
.webhookregistration button.loading.login-webhookregistration__submit-button,
.webhookregistration button.btn.login-webhookregistration__submit-button {
    width: 100%;
    box-sizing: border-box;
    background-color: #008297;
    color: #fff;
}

.login-webhookregistration-password-validation {
    color: #000;
}

.login-webhookregistration-password-validation__icon {
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 8px;
}

.login-webhookregistration-password-validation__weak {
    color: #ff5050;
}

.login-webhookregistration-password-validation-field__valid {
    color: #3ec24e;
}

.form-control-tnc {
    font-size: 13px;
    text-align: center;
    color: #000;
}

.form-control-tnc a {
    font-weight: 900;
}

/**
 * cognito login css
 */

.webhookregistration-cognito-login-wrapper {
    width: 100%;
}

.webhookregistration-cognito-login-box-wrapper {
    padding-top: 153px;
    position: relative;
}

.webhookregistration-cognito-login--title {
    margin: 0 0 24px 4px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
}
.webhookregistration-cognito-login--sub-title {
    text-align: center;
}

.webhookregistration-cognito-login-form-wrapper {
    margin: 0 auto;
    max-width: 480px;
    min-height: 480px;
    color: #000;
    background-color: #f4f4f4;
    padding: 65px 80px 80px 80px;
    border: solid 1px #5a5a5a;
}

.webhookregistration-cognito-login-form-header {
    margin: 0 auto;
    max-width: 640px;
}

.webhookregistration-cognito-login-form-header
    .react-dropdown
    .btn.dropdown-toggle.language-menu--button {
    padding-left: 8px;
}

.webhookregistration-cognito-login--success-wrapper {
    text-align: center;
    margin-top: 140px;
}
.webhookregistration-cognito-login--success--text {
    margin: 16px 0 0;
    font-size: 14px;
    line-height: 1.29;
    text-align: center;
}

/* phone variant */
@media (max-width: 480px) {
    .webhookregistration-cognito-login-form-wrapper {
        padding: 100px 16px 0 16px;
        border: none;
    }

    .webhookregistration-cognito-login-box-wrapper {
        padding-top: 0;
    }
}

.event-placeholder {
    display: flex;
    font-size: 13px;
    border-radius: 4px;
    margin-bottom: 24px;
}

.event-placeholder__details {
    flex-grow: 1;
}

.event-placeholder__container {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding: 16px 24px 0 24px;
    background-color: #f4f4f4;
}

.event-placeholder__thumbnail {
    width: 291px;
    height: 164px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.event-placeholder__title {
    font-size: 18px;
    margin: 0;
    font-weight: 900;
}

.event-placeholder__description {
    margin: 8px 0 0;
    font-size: 14px;
    line-height: 1.43;
    padding-right: 100px;
}

.event-placeholder__action {
    display: flex;
    margin: 8px 0 16px;
    align-items: center;
}
.event-placeholder__action :hover,
.event-placeholder__action :focus {
    text-decoration: none;
}

.event-placeholder__link {
    display: flex;
    justify-content: center;
    font-size: 14px;
    max-height: 32px;
    padding: 4px 20px 4px 8px;
    color: #006efa;
}

.event-placeholder__link:hover {
    color: #006efa;
}

.event-placeholder__link span {
    max-height: 17px;
    margin: 3px 0 4px 4px;
    font-size: 14px;
    font-weight: 900;
}

.event-placeholder__link-icon {
    font-size: 24px;
}

@media (max-width: 767px) {
    .event-placeholder {
        display: flex;
        flex-direction: column;
        margin: 0;
        font-size: 13px;
        border-radius: 4px;
        max-height: 100%;
    }
    .event-placeholder__thumbnail {
        margin-top: 16px;
        width: 100%;
        height: auto;
        text-align: center;
    }
    .event-placeholder__title {
        margin-top: 16px;
    }
    .event-placeholder__container {
        padding: 8px 24px;
    }
    .event-placeholder__description {
        padding-right: 0;
    }
    .event-placeholder__action {
        display: flex;
        flex-wrap: wrap;
        margin: 8px 0 0 0;
        align-items: center;
        max-width: 343px;
    }
    .event-placeholder__link {
        display: flex;
        justify-content: center;
        max-width: 100%;
        max-height: 32px;
        margin: 0 8px 8px 0;
        color: #2d9bd6;
    }
}

/*@media (min-width: 480px) and (max-width: 767px) {*/
/*    .event-placeholder__container {*/
/*        padding: 24px 16px 0 24px;*/
/*    }*/
/*}*/

@media (min-width: 768px) and (max-width: 979px) {
    .event-placeholder__thumbnail {
        width: 222px;
    }
}

.list-page {
    display: flex;
    flex-direction: column;
}

.list-page__title {
    font-size: 48px;
    color: #333333;
}

.list-page__description {
    max-width: 690px;
    margin: 16px 0 32px 0;
    font-size: 16px;
    line-height: 1.31;
}

@media (max-width: 479px) {
    .list-page__description {
        max-width: 343px;
        margin: 16px 0 0 0;
        font-size: 16px;
    }
    .list-page__title {
        font-size: 32px;
        margin: 0;
    }
}

.entry-list__title {
    font-size: 48px;
    margin-bottom: 32px;
    font-weight: 900;
}

.event-channel-list__list {
    margin-top: 32px;
}

@media (max-width: 979px) {
    .entry-list__title.entry-list__title.entry-list__title {
        font-size: 32px;
        margin-bottom: 16px;
    }
}

@media (max-width: 480px) {
    .entry-list__item {
        margin-bottom: 24px;
    }
}

.entry-list-item__container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    border-radius: 4px;
    overflow: hidden;
}

.entry-list-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 1;
    padding: 16px 28px 16px 16px;
}

.entry-list-item {
    width: 100%;
    margin-bottom: 16px;
}

.entry-list-item__name {
    font-size: 20px;
    line-height: normal;
    font-weight: bold;
}

.entry-list-item__details {
    max-width: 800px;
    width: auto;
}

.entry-list-item__description {
    margin-top: 4px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: normal;
}

.entry-list-item__link:hover {
    text-decoration: none;
}
.event-list-item__scheduling {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
}
.event-list-item__scheduling span {
    margin-right: 8px;
}
.entry-list-item__link {
    font-size: 14px;
    display: flex;
    align-items: center;
}
.entry-list-item__link .eventplatform-go-to {
    font-size: 24px;
}
.entry-list-item__link-text {
    vertical-align: super;
}

@media (max-width: 979px) {
    .entry-list-item__img {
        display: none;
    }
    .entry-list-item__content {
        flex-direction: column;
    }
    .entry-list-item__container {
        height: 126px;
    }
    .entry-list-item__details,
    .entry-list-item__link {
        margin-right: auto;
    }
}

@media (max-width: 480px) {
    .entry-list-item__name {
        font-size: 18px;
    }
}

.tv-entry-hero-section-wrapper {
    overflow: hidden;
}

.forgotPassword__submit-button {
    width: 100%;
}

.forgotPassword__field-input {
    width: 100%;
}

.password-validation__icon-wrapper {
    margin-right: 4px;
}
.password-validation-field {
    display: flex;
    align-items: baseline;
    margin-top: 4px;
}

.password__field-input {
    width: 100%;
}

.resetPassword__submit-button {
    width: 100%;
}

.resetPassword-complete-text {
    font-size: 16px;
}

.qr-code-wrapper {
    clear: both;
    margin-top: 24px;
    margin-bottom: 24px;
    text-align: center;
}

.qr-code-wrapper > p {
    margin: 24px auto;
    width: 412px;
}

.qr-code-stores-wrapper > a:first-child {
    margin-right: 32px;
}

.password__field-input {
    width: 100%;
}

.setPassword__submit-button {
    width: 100%;
}

.sßetPassword-complete-text {
    font-size: 16px;
}

.login-error {
    color: #e12437;
}

.password__field-input {
    width: 100%;
}

.passwordExpired__submit-button {
    width: 100%;
}

/**
 * make the autocomplete field look like the rest of
 * the form fields, resetting some DS styling. 
 */

form:not(.searchForm):not(.navbar-search)
	.registration_autocompletefield
	input[type="text"]:not(.tags-input-field) {
	height: 24px;
	border: none;
	margin-bottom: 0;
}

form:not(.searchForm):not(.navbar-search)
	.registration_autocompletefield
	input[type="text"]:not(.tags-input-field):focus {
		outline: none;
	}

.registration__form-container.registration_autocompletefield
	.kepuikit-autocomplete-input {
	height: 36px;
}

/* modal selecyion Field css */

.row-fluid .span8.form-field-modalselection {
    margin-left: 0;
}

.siteRegistration-modalselection_values {
    margin-top: 16px;
}

.siteRegistration-modalselection_value {
    display: inline-block;
}

.siteRegistration-modal h4 {
    margin-top: 8px;
}

.siteRegistration-text {
    margin: 40px 0 -20px 0;
}

.siteRegistration-modal.bootbox.fade {
    transform: translate(0, 0) scale(0.9);
}
.siteRegistration-modal.bootbox.fade.in {
    transform: translate(0, 0) scale(0.9);
}
.btn.siteRegistration-modalselection__button:focus {
    background-color: transparent;
}

.siteRegistration-modal--close {
    text-align: right;
}
button.btn.siteRegistration-modal--close-button {
    font-size: 24px;
    padding: 0;
}

/**
 *  
 */

.siteRegistration-form-section {
    background-color: #f4f4f4;
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    margin-bottom: 32px;
}

.form-section-headline {
    font-size: 20px;
    line-height: 22px;
}

.siteRegistration__field-input {
    width: 100%;
}

.siteRegistration-form-checkbox-wrapper .checkbox-custom-wrapper {
    display: flex;
    align-items: flex-start;
}

.siteRegistration-form-checkbox-wrapper .checkbox-custom-label {
    margin-top: 0;
}

.siteRegistration-form-radio-wrapper  .checkbox-custom-wrapper {
    margin-top: 4px;
    margin-right: 16px;
}


.siteRegistration-recaptcha-container {
    margin-bottom: 32px;
}

.siteRegistration-verification {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
}

.siteRegistration-verification-inner {
    max-width: 475px;
}

.progressBar-container {
    height: 4px;
    background-color: #cccccc;
    margin-bottom: 4px;
}

.progressBar__inner {
    float: left;
    height: 4px;
}

.progressBar--complete {
    background-color: #8dc63f;
}

.progressBar--uploading {
    background-color: #008297;
}

.inviteUsers-form-actions {
    margin-top: 20px;
}

.inviteUsers-form .uploaded-file-name {
    margin-right: 20px;
}

.inviteUsersProgress.progress {
    width: 100%;
}

.inviteUsersProgress.alert {
    word-break: break-all;
}

/**
 *  Categories search results - tablet
 */

.CategorySearchResults__header {
    display: inline-block;
    height: 69px;
    line-height: 78px;
    width: 50%;
}

.CategorySearchResults__results-gallery {
    border-top: 1px solid;
    margin: 0;
    display: inline-block;
    width: 100%;
}

.CategorySearchResults__resultsLink {
    line-height: 69px;
    text-decoration: underline;
    text-align: right;
    display: inline-block;
    width: 50%;
}

.CategorySearchResults__results-channel {
    display: flex;
}

.MobileCategorySearchResults__wrapper {
    margin-top: 16px;
    margin-bottom: 16px;
}

.MobileCategorySearchResults__border {
    font-size: x-large;
    font-weight: 100;
    vertical-align: middle;
    width: 10%;
    display: inline-block;
    text-align: center;
}

.MobileCategorySearchResults__galleryResults {
    vertical-align: middle;
    display: inline-block;
}

.MobileCategorySearchResults__channelResults {
    vertical-align: middle;
    display: inline-block;
}

.horizontalCategorySearchResultsWrapper {
    border-top: 1px solid;
}

/**
 *	css for the global search page
 */

@media (min-width: 1025px) {
    .globalSearchResults__horizontalSidebar {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .globalSearchResults__verticalSidebar {
        display: none !important;
    }
}

.my-media__table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* fix the checkbox in place - when tags expand */
.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column.my-media-table__checkboxes {
    margin-top: 22px;
}
.my-media__table-actions-header > div {
    text-transform: uppercase;
    text-align: left;
    padding: 19px 11px;
    font-size: 13px;
    font-weight: bold;
}

/* leave more room to the internal html - without increasing row height */
.my-media-table.ReactTable .rt-td {
    padding-top: 14px;
    padding-bottom: 14px;
}
.my-media__table-cell-tags,
.my-media__table-cell-publish {
    padding-top: 4px;
    padding-bottom: 0;
    min-height: 20px;
}
.media-entry-results .ReactTable .links-list {
    top: 0;
}

.my-media__table-cell--name {
    padding-left: 0;
    font-weight: bold;
}
.table-extra-actions {
    margin-right: 20px;
}

.my-media__table-cell--actions .my-media__actions {
    justify-content: flex-start;
}

.my-media__table-cell--actions .my-media__actions-link:first-child {
    margin-right: 16px;
}

/*
 * default buttons colors
 */
.my-media__actions-table .my-media__actions-link {
    color: #666666;
}

.my-media__actions-table .my-media__actions-link:hover,
.my-media__actions-link:focus {
    color: #000000;
}

.my-media__actions-table
    .my-media__actions-link.my-media__actions-link--disabled,
.my-media__actions-table
    .my-media__actions-link.my-media__actions-link--disabled:hover,
.my-media__actions-table
    .my-media__actions-link.my-media__actions-link--disabled:focus {
    color: #cccccc;
}

.my-media-table__checkboxes--top {
    margin-top: 15px;
}

.my-media__table-cell--mediaType {
    text-align: center;
    padding-right: 11px;
}

.my-media__table-header--mediaType .rt-resizable-header-content {
    text-align: center;
}

.my-media__table-cell--inner {
    display: inline;
}

/**
 *  My media buttons css
 */

.my-media__actions {
    display: inline-flex;
    justify-content: space-around;
    align-self: flex-start;
}

.my-media__actions-link {
    text-decoration: none;
    border: none;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.my-media__actions-link:hover,
.my-media__actions-link:focus {
    color: #666666;
    text-decoration: none;
}

.my-media__actions-link.my-media__actions-link--disabled,
.my-media__actions-link.my-media__actions-link--disabled:hover,
.my-media__actions-link.my-media__actions-link--disabled:focus {
    color: #cccccc;
}

.my-media-entry__container {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 10px;
    line-height: normal;
    line-height: initial;
    flex: 1 0 auto;
    max-width: 100%;
}

.my-media-entry {
    display: flex;
}

.media-preview {
    display: inline-block;
    flex-shrink: 0;
    width: 322px;
    height: 181px;
}

.my-media-entry__container .my-media-entry-details {
    flex: 1 1 auto;
}

.my-media-entry__name-and-description {
    height: 100%;
}

.my-media-entry__basic .my-media-entry__name {
    display: flex;
    flex: 0 1 20px;
}

.my-media-entry__basic .my-media-entry__name > a {
    flex: 1 1;
    min-width: 0;
    overflow: hidden;
}

.my-media-entry__basic .my-media-entry__name > a:hover span {
    text-decoration: underline;
}

.my-media-entry__name .results-entry__name {
    white-space: normal;
    max-width: 100%;
}

.my-media-entry__basic {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.my-media-entry-details {
    padding-left: 35px;
    overflow: hidden;
}

.my-media-entry-details--shrink {
    width: 100%;
}

.my-media-entry__status {
    display: inline-block;
    padding: 2px 30px;
    color: #ffffff;
    border: 0;
    font-size: 12px;
    line-height: normal;
}

.my-media-entry__bottom-description {
    position: static;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    font-size: 12px;
    color: #666;
    line-height: 1;
}

.my-media-entry__bottom-description
    .bottom-description__item:not(:first-child) {
    margin-left: 6px;
}

.my-media-entry__statistics .entryStatistics__stat {
    margin-left: 16px;
    margin-right: 0;
}

/**
 *  cause the two sections of the bottom stats to take one line,
 *  and overflow each seperatly with ellipsis.
 *
 *  in mobile there is just one multi-line stat section.
 */
@media (min-width: 480px) {
    .bottom-description__section,
    .bottom-description__item.my-media-entry__statistics {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;
    }
}

.my-media-entry__bottom-description
    .bottom-description__item.my-media-entry__statistics {
    margin-left: 14px;
}

.my-media-entry__tags-container {
    min-height: 0;
}

.my-media-entry__buttons {
    flex: 0 0 60px;
    display: flex;
    justify-content: space-around;
}
.my-media-entry__extra-buttons {
    margin-right: 8px;
    margin-left: 14px;
    margin-top: -2px;
}
.my-media-entry__extra-buttons--search-results {
    margin-top: -1px;
}

.my-media-entry__extraInfo {
    margin-top: 8px;
    font-weight: bold;
}
.my-media-entry__extraInfo-icon {
    margin-right: 5px;
    color: #da4f49;
}

@media (max-width: 480px) {
    .my-media-entry__extraInfo {
        display: none;
    }
}

@media (max-width: 1200px) {
    .my-media-entry-details {
        padding-left: 20px;
    }
}

@media (max-width: 980px) {
    .my-media-entry__basic .my-media-entry__tags-container {
        min-height: 0;
    }
}

/**
 * limit media entry width
 */

@media (max-width: 1200px) {
    .media-preview {
        width: 300px;
        height: 169px;
    }
}

@media (max-width: 980px) {
    .media-preview {
        width: 290px;
        height: 163px;
    }

    .my-media-entry__tags-container {
        min-height: 35px;
    }

    .my-media-entry-details .my-media-entry__name .results-entry__name {
        max-width: 200px;
    }
}

/**
 * tablet alignment
 */
@media (max-width: 767px) {
    .media-preview {
        width: 258px;
        height: 145px;
    }

    .my-media-entry-details {
        padding-left: 16px;
    }
}

/**
 *  mobile alignment
 */
@media (max-width: 480px) {
    /**
     *  make the (first) stat section multi-line
     */
    .bottom-description__section {
        max-width: 100%;
        line-height: 1.33;
        white-space: normal;
    }

    .media-preview {
        width: 171px;
        height: 96px;
    }

    .my-media-entry-details {
        min-height: 69px;
    }
}

/**
 * actions modifier
 */

/************************/
/**** Media Queries *****/
/************************/

@media (max-width: 767px) {
    .media-preview {
        vertical-align: top;
    }

    .my-media-entry__container {
        padding: 0;
    }

    .my-media-entry__basic .my-media-entry__name {
        flex-basis: 100px;
    }
}

/**
 *  compensate for the lack of description and tags
 */
@media (max-width: 480px) {
    .my-media-entry__basic .my-media-entry__name {
        flex-basis: 56px;
    }
}

.my-media-status-content__popover {
    display: inline-flex;
    flex-direction: column;
    padding: 17px 24px;
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
}

.my-media-status-content__container:not(:first-child) {
    margin-top: 20px;
}

.my-media-status-content__title {
    font-size: 16px;
    font-weight: normal;
}

.my-media-status-content__categories {
    margin-top: 10px;
}

.my-media-status-content__category {
    display: flex;
    align-items: baseline;
}

.my-media-status-content__category-items {
    margin-left: 45px;
}

.my-media-entry__status {
    position: relative;
}

.my-media-entry__status--warning {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #fcd00d;
    margin-left: 12px;
}

/**
 * My Media Entry Results (List - details, collapsed) css
 */

.ReactTable.-view-type-detailed.-selection-table .rt-tbody .rt-td {
    display: flex;
}

.ReactTable.-view-type-detailed,
.ReactTable.-view-type-collapsed {
    border-top: 0;
}

/**
 * align the list views - details and collapsed, with the
 * table view.
 */
.ReactTable.-selection-table.-view-type-detailed
    .rt-thead
    .rt-th.selection--table--checkbox--column,
.ReactTable.-selection-table.-view-type-collapsed
    .rt-thead
    .rt-th.selection--table--checkbox--column {
    padding-top: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

/**
 * these cause additional spacing, and are invisible in list views.
 */
.ReactTable.-selection-table.-view-type-detailed
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th,
.ReactTable.-selection-table.-view-type-collapsed
    .rt-thead
    .rt-th.selection--table--checkbox--column
    + .rt-th {
    display: none;
}

/**
 *  adjust to my media cells size
 */
.ReactTable.-view-type-detailed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    margin-top: 18px;
}

.ReactTable.-view-type-collapsed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    margin-top: 22px;
}

/* search results */
.ReactTable.search-detailed.-view-type-detailed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column,
.ReactTable.search-detailed.-view-type-collapsed.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column {
    margin-top: 10px;
}

.radio-button-group__container {
    position: relative;
    display: inline-block;
    font-size: 0;
    white-space: nowrap;
    vertical-align: middle;
}

.radio-button-group__container .radioButton {
    margin-top: 0;
    margin-left: 10px;
}

.radio-button {
    display: inline-block;
    vertical-align: middle;
    color: #999999;
    cursor: pointer;
    text-shadow: none;
    text-align: center;
    line-height: 20px;
    padding: 4px 0;
    margin-bottom: 0;
    border-color: transparent !important;
    background-color: transparent;
    box-shadow: none;
    font-size: 32px;
    transition: color 0.2s;
}

.radio-button:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    text-decoration: none;
}

.radio-button:hover,
.radio-button:focus {
    color: #005664;
}

.radio-button.active {
    color: #008297;
}

.my-media-action-bar-controls {
    width: 100%;
}
@media (min-width: 768px) {
    .my-media-action-bar-controls__container > div {
        min-width: 125px;
        padding: 0 5px;
    }
}
@media (max-width: 767px) {
    .react-action-dropdown.my-media-action-bar-controls__add .dropdown-menu {
        left: auto;
        left: initial;
        right: 0;
    }
}

.my-media-action-bar-controls__container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.my-media-action-bar-controls__add {
    margin-left: 10px;
}
button.btn.my-media-action-bar-controls__add__btn {
    padding-right: 8px;
}

.my-media-action-bar-controls .radio-button {
    margin-left: 10px;
}

.my-media-action-bar-controls__container .btn-group > .dropdown-menu {
    right: 0;
    left: auto;
}

@media (max-width: 1200px) {
    .my-media-action-bar-controls__container > div {
        padding: 5px 0;
    }
}

.my-media-action-bar-controls__container .radio-button {
    color: black;
}

.my-media-action-bar-controls__container .radio-button.active {
    color: #008297;
}

.my-media-action-bar-controls__container .radio-button:focus,
.my-media-action-bar-controls__container .radio-button:hover {
    color: #005664;
}

@media (min-width: 980px) {
    .mymediaTable.full tbody tr .description {
        height: 120px;
        margin-bottom: 11px;
    }
}

@font-face {
    font-family: "kmsreact";
    src: url(./fonts/kmsreact.eot);
    src: url(./fonts/kmsreact.eot#iefix) format("embedded-opentype"),
        url(./fonts/kmsreact.woff2) format("woff2"),
        url(./fonts/kmsreact.ttf) format("truetype"),
        url(./fonts/kmsreact.woff) format("woff"),
        url(./kmsreact.8509f88.svg#kmsreact) format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="kmsr-"],
[class*=" kmsr-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "kmsreact" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kmsr-details:before {
    content: "\e900";
}
.kmsr-results:before {
    content: "\e901";
}
.kmsr-send:before {
    content: "\e902";
}
.kmsr-calender:before {
    content: "\e903";
}
.kmsr-search:before {
    content: "\e904";
}
.kmsr-vi:before {
    content: "\e905";
}
.kmsr-close:before {
    content: "\e906";
}
.kmsr-add:before {
    content: "\e907";
}
.kmsr-download:before {
    content: "\e908";
}
.kmsr-edit:before {
    content: "\e909";
}
.kmsr-delete:before {
    content: "\e90a";
}
.kmsr-arrow-down:before {
    content: "\e90b";
}
.kmsr-arrow-left:before {
    content: "\e90c";
}
.kmsr-arrow-right:before {
    content: "\e90d";
}
.kmsr-arrow-up:before {
    content: "\e90e";
}
.kmsr-check-active:before {
    content: "\e90f";
}
.kmsr-check-inactive:before {
    content: "\e910";
}

[class^="kmsr-"],
[class*=" kmsr-"] {
    font-size: 16px;
}

.reach__header {
    padding-bottom: 32px;
}

.page-title {
    color: #000000;
    font-size: 28px;
}

.reach__title {
    color: #008297;
    font-size: 20px;
    line-height: 36px;
    font-weight: normal;
}

button.btn.order-btn {
    font-size: 16px;
    padding: 8px 16px;
}

.info-sign {
    color: #999999;
}

/* dashboard */
.dept-filter {
    display: inline;
}

.dept-filter__title {
    display: inline-block;
    line-height: 36px;
}

.dept-filter__control {
    display: inline-block;
    min-width: 220px;
}

.channel-rules-link {
    display: inline-block;
    line-height: 36px;
}

.table.reach-table {
    border: none;
    font-size: 14px;
    padding-bottom: 12px;
}

/* hide last columns border */
.table.reach-table .ReactTable.-column-separators .rt-thead .rt-th:first-child,
.table.reach-table
    .ReactTable.-column-separators
    .rt-thead
    .rt-th:nth-last-child(2) {
    border-right: none;
}
/* entry table - show first, hide last columns border */
.table.reach-table.reach-table-entry-requests
    .ReactTable.-column-separators
    .rt-thead
    .rt-th:first-child {
    border-right: 1px solid #eaeaea;
}
.table.reach-table.reach-table-entry-requests
    .ReactTable.-column-separators
    .rt-thead
    .rt-th:nth-last-child(3) {
    border-right: none;
}

.ReactTable.-striped .rt-tr.-even + .task-details {
    background: #ffffff;
}

.requests-table-cell {
    text-transform: capitalize;
    line-height: normal;
    margin-left: 5px;
}
.requests-table-cell__arrow-cell {
    padding: 20px;
    line-height: 22px;
}

.requests-table-cell__inline-value {
    display: inline;
}
.requests-table-cell__inline-value .enum-renderer__text {
    margin: 0;
}

.table.reach-table .ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.table.reach-table .ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
}
@media (max-width: 767px) {
    .table.reach-table .ReactTable .rt-thead {
        display: none;
    }
}

.table.reach-table .rt-thead.-header .requests-table-cell {
    padding-top: 15px;
}

.enum-renderer__text {
    margin-right: 10px;
}
.enum-renderer__bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: grey;
    margin-right: 10px;
}

.task-details-inline {
    display: inline;
}
.task-details {
    float: left;
    width: 100%;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 32px 0;
}
.task-details-block {
    float: left;
    width: 45%;
    box-sizing: border-box;
    padding: 0 24px;
}
.task-details-block__info {
    margin-bottom: 10px;
}

.task-details-block__info-feature {
    display: inline-block;
    text-transform: capitalize;
}
.task-details-block__info-label {
    font-weight: bold;
}
@media (max-width: 767px) {
    .task-details-block {
        width: 100%;
    }
    .task-details-block__status-data {
        display: none;
    }
    .task-details {
        padding: 24px 0;
    }
}

/**
 *  OrderForm Component css
 */

.order-form__department {
    padding: 0 0 0 0px;
    margin-bottom: 33px;
}

.order-form_row {
    margin-left: 0 !important;
}
.order-form {
    text-align: left;
    font-size: 14px;
}

.order-form__service-and-source,
.order-form__instructions-container,
.order-form__feature-and-turnaround,
.order-form__submit-box {
    background-color: #f8f8f8;
}

.order-form__instructions-container {
    margin-left: 0 !important;
    max-height: 200px;
    overflow-y: auto;
}

.order-form__welcome {
    padding: 8px 8px 8px 8px;
    margin: 32px 24px 32px 24px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    border-radius: 3.5px;
}

.order-form__welcome a {
    text-decoration: underline;
}
.order-form__welcome a:hover {
    opacity: 0.7;
}

.order-form__service-and-source,
.order-form__instructions {
    border-top: 1px solid #eaeaea;
}

.order-form__submit-box,
.order-form__feature-and-turnaround,
.order-form__service-and-source {
    margin-left: 0 !important;
    padding: 24px 56px 24px 24px;
}

.order-form__instructions {
    margin-left: 0;
    padding: 10px 0 0 0;
    border-top: 0;
}

.order-form__add-speaker-box {
    margin-left: 0 !important;
    display: block;
}

.order-form__instructions {
    margin-bottom: 20px;
}

.order-form__submit-box {
    margin-left: 0 !important;
    overflow: auto;
    border-bottom: 1px solid #eaeaea;
}

.order-form__instructions-textarea {
    width: 100%;
    height: 105px;
    display: block;
    box-sizing: border-box;
    resize: none;
    margin: 0;
}

.order-form__drop-down {
    margin-left: 0 !important;
}

.order-form__department__dropDown {
    padding-right: 16px;
    margin-left: 0 !important;
}

.order-form__submit-button {
    padding: 7px 20px;
    font-size: 16px;
    margin-left: 16px;
}

.order-form__feature-and-turnaround {
    border-bottom: 1px solid #eaeaea;
    border-top: 1px solid #eaeaea;
}

.order-form__target-translation {
    padding-top: 20px;
}

.order-form .Select-menu {
    max-height: 120px;
}

.reach-order-form__turnAroundTime__message {
    display: inline-block;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    .order-form__department__dropDown {
        padding-right: 56px;
    }
}
@media (max-width: 480px) {
    .order-form__department__dropDown {
        padding-right: 20px;
    }

    .order-form__feature-and-turnaround,
    .order-form__service-and-source {
        padding: 25px 20px;
        float: left !important;
    }
    .order-form__instructions {
        padding: 25px 0;
    }
    .order-form__languages,
    .order-form__turnaround-time {
        padding-top: 25px;
    }
    .order-form__instructions {
        margin-bottom: 0;
    }
    .order-form__instructions,
    .order-form__submit-button {
        width: 100%;
    }
    .order-form__submit-box {
        padding: 24px;
    }
}

/**
 * OrderFormDropDown component css
 */

.order-form__drop-down {
    margin-bottom: 20px;
}

.order-form__drop-down--label {
    margin-bottom: 8px;
    display: block;
}

.order-form__drop-down--label--inline {
    padding-right: 16px;
    line-height: 36px;
}

.field-description {
    font-size: 12px;
}
.select-file {
    min-width: 135px;
    min-height: 38px;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 16px;
}
.upload-form-field {
    width: 95%;
}
.field-label {
    margin-top: 20px;
}

.upload__progress {
    height: 36px;
    width: 100%;
    overflow: hidden;
    background-color: #f7f7f7;
    background-image: linear-gradient(to bottom, #f5f5f5, #f9f9f9);
    background-repeat: repeat-x;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    margin: 10px auto;
}
.progress__bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: #92ca3f;
    background-image: linear-gradient(to bottom, #92ca3f, #92ca3f);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf',endColorstr='#ff0480be',GradientType=0);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transition: width 0.6s ease;
}
.progress__upload-status {
    height: 0;
    color: black;
    font-size: 17px;
    text-align: center;
    line-height: 35px;
}

/**
 *  AttachmentUploadForm css
 */

.order-form__alignment__uploadForm__field-label {
    margin-top: 14px;
}

.order-form__alignment__uploadForm__field-description {
    font-size: 12px;
    margin-bottom: 8px;
}

.order-form__alignment__uploadForm__field {
    width: 100%;
    box-sizing: border-box;
    min-height: 36px;
}

/**
 * css for the FeatureAlignmentForm component
 */

.order-form__alignment {
    margin-top: 10px;
}

.order-form__alignment__header {
    display: table;
    height: 36px;
    margin-bottom: 24px;
}

.form__alignment__title {
    display: table-cell;
    vertical-align: middle;
}

.form__alignment__uploadButton {
    float: right;
    padding: 7px 20px;
    font-size: 16px;
    margin-left: 16px;
}

.btn.form__alignment__uploadButton {
    color: #666;
    border-color: #666 !important;
}

.order-form__alignment__radios {
    padding-right: 16px;
    vertical-align: bottom;
}

.order-form__alignment__radios .filter-checkbox {
    margin-top: 8px;
    padding-left: 2px;
    height: 26px;
}

.order-form__alignment__table table.table {
    background-color: white;
    margin-bottom: 0;
}

.order-form__alignment__table table.table th {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.order-form__alignment__table table.table td {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}

/**
 * css for CaptionUploadForm component
 */
.order-form__caption-upload-form__modal {
    max-width: 500px;
}

.order-form__caption-upload-form__select-file-label,
.order-form__caption-upload-form__caption-label {
    display: inline;
    vertical-align: top;
    line-height: 22px;
}

.order-form__caption-upload-form__select-file-label {
    margin-right: 54px;
}

.order-form__caption-upload-form__caption-label {
    margin-right: 90px;
}

.order-form__caption-upload-form__upload-ui,
.order-form__caption-upload-form__description {
    display: inline;
}

.order-form__caption-upload-form__upload-ui-button {
    margin: 0;
}

.order-form__caption-upload-form__description {
    font-size: 12px;
}

.order-form__caption-upload-form__label-input {
    max-width: 240px;
    width: 100%;
}

.order-form__caption-upload-form__field {
    margin-bottom: 24px;
}

/**
 * css for MachineTranslationForm component
 */
.order-form__translation {
    margin-top: 10px;
}

.order-form__translation__header {
    display: table;
    height: 36px;
    margin-bottom: 24px;
}

.form__translation__label {
    display: table-cell;
    vertical-align: middle;
}

.form__translation__upload-button {
    float: right;
    padding: 7px 20px;
    font-size: 16px;
    margin-left: 16px;
}

.btn.form__translation__upload-button {
    color: #666;
    border-color: #666 !important;
}

/* ==================== loading animation ========================== */
@-webkit-keyframes kaltura-spinner {
    0% {
        transform: rotate(0deg) scale(0.7);
        opacity: 1;
    }
    70% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
    82% {
        transform: rotate(360deg) scale(0);
        opacity: 0;
    }
    87% {
        transform: rotate(360deg) scale(0.9);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
}
@keyframes kaltura-spinner {
    0% {
        transform: rotate(0deg) scale(0.7);
        opacity: 1;
    }
    70% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
    82% {
        transform: rotate(360deg) scale(0);
        opacity: 0;
    }
    87% {
        transform: rotate(360deg) scale(0.9);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
}

.loading-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
}
.spinner-container {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50px, -50px, 0);
}

.spinner {
    width: 100px;
    height: 100px;
    position: relative;
    -webkit-animation: kaltura-spinner 2.5s infinite;
            animation: kaltura-spinner 2.5s infinite;
}
.spinner span.spinner__part {
    width: 8px; /* $spinner-circle-radius *2; */
    height: 8px; /* $spinner-circle-radius *2; */
    background-color: #fff;
    display: block;
    border-radius: 8px; /* $spinner-circle-radius *2; */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -4px; /* -$spinner-circle-radius; */
    margin-left: -4px; /* -$spinner-circle-radius; */
}

.spinner span:nth-child(1) {
    transform: rotate(45deg) translateX(-25px) translateY(-25px);
    background-color: rgb(218, 31, 38);
}
.spinner span:nth-child(2) {
    transform: rotate(90deg) translateX(-25px) translateY(-25px);
    background-color: rgb(6, 168, 133);
}
.spinner span:nth-child(3) {
    transform: rotate(135deg) translateX(-25px) translateY(-25px);
    background-color: rgb(0, 147, 68);
}
.spinner span:nth-child(4) {
    transform: rotate(180deg) translateX(-25px) translateY(-25px);
    background-color: rgb(248, 166, 26);
}
.spinner span:nth-child(5) {
    transform: rotate(225deg) translateX(-25px) translateY(-25px);
    background-color: rgb(27, 74, 151);
}
.spinner span:nth-child(6) {
    transform: rotate(270deg) translateX(-25px) translateY(-25px);
    background-color: rgb(218, 31, 38);
}
.spinner span:nth-child(7) {
    transform: rotate(315deg) translateX(-25px) translateY(-25px);
    background-color: rgb(177, 210, 56);
}
.spinner span:nth-child(8) {
    transform: rotate(360deg) translateX(-25px) translateY(-25px);
    background-color: rgb(252, 210, 3);
}

.dept-filter__unit {
    margin-right: 20px;
    height: 35px;
    display: table-cell;
    vertical-align: middle;
}
.dept-filter__control {
    margin-left: 20px;
}

.dashboard-profiles {
    display: table;
    margin-bottom: 12px;
}

.dashboard-wrap {
    padding: 32px 32px 16px 32px;
    background-color: #f1f1f1;
}

.requests-wrap {
    padding-top: 32px;
    margin-bottom: 16px;
}

.dashboard-row:before,
.dashboard-row:after {
    display: table;
    line-height: 0;
    content: "";
}

.dashboard-row:after {
    clear: both;
}

.dashboard-row:before,
.dashboard-row:after {
    display: table;
    line-height: 0;
    content: "";
}
.dashboard-show-details {
    margin-top: 16px;
    color: #00b4d1;
}

.dashboard-requests-title {
    padding-left: 32px;
    padding-top: 5px;
    float: left;
    color: #4a4a4a;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.38px;
    line-height: 24px;
    text-transform: uppercase;
}

.dashboard-container {
    margin-right: auto;
    margin-left: auto;
    width: 940px;
}

.dashboard-container:before,
.dashboard-container:after {
    display: table;
    line-height: 0;
    content: "";
}
.dashboard-filter-container {
    padding-top: 32px;
}
.dashboard-container:after {
    clear: both;
}

@media (min-width: 1200px) {
    .dashboard-container {
        width: 1170px;
    }
}
@media (max-width: 768px) {
    .dashboard-container {
        width: 724px;
    }
}

.requests-table-cell.channel-rules-link.button--transparent {
    line-height: normal;
    margin-top: 0;
}

.dashboard-task-details-inline {
    display: inline;
}
.dashboard-task-details {
    float: left;
    width: 100%;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 32px 0;
}
.dashboard-task-details-block {
    border-right: 1px solid #cccccc;
    min-height: 160px;
}
.dashboard-task-details-block:last-child {
    border-right: none;
}
.dashboard-task-details-block {
    float: left;
    width: 33.33334%;
    box-sizing: border-box;
    padding: 0 24px;
    text-align: center;
}
.dashboard-task-details-block__block {
    margin: auto;
    display: inline-block;
}
.dashboard-task-details-block__text,
.dashboard-task-details-block__info {
    margin-bottom: 10px;
}
.dashboard-task-details-block__text {
    text-align: left;
}

.dashboard-task-details-block__info-label {
    font-weight: bold;
}
.dashboard-task-details-block__info-icon {
    font-size: 20px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .dashboard-task-details-block {
        width: 100%;
    }
    .dashboard-task-details-block__status-data {
        display: none;
    }
}

.reach-action-button {
    font-size: 16px;
    padding: 8px 16px;
    margin: 0 8px;
}
.download-btn__icon {
    margin-right: 0 !important;
}

.credit-wrapper {
    width: 100%;
    padding: 24px 32px;
    float: left;
    border-radius: 4px;
    background: white;
    box-sizing: border-box;
}
.credit-status {
    float: left;
    font-family: Lato;
    box-sizing: border-box;
}
.credit-status__title {
    float: left;
    color: #4a4a4a;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.38px;
    line-height: 24px;
    width: 100%;
    margin-bottom: 15px;
    text-transform: uppercase;
}
.credit-status-info {
    float: left;
    width: 100%;
    font-size: 17px;
}
.credit-status-info__date {
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 19px;
    font-weight: bold;
    color: black;
}
.credit-bar-chart-wrapper {
    float: left;
    box-sizing: border-box;
}
.credit-status-info__date-value {
    display: inline-block;
}
@media (max-width: 1199px) {
    .credit-status__title {
        width: 50%;
    }
    .credit-status-info {
        width: 50%;
        text-align: right;
    }
    .credit-wrapper .credit-status {
        width: 100%;
        margin-bottom: 35px;
    }
    .credit-wrapper .credit-bar-chart-wrapper {
        width: 100%;
        margin: 0;
    }
}
@media (max-width: 991px) {
    .credit-status-info {
        font-size: 14px;
    }
    .credit-status-info__date {
        font-size: 14px;
    }
}


.bar-chart {
    position: relative;
    margin: 0;
    width: 100%;
    float: left;
}
.bar-chart-progress:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.bar-chart-progress:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.bar-chart-progress {
    float: left;
    width: 0;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: black;
    text-align: center;
    background-color: #d8d8d8;
    transition: width 0.6s ease;
    position: relative;
}
.bar-chart-progress__label-item {
    position: absolute;
    right: 0;
    display: none;
}
.bar-chart-progress-with-labels {
    margin-bottom: 20px;
    margin-top: 20px;
}

.bar-chart-progress-with-labels.top:first-child:before {
    bottom: 100%;
}
.bar-chart-progress-with-labels.bottom:first-child:before {
    top: 100%;
}
.bar-chart-progress-with-labels .bar-chart-progress__label-item {
    display: block;
}
.bar-chart-progress-with-labels.top .bar-chart-progress__label-item {
    bottom: 100%;
}
.bar-chart-progress-with-labels.bottom .bar-chart-progress__label-item {
    top: 100%;
}
.bar-chart-unlimited {
    background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 40px 40px;
}

.legend {
    height: 16px;
    color: #666666;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}
.legend__clickable {
    cursor: pointer;
}

.legend-label {
    margin-right: 16px;
}
.legend-value {
    font-weight: bold;
}
.legend-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 16px;
}
.legends-inline .legend {
    text-align: center;
    display: inline-block;
}
.legends-inline .legend:last-child {
    text-align: right;
}
.legends-inline .legend:first-child {
    text-align: left;
}
.legends-list {
    float: left;
    width: 100%;
}
.legends-list .legend {
    margin-bottom: 18px;
}
.legends-list .legend .legend-value {
    float: right;
    font-size: 20px;
}

@media (max-width: 1199px) {
    .legend {
        font-size: 14px;
    }
    .legends-list .legend .legend-value {
        font-size: 18px;
    }
    .legend-label {
        margin-right: 10px;
    }
}
@media (max-width: 991px) {
    .legend-bullet {
        margin-right: 5px;
    }
    .legend-label {
        margin-right: 10px;
    }
}

.pie-chart {
    position: relative;
    float: left;
    padding: 44px 0;
}
.pie-chart__total {
    position: absolute;
    color: #666666;
    font-family: Lato;
    font-size: 36px;
    font-weight: bold;
    line-height: 44px;
}
.pie-chart__total.above {
    top: 0;
    left: 50%;
    transform: translate(-50%);
}
.pie-chart__total.below {
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
}
.pie-chart__total.inside {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@media (max-width: 991px) {
    .pie-chart {
        float: none;
        float: initial;
        margin: 0 auto;
    }
}

.pie-chart-with-legend {
    float: left;
    width: auto;
    box-sizing: border-box;
}
.legends-with-pie-chart {
    float: left;
    padding-top: 30px;
    min-width: 250px;
    margin-left: 85px;
    box-sizing: border-box;
}
.pie-chart-with-legend-container {
    padding: 0 30px;
    float: left;
    min-width: 100%;
    box-sizing: border-box;
}
@media (min-width: 992px) and (max-width: 1199px) {
    .legends-with-pie-chart {
        min-width: 200px;
        margin-left: 35px;
    }
    .pie-chart-with-legend-container {
        padding: 0 24px;
    }
}
@media (max-width: 991px) {
    .pie-chart-with-legend {
        float: left;
        max-width: 50%;
    }
    .pie-chart-with-legend-container {
        padding: 0;
    }
    .legends-with-pie-chart {
        max-width: 50%;
        margin-left: 0;
    }
}

.request-summary-by-status__info:first-child {
    border-right: 2px solid #eaeaea;
}

.request-summary-by-status__info:last-child {
    border: none;
}

.request-summary-by-status__info {
    float: left;
    text-align: center;
    font-family: Lato;
    border-right: 2px solid #eaeaea;
    box-sizing: border-box;
}

.request-summary-by-status__info-value {
    color: #00b4d1;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.26px;
    line-height: 44px;
}

.request-summary-by-status__info-title {
    font-weight: 500;
    line-height: 28px;
    color: #666666;
    font-size: 16px;
}
.request-summary-by-status {
    padding-top: 30px;
    float: left;
    width: 100%;
}

.request-summary-by-status__title-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 16px;
}

.request-summary-by-status__title-text {
    height: 16px;
    color: #666666;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
}

.request-summary-by-status__title {
    padding-left: 24px;
    margin-bottom: 35px;
}

.row-fluid .request-summary-pie-chart-with-legend,
.row-fluid .wrapper-request-summary-with-status {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    min-height: 233px;
}

@media (max-width: 991px) {
    .row-fluid .request-summary-pie-chart-with-legend,
    .row-fluid .wrapper-request-summary-with-status {
        width: 100%;
        padding: 0 30px;
        margin: 0;
        margin-bottom: 30px;
    }
}

.channel-rules-summary {
    font-size: 16px;
    margin-top: 20px;
    display: inline-block;
}
.channel-rules-link {
    text-decoration: underline;
    margin-top: 10px;
    color: #00b4d1;
}
.channel-rules__status-line {
    height: 80px;
    vertical-align: center;
}

.kccs-frame {
    width: 100%;
    margin-top: 20px;
    min-height: 672px;
    /*
      * mobile make sure we simulate landscape mode
      * to get proper error in KEA.
      */
    margin-left: -15px;
    margin-right: -15px;
}
@media (min-width: 768px) and (max-width: 1200px) {
    .kccs-frame {
        min-height: 768px;
    }
}
@media (max-width: 767px) {
    .kccs-frame {
        width: 400px;
        height: 401px;
    }
}

.gallery-result__name {
    font-size: 20px;
    vertical-align: middle;
    margin-top: 8px;
    text-indent: -0.5em;
    margin-left: 21px;
    line-height: 24px;
}
.gallery-result__bullet {
    font-size: 5px;
    padding-right: 10px;
    vertical-align: middle;
}

.gallery-result__tags {
    float: left;
}
.gallery-result__more {
    padding-top: 4px;
}
.gallery-result {
    padding: 32px 0 32px 20px;
}
@media (max-width: 767px) {
    .gallery-result__tags {
        margin-top: 8px;
    }
    .gallery-result__more {
        padding-top: 12px;
    }
}

.galleries-results-list {
    list-style-type: none;
    margin-left: 0;
}

.classroom__resource-text {
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    color: #4a4a4a;
}

.classroom-columns-header {
    padding-left: 0;
    cursor: default;
}

.classroomDashboardComponent .rt-resizable-header-content {
    padding-left: 0;
}

.classroom-no-data {
    position: absolute;
    bottom: -40px;
    width: 100%;
    text-align: center;
}

.classroomDashboardComponent .ReactTable .rt-tbody {
    overflow-y: hidden;
}

.classroomDashboardComponent .rt-tr {
    cursor: pointer;
}

.classroomDashboardComponent .ReactTable .rt-resizer {
    display: none;
}

.classroomDashboardComponent .ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: none;
}

.classroomDashboardComponent .ReactTable .rt-tbody .rt-td {
    border-right: none;
}

.resource-preview-container {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    float: left;
}

.classroom__resource-id {
    position: absolute;
    float: right;
    bottom: 20px;
    right: 0;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: left;
    color: #999999;
}

.classroom__resource-thumb-container {
    float: left;
    background-color: #eaeaea;
    margin-right: 26px;
    width: 256px;
    border-radius: 3px;
}

.resource-additional-data-header {
    height: 58px;
}

.classroom__resource-additional-data-header-text {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #4a4a4a;
}

.classroom__input-preview-text-container {
    width: 50%;
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
    float: left;
}

.classroom__input-preview-live-data {
    width: 50%;
    height: 100%;
    padding-top: 10px;
    float: left;
}

.classroom__resource-thumb-image {
    position: relative;
    height: 144px;
    background-color: #4a4a4a;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.classroom__resource-thumb-text {
    top: calc(50% - 14px);
    margin-left: auto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #dadada;
    width: 100%;
    position: absolute;
    text-align: center;
}

.classroom__streaming-tag-container {
    width: 65px;
    height: 20px;
    border-radius: 4px;
    background-color: #db1f26;
    float: left;
}

.classroom__offair-tag-container {
    width: 65px;
    height: 20px;
    border-radius: 4px;
    background-color: #666666;
    float: left;
}

.classroom__viewers-number-container {
    position: relative;
    width: 52px;
    height: 20px;
    border-radius: 4px;
    background-color: #000000;
    margin-left: 8px;
    float: left;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.classroom__live-tag-text {
    opacity: 0.9;
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.classroom__viewers-number-text {
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
}

.classroom__viewers-number-icon {
    float: left;
    padding-left: 8px;
    padding-top: 2px;
    color: #ffffff;
}

.classroom__resource-text {
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    color: #4a4a4a;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.resource-status-icon {
    font-size: 16px;
    float: left;
}

.classroom__resource-circle-icon {
    font-size: 12px;
    float: left;
}

.classroom__resource-circle-icon-green {
    color: #009344;
}

.classroom__resource-circle-icon-gray {
    color: #666666;
}

.classroom__resource-circle-icon-red {
    color: #da1f26;
}

.classroom__resource-circle-icon-yellow {
    color: #f8a61a;
}

.classrooom__resource-favorite-button {
    outline: none;
    border: none;
    background: transparent;
    float: left;
}

.ResourceCellComponent {
    float: left;
}

.connection-text {
    padding-left: 17px;
    line-height: 1.15;
}

.recording-text {
    padding-left: 17px;
    line-height: 1;
}

.status-text {
    padding-left: 22px;
}

.classroom__scheduledEventTextContainer {
    float: left;
}

.classroom__scheduledEventLink {
    text-decoration: none !important;
    height: 16px;
    width: 16px;
    color: #bbbbbb;
}

.classroom__nextScheduledEvent {
    float: right;
}

.classroom__currentScheduledEvent {
    margin-left: 10px;
    font-size: 13px;
}

.classroom__is-live {
    height: 20px;
    width: 20px;
    text-align: center;
    float: left;
}

.classroom__is-live-streaming {
    color: #da1f26;
}

.classroom__is-live-offline {
    color: #666666;
}

/**
 *  EntrySearchForm component css
 */

.entrySearchForm__toggle {
    font-size: 16px;
    padding-top: 1px;
}

.entrySearchForm__toggle a {
    text-decoration: none;
}

/**
 * Registration Form css
 */

.registrationPage-form {
    font-size: 14px;
}

.registrationPage-form__title {
    display: block;
    margin-bottom: 24px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    line-height: normal;
    color: #333333;
}

.registrationPage-form .form--compact {
    margin-bottom: 0;
}

.registrationPage-form__btns {
    text-align: center;
}

.registrationPage-form__btns--submit {
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
}

.registrationPage-form .form-field input[type="text"] {
    height: 40px;
}

.registrationPage-form
    .form-field
    .autocomplete-dropdown-container.registrationPage-form-field__input
    input[type="text"] {
    height: 36px;
}

.registrationPage-form .form-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.registrationPage-form-field__input {
    margin-bottom: 10px;
}

.registrationPage-form-field__input--error.registrationPage-form-field__input--error {
    border-color: #dc430d;
}

.form-field__error-text {
    font-size: 12px;
    color: #dc430d;
    text-align: center;
}

.timer {
    height: 38px;
    color: white;
    font-size: 18px;
    letter-spacing: 0.24px;
    line-height: 24px;
    font-weight: bold;
}

.timer__data {
    font-size: 32px;
    padding-left: 20px;
    display: inline-block;
}

.timer__title {
    font-size: 22px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 6px;
    border-right: 1px solid rgba(255, 255, 255, 0.7);
    display: inline-block;
}

.timer__data--first {
    padding-left: 0px;
}

.timer__title.timer__title--last {
    padding-right: 0;
    border: none;
}

@media (max-width: 768px) {
    .timer {
        height: 34px;
    }

    .timer__data {
        font-size: 28px;
    }

    .timer__title {
        font-size: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 4px;
    }
}

@media (max-width: 480px) {
    .timer--long-days > .timer__data {
        font-size: 26px;
    }

    .timer--long-days > .timer__title {
        font-size: 18px;
    }
}

/**
 *  Registration Page Scheduling css 
 */
.registrationPage-scheduling__data + .registrationPage-scheduling-timer {
    margin-top: 30px;
}

.registrationPage-scheduling__data__time {
    margin-top: 12px;
}

/**
 * Registration Page Presenters Component css
 */
.registrationPage-presenters {
    margin-top: 36px;
}

.registrationPage-presenters__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    color: #999999;
}

.registrationPage-presenters-hosts {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.registrationPage-presenters-hosts__presenter {
    margin-top: 32px;
    text-align: center;
    width: 30%;
}

.registrationPage-presenters-hosts__presenter--0 {
    width: 25%;
}

@media (max-width: 1024px) {
    .registrationPage-presenters-hosts__presenter,
    .registrationPage-presenters-hosts__presenter--0 {
        width: 50%;
    }
}

@media (max-width: 480px) {
    .registrationPage-presenters-hosts__presenter,
    .registrationPage-presenters-hosts__presenter--0 {
        width: 100%;
    }
}

.registrationPage-presenters-hosts__presenter-name {
    font-size: 16px;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.registrationPage-presenters-hosts__presenter-title {
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.registrationPage-presenters-hosts__presenter-image,
.registrationPage-presenters-hosts__presenter-image.center {
    height: 64px;
    width: 64px;
}

.registrationPage-presenters__more {
    text-align: center;
    font-size: 15px;
    margin-top: 24px;
}

/**
 * Registration Page css
 */

.registrationPage-header {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    border-radius: 6px;
    background-color: #000000; /* so high-contrast script will render text over this in correct color */
}

.registrationPage-header__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
}

.registrationPage-header__title {
    font-size: 36px;
    font-weight: 900;
    line-height: 46px;
    letter-spacing: 1.11px;
    color: white;
    word-break: break-word;
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.registrationPage-header__content {
    margin-top: 47px;
    margin-bottom: 47px;
}

.registrationPage-header__content-wrapper {
    display: flex;
}

.registrationPage-header__content-wrapper__data {
    flex: 1 1;
    padding-right: 24px;
}

.registrationPage-header__form {
    width: 296px;
}

.registrationPage-body {
    margin-bottom: 47px;
}

.registrationPage-body__description {
    margin-top: 48px;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.06px;
    word-break: break-word;
}

.registrationPage-body__media {
    position: relative;
    margin-top: 48px;
    padding-bottom: 56.25%;
}

/** 
 *  when we miss the aspect ration - prevent black border
 */
.registrationPage-body__media .playkit-video-player {
    background: white;
}
.registrationPage-body__media .playkit-poster {
    background-color: white;
}

/**
 *  header form (tabelet - desktop)
 */
.registrationPage-header__form {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 6.8px;
    padding: 24px;
}

/**
 *  header register button
 */

.registrationPage-header-btn__subbmit {
    width: 240px;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
}

@media (max-width: 480px) {
    .registrationPage-header-btn__subbmit {
        width: 100%;
    }
}

/**
 *  body form (mobile- tablet)
 */
.registrationPage-footer {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 48px;
    background-color: #f5f5f5;
}

/**
 *  responsive adjustments
 */
@media (max-width: 1024px) {
    .registrationPage-header__content.span10.offset1,
    .row-fluid .registrationPage-body__content.span8.offset2:first-child {
        margin-left: 0;
        padding-left: 42px;
        padding-right: 42px;
        width: 100%;
    }

    .registrationPage-footer.span10.offset1 {
        margin-left: 0;
        padding-left: 20%;
        padding-right: 20%;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .registrationPage-header.row-fluid [class*="span"] {
        margin-left: 0;
        width: 100%;
    }

    .registrationPage-header__content.span10.offset1,
    .row-fluid .registrationPage-body__content.span8.offset2:first-child {
        margin-left: 0;
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .registrationPage-footer.span10.offset1 {
        padding-left: 10%;
        padding-right: 10%;
    }
}

/**
 *  Registration Preview Banner css
 */

.registrationPreviewBanner {
    background-color: #222222;
    height: 50px;
    position: relative;
    top: 0;
    z-index: 2000;
    border-bottom: 1px solid white;
    font-size: 15px;
    font-weight: bold;
    line-height: 18px;
    padding-left: 24px;
    padding-right: 24px;
}

.registrationPreviewBanner-text {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 16px;
    margin-top: 16px;
    padding-right: 12px;
    border-right: 2px solid rgba(255, 255, 255, 0.5);
}

.registrationPreviewBanner-title {
    padding-left: 12px;
    color: white;
}

.registrationPreviewBanner-checkbox {
    color: white;
    margin-top: 16px;
    float: right;
}

/**
 *  tablet adjustment
 */
@media (max-width: 1024px) {
    .registrationPreviewBanner {
        height: 82px;
        display: flex;
        flex-wrap: wrap;
    }

    .registrationPreviewBanner-title {
        margin-top: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 1 1;
    }

    .registrationPreviewBanner-checkbox {
        margin-top: 0;
        width: 100%;
        float: none;
    }
}

/**
 *  Registration  component css
 */

.registration--fullPage {
    position: absolute;
    width: 100%;
    left: 0;
    background-color: white;
    z-index: 2000;
}

@media (min-width: 768px) {
    .registration-modal {
        margin-top: -22px;
    }
}

.registration-modal.modal {
    position: absolute;
    margin-top: 20px;
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);
    border: none;
}

@media (min-width: 1024px) {
    .registration-modal.modal {
        width: 840px;
    }
}

@media (min-width: 1200px) {
    .registration-modal.modal {
        width: 970px;
    }
}

@media (min-width: 1336px) {
    .registration-modal.modal {
        width: 1000px;
    }
}

@media (min-width: 1600px) {
    .registration-modal.modal {
        width: 1200px;
    }
}

.registration .registration-modal.modal .registration-modal__body.modal-body,
.registration .registration-modal .registration-modal__body.modal-body {
    padding: 0;
    max-height: none;
}

/**
 *  Initial Password Form Component css
 */

.repeat-password-error {
    color: #dc430d;
    min-height: 20px;
}
.initialPassword-form {
    margin-bottom: 0;
}
.initialPassword-main-container {
    display: flex;
    flex-wrap: wrap;
}

.initialPassword-form-container {
    flex-basis: 45%;
}
.error-messages__container {
    margin-top: 32px;
    margin-left: 72px;
    flex-basis: 40%;
}

.initialPassword-form__title {
    font-size: 24px;
    line-height: normal;
    margin-bottom: 56px;
}

form.initialPassword-form .initialPassword-form-field-label {
    float: left;
}

form.initialPassword-form
    .form-field
    input[type="password"].initialPassword-form-field__input {
    height: 48px;
    max-width: 100%;
    width: 100%;
}
.form-spacer {
    min-height: 20px;
}

.initialPassword-form-field__input--error {
    border-color: #dc430d;
    outline-color: transparent;
}

form.initialPassword-form
    input[type="password"].initialPassword-form-field__input.initialPassword-form-field__input--error {
    border-color: #dc430d;
    outline-color: transparent;
}

.initialPassword-form__btns {
    margin-top: 10px;
    text-align: center;
    flex-basis: 45%;
}

.initialPassword-form__btns--submit.btn {
    transition: background-color 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67),
        border-color 0.75s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
}

.initialPassword-form__btns--mobile {
    display: none;
}

@media (max-width: 768px) {
    .error-messages__container {
        margin: 12px auto 0;
        flex-basis: 80%;
    }
    .initialPassword-form-container {
        flex-basis: 80%;
        margin: 0 auto;
    }
    .initialPassword-form__btns--mobile {
        display: block;
        text-align: center;
        flex-basis: 80%;
        margin: 32px auto 0;
    }
    .initialPassword-form__btns--desktop {
        display: none;
    }
}

@media (max-width: 480px) {
    .error-messages__container {
        flex-basis: 90%;
    }
    .initialPassword-form-container {
        flex-basis: 90%;
    }
    .initialPassword-form__btns--mobile {
        flex-basis: 90%;
    }
}

.errors__header {
    font-size: 15px;
    margin-bottom: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    color: #999999;
}
.error-message-status .v2ui-status_info-icon {
    color: #cccccc;
}

.password-error-messages {
    min-width: 80%;
}
.password-error-messages__list-item {
    list-style: none;
    margin-top: 18px;
    text-indent: -26px;
    margin-left: 26px;
}
.error-message__icon {
    vertical-align: middle;
}
.password-error-messages__list {
    margin: 0;
}
.error-message-status {
    margin-right: 10px;
}
.error-message-status .v2ui-status_complete-icon {
    color: #29be86;
}
.error-message-status .v2ui-status_no-icon {
    color: #dc430d;
}

/**
 *  Initial Password Component css
 */

.initialPassword {
    padding: 50px 46px 80px;
    width: 55%;
    height: 100%;
    margin: 0 auto;
}
.initialPassword--success,
.initialPassword--failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
}

.initialPassword__logo {
    margin-bottom: 40px;
    text-align: center;
}

.initialPassword__title {
    text-align: center;
    margin-top: 0;
    font-size: 24px;
    line-height: normal;
}

@media (max-width: 1024px) {
    .initialPassword {
        width: 80%;
    }
}
@media (max-width: 768px) {
    .initialPassword {
        width: 65%;
    }
}
@media (max-width: 480px) {
    .initialPassword {
        width: 100%;
        padding: 10px 0 0;
        height: 100vh;
    }
}

.reset-password__success-message {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
.reset-password__button,
.reset-password__success-message {
    margin-top: 40px;
}
.reset-password__button .btn {
    height: 48px;
    font-size: 20px;
    box-sizing: border-box;
    line-height: 42px;
}

.send-email__wrapper {
    width: 85%;
    margin: 0 auto;
}
.send-email__header {
    margin: 0 auto 1rem;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #666666;
}
.send-email__error {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #000000;
}
.send-email__email-field-input.send-email__email-field-input--error {
    border-color: #dc430d;
    outline-color: transparent;
}
.email-form-spacer {
    min-height: 20px;
}
.email-error {
    font-size: 14px;
    color: #dc430d;
}
.btn.send-email__email-submit {
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
}
.send-email__item {
    margin-top: 20px;
}
.send-email__form-container {
    display: flex;
    flex-direction: column;
}
.send-email__form .form-label {
    margin-bottom: 12px;
    font-size: 15px;
    font-weight: bold;
    color: #999999;
}
.send-email__form-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}
.send-email__form .send-email__form-container .send-email__email-field-input {
    max-width: 100%;
    width: calc(100% - 24px);
    padding: 13px 12px;
}
.send-email__buttons-container {
    text-align: center;
}
.send-email__button.btn {
    margin: 0 10px;
    font-size: 20px;
    height: 48px;
    line-height: 2.3em;
}

.login-email__container .login-email__email-field-input {
    width: 100%;
    max-width: 100%;
    height: 48px;
    padding: 0 10px;
    margin: 6px 0px;
    box-sizing: border-box;
}
.login-email__buttons-container {
    text-align: center;
}
.login-email__login-button {
    margin-top: 5px;
    text-align: center;
    color: #006efa;
    padding: 20px;
    font-weight: bold;
    box-sizing: border-box;
    display: inline-block;
}
.login-email-error {
    font-size: 14px;
    color: #dc430d;
}
.error__header {
    /*text-align: center;*/
    /*font-size: 24px;*/
}
.login-email__label {
    display: inline-block;
    color: #888888;
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 5px;
}
.email-login__header {
    opacity: 0;
    color: #666666;
    font-size: 16px;
    margin-top: 24px;
    margin-bottom: 40px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}
.login-email-success .base-form__logo {
    padding-bottom: 0;
}
.base-form__logo {
    padding-bottom: 24px;
}
.btn.login-email__send-button {
    width: 100%;
    border-radius: 8px;
    padding: 10px;
    margin-top: 20px;
    background-color: #006efa;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
}

.login-email-success {
    text-align: center;
}
.login-email-success__header {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
}
.login-email-success__body {
    color: #666666;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
}
.login-email-success > * {
    margin: 20px;
}
.login__welcome-text {
    color: #333333;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
}
.login-email__form-container {
    text-align: justify;
}
.login-email__container {
    text-align: center;
}

/**
 *  Initial Password Form Component css
 */

.base-form-container {
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    max-width: 642px;
    padding: 54px 46px 20px;
    margin: 0 auto;
}
.base-form {
    width: 100%;
}

.base-form-container {
    flex-basis: 45%;
}
@media (max-width: 480px) {
    .initialPassword-form-container {
        flex-basis: 90%;
    }
    .initialPassword-form__btns--mobile {
        flex-basis: 90%;
    }
}

@media (max-width: 1024px) {
    .base-form-container {
        width: 80%;
    }
}
@media (max-width: 768px) {
    .base-form-container {
        width: 65%;
    }
}
@media (max-width: 480px) {
    .base-form-container {
        width: 100%;
        padding: 0 24px;
        height: 100vh;
    }
}

/* dropdown style */
.client-side-filter-bar .autocomplete-dropdown__multi-value {
    display: none !important;
}
.client-side-filter-bar .autocomplete-dropdown__placeholder {
    visibility: hidden;
}
.client-side-filter-bar-filters__search,
.client-side-filter-bar-filters__dropdown .autocomplete-dropdown__control {
    width: 180px;
}
.client-side-filter-bar-filters__dropdown .value-container {
    padding-right: 0;
}
.client-side-filter-bar .autocomplete-dropdown__menu {
    z-index: 99999 !important;
}
/* end of dropdown style */
.client-side-filter-bar {
    display: flex;
}
.client-side-filter-bar-filters__search {
    margin-right: auto;
}
.client-side-filter-bar-filters__dropdown {
    margin-left: 8px;
}
.client-side-filter-bar__bubbles {
    margin-top: 11px;
}
.client-side-filter-bar__clear-filters {
    background: none;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
    line-height: normal;
}

.filterbar-bubble {
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #cccccc;
    color: #333333;
    font-size: 13px;
    font-weight: bold;
    padding: 0 6px;
    margin-right: 8px;
}

.filterbar-bubble i {
    position: relative;
    top: 1px;
    margin-left: 4px;
    color: black;
}
.filterbar-bubble__btn {
    background: white none;
    border: none;
    padding: 0;
    font-size: 13px;
    vertical-align: baseline;
}
.filterbar-bubble__btn:hover {
    background-color: transparent;
}

.my-calendar-header__create-button {
    float: left;
    margin-right: 16px;
}
.my-calendar-header {
    margin: 32px 0;
}

.hostnames-table-wrapper {
    font-weight: bold;
    margin-bottom: 20px;
}

.hostnames-table-wrapper .rt-resizable-header-content {
    font-size: 13px;
    line-height: 16px;
    color: #888888;
}

.hostnames-table-wrapper .rt-resizable-header-content {
    text-transform: capitalize;
}

.hostnames-table-wrapper .rt-resizable-header:nth-child(2) .rt-resizable-header-content {
    text-transform: uppercase;
}


.hostnames-table-wrapper .rt-tr > .rt-td:nth-child(1) {
    color: #333333;
}

.hostnames-table-wrapper .rt-tr > .rt-td:nth-child(2) {
    color: #888888;
}

.hostnames-table-wrapper .rt-tr > .rt-td:nth-child(3) {
    text-transform: capitalize;
}

.hostnames-table-wrapper .rt-thead.-header {
    border-bottom: 1px solid #D9D9D9;
}

.hostnames-table-wrapper .rt-tbody .rt-tr-group,
.hostnames-table-wrapper .rt-tbody .rt-tr-group:last-child {
    border-bottom: 1px solid #CCCCCC;
}

.hostnames-table-wrapper .hostnames-status-ready {
    color: #00A078;
}

.hostnames-table-wrapper .hostnames-status-ready .hostnames-status__bullet {
    background-color: #00A078;
}

.hostnames-table-wrapper .hostnames-status-pending {
    color: #FFAA00;
}

.hostnames-table-wrapper .hostnames-status-error {
    color: #E12437;
}

.hostnames-table-wrapper .hostnames-status-error .hostnames-status__bullet {
    background-color: #E12437;
}

.hostnames-table-wrapper .hostnames-status-pending .hostnames-status__bullet {
    background-color: #FFAA00;
}

.hostnames-table-wrapper .hostnames-status__bullet {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    margin-right: 8px;
}

.modal.hostnames-modal .modal-header {
    background-color: transparent;
    border-bottom: none;
}

.modal.hostnames-modal .modal-header h3 {
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
    text-align: center;
    text-transform: capitalize;
}

.modal.hostnames-modal .modal-body {
    padding-top: 7px;
    font-size: 14px;
}

.modal.hostnames-modal .hostnames-modal-body input {
    width: calc(100% - 6px);
    margin-top: 6px;
    line-height: 32px;
    border-radius: 4px;
    padding: 2px;
    border-width: 1px;
}

.modal.hostnames-modal input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    text-transform: capitalize;
}

.modal.hostnames-modal input::-moz-placeholder {
    text-transform: capitalize;
}

.modal.hostnames-modal input::placeholder {
    text-transform: capitalize;
}

.modal.hostnames-modal .hostnames-modal-body {
    margin-bottom: 14px;
}

.modal.hostnames-modal .hostnames-modal-body a {
    color: #006EFA;
    font-weight: bold;
    margin-left: 5px;
    text-transform: capitalize;
}

.hostnames-modal-body a:hover {
    color: #006EFA;
}

.modal.hostnames-modal .hostnames-modal-body-hostname {
    text-decoration: underline;
    font-weight: bold;
    display: block;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-result {
    display: flex;
    align-items: center;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-available,
.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-errors {
    background: #F4F4F4;
    border-radius: 4px;
    margin-top: 8px;
    font-size: 14px;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-errors {
    color: #E12437;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-available svg,
.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-errors svg {
    margin: auto 7px auto 10px;
}

.modal.hostnames-modal .hostnames-modal-body .hostnames-modal-available {
    color: #217A37;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn {
    font-size: 14px;
}

.modal.hostnames-modal .hostnames-modal-footer .button.btn.btn-cancel {
    color: #006EFA;
    text-transform: capitalize;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn.btn-cancel:hover:not(.btn-link, button.btn .button--transparent) {
    color: #4798FF;
}

.modal.hostnames-modal .hostnames-modal-footer {
    background-color: transparent;
    border-top-color: #CCCCCC;
    position: relative;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn-action.btn:not(.btn-danger):not(.btn-primary) {
    background-color: #006EFA;
    color: #FFFFFF;
    text-transform: capitalize;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn-action.btn:not(.btn-danger):not(.btn-primary):hover {
    background-color: #4798FF;
}

.modal.hostnames-modal .hostnames-modal-footer button.btn-action.btn:disabled:not(.btn-danger):not(.btn-primary) {
    color: #CCCCCC;
    border: 1px solid #CCCCCC;
    background-color: transparent;
}

.modal.hostnames-modal .hostnames-modal-footer .hostnames-modal-loader-wrapper {
    position: absolute;
    right: 30px;
    width: 20px;
    height: 20px;
    top: calc(50% - 10px);
}

h1.hostnames-main-title {
    font-size: 32px;
    margin-top: 40px;
    margin-bottom: 40px;
    font-weight: bold;
    text-transform: capitalize;
}

.hostnames-alert {
    display: flex;
    align-items: center;
    color: #006EFA;
    background: #F4F4F4;
    border-radius: 4px;
    font-size: 14px;
    text-transform: capitalize;
}

.hostnames-alert svg  {
    margin: auto 7px auto 10px;
}

.hostnames-button-add {
    display: flex;
    align-items: center;
    background-color: #006EFA;
    border: none;
    border-radius: 4px;
    color: #ffffff;
    font-weight: bold;
    padding: 4px 8px;
    text-transform: capitalize;
}

.hostnames-button-add:hover,
.hostnames-button-add:disabled {
    background-color: #4798FF;
}

.hostnames-button-add > svg {
    margin-right: 8px;
}

/**
 * css for the Header Search Form
 */

.headerSearchForm__wrapper {
    background-color: white;
    border-radius: 4px;
    text-align: left;
    margin-top: 2px;
    padding: 7px 8px 1px 8px;
}

.headerSearchForm__wrapper .searchForm {
    border-bottom: none;
}

.eSearch__link .btn-group {
    margin-top: 0;
}
.eSearch__link .btn-group .accordionToggleWrapper {
    margin-top: 5px;
}

.headerSearchForm__searchForm {
    margin-bottom: 0;
}

.headerSearchForm__searchForm .searchForm__prepend {
    margin: 0;
    padding-bottom: 5px;
}

.headerSearchForm__searchForm .searchSuggestions {
    top: -5px;
}

.add-collaborator-form__select-permission-text {
    margin: 24px 0 20px;
}

@-webkit-keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.processing-animation-container {
    position: relative;
}

.processing-animation {
    height: 78px;
    width: 78px;

    /* positions the animation in the center of the div */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.processing-animation .processing-animation__image {
    min-width: 0;
    min-width: initial;
    min-height: 0;
    min-height: initial;
}

.processing-animation__image--gear__big {
    -webkit-animation: spin 8s linear infinite;
            animation: spin 8s linear infinite;
    position: absolute;
    bottom: 0;
}

.processing-animation__image--gear__small {
    animation: spin 8s linear infinite reverse;
    position: absolute;
    right: 0;
    top: 5px;
}

.span6.embed-settings__right {
    margin-left: 0;
    min-height: 305px;
}

.span6.embed-settings__left {
    border-right: 1px solid #d9d9d9;
    padding-right: 14px;
    margin-right: 16px;
}

.embed-settings__left .h4 {
    font-size: 15px;
    font-weight: bold;
    color: #000000;
}

.embed-settings__player {
    position: relative;
    margin: auto;
    float: right;
}
.embed-settings__player.player-horizontal {
    width: 95%;
    height: 220px;
}
.embed-settings__player.player-vertical {
    width: 80%;
    height: 305px;
}

.form-control {
    margin-top: 25px;
}

.form-label {
    padding-top: 7px;
}

.fancy-ios-like-switch {
    margin-top: 7px;
}

.toggleLabel {
    margin-left: 16px;
    position: relative;
    top: 4px;
}

input.embedSettings__timeInput.embedSettings__timeInput {
    margin-bottom: 0;
    max-width: 50px;
    text-align: center;
    margin-right: 10px;
    padding-left: 6px;
}

.embedSettings__timeFromPlayerButton {
    padding: 3px 0 0 0;
}

.embedSettings__timeFromPlayerButton--start {
    margin-right: 25px;
}

.embed-code-text {
    padding-bottom: 20px;
}

div.autocomplete-dropdown__control {
    background-color: white;
}

/**
 *  BrodcastDetails component css
 */

.broadcastDetailsTable {
    margin-top: 24px;
    margin-bottom: 48px;
}

.table.broadcastDetailsTable tr td {
    font-size: 14px;
}

.broadcastDetails-copy-text__input[readonly] {
    background-color: transparent;
    border: none;
    line-height: normal;
    vertical-align: baseline;
    vertical-align: initial;
    color: #666666;
    width: 100%;
}

.broadcastDetails-button.btn {
    padding: 0;
    text-decoration: underline;
}

.broadcastDetails-tooltip-button.btn.btn-link,
.broadcastDetails-tooltip-button {
    padding: 0;
    color: #666666;
}

.broadcastDetails-tooltip-button.btn.btn-link:hover,
.broadcastDetails-tooltip-button:hover {
    text-decoration: none;
    color: #000000;
}

.broadcastDetails-icon {
    font-size: 16px;
    padding-right: 5px;
}

.broadcastDetails-copy-icon {
    font-size: 18px;
    position: relative;
    top: 3px;
}
.broadcastDetails__description {
    font-size: 14px;
    color: #999999;
    margin-top: 3px;
}

.source-type__pip {
    float: left;
}
.sip-source-type-modal-content {
    font-size: 14px;
}
.modal .modal-body.sip-source-type-modal-content {
    min-height: 260px;
    padding-top: 40px;
}
.sip-source-type-modal {
    width: 600px;
}
.sip-source-type-modal__table {
    text-align: center;
}
.source-type__talking-heads {
    padding-left: 30px;
}
.source-type__talking-heads-img {
    padding-left: 20px;
    padding-top: 14px;
}
.source-type__pip-img {
    padding-top: 14px;
}
.sip-source-type__message {
    margin-top: 35px;
}
.sip-source-option {
    padding: 1px;
}
@media (min-width: 1200px) {
    .modal.sip-source-type-modal {
        width: 600px;
    }
}

/**
 *  Stream Comfiguration component css
 */

.streamConfiguration {
    margin-top: 14px;
    margin-bottom: 12px;
}

.streamConfiguration-row {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 15px;
    line-height: 20px;
}

.streamConfiguration-cell {
    float: left;
    width: 22.5%;
    min-height: 30px;
}

/**
 *  Edit Entry Footer component css 
 */

.editEntryFooter {
    margin-bottom: 32px;
}

.editEntryFooter--link {
    margin-left: 32px;
    text-decoration: underline;
}

/**
 *  LiveStreamDetails component css
 */

.captcha-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

