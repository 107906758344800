/**
 *  css for the Entry Metadata Label component
 */

dt.metadata__label {
    font-size: 14px;
    font-weight: normal;
    width: 120px;
    float: left;
}

@media (max-width: 480px) {
    dt.metadata__label {
        width: 100%;
        float: none;
        margin-bottom: 6px;
        font-weight: bold;
    }
}
