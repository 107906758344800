.webcast-homepage-presenter,
.webcast-homepage-presenter__image,
.webcast-homepage-presenter__name {
    display: inline-block;
    vertical-align: top;
    direction: ltr;
}

.webcast-homepage-presenter__image {
    position: relative;
    margin: 0;
    border-radius: 50%;
    overflow: hidden;
    box-sizing: border-box;
}

/* override carousel vendor styles */
.webcast-homepage-presenter__image,
.kms-react-carousel .carousel .slide img.webcast-homepage-presenter__image {
    border: 1px solid transparent;
    /* the real width will be set by the responsive "min-width" rule later in this file */
    width: 1px;
}

.webcast-homepage-presenter__name {
    padding-left: 14px;
}

.webcast-homepage-presenter--collapsed .webcast-homepage-presenter__image {
    border-color: white;
    background-color: white;
}

.webcast-homepage-presenter--small {
    height: 24px;
    line-height: 24px;
}

.webcast-homepage-presenter--small .webcast-homepage-presenter__image {
    height: 24px;
    min-width: 24px;
    /* 2px less is for borders */
    line-height: 22px;
}

/* tablet portrait / landscape / desktop / desktop large */
.webcast-homepage-presenter--large {
    height: 44px;
    line-height: 44px;
}

.webcast-homepage-presenter--large .webcast-homepage-presenter__image {
    height: 44px;
    min-width: 44px;
    /* 2px less is for borders */
    line-height: 42px;
}

.webcast-homepage-presenter:last-child {
    margin-right: 0;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-presenter--large {
        height: 32px;
        line-height: 32px;
    }

    .webcast-homepage-presenter--large .webcast-homepage-presenter__image {
        height: 32px;
        min-width: 32px;
        /* 2px less is for borders */
        line-height: 30px;
    }
}
