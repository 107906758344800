.fancy-ios-like-switch {
    position: relative;
    -webkit-appearance: none;
    background-color: #fff;
    border: 2px solid #d9dadc;
    border-radius: 12px;
    box-shadow: inset -15px 0 0 0 #e0e0e0;
}

input.fancy-ios-like-switch {
    height: 20px;
    width: 36px;
    outline-offset: 2px;
}

.fancy-ios-like-switch:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    background: transparent;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
}

.fancy-ios-like-switch:checked {
    box-shadow: inset 16px 0 0 0 #008297;
    border-color: #008297;
}

.fancy-ios-like-switch:checked:after {
    left: 20px;
    box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

.fancy-ios-like-switch:disabled {
    box-shadow: inset -17px 0 0 0 #f4f4f4;
    border-color: #e0e0e0;
}

.fancy-ios-like-switch:checked:disabled {
    box-shadow: inset 16px 0 0 0 #f4f4f4;
    border-color: #e0e0e0;
}
