/**
 * make the autocomplete field look like the rest of
 * the form fields, resetting some DS styling. 
 */

form:not(.searchForm):not(.navbar-search)
	.registration_autocompletefield
	input[type="text"]:not(.tags-input-field) {
	height: 24px;
	border: none;
	margin-bottom: 0;
}

form:not(.searchForm):not(.navbar-search)
	.registration_autocompletefield
	input[type="text"]:not(.tags-input-field):focus {
		outline: none;
	}

.registration__form-container.registration_autocompletefield
	.kepuikit-autocomplete-input {
	height: 36px;
}
