.list-page {
    display: flex;
    flex-direction: column;
}

.list-page__title {
    font-size: 48px;
    color: #333333;
}

.list-page__description {
    max-width: 690px;
    margin: 16px 0 32px 0;
    font-size: 16px;
    line-height: 1.31;
}

@media (max-width: 479px) {
    .list-page__description {
        max-width: 343px;
        margin: 16px 0 0 0;
        font-size: 16px;
    }
    .list-page__title {
        font-size: 32px;
        margin: 0;
    }
}
