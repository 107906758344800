/**
 * Button component css
 */

.button {
    position: relative;
}

.button--transparent {
    background: none;
    border: none;
}

/**
 *  primary processing button - change icon color to white
 */
.btn-primary .v2ui-Spinner-icon .path1:before,
.btn-primary .v2ui-Spinner-icon .path2:before {
    color: white;
}

.btn i.button__spinner {
    position: absolute;
    right: 50%;
    margin-right: -12px;
    font-size: 24px;
    top: 50%;
    margin-top: -12px;
}

.btn.btn-large i.button__spinner {
    margin-top: -12px;
}

.button__content--proecessing {
    display: none;
}

.button--reset-styles {
    background: none repeat scroll 0 0 transparent;
    border: medium none;
    border-spacing: 0;
    list-style: none outside none;
    text-align: left;
    text-decoration: none;
    text-indent: 0;
}
.button--reset-styles::-moz-focus-inner {
    border: 0;
    padding: 0;
}
