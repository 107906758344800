.webcast-homepage-carousel-item-wrapper {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    color: #fff;
}

.webcast-homepage-carousel-item-inner {
    width: 100%;
    max-width: 600px;
}

.webcast-homepage-carousel-ready-item-backdrop,
.webcast-homepage-carousel-upcoming-item-backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.webcast-homepage-carousel-ready-item-backdrop {
    background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.73)
    );
}

.webcast-homepage-carousel-upcoming-item-backdrop {
    background: rgba(0, 0, 0, 0.5);
}

/* TODO: use .system-width-mixin() when LESS will be supported */

.webcast-homepage-carousel-item-wrapper {
    width: 940px;
}

@media (max-width: 767px) {
    .webcast-homepage-carousel-item-wrapper {
        width: auto;
        margin-right: 12px;
        margin-left: 12px;
    }
}

@media (max-width: 979px) and (min-width: 768px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 724px;
    }
}

@media (min-width: 1200px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 1170px;
    }
}

@media (min-width: 1336px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 1200px;
    }
}

@media (min-width: 1600px) {
    .webcast-homepage-carousel-item-wrapper {
        width: 1470px;
    }
}
