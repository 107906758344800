.small-media-thumbnail {
    display: block;
    position: relative;
    text-decoration: none;
}

.small-media-thumbnail::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0.15) 33%,
        rgba(0, 0, 0, 0.79) 79%,
        #000000
    );
}

.small-media-thumbnail__img {
    width: 100%;
    overflow: hidden;
}

.small-media-thumbnail__duration {
    line-height: normal;
    left: 12px;
    position: absolute;
    font-size: 10px;
    bottom: 8px;
    color: #fff;
}

.small-media-thumbnail--error .small-media-thumbnail__img {
    display: none;
}

.small-media-thumbnail__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    background-color: #dc430d;
    width: 28px;
    height: 16px;
}

.small-media-thumbnail__icon {
    font-size: 12px;
    color: #fff;
}
