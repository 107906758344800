.countdown-timer-timerContainer {
    color: #ffffff;
}

.countdown-timer-timerDigit {
    width: 84px;
    height: 64px;
    padding: 5px;
    font-size: 32px;
    font-weight: bold;
    line-height: 26px;
    display: inline-block;
    border-left: solid 1px #ffffff;
    border-right: solid 1px #ffffff;
    text-align: center;
}
.countdown-timer-timerDigit-digit {
    margin: 8px;
}
.countdown-timer-timerDigit-valueType {
    font-size: 16px;
    margin: 4px;
    font-weight: normal;
}
