/**
 * Registration Page css
 */

.registrationPage-header {
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1;
    border-radius: 6px;
    background-color: #000000; /* so high-contrast script will render text over this in correct color */
}

.registrationPage-header__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
    z-index: -1;
}

.registrationPage-header__title {
    font-size: 36px;
    font-weight: 900;
    line-height: 46px;
    letter-spacing: 1.11px;
    color: white;
    word-break: break-word;
    margin-top: 0;
    margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.registrationPage-header__content {
    margin-top: 47px;
    margin-bottom: 47px;
}

.registrationPage-header__content-wrapper {
    display: flex;
}

.registrationPage-header__content-wrapper__data {
    flex: 1;
    padding-right: 24px;
}

.registrationPage-header__form {
    width: 296px;
}

.registrationPage-body {
    margin-bottom: 47px;
}

.registrationPage-body__description {
    margin-top: 48px;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.06px;
    word-break: break-word;
}

.registrationPage-body__media {
    position: relative;
    margin-top: 48px;
    padding-bottom: 56.25%;
}

/** 
 *  when we miss the aspect ration - prevent black border
 */
.registrationPage-body__media .playkit-video-player {
    background: white;
}
.registrationPage-body__media .playkit-poster {
    background-color: white;
}

/**
 *  header form (tabelet - desktop)
 */
.registrationPage-header__form {
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 6.8px;
    padding: 24px;
}

/**
 *  header register button
 */

.registrationPage-header-btn__subbmit {
    width: 240px;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 40px;
}

@media (max-width: 480px) {
    .registrationPage-header-btn__subbmit {
        width: 100%;
    }
}

/**
 *  body form (mobile- tablet)
 */
.registrationPage-footer {
    display: flex;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 48px;
    background-color: #f5f5f5;
}

/**
 *  responsive adjustments
 */
@media (max-width: 1024px) {
    .registrationPage-header__content.span10.offset1,
    .row-fluid .registrationPage-body__content.span8.offset2:first-child {
        margin-left: 0;
        padding-left: 42px;
        padding-right: 42px;
        width: 100%;
    }

    .registrationPage-footer.span10.offset1 {
        margin-left: 0;
        padding-left: 20%;
        padding-right: 20%;
        width: 100%;
    }
}

@media (max-width: 768px) {
    .registrationPage-header.row-fluid [class*="span"] {
        margin-left: 0;
        width: 100%;
    }

    .registrationPage-header__content.span10.offset1,
    .row-fluid .registrationPage-body__content.span8.offset2:first-child {
        margin-left: 0;
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .registrationPage-footer.span10.offset1 {
        padding-left: 10%;
        padding-right: 10%;
    }
}
