.main-sidebar {
    position: fixed;
    display: flex;
    left: 0;
    align-content: center;
    z-index: 999;
    font-size: 16px;
    font-weight: 400;
    background-color: #ffffff;
    top: 0;
    width: 319px;
}

.main-sidebar__container-overlay {
    position: fixed;
    width: 100%;
    min-height: 100%;
    background: transparent;
    z-index: 998;
    top: 0;
    left: 0;
}

.main-sidebar a,
.main-sidebar a :hover,
.main-sidebar a :active {
    color: #333333;
}

.main-sidebar ul {
    margin: 0;
}

@media (max-width: 479px) {
    .main-sidebar {
        width: 80vw;
        max-width: 319px;
    }
}
