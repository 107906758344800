.message {
    position: relative;
    width: 100%;
    font-size: 14px;
    box-sizing: border-box;
}

.message__title {
    display: inline-block;
    margin-right: 16px;
    font-weight: bold;
    vertical-align: top;
}
.message__text {
    display: inline-block;
    max-width: 90%;
}
.message__close-button {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    font-weight: bold;
    padding: 13px 24px;
    color: inherit; /* override user-agent (browser) default button color (black) */
}
@media (max-width: 767px) {
    .message__close-button {
        padding-right: 12px;
    }
}

.message.alert {
    line-height: inherit;
}
