.webcast-homepage-upcoming-entry-details-wrapper,
.webcast-homepage-upcoming-entry-details-wrapper > div {
    height: 100%;
}

.webcast-homepage-upcoming-entry-details-inner {
    text-align: center;
    vertical-align: middle;
}

.webcast-homepage-upcoming-entry-details-inner
    .webcast-homepage-carousel-item-inner {
    display: inline-block;
}

.webcast-homepage-upcoming-entry-details-inner
    .webcast-homepage-carousel-entry-description {
    font-weight: normal;
    opacity: 0.8;
}
