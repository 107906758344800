.radio-button-group__container {
    position: relative;
    display: inline-block;
    font-size: 0;
    white-space: nowrap;
    vertical-align: middle;
}

.radio-button-group__container .radioButton {
    margin-top: 0;
    margin-left: 10px;
}

.radio-button {
    display: inline-block;
    vertical-align: middle;
    color: #999999;
    cursor: pointer;
    text-shadow: none;
    text-align: center;
    line-height: 20px;
    padding: 4px 0;
    margin-bottom: 0;
    border-color: transparent !important;
    background-color: transparent;
    box-shadow: none;
    font-size: 32px;
    transition: color 0.2s;
}

.radio-button:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    text-decoration: none;
}

.radio-button:hover,
.radio-button:focus {
    color: #005664;
}

.radio-button.active {
    color: #008297;
}
