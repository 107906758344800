.scheduling-section {
}

.scheduling-section__title {
    margin: 74px 0 24px 0;
    font-size: 32px;
    font-weight: 900;
}

.scheduling-section__description {
    font-size: 16px;
    margin: 24px 0 38px 0;
    font-weight: 400;
    width: 60%;
}

.scheduling-section__title-text {
    display: flex;
    align-items: center;
    margin-top: 28px;
}

.scheduling-section__title-text h3 {
    font-size: 24px;
    font-weight: 300;
    height: 28px;
    display: flex;
    align-items: center;
}

@media (max-width: 979px) {
    .scheduling-section__title-text {
        margin-top: 24px;
    }

    .scheduling-section__title-text h3 {
        font-size: 20px;
        margin: 0;
    }

    .scheduling-section__description {
        width: 60%;
    }

    .scheduling-section__title p {
        margin: 20px 0 0 0;
    }

    .scheduling-section__items {
        margin-bottom: 54px;
    }

    .scheduling-section__item-description-html {
        color: #b2b2b2;
        margin: 8px 0 8px 0;
    }

    .scheduling-section__items {
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .scheduling-section__description {
        width: 80%;
    }
}
