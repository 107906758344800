/**
 *  Initial Password Component css
 */

.initialPassword {
    padding: 50px 46px 80px;
    width: 55%;
    height: 100%;
    margin: 0 auto;
}
.initialPassword--success,
.initialPassword--failure {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
}

.initialPassword__logo {
    margin-bottom: 40px;
    text-align: center;
}

.initialPassword__title {
    text-align: center;
    margin-top: 0;
    font-size: 24px;
    line-height: normal;
}

@media (max-width: 1024px) {
    .initialPassword {
        width: 80%;
    }
}
@media (max-width: 768px) {
    .initialPassword {
        width: 65%;
    }
}
@media (max-width: 480px) {
    .initialPassword {
        width: 100%;
        padding: 10px 0 0;
        height: 100vh;
    }
}
