.groupInfo__content {
    background-color: white;
    opacity: 1;
    font-size: 14px;
    font-weight: normal;
    min-height: 72px;
    line-height: 72px;
    padding: 0 16px;
}

.groupInfo__container {
    background-color: #f8f8f8;
    padding: 32px;
}

.groupInfo__content .stat-item {
    margin-left: 16px;
}

.groupInfo__content .stat-item::after {
    content: "|";
    margin-left: 16px;
}

.groupInfo__content .stat-item:last-child::after {
    content: "";
    margin-left: 16px;
}

.stat__label {
    letter-spacing: 0.3px;
}

.stat__value {
    font-weight: bold;
}

.email-editable-label,
input.email-edit-box__input {
    color: #008297;
    font-weight: bold;
}

.email-editable-label.editable-label--empty {
    color: inherit;
}

.email-editable-label .edit-icon {
    font-size: 14px;
}

.email-edit-actions {
    top: 0;
}
