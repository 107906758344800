/**
 *  cognito login form css
 */

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-webhookregistration__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-webhookregistration__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-webhookregistration__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-webhookregistration__field-input {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: 50px;
    background-color: #fff;
    color: #000;
    border: solid 1px grey;
    border-radius: 0px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 8px;
    opacity: 0.8;
}

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-webhookregistration__field-input.login-webhookregistration__field-input--error,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-webhookregistration__field-input.login-webhookregistration__field-input--error,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-webhookregistration__field-input.login-webhookregistration__field-input--error,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-webhookregistration__field-input.login-webhookregistration__field-input--error {
    border: solid 1px #d20156;
}

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-webhookregistration__field-input:focus,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-webhookregistration__field-input:focus,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-webhookregistration__field-input:focus,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-webhookregistration__field-input:focus {
    outline: none;
    border: solid 1px #000;
    background-color: var(--black-30);
}

/* reverse chrome autofill style */
input.login-webhookregistration__field-input:-internal-autofill-selected,
input.login-webhookregistration__field-input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

.login-webhookregistration__label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 12px;
    padding-top: 0;
    display: inline-block;
}

.login-webhookregistration__error-message {
    margin-top: 40px;
    margin-bottom: 24px;
    color: #ff5050;
}

.login-webhookregistration-info {
    font-size: 16px;
    text-align: center;
}

.login-webhookregistration-error {
    font-size: 14px;
    font-weight: bold;
    color: #ff5050;
}

.form-control.login-webhookregistration__form-container {
    margin-top: 16px;
}

.login-webhookregistration__buttons-container {
    margin-top: 30px;
}

/* make sure the button is always 100% width */
.webhookregistration button.loading.login-webhookregistration__submit-button,
.webhookregistration button.btn.login-webhookregistration__submit-button {
    width: 100%;
    box-sizing: border-box;
    background-color: #008297;
    color: #fff;
}

.login-webhookregistration-password-validation {
    color: #000;
}

.login-webhookregistration-password-validation__icon {
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 8px;
}

.login-webhookregistration-password-validation__weak {
    color: #ff5050;
}

.login-webhookregistration-password-validation-field__valid {
    color: #3ec24e;
}

.form-control-tnc {
    font-size: 13px;
    text-align: center;
    color: #000;
}

.form-control-tnc a {
    font-weight: 900;
}
