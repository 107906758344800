.kms-react-carousel.webcast-homepage-carousel {
    position: absolute;
    left: 0;
    right: 0;
}

.webcast-homepage-carousel-item {
    position: relative;
}

/* tablet portrait / landscape / desktop / desktop large */
.webcast-homepage-carousel-height {
    height: 600px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-carousel-height {
        height: 375px;
    }
}
