.virtual-event-entry-hero-section .hero-section__browser-not-supported {
    margin: 16px 0;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.2);
    font-size: 13px;
    font-weight: bold;
    color: #ffffff;
    text-align: left;
}

.hero-section__browser-not-supported-logos {
    display: flex;
}

.hero-section__browser-not-supported-logos i {
    font-size: 24px;
}

.meeting-entry__join-meeting {
    width: 100%;
    height: 100%;
    background-color: #000000;
    display: table;
    text-align: center;
}
.meeting-entry__join-meeting-content {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    color: #ffffff;
}
@media (min-width: 667px){
    button.btn.meeting-entry__join-meeting-btn {
        margin-top: 24px;
    }
}
button.btn.meeting-entry__join-meeting-btn {
    background-color: #e12437;
    color: #ffffff;
    border: none;
}

button.btn.meeting-entry__join-meeting-btn:hover:not(.btn-link, button.btn
        .button--transparent) {
    color: #ffffff;
    background-color: #b41c2c;
}

button.btn.meeting-entry__start-meeting-btn {
    margin: 32px 0;
}

.meeting-entry__newrowIframe {
    width: 100%;
    height: calc(100vh - 60px);
    border: none;
}

.meeting-entry-hero-section__lobby {
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    justify-content: space-between;
}

.meeting-entry__hero-section__no-schedule {
    margin-bottom: 32px;
}

.meeting-entry__hero-section__no-schedule > p {
    color: #ffffff;
}

.meeting-entry__hero-section__primary-message {
    font-size: 24px;
    font-weight: 700;
}

.meeting-entry__hero-section__secondary-message {
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
}

.meeting-entry-hero-section__lobby button.btn.meeting-entry__start-meeting-btn {
    margin: 0px;
    background-color: #006efa;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
}

.meeting-entry-hero-section__lobby
    button.btn.meeting-entry__start-meeting-btn:hover:not(.btn-link, button.btn
        .button--transparent) {
    color: #ffffff;
    background-color: #4798ff;
}

.meeting-entry-hero-section__lobby
    button.btn.meeting-entry__start-meeting-btn
    > i {
    font-size: 24px;
}

.meeting-entry-hero-section__thumbnail {
    width: 686px;
    height: 386px;
    border-radius: 8px;
}

.meeting-entry-hero-section__thumbnail > img {
    display: block;
    border-radius: 8px;
}

/* mobile portrait */
@media (max-width: 666px) {
    .meeting-entry-hero-section__lobby {
        justify-content: center;
    }
    .meeting-entry-hero-section__thumbnail {
        display: none;
    }
    .meeting-entry__hero-section__no-schedule {
        text-align: center;
    }
    .meeting-entry-hero-section__lobby
    button.btn.meeting-entry__start-meeting-btn {
        margin-left: auto;
        margin-right: auto;
    }
    .meeting-entry__join-meeting-content{
        font-size: inherit;
    }
}

/* mobile landscape */
@media (min-width: 667px) and (max-width: 767px) {
    .meeting-entry-hero-section__lobby {
        width: 100%;
    }
    .meeting-entry-hero-section__thumbnail {
        width: 236px;
        height: 129px;
    }
}

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
    .meeting-entry-hero-section__thumbnail {
        width: 352px;
        height: 198px;
    }
}

/* tablet landscape */
@media (min-width: 980px) and (max-width: 1199px) {
    .meeting-entry-hero-section__thumbnail {
        width: 554px;
        height: 312px;
    }
}
