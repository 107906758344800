/** 
 *  EntryData component css
 */

.entry-data {
    position: relative;
    line-height: normal;
}

.entry-data__details {
    margin-top: 16px;
}
