.login-error {
    color: #e12437;
}

.password__field-input {
    width: 100%;
}

.passwordExpired__submit-button {
    width: 100%;
}
