.user-selection-icon-container {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    text-align: center;
    padding: 8px 6px 8px 8px;
}

.user-selection-icon-container--group {
    background-color: #b9df90;
}

.user-selection-icon-container--user {
    background-color: #ebebeb;
    color: #666666;
}

.user-selection-option__label {
    display: inline-block;
    margin-left: 10px;
}

.user-selection-help-icon-container {
    width: 1%;
}

.user-selection-box {
    width: 99%;
}

.user-selection-multi-value--new {
    border: 1px solid #dc430d;
    border-radius: 18px;
}

.user-selection-multi-value {
    margin-top: 8px;
}
