/**
 * Single Entry Search Results
 */

.icon.icon--vertical-align-bottom {
    vertical-align: text-bottom;
}

.icon.icon--vertical-align-sub {
    vertical-align: sub;
}

.icon.icon--vertical-align-middle {
    vertical-align: middle;
}

.results-preview__details {
    font-size: 12px;
}

.results-entry {
    white-space: normal;
}

.results-entry__summary {
    margin-bottom: 15px;
}

/**
 *  apply only to entry search results inside the react wrapper
 */
.entry-search-results .results-entry__container {
    padding-top: 32px;
    padding-bottom: 32px;
    min-height: 10px;
    border-bottom: 1px solid;
    line-height: initial;
}

.results-entry__container {
    flex: 1 1 auto;
}

.results-entry__description {
    display: block;
    overflow: hidden;
    margin-bottom: 16px;
    margin-top: 8px;
    flex: 1 1 auto;
}

.search-results-icon {
    font-size: 20px;
}

.results__result-item {
    margin-top: 5px;
}
.results__result-item--time,
.results__result-item--text {
    margin-left: 5px;
}

.results-items {
    margin-top: 24px;
}

.results-entry__container .from-now {
    font-size: 12px;
    margin-top: 8px;
}

/** 
 * alignments
 */

.results-preview {
    float: left;
    width: 204px;
    margin-right: 30px;
}

.offset-left {
    margin-left: 234px;
}

/**
 * tablet alignment
 */
@media (max-width: 767px) {
    .results-preview {
        width: 120px;
        margin-right: 12px;
    }

    .results-details {
        margin-left: 132px;
        min-height: 50px;
    }

    .results-items,
    .results-icons {
        margin-left: 0;
    }

    .results-items {
        width: 100%;
    }
}

/**
 *  mobile alignment
 */
@media (max-width: 480px) {
    .results-details {
        min-height: 69px;
    }

    .results-icons {
        width: 100%;
    }
}

/**
 * limit entry search result width
 */

@media (min-width: 980px) and (max-width: 1024px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 630px;
    }
}

@media (min-width: 1025px) and (max-width: 1199px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 405px;
    }
}

@media (min-width: 1200px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 560px;
    }
}

@media (min-width: 1336px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 582px;
    }
}

@media (min-width: 1600px) {
    .results-entry__name,
    .results-entry__description,
    .results-entry__icons:not(.results-icons--full-width),
    .results-summary__wrapper__icons,
    .results-items {
        max-width: 765px;
    }
}

/**
 * actions modifier
 */

/* tablet portrait */
@media (min-width: 768px) and (max-width: 979px) {
    .entry-search-results .results-entry__container {
        padding: 16px 0;
    }
    .results-entry__description {
        margin: 8px 0;
    }
    .results-entry.results-entry--hasActions
        .results-details
        .results-entry__name {
        max-width: 420px;
        display: inline-block;
    }
}

/* tablet landscape and up */
@media (min-width: 979px) and (max-width: 1024px) {
    .results-entry.results-entry--hasActions
        .results-details
        .results-entry__name {
        max-width: 600px;
        display: inline-block;
    }
}

/************************/
/**** Media Queries *****/
/************************/

@media (max-width: 480px) {
    .results-icons {
        margin-top: 8px;
    }
}

@media (max-width: 767px) {
    .results-entry__name {
        font-size: 16px;
    }

    .summary__wrapper {
        font-size: 13px;
    }

    .results-preview {
        vertical-align: top;
    }
    .entry-search-results .results-entry__container {
        padding: 16px 0;
    }
    .results-entry__description {
        margin: 8px 0;
    }
    .results-items {
        margin-top: 16px;
    }
}

@media (max-width: 540px) {
    .row-fluid .results-summary__show-more--span {
        display: none;
    }
}
