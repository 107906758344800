.webcast-homepage-recorded-item {
    width: 32%;
    margin-right: 2%;
    float: left;
    margin-bottom: 32px;
    position: relative;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0;
    font-weight: bold;
    color: #999999;
}

.webcast-homepage-recorded-item-link,
.webcast-homepage-recorded-item-link:hover,
.webcast-homepage-recorded-item-link:focus,
.webcast-homepage-recorded-item-link:active {
    text-decoration: none;
    color: inherit;
}

.webcast-homepage-recorded-item-details__line2 {
    margin-top: 8px;
}

.webcast-homepage-recorded-item-details__cell {
    vertical-align: middle;
}

.webcast-homepage-recorded-item:nth-child(3n) {
    margin-right: 0;
}

.webcast-homepage-recorded-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    color: #222222;
}

.webcast-homepage-carousel-item-time-ago {
    text-align: right;
}

/* tablet portrait / landscape */
@media (max-width: 1336px) {
    .webcast-homepage-recorded-item {
        margin-bottom: 24px;
    }
}

/* phone landscape / tablet portrait */
@media (min-width: 667px) and (max-width: 1023px) {
    .webcast-homepage-recorded-item {
        width: 49%;
        margin-right: 2%;
    }

    .webcast-homepage-recorded-item:nth-child(3n) {
        margin-right: 2%;
    }

    .webcast-homepage-recorded-item:nth-child(2n) {
        margin-right: 0;
    }
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-recorded-item {
        margin-bottom: 16px;
    }
}

/* phone portrait */
@media (max-width: 666px) {
    .webcast-homepage-recorded-item {
        width: 100%;
    }
}
