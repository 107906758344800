.webcast-homepage-presenters--rtl {
    direction: rtl;
}

.webcast-homepage-presenters--ltr
    .webcast-homepage-presenter--collapsed
    + .webcast-homepage-presenter--collapsed {
    margin-left: -7px;
}
.webcast-homepage-presenters--rtl
    .webcast-homepage-presenter--collapsed
    + .webcast-homepage-presenter--collapsed {
    margin-right: -7px;
}

/* tablet / desktop */
.webcast-homepage-presenters--ltr
    .webcast-homepage-presenter--expanded
    + .webcast-homepage-presenter--expanded {
    margin-left: 43px;
}
.webcast-homepage-presenters--rtl
    .webcast-homepage-presenter--expanded
    + .webcast-homepage-presenter--expanded {
    margin-right: 43px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-presenters--ltr
        .webcast-homepage-presenter--expanded
        + .webcast-homepage-presenter--expanded {
        margin-left: 35px;
    }
    .webcast-homepage-presenters--rtl
        .webcast-homepage-presenter--expanded
        + .webcast-homepage-presenter--expanded {
        margin-right: 35px;
    }
}
