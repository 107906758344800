.tag {
    font-size: 12px;
    display: inline-block;
    border-radius: 4px;
    padding: 0px 16px 0px 16px;
    border: 1px solid #666666;
    color: #666666;
}

.tag:hover {
    color: #666666;
}
