/*
 * Fhe following modificator classes are supported in ReactTable and SelectionTable components:
 * - "-resizable-header":
 *      Aligns non-resizable column styles with the resizable column styles.
 *      Should be applied to the component:
 *      <ReactTable className={"... -resizable-header"} ...
 * - "-column-separators":
 *      Adds separator lines between the columns, add padding between the columns.
 *      Should be applied to the component:
 *      <ReactTable className={"... -column-separators"} ...
 * - "rt-column-center":
 *      Aligns cell contents to the center (horizontally) in the column.
 *      Should be applied both to the header and body cells in the column:
 *      const column = {
 *        ...,
 *        headerClassName: "rt-column-center",
 *        className: "rt-column-center"
 *      }
 */

.ReactTable {
    border-left: none;
    border-right: none;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
}

.ReactTable .rt-thead.-header {
    box-shadow: none;
}

.ReactTable .rt-tbody {
    overflow: hidden;
}

.ReactTable .rt-tbody .rt-tr-group {
    border-top: 1px solid #eaeaea;
    border-bottom: none;
}

.ReactTable.-striped .rt-tr.-odd {
    background: #f8f8f8;
}
.ReactTable.-striped .rt-tr.-even {
    background: #ffffff;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-tbody .rt-td {
    border-right: none;
}

.ReactTable.-resizable-header .rt-th:not(.rt-resizable-header),
.rt-resizable-header-content {
    text-transform: uppercase;
    text-align: left;
    padding: 19px 11px;
    font-size: 13px;
    font-weight: bold;
}

.ReactTable.-resizable-header .rt-th:not(.rt-resizable-header) {
    padding: 26px 21px 26px 0;
}

.ReactTable .rt-td {
    padding: 20px 11px;
}

.ReactTable .rt-thead .rt-resizable-header {
    overflow: hidden;
}

.ReactTable.-column-separators .rt-thead .rt-th {
    border-right: 1px solid #eaeaea;
}

.ReactTable.-column-separators .rt-thead .rt-th:last-child {
    border-right: none;
}

.ReactTable .rt-tbody .rt-td.rt-column-center,
.ReactTable .rt-thead .rt-th.rt-column-center {
    text-align: center;
}

.rt-th {
    position: relative;
}
.sort-arrow {
    position: absolute;
    top: 24px;
    right: 12px;
}
