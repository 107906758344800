.copy-text {
    display: table;
}

.copy-text__input-container {
    margin-bottom: 40px;
    width: 100%;
    display: table-cell;
}

.copy-text__input {
    width: 100%;
    border-radius: 3px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
    color: #666666;
    padding: 1px 16px;
    overflow-y: scroll;
    resize: none;
    box-sizing: border-box;
    height: 36px;
}
.copy-text__input::-moz-selection {
    color: #666666;
    background: rgba(0, 130, 151, 0.2);
}
.copy-text__input::selection {
    color: #666666;
    background: rgba(0, 130, 151, 0.2);
}

.copy-text__button-container {
    text-align: right;
    display: table-cell;
    padding-left: 16px;
    vertical-align: top;
}

button.btn-link.copy-text__button {
    white-space: nowrap;
    padding-right: 0;
    padding-left: 0;
}
