/* dropdown style */
.client-side-filter-bar .autocomplete-dropdown__multi-value {
    display: none !important;
}
.client-side-filter-bar .autocomplete-dropdown__placeholder {
    visibility: hidden;
}
.client-side-filter-bar-filters__search,
.client-side-filter-bar-filters__dropdown .autocomplete-dropdown__control {
    width: 180px;
}
.client-side-filter-bar-filters__dropdown .value-container {
    padding-right: 0;
}
.client-side-filter-bar .autocomplete-dropdown__menu {
    z-index: 99999 !important;
}
/* end of dropdown style */
.client-side-filter-bar {
    display: flex;
}
.client-side-filter-bar-filters__search {
    margin-right: auto;
}
.client-side-filter-bar-filters__dropdown {
    margin-left: 8px;
}
.client-side-filter-bar__bubbles {
    margin-top: 11px;
}
.client-side-filter-bar__clear-filters {
    background: none;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
    line-height: normal;
}
