.homepage-no-webcasts {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 370px;
    margin: 40px auto 0;
    text-align: center;
}

.homepage-no-webcasts__header {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 22px;
    line-height: normal;
    letter-spacing: 0.7px;
}

.homepage-no-webcasts__description {
    margin-top: 16px;
    font-size: 16px;
    line-height: 1.5;
    letter-spacing: 0.7px;
}

.homepage-no-webcasts__button a {
    margin-top: 16px;
}
