/**
 *  Initial Password Form Component css
 */

.base-form-container {
    display: flex;
    box-sizing: border-box;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    max-width: 642px;
    padding: 54px 46px 20px;
    margin: 0 auto;
}
.base-form {
    width: 100%;
}

.base-form-container {
    flex-basis: 45%;
}
@media (max-width: 480px) {
    .initialPassword-form-container {
        flex-basis: 90%;
    }
    .initialPassword-form__btns--mobile {
        flex-basis: 90%;
    }
}

@media (max-width: 1024px) {
    .base-form-container {
        width: 80%;
    }
}
@media (max-width: 768px) {
    .base-form-container {
        width: 65%;
    }
}
@media (max-width: 480px) {
    .base-form-container {
        width: 100%;
        padding: 0 24px;
        height: 100vh;
    }
}
