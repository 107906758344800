.btn.create-playlist-button {
    height: 36px;
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.create-playlist-button__icon {
    position: relative;
    top: 1px;
}

.create-playlist-button__text {
    margin-left: 5px;
}
