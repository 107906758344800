/**
 *  Categories in entry Metadata css
 */

.metadata__item__categories__category {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 14px;
    line-height: 20px;
    display: inline-block;
    border-right: 1px solid #666666;
}

.metadata__item__categories__category:last-child {
    border-right: none;
}
