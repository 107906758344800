.channel-header {
    height: 285px;
    color: #ffffff;
    display: flex;
    line-height: normal;
    padding-top: 112px;
}

.btn.channel-header__link.channel-header__banner__newrow-link.btn-cta-eventplatform {
    display: inline-flex;
}

.channel-header__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    max-height: 397px;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.4);
}
.channel-header__content-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.channel-header__details {
    display: flex;
    overflow: hidden;
}
.channel-header__text-and-links-wrapper {
    overflow: hidden;
}
.channel-header__logo {
    max-width: 76px;
    min-width: 76px;
    max-height: 76px;
    min-height: 76px;
    border-radius: 44px;
    border: solid 2px #ffffff;
}
.channel-header__logo-wrapper {
    margin-right: 40px;
}
.channel-header__tier {
    font-size: 11px;
    font-weight: bold;
    border-radius: 4px;
    border: solid 1px #ffffff;
    height: 13px;
    padding: 1px 4px;
    display: table;
}
.channel-header__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 48px;
    font-weight: bold;
    display: inline-block;
    max-width: 100%;
}
.channel-header__description {
    max-width: 490px;
    font-size: 16px;
    line-height: 1.31;
    letter-spacing: normal;
    margin-top: 5px;
}
.channel-header__description .truncateManager__more,
.channel-header__description .truncateManager__less-link,
.channel-header__description .truncateManager__less-link:hover,
.channel-header__description .truncateManager__more:hover {
    color: #ffffff;
    font-weight: normal;
    text-decoration: underline;
}
.channel-header__description .truncateManager__less {
    display: flex;
}
.channel-header__links {
    margin-top: 24px;
}
a.channel-header__link.channel-header__banner__newrow-link:hover {
    color: #ffffff !important;
    background-color: #0055cc !important;
}
a.channel-header__link {
    margin-right: 8px;
    padding: 0 16px 0 8px;
    color: #ffffff;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.4);
    border: none;
}
a.channel-header__banner__lets-chat-link:hover,
a.channel-header__banner__external-link:hover {
    color: #ffffff !important;
    background-color: rgba(0, 0, 0, 0.7) !important;
}
.channel-header__link-icon {
    font-size: 24px;
    vertical-align: sub;
}
.channel-header__link--secondary {
    color: #ffffff;
}
.channel-header__media {
    margin-left: 24px;
    min-width: 407px;
    height: 229px;
    overflow: hidden;
}
.channel-header__img {
    border-radius: 8px;
}
.channel-header__video {
    width: 100%;
    height: 100%;
    position: relative;
}

@media (max-width: 1199px) {
    .channel-header__description {
        max-width: 397px;
    }
    .channel-header__logo-wrapper {
        margin-right: 16px;
    }
    .channel-header__links {
        margin-top: 16px;
    }
    .channel-header__media {
        width: 391px;
        height: 220px;
    }
}

@media (max-width: 979px) {
    .channel-header__details {
        flex-direction: column;
    }

    .channel-header__logo {
        max-width: 44px;
        min-width: 44px;
        max-height: 44px;
        min-height: 44px;
    }
    .channel-header__logo-wrapper {
        margin-right: 24px;
    }
    .channel-header {
        height: 220px;
    }
    .channel-header__media {
        max-width: 291px;
        min-width: 291px;
        max-height: 164px;
        min-height: 164px;
    }
    .channel-header__banner {
        max-height: 332px;
    }
    .channel-header__title {
        font-size: 32px;
        margin-top: 4px;
    }
    .channel-header__tier {
        margin-top: 9px;
    }
    .channel-header__links {
        margin-top: 13px;
    }
}

@media (max-width: 767px) {
    .channel-header__logo-wrapper {
        margin-right: 8px;
    }
    .channel-header__content-wrapper {
        flex-direction: column;
    }
    .channel-header__logo {
        min-width: 48px;
        max-width: 48px;
        min-height: 48px;
        max-height: 48px;
    }
    .channel-header {
        position: relative;
        height: 100%;
        flex-direction: column;
        padding-top: 92px;
        padding-bottom: 25px;
    }
    .channel-header__banner {
        max-height: 100%;
        width: calc(100% + 56px);
        margin-left: -28px;
        margin-top: -22px;
        padding-bottom: 24px;
    }
    .channel-header__media {
        min-width: 100%;
        max-width: 100%;
        min-height: 193px;
        max-height: 193px;
        flex-basis: 100%;
        text-align: center;
        margin: 16px auto auto 0;
    }
    @media (max-width: 320px) {
        .channel-header__links .btn {
            font-size: 12px;
        }
        .btn.channel-header__link.channel-header__banner__newrow-link.btn-cta-eventplatform {
            font-size: 12px;
        }
    }
}
