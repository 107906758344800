/**
 * Tags in Entry Page
 */

.entry-tags {
    font-size: 12px;
    line-height: 20px;
}

.entry-tag {
    margin-top: 12px;
    margin-right: 16px;
    display: inline-block;
}
