.search-filters__more-filters {
    display: inline-block;
    border-radius: 5px;
    padding: 10px;
    float: right;
}

.filters__clear-all {
    cursor: pointer;
}

.search-filters__more-filters__button-container {
    width: 20px;
    display: inline-block;
}
.filters__button-container__right {
    text-align: left;
}

.search-filters__arrow {
    cursor: pointer;
    text-align: center;
    border: 0;
    padding: 0;
    background: none;
    font-size: 16px;
    margin-top: -2px;
}
.search-filters__arrow--previous {
    padding-right: 10px;
    padding-left: 4px;
}

.search-filters__arrow--next {
    padding: 0 10px;
}
