.sub-section-item {
    display: flex;
    margin: 5px 0 3px 0;
}

.sub-section-item__icon-country {
    display: flex;
}

.sub-section-item__icon {
    font-size: 20px;
    color: #b2b2b2;
}

.sub-section-item__country {
    margin: 0 10px;
    width: 80px;
    font-size: 14px;
    color: #b2b2b2;
}

.sub-section-item__date {
    display: flex;
}

.sub-section-item__date-text {
    margin: 0 10px;
}

@media (max-width: 979px) {
    .sub-section-item {
        margin-top: 8px;
    }

    .sub-section-item-date {
        display: flex;
        flex-direction: column;
    }

    .sub-section__date-text {
        margin: 0;
    }
}

@media (max-width: 479px) {
    .sub-section-item__icon-country {
        display: flex;
    }

    .sub-section-item__date {
        display: flex;
        flex-direction: column;
    }

    .sub-section-item__date-text {
        margin: 0;
        width: 150px;
    }
}
