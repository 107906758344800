.dashboard-task-details-inline {
    display: inline;
}
.dashboard-task-details {
    float: left;
    width: 100%;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 32px 0;
}
.dashboard-task-details-block {
    border-right: 1px solid #cccccc;
    min-height: 160px;
}
.dashboard-task-details-block:last-child {
    border-right: none;
}
.dashboard-task-details-block {
    float: left;
    width: 33.33334%;
    box-sizing: border-box;
    padding: 0 24px;
    text-align: center;
}
.dashboard-task-details-block__block {
    margin: auto;
    display: inline-block;
}
.dashboard-task-details-block__text,
.dashboard-task-details-block__info {
    margin-bottom: 10px;
}
.dashboard-task-details-block__text {
    text-align: left;
}

.dashboard-task-details-block__info-label {
    font-weight: bold;
}
.dashboard-task-details-block__info-icon {
    font-size: 20px;
    margin-bottom: 20px;
}
@media (max-width: 767px) {
    .dashboard-task-details-block {
        width: 100%;
    }
    .dashboard-task-details-block__status-data {
        display: none;
    }
}
