.event-list-item-tag {
    margin: 0 4px;
    max-width: 50%;
    display: inline-block;
}
.event-list-item-tag .tag {
    padding: 0 6px 0 6px;
    border: solid 1px #cccccc;
    color: #666666;
    font-size: 13px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    box-sizing: border-box;
}
@media (max-width: 767px) {
    .event-list-item-tag:first-child {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (max-width: 979px) {
    .event-list-item-tag .tag {
        max-width: 120px;
    }
}

@media (min-width: 980px) {
    .event-list-item-tag .tag {
        max-width: 148px;
    }
}
