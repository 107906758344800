button.btn.btn-borderless-eventplatform.user-menu-toggle-btn {
    color: #ffffff;
}
button.btn.btn-borderless-eventplatform.user-menu-toggle-btn:hover {
    background-color: rgba(256, 256, 256, 0.2);
    color: #ffffff;
}

.eventsplatform
    button.btn.btn-borderless-eventplatform.user-menu-toggle-btn.user-menu-toggle-btn:hover,
.eventsplatform
    button.btn.btn-borderless-eventplatform.user-menu-toggle-btn.user-menu-toggle-btn:focus,
.eventsplatform
    button.btn.btn-borderless-eventplatform.user-menu-toggle-btn.user-menu-toggle-btn:active {
    height: auto;
    line-height: normal;
}

button.btn.btn-borderless-eventplatform.user-menu-toggle-btn:focus,
button.btn.btn-borderless-eventplatform.user-menu-toggle-btn:active {
    outline: 2px solid #006efa;
}

.eventsplatform #header.navbar #userMenuToggle.btn-group {
    margin: 0;
}

.eventsplatform #header .btn.user-menu-toggle-btn,
.eventsplatform #header .btn.user-menu-toggle-btn:active,
.eventsplatform #header .btn.user-menu-toggle-btn:focus {
    padding: 0 8px;
}

.eventsplatform #header.navbar .btn-group i {
    margin-right: 0;
    position: initial;
    top: 0;
    font-size: 24px;
    line-height: 32px;
}
