.blink {
    -webkit-animation-name: blink;
    animation-name: blink;
    -webktit-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
