.btn.grid-view,
.btn.longView {
    padding-left: 0;
    padding-right: 0;
    border-color: transparent !important;
    background-color: transparent !important;
    box-shadow: none;
    font-size: 32px;
    margin-top: 0;
    margin-left: 10px;
    transition: color 0.2s;
}
.btn.grid-view.active,
.btn.longView.active {
    color: #008297;
}
.buttons__list {
    list-style: none;
}
