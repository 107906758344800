/** animation */
@keyframes show-search-input {
    0% {
        margin-top: -200%;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes hide-logo {
    0% {
        opacity: 100%;
    }
    100% {
        opacity: 0;
    }
}

.header-menu__mobile-search.show-search-input {
    animation-name: show-search-input;
    animation-duration: 0.2s;
    animation-iteration-count: 1;
    animation-fill-mode: both;
}
.hide-logo {
    animation-name: hide-logo;
    animation-duration: 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.header-menu__mobile-search {
    margin-top: -200%;
    flex-grow: 1;
}

/** animation end */
.site-header__container.header-menu__navbar-inverse
    .header-menu__navbar--inner {
    background-image: inherit;
    background-color: #000000;
}

.site-header__container.header-menu__navbar-inverse.gradient
    .header-menu__navbar--inner {
    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
    );
    background-color: initial;
}

.site-header__container .header-menu__navbar--inner {
    padding: 8px 8px;
}

.site-header__container .main-sidebar__menu-button {
    margin-right: 10px;
}

.site-header__container .main-sidebar__menu-button i {
    color: #ffffff;
}
.site-header__container .user-menu-toggle-btn {
    display: flex;
    padding: 0 8px;
}

.site-header__container .user-menu-toggle-btn i {
    color: #ffffff;
    font-size: 24px;
    line-height: 32px;
}

.site-header__container .logoImg.brand {
    padding: 0;
    margin: 0;
}

.site-header__container .main-sidebar__menu-button:focus {
    outline: 2px solid #006efa;
}

.header-menu__logo {
    flex-grow: 0;
}

.header-menu__search-wrap {
    padding: 0 8px;
    flex-grow: 1;
}
.header-menu__search.search-input {
    width: 210px;
    float: right;
    background-color: rgba(0, 0, 0, 0.3);
    border-color: rgba(256, 256, 256, 0.4);
}
.header-menu__search.search-input:hover {
    border-color: rgba(256, 256, 256, 0.6);
}
.header-menu__search.search-input__focused,
.header-menu__search.search-input__focused:hover {
    border-color: rgba(256, 256, 256, 0.6);
}

.header-menu__search .search-input__icon {
    color: rgba(256, 256, 256, 0.7);
}

.site-header__container .header-menu__search input[type="text"] {
    background-color: initial;
    color: #ffffff;
    opacity: 0.7;
}

.site-header__container .header-menu__search .search-input__input::placeholder {
    color: #ffffff;
    opacity: 0.7;
}

.header-menu__navbar {
    display: flex;
    align-items: center;
}
.skip-to-content-link {
    flex-grow: 0;
}

button.btn.btn-borderless-eventplatform.header-menu__search-btn,
button.btn.btn-borderless-eventplatform.header-menu__search-btn:hover {
    color: #ffffff;
}

@media (max-width: 480px) {
    .site-header__container .btn.header-menu__search-btn {
        font-size: 24px;
        line-height: 32px;
        margin-top: 0;
        float: right;
        padding: 0;
    }
    .btn.header-menu__search-btn i {
        margin-right: 0;
    }
    .header-menu__search-wrap {
        padding: 0;
    }
    .site-header__container .btn.header-menu__search-btn,
    .site-header__container .btn.header-menu__search-btn:active,
    .site-header__container .btn.header-menu__search-btn:focus {
        padding: 0;
    }
}
@media (max-width: 767px) {
    /* override kms.css */
    .site-header__container .header-menu__navbar--inner {
        margin-left: 0;
    }
}
