.edit-registration-form__block {
    display: block;
}

.registration-form-editor__section {
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
    background-color: #F4F4F4;
}

.registration-form-editor-section__field {
    border: solid 1px #cccccc;
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
}

.field-defaults-wrap {
    border: solid 1px #cccccc;
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
}

.field-defaults-wrap input {
    width: 100%;
}

.field-options-wrap {
    border: solid 1px #cccccc;
    padding: 16px;
    margin: 16px;
    border-radius: 4px;
}

.field-options-wrap input {
    width: 100%;
}

.registration-form-editor__add-item {
    margin: 6px;
}
.registration-form-editor .siteRegistration-error {
    color: #dc430d;
}

button.btn.registration-form-editor__remove-item  {
    color: #666666;
}

.registration-form-editor__section .toggleButton {
    width: 100%;
    text-align: left;
}

hr {
    border-top: 1px solid #cccccc;
    border-bottom:0;
}
