h3.media-item__name {
    font-size: 16px;
    line-height: 1.38;
    letter-spacing: normal;
    margin: 0;
}
.media-item__name {
    max-height: 44px;
    overflow: hidden;
}
.media-item__name-wrapper {
    padding: 0px 16px;
}
.media-item__img--no-image {
    background-color: black;
}
.media-carousel__arrow {
    font-size: 48px;
}

/* override carousel style to match design */
.media-carousel__item-container {
    position: relative;
    margin-right: 32px;
}
.media-carousel .slick-slide {
    width: 276px;
}
.media-carousel .slick-list {
    margin-right: -32px;
}
.media-carousel .slick-arrow:before {
    content: none;
}

.media-carousel .slick-arrow a.tabbed-arrow {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.media-carousel .slick-next {
    right: -41px;
}
.media-carousel .slick-prev {
    left: -66px;
}
.media-carousel--one-slide .slick-track {
    margin-left: 0;
}

/* Styles for "unslick" for mobiles - navigate by scrolling, but don't show the scrollbar */
.media-carousel.regular {
    white-space: nowrap;
    overflow-x: auto;

    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
}
.media-carousel.regular::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.media-carousel.regular .media-carousel__item-container {
    display: inline-block;
    vertical-align: top;
}

.media-carousel.regular .media-carousel__item-container:last-child {
    margin-right: 0;
}

@media (max-width: 1199px) {
    .media-item__img-wrapper {
        height: 174px;
    }
    .media-carousel .slick-next {
        right: -25px;
    }
    .media-carousel .slick-prev {
        left: -35px;
    }
    .media-carousel__arrow {
        font-size: 32px;
    }
    .media-carousel .slick-list {
        margin-right: -16px;
        min-width: 958px;
    }
    .media-carousel__item-container {
        margin-right: 16px;
    }
}
