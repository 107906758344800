/* Margin between sections */
* + .homepage-eventplatform__component {
    margin-top: 60px;
}

@media (max-width: 979px) {
    * + .homepage-eventplatform__component {
        margin-top: 40px;
    }
}
