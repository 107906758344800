.channel-grid__title {
    margin-bottom: 32px;
}

.channel-grid__description {
    width: 690px;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.31;
    color: #666666;
}

.channel-grid__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
}

@media (max-width: 1200px) {
    .channel-grid__description {
        width: 770px;
    }
}

@media (max-width: 979px) {
    .channel-grid__title {
        font-size: 32px;
        margin-bottom: 16px;
    }

    .channel-grid__description {
        margin-bottom: 24px;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .channel-grid__search {
        min-width: 193px;
        max-width: 193px;
    }

    .channel-grid__tiers-filter {
        min-width: 104px;
        max-width: 104px;
    }
}
