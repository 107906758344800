.modal .modal-body.user-edit-modal__body {
    padding: 0;
    min-height: 367px;
}

.modal .modal-footer .user-edit-modal__button.user-edit-modal__button {
    border: none;
    padding: 8px;
    margin-left: 8px;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform {
    background-color: #006efa;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform:hover {
    background-color: #0055cc;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform:active,
.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-cta-eventplatform:focus {
    background-color: #006bff;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-borderless-eventplatform:hover {
    background-color: #cccccc;
}

.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-borderless-eventplatform:active,
.modal
    .modal-footer
    .user-edit-modal__button.user-edit-modal__button.btn-borderless-eventplatform:focus {
    background-color: #ebebeb;
}

.user-edit-modal__content-wrapper {
    padding: 20px 40px 26px 32px;
    margin-top: -87px;
}

.user-edit-head__img {
    background-size: cover;
    height: 160px;
}

.user-edit-head__user-img {
    display: inline-block;
    vertical-align: top;
    margin-right: 32px;
}

.user-thumbnail.user-edit-head__user-img {
    width: 133px;
    height: 133px;
}

.user-thumbnail.user-edit-head__user-img .user-thumbnail__img {
    height: 100%;
}

.user-edit-modal__text {
    box-sizing: border-box;
    width: 100%;
}

.textarea__char-count.user-edit-modal__char-count {
    margin-top: 0;
    font-size: 13px;
}

.user-edit-modal__description {
    margin-bottom: 0;
    font-size: 13px;
    color: #666666;
}

.user-edit-modal__form_dropdown {
    margin-top: 8px;
}

.user-edit-modal__error {
    margin: 24px 0 0;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .user-edit-modal {
        width: 945px;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .user-edit-modal {
        width: 797px;
    }

    .user-edit-modal__content-wrapper {
        padding: 20px 40px 26px 32px;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .user-edit-modal__content-wrapper {
        padding: 24px 24px 26px 24px;
    }
}

@media (max-width: 480px) {
    .user-edit-modal__content-wrapper {
        padding: 24px 16px 26px 16px;
    }
}
