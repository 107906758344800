.playlist-item__head {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 24px;
}

.playlist-item__head > div:nth-child(2) {
    white-space: nowrap;
}

.playlist-item__title {
    margin: 0;
    line-height: normal;
}
.playlist-item {
    margin-bottom: 32px;
}
/* make sure arrows are shown outside tab content*/
#wrap .event-channel__tab-content.tab-content {
    overflow: visible !important;
}
@media (max-width: 979px) {
    #wrap .event-channel__tab-content.tab-content {
        overflow: hidden !important;
    }
}
