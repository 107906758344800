/**
 *  AttachmentUploadForm css
 */

.order-form__alignment__uploadForm__field-label {
    margin-top: 14px;
}

.order-form__alignment__uploadForm__field-description {
    font-size: 12px;
    margin-bottom: 8px;
}

.order-form__alignment__uploadForm__field {
    width: 100%;
    box-sizing: border-box;
    min-height: 36px;
}
