.history-container.progress {
    margin: 0;
    width: 100%;
    height: 6px;
    border-radius: 0;
    box-shadow: none;
    /* will be overridden by config */
    background-color: #bbbbbb;
}
.history__bar.complete {
    /* will be overridden by config */
    background: #bbbbbb none;
}

.history__bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    color: #fff;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background: #0e90d2 linear-gradient(to bottom, #149bdf, #0480be);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff149bdf',endColorstr='#ff0480be',GradientType=0);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    transition: width 0.6s ease;
}
