.csv-upload-button {
    padding-left: 0;
}

.csv-upload-button--replace {
    margin-left: 9px;
}

.csv-upload-button--retry {
    margin-left: 4px;
    padding: 0 0 2px 0;
}

.uploaded-file-name {
    font-size: 14px;
    padding: 5px 8px;
    font-weight: 300;
}

.action-button-icon {
    color: #666;
    margin-left: 9px;
}

.manual-emails__errors {
    color: #dc430d;
}

.manual-emails-status-bar {
    margin-top: 8px;
    font-size: 12px;
}

.manual-emails__count--limit-reached {
    color: #dc430d;
}

.registration__form-email-validation-row {
    margin-top: 20px;
}
