.channelActionsBtn__wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.btn-group {
    margin-top: auto;
}

.channelActionsBtn__wrapper button.btn.btn-borderless-eventplatform {
    display: flex;
    align-items: center;
    color: #006bff;
}

.channelActionsBtn__wrapper .btn-group.open .btn.dropdown-toggle {
    background-color: #e6e6e6;
}

.channelActionsBtn__wrapper .dropdown-menu {
    border-radius: 6px;
}

.channelActionsBtn__wrapper li {
    height: auto;
}

.channelActionsBtn__wrapper a:hover {
    font-weight: inherit;
}
