.user-profile__page-content {
    margin-top: 184px;
    padding-top: 32px;
}

#wrap .user-profile__page-content .nav.nav-tabs {
    margin-bottom: 32px;
}
@media (max-width: 1199px) {
    .user-profile__page-content {
        margin-top: 143px;
    }
}
@media (max-width: 980px) {
    .user-profile__page-content {
        margin-top: 190px;
    }
}
@media (max-width: 480px) {
    .user-profile__page-content {
        padding-top: 0;
        margin-top: 107px;
    }
}

.user-profile__tab-content {
    display: contents;
}
#wrap .tab-content.user-profile__tab-content {
    overflow: visible !important;
}
@media (max-width: 979px) {
    .user-profile__tab-content {
        padding-top: 8px;
        clear: both;
    }
}

.user-profile__btn-update {
    margin-right: 8px;
}
.user-profile__btn-unregister {
    margin-left: 8px;
}
