.event-presenter {
    display: flex;
    padding-right: 16px;
}
@media (min-width: 481px) {
    .event-presenter {
        max-width: 40%;
    }
}
.event-presenter__details {
    padding-left: 8px;
}
.event-presenter__thumbnail {
    border-radius: 8px;
    width: 36px;
    height: 36px;
}
.event-presenter__name {
    color: #333333;
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    margin: 0;
}
.event-presenter__title {
    color: #666666;
    font-size: 13px;
    line-height: 21px;
    margin: 0;
}
