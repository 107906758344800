.classroom__resource-text {
    font-size: 12px;
    line-height: 1.33;
    text-align: left;
    color: #4a4a4a;
}

.classroom-columns-header {
    padding-left: 0;
    cursor: default;
}

.classroomDashboardComponent .rt-resizable-header-content {
    padding-left: 0;
}

.classroom-no-data {
    position: absolute;
    bottom: -40px;
    width: 100%;
    text-align: center;
}

.classroomDashboardComponent .ReactTable .rt-tbody {
    overflow-y: hidden;
}

.classroomDashboardComponent .rt-tr {
    cursor: pointer;
}

.classroomDashboardComponent .ReactTable .rt-resizer {
    display: none;
}

.classroomDashboardComponent .ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
    border-right: none;
}

.classroomDashboardComponent .ReactTable .rt-tbody .rt-td {
    border-right: none;
}
