/**
 * Search Suggestions component css
 */

.searchSuggestions {
    position: relative;
    top: -10px;
    margin-left: 38px;
    background-color: white;
}

.searchSuggestions__list {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 4px;
    padding-bottom: 4px;

    position: absolute;
    width: 100%;
    z-index: 1000;

    border: 1px solid #cccccc;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.searchSuggestions__suggestion {
    padding-bottom: 8px;
    padding-top: 8px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    outline: none;
    background-color: white;
    font-size: 14px;
    font-weight: normal;
}

.searchSuggestions__suggestion.active {
    background-color: #f9f9f9;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}
