/**
 * Registration Form css
 */

.registrationPage-form {
    font-size: 14px;
}

.registrationPage-form__title {
    display: block;
    margin-bottom: 24px;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    line-height: normal;
    color: #333333;
}

.registrationPage-form .form--compact {
    margin-bottom: 0;
}

.registrationPage-form__btns {
    text-align: center;
}

.registrationPage-form__btns--submit {
    width: 100%;
    height: 48px;
    font-size: 20px;
    font-weight: bold;
    margin-top: 4px;
}

.registrationPage-form .form-field input[type="text"] {
    height: 40px;
}

.registrationPage-form
    .form-field
    .autocomplete-dropdown-container.registrationPage-form-field__input
    input[type="text"] {
    height: 36px;
}

.registrationPage-form .form-label {
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.4px;
}

.registrationPage-form-field__input {
    margin-bottom: 10px;
}

.registrationPage-form-field__input--error.registrationPage-form-field__input--error {
    border-color: #dc430d;
}

.form-field__error-text {
    font-size: 12px;
    color: #dc430d;
    text-align: center;
}
