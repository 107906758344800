.action-icon__button {
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-icon__button:hover,
.action-icon__button:focus {
    color: #666666;
}
