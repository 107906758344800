.event-list-item {
    display: flex;
    font-size: 14px;
    border-radius: 4px;
}

.event-list-item .dropdown-menu.pull-right {
    left: 0;
}

.event-list-item__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1;
    background-color: #f4f4f4;
    padding: 16px;
    min-width: 0;
}

.event-list-item__details {
    max-width: 90%;
}

.event-list-item__thumbnail {
    width: 330px;
    height: 100%;
}

.event-list-item__name {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    margin: 0 0 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.event-list-item__description {
    color: #666666;
    font-size: 14px;
    line-height: 1.29;
    margin: -4px 0 -16px;
}
.eventsplatform .event-list-item__description .truncateManager__more {
    font-size: inherit;
}
.event-list-item__join-button {
    float: right;
}
.event-list-item__status--offline {
    color: #999999;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border: 1px solid #999999;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
    padding: 0 2px;
}
.event-list-item__status--live {
    color: #ffffff;
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border-radius: 4px;
    display: inline-block;
    padding: 1px 3px;
    background-color: #fa374b;
}

/* region Make sure that the "actions wrapper" element will not prevent neighbor elements from mouse clicking */
.event-list-item__actions-wrapper {
    pointer-events: none;
}

.event-list-item__tags,
.event-list-item__action > * {
    pointer-events: all;
}
/* endregion */

.event-list-item__action {
    display: flex;
    padding-top: 5px;
    align-items: center;
}

.event-list-item__action button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.event-list-item__action button i {
    font-size: 24px;
}

.event-list-item__action .event-list-item__join-button {
    float: none;
    margin-right: 8px;
}

.event-list-item__agenda-button {
    margin-right: 8px;
}

.event-list-item
    .event-list-item__container
    button.btn.btn-danger-eventplatform[type="button"]:hover {
    background-color: #c82c3c;
    color: #ffffff;
}

.event-list-item
    .event-list-item__container
    button.event-list-item__join-button[type="button"]:hover {
    background-color: #0058c8;
    color: #ffffff;
}

.event-list-item
    .event-list-item__container
    button.btn.btn-borderless-eventplatform[type="button"]:hover {
    background-color: #cccccc;
    color: #006efa;
}

@media (max-width: 767px) {
    .event-list-item__action {
        padding-top: 16px;
    }

    .event-list-item__tags {
        padding: 16px 0 0;
    }

    .event-list-item__description {
        margin: -4px 0 0;
    }
}

@media (min-width: 768px) {
    .event-list-item__tags {
        flex-grow: 1;
        padding: 0;
        align-items: center;
    }
    .event-list-item__tags .event-list-item-tag {
        float: right;
    }
}
