.gallery-result__name {
    font-size: 20px;
    vertical-align: middle;
    margin-top: 8px;
    text-indent: -0.5em;
    margin-left: 21px;
    line-height: 24px;
}
.gallery-result__bullet {
    font-size: 5px;
    padding-right: 10px;
    vertical-align: middle;
}

.gallery-result__tags {
    float: left;
}
.gallery-result__more {
    padding-top: 4px;
}
.gallery-result {
    padding: 32px 0 32px 20px;
}
@media (max-width: 767px) {
    .gallery-result__tags {
        margin-top: 8px;
    }
    .gallery-result__more {
        padding-top: 12px;
    }
}
