/**
 * css for the FeatureAlignmentForm component
 */

.order-form__alignment {
    margin-top: 10px;
}

.order-form__alignment__header {
    display: table;
    height: 36px;
    margin-bottom: 24px;
}

.form__alignment__title {
    display: table-cell;
    vertical-align: middle;
}

.form__alignment__uploadButton {
    float: right;
    padding: 7px 20px;
    font-size: 16px;
    margin-left: 16px;
}

.btn.form__alignment__uploadButton {
    color: #666;
    border-color: #666 !important;
}

.order-form__alignment__radios {
    padding-right: 16px;
    vertical-align: bottom;
}

.order-form__alignment__radios .filter-checkbox {
    margin-top: 8px;
    padding-left: 2px;
    height: 26px;
}

.order-form__alignment__table table.table {
    background-color: white;
    margin-bottom: 0;
}

.order-form__alignment__table table.table th {
    font-size: 14px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.order-form__alignment__table table.table td {
    font-size: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
}
