.upcoming-webcast-month-name {
    font-size: 14px;
    line-height: 21px;
    color: black;
}

.upcoming-webcast-month-name + .upcoming-webcast-card-container {
    margin-top: 8px;
}

.upcoming-webcast-card-container + .upcoming-webcast-card-container {
    margin-top: 16px;
}

.upcoming-webcast-card-container + .upcoming-webcast-month-name {
    margin-top: 24px;
}

@media (max-width: 767px) {
    .upcoming-webcast-card-container + .upcoming-webcast-card-container {
        margin-top: 0;
    }

    .upcoming-webcast-card-container + .upcoming-webcast-month-name {
        margin-top: 15px;
    }
}
