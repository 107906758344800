.group-page-header {
    margin-bottom: 32px;
}

.back-to-groups-link {
    line-height: 54px;
}

.group-page-header .editable-group-name {
    display: block;
    margin-left: 0;
}

.editable-group-name .edit-icon,
.group-name-edit-actions i {
    font-size: 16px;
}

.group-name-editable-label {
    padding-right: 50px;
}

.group-name-edit-actions {
    right: 16px;
    top: 30%;
}

input.group-name-edit-box__input {
    width: 100%;
    height: 58px;
    font-size: 28px;
    padding-right: 10%;
}

.group-header-nav {
    padding: 2px 0;
}
