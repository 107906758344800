.my-media__table-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-top: 5px;
    padding-bottom: 5px;
}

/* fix the checkbox in place - when tags expand */
.ReactTable.-selection-table
    .rt-tbody
    .rt-td.selection--table--checkbox--column.my-media-table__checkboxes {
    margin-top: 22px;
}
.my-media__table-actions-header > div {
    text-transform: uppercase;
    text-align: left;
    padding: 19px 11px;
    font-size: 13px;
    font-weight: bold;
}

/* leave more room to the internal html - without increasing row height */
.my-media-table.ReactTable .rt-td {
    padding-top: 14px;
    padding-bottom: 14px;
}
.my-media__table-cell-tags,
.my-media__table-cell-publish {
    padding-top: 4px;
    padding-bottom: 0;
    min-height: 20px;
}
.media-entry-results .ReactTable .links-list {
    top: 0;
}

.my-media__table-cell--name {
    padding-left: 0;
    font-weight: bold;
}
.table-extra-actions {
    margin-right: 20px;
}

.my-media__table-cell--actions .my-media__actions {
    justify-content: flex-start;
}

.my-media__table-cell--actions .my-media__actions-link:first-child {
    margin-right: 16px;
}

/*
 * default buttons colors
 */
.my-media__actions-table .my-media__actions-link {
    color: #666666;
}

.my-media__actions-table .my-media__actions-link:hover,
.my-media__actions-link:focus {
    color: #000000;
}

.my-media__actions-table
    .my-media__actions-link.my-media__actions-link--disabled,
.my-media__actions-table
    .my-media__actions-link.my-media__actions-link--disabled:hover,
.my-media__actions-table
    .my-media__actions-link.my-media__actions-link--disabled:focus {
    color: #cccccc;
}

.my-media-table__checkboxes--top {
    margin-top: 15px;
}

.my-media__table-cell--mediaType {
    text-align: center;
    padding-right: 11px;
}

.my-media__table-header--mediaType .rt-resizable-header-content {
    text-align: center;
}

.my-media__table-cell--inner {
    display: inline;
}
