.badges__container,
.badges__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.badges__list-header {
    font-size: 18px;
    font-weight: bold;
    width: 100%;
}
.badges__list-sub-header {
    font-weight: normal;
    font-size: 16px;
}
