.siteRegistration-verification {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10vh;
}

.siteRegistration-verification-inner {
    max-width: 475px;
}
