/**
 *  Edit Entry Footer component css 
 */

.editEntryFooter {
    margin-bottom: 32px;
}

.editEntryFooter--link {
    margin-left: 32px;
    text-decoration: underline;
}
