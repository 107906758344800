/**
 * 	FilterBar component css
 */

.filterBar__controls {
    width: 100%;
}

.filterBar__pre {
    margin-right: 24px;
}

.filterBar__controls-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
}

.filterBar__filters {
    flex: 1;
}

.filterBar {
    margin-top: 8px;
    margin-bottom: 10px;
}
.filterBar__description {
    padding-top: 8px;
    font-size: 16px;
    height: 36px;
}
@media (max-width: 767px) {
    .filterBar {
        margin-top: 0;
    }
}

.filterBar .DropdownFilter {
    float: left;
}

.filterBar__dropdowns {
    margin-bottom: 0;
    float: left;
}

@media (max-width: 480px) {
    .filterBar__dropdowns {
        width: 100%;
        margin-top: 13px;
    }
}
/* adjust for kms css - untill we stop using the shrink wrapper classes */
#wrap #filterBarWrap .filterBar .toggleButton {
    margin-left: 0;
}

#wrap #filterBarWrap:not(.navbar) .filterBar .btn {
    margin-top: 0;
}

#wrap #filterBarWrap .filterBar .shrink-container__button.btn {
    margin-left: 0;
}
