.user-badge__image {
    transform: translateX(-8px);
    opacity: 0.5;
}
.user-badge__image img {
    width: 100px;
    height: 100px;
}
.user-badge__image--in-progress {
    opacity: 1;
}
.user-badge__info {
    flex: 1 1;
}
.user-badge *,
.user-badge *:before,
.user-badge *:after {
    box-sizing: border-box;
}
.badge__label {
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.4px;
    border: solid 1px #333333;
    margin-left: 8px;
    padding: 2px 2px;
}
.user-badge__name-and-label {
    display: flex;
    align-items: center;
}
.user-badge__name {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
}
.user-badge__how-to {
    font-weight: bold;
}
.user-badge__level-image img {
    /*
    works better images than directly setting width and height
    */
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
}
.user-badge__level-image {
    background-color: transparent;
    position: relative;
    cursor: pointer;
    opacity: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.user-badge__level-points {
    position: absolute;
    bottom: 0;
    transform: translateY(100%);
}
.user-badge__level-image--disabled {
    cursor: auto;
}
.user-badge__level-popover .Popover-tip {
    display: none;
}
.user-badge__level-image--completed {
    opacity: 1;
}
.user-badge__current-points {
    position: relative;
}
.user-badge__current-points span {
    background-color: #ffaa00;
    padding: 3px;
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    border-radius: 4px;
}
.level-tooltip__container {
    display: flex;
    background-color: #ffffff;
    padding: 8px;
    box-shadow: 0 4px 30px -8px rgba(0, 0, 0, 0.2);
    border: solid 1px #d9d9d9;
    border-radius: 4px;
}
.level-tooltip__image {
    flex-shrink: 0;
}
.level-tooltip__image img {
    width: 72px;
    height: 72px;
}
.level-tooltip__content {
    padding-left: 8px;
}
.level-tooltip__text-container {
    max-width: 352px;
}
.level-tooltip__header {
    font-size: 16px;
    font-weight: bold;
}
.level-tooltip__description {
    padding-bottom: 1em;
}
.user-badge__how-to {
    padding-left: 0;
    text-decoration: none;
}
.user-badge__how-to:hover {
    text-decoration: none;
}
.user-badge {
    width: 100%;
    display: flex;
    border-radius: 4px;
    background-color: #f4f4f4;
    padding: 20px;
    margin-top: 16px;
    margin-bottom: 16px;
    flex-wrap: wrap;
}
.user-badge.user-badge__progress {
    width: 100%;
}
.user-badge__progress-line-container {
    background-color: #cccccc;
    height: 8px;
    width: 10%;
    flex-basis: 40%;
    position: relative;
}
.user-badge__progress-line {
    position: absolute;
}

.user-badge__progress-line--completed {
    position: absolute;
    height: 8px;
    left: 0;
    top: 0;
    background-color: #ffaa00;
}
.user-badge__levels {
    display: flex;
    margin-left: auto;
    align-items: center;
    padding-bottom: 20px;
}
/* tablet */
@media (min-width: 766px) {
    .user-badge {
        width: calc(50% - 64px);
    }
    .user-badge__info {
        flex: 2 1;
    }
    .user-badge__levels {
        width: 100%;
    }
}
/* desktop */
@media (min-width: 1024px) {
    .user-badge__levels {
        flex: 3 1;
        padding-bottom: 0;
    }
}
