.classroom__resource-thumb-container {
    float: left;
    background-color: #eaeaea;
    margin-right: 26px;
    width: 256px;
    border-radius: 3px;
}

.resource-additional-data-header {
    height: 58px;
}

.classroom__resource-additional-data-header-text {
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.2px;
    color: #4a4a4a;
}

.classroom__input-preview-text-container {
    width: 50%;
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
    float: left;
}

.classroom__input-preview-live-data {
    width: 50%;
    height: 100%;
    padding-top: 10px;
    float: left;
}

.classroom__resource-thumb-image {
    position: relative;
    height: 144px;
    background-color: #4a4a4a;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.classroom__resource-thumb-text {
    top: calc(50% - 14px);
    margin-left: auto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.2px;
    color: #dadada;
    width: 100%;
    position: absolute;
    text-align: center;
}

.classroom__streaming-tag-container {
    width: 65px;
    height: 20px;
    border-radius: 4px;
    background-color: #db1f26;
    float: left;
}

.classroom__offair-tag-container {
    width: 65px;
    height: 20px;
    border-radius: 4px;
    background-color: #666666;
    float: left;
}

.classroom__viewers-number-container {
    position: relative;
    width: 52px;
    height: 20px;
    border-radius: 4px;
    background-color: #000000;
    margin-left: 8px;
    float: left;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

.classroom__live-tag-text {
    opacity: 0.9;
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.classroom__viewers-number-text {
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
}

.classroom__viewers-number-icon {
    float: left;
    padding-left: 8px;
    padding-top: 2px;
    color: #ffffff;
}
