.request-summary-by-status__info:first-child {
    border-right: 2px solid #eaeaea;
}

.request-summary-by-status__info:last-child {
    border: none;
}

.request-summary-by-status__info {
    float: left;
    text-align: center;
    font-family: Lato;
    border-right: 2px solid #eaeaea;
    box-sizing: border-box;
}

.request-summary-by-status__info-value {
    color: #00b4d1;
    font-size: 36px;
    font-weight: bold;
    letter-spacing: 0.26px;
    line-height: 44px;
}

.request-summary-by-status__info-title {
    font-weight: 500;
    line-height: 28px;
    color: #666666;
    font-size: 16px;
}
.request-summary-by-status {
    padding-top: 30px;
    float: left;
    width: 100%;
}

.request-summary-by-status__title-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 16px;
}

.request-summary-by-status__title-text {
    height: 16px;
    color: #666666;
    font-family: Lato;
    font-size: 16px;
    font-weight: bold;
    line-height: 16px;
    text-transform: uppercase;
}

.request-summary-by-status__title {
    padding-left: 24px;
    margin-bottom: 35px;
}
