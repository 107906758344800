.resource-preview-container {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    float: left;
}

.classroom__resource-id {
    position: absolute;
    float: right;
    bottom: 20px;
    right: 0;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: left;
    color: #999999;
}
