.share-page__copy-wrap {
    width: 100%;
    box-sizing: border-box;
    margin-top: 24px;
}

.share-page__copy-text {
    width: 800px;
}

.copy-text__input.copy-text__input {
    background-color: white;
}
