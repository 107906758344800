.toggleButton.toggleButton--disabled {
    color: #e1e1e1;
    transition: opacity 0.3ms;
}
.toggleButton--disabled .toggleButton__icon {
    cursor: default;
}
.toggleButton {
    opacity: 1;
}
.toggleButton__icon {
    cursor: pointer;
}
