/**
 * Checkbox Component css
 */

.checkbox-custom-wrapper {
    display: inline-block;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer;
    line-height: 20px;
}

.checkbox-custom {
    opacity: 0;
    position: absolute;
}
.checkbox-custom,
.checkbox-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px 5px 5px 0;
    cursor: pointer;
    line-height: 14px;
}
.checkbox-custom-label {
    position: relative;
    padding-left: 1px;
}

.checkbox-custom-icon {
    box-sizing: border-box;
    margin-right: 10px;
    vertical-align: middle;
}

.checkbox-custom-icon--active {
    color: #008297;
}

.checkbox-custom-icon--disabled {
    color: #9e9d9d;
    cursor: not-allowed;
}

.checkbox-custom-label--disabled {
    color: #9e9d9d;
    cursor: not-allowed;
}
