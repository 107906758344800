.webcast-homepage-recorded-item-thumbnail-container {
    margin-bottom: 5px;
    border-radius: 4px;
    overflow: hidden;
}

.webcast-homepage-recorded-item-thumbnail-container
    .webcast-homepage-recorded-item-thumbnail-duration {
    background-color: #000000;
    padding: 7px 12px 7px 16px;
    opacity: 0.7;
    font-size: 16px;
}

.webcast-homepage-recorded-item-thumbnail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
