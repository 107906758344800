.playlist-search-results {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}
.playlist-search-results::after {
    content: "";
    width: 32%;
}
.playlist-search-results--no-playlists:not(.playlist-search-results--no-extra-playlits) {
    border-top: 1px solid #ebebeb;
    padding-top: 16px;
}
.playlist-search-results--grid {
    justify-content: space-between;
    flex-flow: row wrap;
}
.playlist-search-results--detailed {
    flex-direction: column;
}
.playlist-result--grid .collage {
    display: block;
}
