/**
 *	css for the global search page
 */

@media (min-width: 1025px) {
    .globalSearchResults__horizontalSidebar {
        display: none !important;
    }
}

@media (max-width: 1024px) {
    .globalSearchResults__verticalSidebar {
        display: none !important;
    }
}
