.my-media-entry__container {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 10px;
    line-height: initial;
    flex: 1 0 auto;
    max-width: 100%;
}

.my-media-entry {
    display: flex;
}

.media-preview {
    display: inline-block;
    flex-shrink: 0;
    width: 322px;
    height: 181px;
}

.my-media-entry__container .my-media-entry-details {
    flex: 1 1 auto;
}

.my-media-entry__name-and-description {
    height: 100%;
}

.my-media-entry__basic .my-media-entry__name {
    display: flex;
    flex: 0 1 20px;
}

.my-media-entry__basic .my-media-entry__name > a {
    flex: 1;
    min-width: 0;
    overflow: hidden;
}

.my-media-entry__basic .my-media-entry__name > a:hover span {
    text-decoration: underline;
}

.my-media-entry__name .results-entry__name {
    white-space: normal;
    max-width: 100%;
}

.my-media-entry__basic {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.my-media-entry-details {
    padding-left: 35px;
    overflow: hidden;
}

.my-media-entry-details--shrink {
    width: 100%;
}

.my-media-entry__status {
    display: inline-block;
    padding: 2px 30px;
    color: #ffffff;
    border: 0;
    font-size: 12px;
    line-height: normal;
}

.my-media-entry__bottom-description {
    position: static;
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
    font-size: 12px;
    color: #666;
    line-height: 1;
}

.my-media-entry__bottom-description
    .bottom-description__item:not(:first-child) {
    margin-left: 6px;
}

.my-media-entry__statistics .entryStatistics__stat {
    margin-left: 16px;
    margin-right: 0;
}

/**
 *  cause the two sections of the bottom stats to take one line,
 *  and overflow each seperatly with ellipsis.
 *
 *  in mobile there is just one multi-line stat section.
 */
@media (min-width: 480px) {
    .bottom-description__section,
    .bottom-description__item.my-media-entry__statistics {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50%;
    }
}

.my-media-entry__bottom-description
    .bottom-description__item.my-media-entry__statistics {
    margin-left: 14px;
}

.my-media-entry__tags-container {
    min-height: 0;
}

.my-media-entry__buttons {
    flex: 0 0 60px;
    display: flex;
    justify-content: space-around;
}
.my-media-entry__extra-buttons {
    margin-right: 8px;
    margin-left: 14px;
    margin-top: -2px;
}
.my-media-entry__extra-buttons--search-results {
    margin-top: -1px;
}

.my-media-entry__extraInfo {
    margin-top: 8px;
    font-weight: bold;
}
.my-media-entry__extraInfo-icon {
    margin-right: 5px;
    color: #da4f49;
}

@media (max-width: 480px) {
    .my-media-entry__extraInfo {
        display: none;
    }
}

@media (max-width: 1200px) {
    .my-media-entry-details {
        padding-left: 20px;
    }
}

@media (max-width: 980px) {
    .my-media-entry__basic .my-media-entry__tags-container {
        min-height: 0;
    }
}

/**
 * limit media entry width
 */

@media (max-width: 1200px) {
    .media-preview {
        width: 300px;
        height: 169px;
    }
}

@media (max-width: 980px) {
    .media-preview {
        width: 290px;
        height: 163px;
    }

    .my-media-entry__tags-container {
        min-height: 35px;
    }

    .my-media-entry-details .my-media-entry__name .results-entry__name {
        max-width: 200px;
    }
}

/**
 * tablet alignment
 */
@media (max-width: 767px) {
    .media-preview {
        width: 258px;
        height: 145px;
    }

    .my-media-entry-details {
        padding-left: 16px;
    }
}

/**
 *  mobile alignment
 */
@media (max-width: 480px) {
    /**
     *  make the (first) stat section multi-line
     */
    .bottom-description__section {
        max-width: 100%;
        line-height: 1.33;
        white-space: normal;
    }

    .media-preview {
        width: 171px;
        height: 96px;
    }

    .my-media-entry-details {
        min-height: 69px;
    }
}

/**
 * actions modifier
 */

/************************/
/**** Media Queries *****/
/************************/

@media (max-width: 767px) {
    .media-preview {
        vertical-align: top;
    }

    .my-media-entry__container {
        padding: 0;
    }

    .my-media-entry__basic .my-media-entry__name {
        flex-basis: 100px;
    }
}

/**
 *  compensate for the lack of description and tags
 */
@media (max-width: 480px) {
    .my-media-entry__basic .my-media-entry__name {
        flex-basis: 56px;
    }
}
