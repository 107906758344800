/**
 *  Categories search results - tablet
 */

.CategorySearchResults__header {
    display: inline-block;
    height: 69px;
    line-height: 78px;
    width: 50%;
}

.CategorySearchResults__results-gallery {
    border-top: 1px solid;
    margin: 0;
    display: inline-block;
    width: 100%;
}

.CategorySearchResults__resultsLink {
    line-height: 69px;
    text-decoration: underline;
    text-align: right;
    display: inline-block;
    width: 50%;
}

.CategorySearchResults__results-channel {
    display: flex;
}
