.no-registration-container {
    text-align: center;
}

h1.no-registration_title {
    color: #666666;
    font-size: 22px;
    padding-bottom: 12px;
}

.no-registration_text {
    padding-bottom: 30px;
    color: #999999;
    max-width: 722px;
    display: inline-block;
}

.no-registration_image {
    padding-bottom: 30px;
}

.no-registration_btn {
    font-size: 16px;
    height: 36px;
}

.onboarding {
    width: 384px;
    height: 150px;
    object-fit: contain;
}
