@-webkit-keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.processing-animation-container {
    position: relative;
}

.processing-animation {
    height: 78px;
    width: 78px;

    /* positions the animation in the center of the div */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.processing-animation .processing-animation__image {
    min-width: 0;
    min-width: initial;
    min-height: 0;
    min-height: initial;
}

.processing-animation__image--gear__big {
    -webkit-animation: spin 8s linear infinite;
            animation: spin 8s linear infinite;
    position: absolute;
    bottom: 0;
}

.processing-animation__image--gear__small {
    animation: spin 8s linear infinite reverse;
    position: absolute;
    right: 0;
    top: 5px;
}
