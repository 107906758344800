.event-videos__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.event-videos__item {
    height: 281px;
    width: 378px;
    border-radius: 4px;
    overflow: hidden;
}
/* system width 1140 */
@media (max-width: 1335px) {
    .event-videos__item {
        width: 358px;
        height: 281px;
    }
}
/* system width 960 */
@media (max-width: 1199px) {
    .event-videos__item {
        width: 309px;
        height: 242px;
    }
}
/* system width 720 */
@media (max-width: 979px) {
    .event-videos__item {
        width: 229px;
        height: 208px;
    }
}
/* system width auto */
@media (max-width: 480px) {
    .event-videos__item {
        width: auto;
        height: 272px;
    }
}
