.webcast-section-title {
    margin-top: 40px;
    margin-bottom: 16px;
    font-size: 32px;
    line-height: 39px;
    font-weight: bold;
}

@media (max-width: 767px) {
    .webcast-section-title {
        margin-top: 24px;
        font-size: 24px;
        line-height: 29px;
    }
}
