.errors__header {
    font-size: 15px;
    margin-bottom: 1rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.73;
    letter-spacing: normal;
    color: #999999;
}
.error-message-status .v2ui-status_info-icon {
    color: #cccccc;
}

.password-error-messages {
    min-width: 80%;
}
.password-error-messages__list-item {
    list-style: none;
    margin-top: 18px;
    text-indent: -26px;
    margin-left: 26px;
}
.error-message__icon {
    vertical-align: middle;
}
.password-error-messages__list {
    margin: 0;
}
.error-message-status {
    margin-right: 10px;
}
.error-message-status .v2ui-status_complete-icon {
    color: #29be86;
}
.error-message-status .v2ui-status_no-icon {
    color: #dc430d;
}
