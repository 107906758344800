.event-list-item-modal {
    position: absolute;
    border-radius: 8px;
    transform: translate(-50%, 0);
    max-width: 583px;
}

.event-list-item-modal.event-list-item-modal {
    width: 995px;
    margin: auto;
}

.event-list-item-modal.modal.fade.in {
    top: 80px;
    border-bottom: 155px solid transparent;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 8px;
}

.event-list-item-modal__container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
    z-index: 2000;
    max-width: 100vw;
    overflow-x: hidden;
}

body.event-list-modal-open {
    overflow: hidden;
    position: relative;
    height: 100%;
}

.eventsplatform .event-list-item-modal .modal-body {
    max-height: 100%;
}

.event-list-item-modal__body {
    max-height: none;
    word-break: break-word;
}

.modal .modal-body.event-list-item-modal__body {
    padding: 0;
}

.event-list-item-modal__close-button.btn.btn-link {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    z-index: 2000;
    padding: 0 !important;
    background-color: rgba(0, 0, 0, 0.4);
}

.event-list-item-modal__close-button.btn.btn-link:focus,
.event-list-item-modal__close-button.btn.btn-link:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

.event-list-item-modal__close-button.btn.btn-link i {
    margin: 0;
    font-size: 24px;
    color: #ffffff;
}

.event-list-item-modal
    .event-list-item-modal__content-wrapper
    button.btn.btn-danger-eventplatform[type="button"]:hover {
    background-color: #c82c3c;
    color: #ffffff;
}
.event-list-item-modal
    .event-list-item-modal__content-wrapper
    button.event-list-item__join-button[type="button"]:hover {
    background-color: #0058c8;
    color: #ffffff;
}
.event-list-item-modal
    .event-list-item-modal__content-wrapper
    button.btn.btn-borderless-eventplatform[type="button"]:hover {
    background-color: #cccccc;
    color: #006efa;
}

.event-list-item-modal__content-wrapper {
    padding: 0;
}

.event-list-item-modal__image {
    width: 100%;
    height: 328px;
    border-radius: 8px;
    border-bottom: 8px solid transparent;
}

.event-list-item-modal__info {
    padding: 14px 32px 32px 32px;
}

.event-list-item-modal__info .event-list-item__action {
    margin-bottom: 16px;
    padding: 0;
    max-height: 30px;
}
.event-list-item-modal__info .event-list-item__action button {
    max-height: 30px;
}

.event-list-item-modal__updated-at {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 900;
}

.event-list-item-modal__name {
    font-size: 20px;
    font-weight: 900;
    margin: 8px 0 16px;
    line-height: normal;
}

.event-list-item-modal__description {
    font-size: 14px;
    line-height: 1.29;
    margin-top: 16px;
}

.event-list-item-modal__presenters {
    margin-bottom: 8px;
}

.event-list-item-modal__tags {
    margin-left: -4px;
}

.event-list-item-modal__tags .tag {
    font-size: 15px;
    line-height: 20px;
    height: 24px;
    color: #333333;
}

@media (min-width: 1200px) and (max-width: 1335px) {
    .event-list-item-modal {
        width: 945px;
    }
}

@media (min-width: 980px) and (max-width: 1199px) {
    .event-list-item-modal {
        width: 797px;
    }

    .event-list-item-modal__info {
        padding: 14px 32px 32px 32px;
    }

    .event-list-item-modal__image {
        height: 328px;
        width: 100%;
    }
}

@media (min-width: 481px) and (max-width: 979px) {
    .event-list-item-modal.modal.fade.in {
        top: 60px;
    }
}

@media (max-width: 979px) {
    .event-list-item-modal.event-list-item-modal.event-list-item-modal {
        width: 100%;
        top: 0;
    }
    .event-list-item-modal.modal.fade.in {
        border-bottom: 59px solid transparent;
    }
}

@media (max-width: 480px) {
    .event-list-item-modal.modal.fade.in {
        top: 0;
        border-bottom: none;
        border-radius: 0;
    }

    .event-list-item-modal__description {
        margin-top: 16px;
    }

    .event-list-item-modal__image {
        max-height: 211px;
        border-radius: 0;
        border-bottom: none;
    }

    .event-list-item-modal__info {
        padding: 16px 16px 0 16px;
    }
}
