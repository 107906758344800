.eventsplatform.virtual-event .expansion-panel-summary__close-icon-label {
    font-size: 14px;
    font-weight: bold;
    margin: 8px;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.eventsplatform.virtual-event .expansion-panel-summary__expand-icon-label {
    font-size: 14px;
    font-weight: bold;
    margin: 8px;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.eventsplatform.virtual-event .expansion-panel-summary__icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: transparent;
    padding: 0;
    margin: 0;
    height: 32px;
}
