.tags-input-container {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-radius: 2px;
    border: solid 1px #d9d9d9;
    cursor: text;
    flex-flow: row wrap;
}

.tags-input-container .tags-input-field {
    margin: 0;
    outline: none;
    border: none;
    box-shadow: none;
    flex: 1;
    flex-wrap: wrap;
    padding: 0;
}

.tags-input__tag-item {
    background-color: #ebebeb;
    color: #666666;
    padding: 0 8px;
    margin-right: 12px;
}

.tags-input__tag-item i {
    position: relative;
    top: 2px;
    margin-left: 4px;
}
