.main-sidebar__menu {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 319px;
    font-size: 16px;
    overflow-y: auto;
}

.main-sidebar__menu :hover {
    background-color: #f4f4f4;
}

.main-sidebar__back-btn {
    display: flex;
    align-items: center;
    height: 56px;
    padding-left: 24px;
}

.main-sidebar__back-btn span {
    display: inline-block;
    font-size: 16px;
    font-weight: 900;
    margin-left: 8px;
}

.main-sidebar__back-btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
}

.main-sidebar__menu-wrapper {
    display: flex;
}

@media (max-width: 479px) {
    .main-sidebar__menu {
        width: 80vw;
    }
}
