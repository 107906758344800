.search-filter-group {
    height: auto;
    transition: max-height 0.3s;
    display: inline-block;
    vertical-align: top;
}

.modal .row-fluid [class*="span"] .search-filter-group {
    display: block;
}

@media (max-width: 767px) {
    .modal .modal-mode.search-filter-group {
        transition: max-height 0.7s;
        height: auto;
        padding: 15px;
        margin-left: 1px;
        border-bottom: solid 2px;
        /**
        Two times the padding + 2px for the border.
         */
        width: calc(100% - 40px);
        line-height: normal;
        padding-top: 0;
        padding-bottom: 0;
    }
}

.filter-modal__accordion-toggle.modal-mode {
    margin-bottom: 20px;
    margin-top: 19px;
    background-color: transparent;
    border: none;
}

.search-filters-group__title--desktop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    margin-bottom: 20px;
    display: block;
}

.search-filters__modal--spacer {
    padding-top: 10px;
}

.modal .search-filters-group__title--desktop {
    display: none;
}

.filter-modal__accordion-toggle {
    width: 100%;
    padding: 0;
}
