.registration__title {
    color: #666666;
    font-size: 18px;
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 2px;
}

.registration-settings__header {
    padding: 0 24px;
}

.registration__subtext {
    margin-top: 8px;
}

.registration-settings-accordion {
    padding-top: 40px;
}

.registration__accordion-toggle {
    height: 68px;
    width: 100%;
    padding: 0 24px;
    background-color: #ffffff;
    border-color: #eaeaea;
    border-style: solid none;
    border-width: 1px 0;
    color: #666666;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.43px;
    line-height: 26px;
}

.registration-form__section {
    background-color: #f1f1f1;
    padding: 40px 24px;
}

.registration__help-text {
    font-size: 12px;
    line-height: 17px;
    color: #666666;
    margin-top: 8px;
}

.registration__help-text.invalid {
    color: #dc430d;
}

.registration__form-element-with-label {
    margin-top: 28px;
    max-width: 816px;
}

.registration__form-element-with-label:first-child {
    margin-top: 0;
}

.registration__control-label {
    color: #666666;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 8px;
}

.registration__actions {
    margin-top: 40px;
    padding: 0 24px;
}

.registration__actions .btn {
    height: 36px;
    font-size: 16px;
}

.registration__btn-preview {
    margin: 0 16px;
}

.registration__btn-enable {
    float: right;
}

.registration-settings-accordion .accordion__container:last-child {
    margin-bottom: 40px;
}

.registration-section-title {
    margin-left: 12px;
}

.registration-settings-accordion .toggleButton__icon {
    font-size: 16px;
    position: relative;
    top: 2px;
}

.registration-section-icon {
    font-size: 16px;
    position: relative;
    top: 2px;
}

.registration-section-icon.registration-section-icon--bold {
    font-weight: bold;
}
