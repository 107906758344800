/**
 * RecentSearch component css
 */

.recentSearch {
    outline: none;
}

.recentSeatch__icon,
.recentSeatch__removed {
    vertical-align: text-top;
    margin-right: 18px;
    margin-left: 18px;
    color: #cccccc;
    font-size: 16px;
}

.recentSearch__remove .btn.btn-link {
    padding: 0;
    font-size: 12px;
    margin-right: 16px;
    text-decoration: none;
    outline: none;
    border: none !important;
    color: #cccccc;
    text-transform: none;
}

.recentSearch__remove .btn.btn-link:active,
.recentSearch__remove .btn.btn-link:hover,
.recentSearch__remove .btn.btn-link:focus {
    border: none !important;
    color: #999999;
}
