.my-media-status-content__popover {
    display: inline-flex;
    flex-direction: column;
    padding: 17px 24px;
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
}

.my-media-status-content__container:not(:first-child) {
    margin-top: 20px;
}

.my-media-status-content__title {
    font-size: 16px;
    font-weight: normal;
}

.my-media-status-content__categories {
    margin-top: 10px;
}

.my-media-status-content__category {
    display: flex;
    align-items: baseline;
}

.my-media-status-content__category-items {
    margin-left: 45px;
}

.my-media-entry__status {
    position: relative;
}

.my-media-entry__status--warning {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #fcd00d;
    margin-left: 12px;
}
