.my-playlist-details {
    display: flex;
}

.my-playlist-details__thumbnail {
    flex: 0 0 425px;
}

.my-playlist-details__description {
    margin: 0 0 0 40px;
    width: 100%;
}

.playlist-details__back-to-channel-button {
    font-size: 1rem;
    font-weight: normal;
    text-decoration: underline;
}

.description-container {
    font-size: 16px;
    overflow: hidden;
}

.description-container .description-container__entry-name {
    font-size: 28px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.description-container__entry-field {
    margin-top: 16px;
}

.description-container__entry-time .dot-separator {
    margin: 0 5px;
}

.description-container .description-container__action-buttons {
    margin-top: 24px;
}

.description-container__action-buttons .btn {
    margin-right: 12px;
}

.description-container__action-buttons .btn i {
    position: relative;
    top: 1px;
}

@media (max-width: 979px) {
    .my-playlist-details__thumbnail {
        display: none;
    }

    .my-playlist-details__description {
        margin: 0;
    }
}

@media (max-width: 767px) {
    .description-container .description-container__entry-name {
        font-size: 22px;
    }

    .description-container .search-results__tag {
        margin-right: 12px;
        line-height: 18px;
    }
}

@media (max-width: 480px) {
    .description-container .description-container__action-buttons .btn-primary {
        width: calc(100% - 30px);
        margin-right: 0;
    }
}
