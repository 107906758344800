.cognito-admin-status-wrapper .alert {
    width: 77%;
    margin-left: auto;
    margin-right: auto;
}

.cognito-admin-status .table:not(.mediaTable) td {
    font-size: 16px;
}

.cognito-admin-status .hero-unit {
    font-weight: normal;
    padding: 40px;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.cognito-admin-status-content {
    background-color: white;
}
