.eventsplatform .search-result__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #4c4c4c;
    height: 500px;
}

@media (max-width: 479px) {
    .eventsplatform .search-result__empty {
        height: 400px;
    }
}
