.webcast-homepage-live-entry-details {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;

    text-align: left;
}

.webcast-homepage-live-entry-buttons {
    margin-top: 16px;
}
