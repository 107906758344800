.row-fluid .request-summary-pie-chart-with-legend,
.row-fluid .wrapper-request-summary-with-status {
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    min-height: 233px;
}

@media (max-width: 991px) {
    .row-fluid .request-summary-pie-chart-with-legend,
    .row-fluid .wrapper-request-summary-with-status {
        width: 100%;
        padding: 0 30px;
        margin: 0;
        margin-bottom: 30px;
    }
}
