/**
 * OrderFormDropDown component css
 */

.order-form__drop-down {
    margin-bottom: 20px;
}

.order-form__drop-down--label {
    margin-bottom: 8px;
    display: block;
}

.order-form__drop-down--label--inline {
    padding-right: 16px;
    line-height: 36px;
}
