.search-results__tags-container {
    padding-left: 8px;

    box-sizing: content-box;
    border-collapse: separate;
    line-height: normal;
}

.search-results__tag {
    margin-right: 16px;
    margin-top: 3px;
}
