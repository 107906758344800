/**
 *  EntryThumbnail component css
 */

.entryThumbnail {
    display: block;
    position: relative;
    text-decoration: none;
}

.entryThumbnail__type-overlay {
    position: absolute;
    top: 12px;
    left: 12px;
}
.entryThumbnail__icon {
    font-size: 16px;
}

.entryThumbnail__textWrapper {
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.entryThumbnail__text {
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    color: white;
    background-color: red;
    text-transform: uppercase;
    font-size: 12px;
}

.entryThumbnail__img {
    width: 100%;
    overflow: hidden;
}

.entryThumbnail__duration {
    font-size: 12px;
    line-height: normal;
    left: 12px;
    bottom: 15px;
    position: absolute;
}

.entryThumbnail--status .entryThumbnail__duration {
    bottom: 22px;
}

.entryThumbnail__status {
    line-height: 18px;
}

@media (max-width: 767px) {
    .entryThumbnail__icon {
        font-size: 12px;
        top: 8px;
        left: 8px;
    }

    .entryThumbnail__duration {
        font-size: 10px;
        left: 8px;
        bottom: 11px;
    }

    .entryThumbnail--status .entryThumbnail__duration {
        bottom: 22px;
    }
}

.entryThumbnail--error::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 56.25%;
}

.entryThumbnail--error::after {
    content: "";
    display: table;
    clear: both;
}

.entryThumbnail--error .entryThumbnail__img {
    display: none;
}

.entryThumbnail__overlay-wrapper {
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 53%,
        rgba(0, 0, 0, 0.15) 73%,
        rgba(0, 0, 0, 0.79) 93%,
        #000000
    );
    position: absolute;
    top: 0;
    right: 0 !important;
    left: 0 !important;
    height: 100%;
}
