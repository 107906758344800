.playlist-entry-expandable-item {
    margin: 8px 0;
}

.playlist-entry-expandable-item .summary-field {
    align-items: center;
    margin: 17px 0;
}

.playlist-entry-expandable-item .playlist-entry-expandable-item__details {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 59px 32px;
}

.playlist-entry-expandable-item__details-item {
    margin-bottom: 24px;
    max-width: 800px;
}

.playlist-entry-expandable-item__statistics {
    margin-top: 0;
}
