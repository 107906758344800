/* CollectionSearchFormWrapper css */

.collectionSearchFormFilterBar {
    width: 100%;
}

.event-channel-collection__filters {
    display: flex;
}

.event-channel-collection__sorter .dropdown-eventplatform__menu {
    min-width: 100px;
    right: 0;
}

.event-channel-collection-search {
    height: 30px;
    min-width: 300px;
    max-width: 300px;
    margin-right: 16px;
}

.event-channel-collection__sorter {
    min-width: 160px;
}
