@font-face {
    font-family: "kmsreact";
    src: url("fonts/kmsreact.eot?w436h1");
    src: url("fonts/kmsreact.eot?w436h1#iefix") format("embedded-opentype"),
        url("fonts/kmsreact.woff2?w436h1") format("woff2"),
        url("fonts/kmsreact.ttf?w436h1") format("truetype"),
        url("fonts/kmsreact.woff?w436h1") format("woff"),
        url("fonts/kmsreact.svg?w436h1#kmsreact") format("svg");
    font-weight: normal;
    font-style: normal;
}

[class^="kmsr-"],
[class*=" kmsr-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "kmsreact" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.kmsr-details:before {
    content: "\e900";
}
.kmsr-results:before {
    content: "\e901";
}
.kmsr-send:before {
    content: "\e902";
}
.kmsr-calender:before {
    content: "\e903";
}
.kmsr-search:before {
    content: "\e904";
}
.kmsr-vi:before {
    content: "\e905";
}
.kmsr-close:before {
    content: "\e906";
}
.kmsr-add:before {
    content: "\e907";
}
.kmsr-download:before {
    content: "\e908";
}
.kmsr-edit:before {
    content: "\e909";
}
.kmsr-delete:before {
    content: "\e90a";
}
.kmsr-arrow-down:before {
    content: "\e90b";
}
.kmsr-arrow-left:before {
    content: "\e90c";
}
.kmsr-arrow-right:before {
    content: "\e90d";
}
.kmsr-arrow-up:before {
    content: "\e90e";
}
.kmsr-check-active:before {
    content: "\e90f";
}
.kmsr-check-inactive:before {
    content: "\e910";
}
