.text-only__wrapper {
    display: flex;
    justify-content: center;
}

.text-only {
    display: inline-block;
    font-size: 24px;
    line-height: 1.42;
    text-align: center;
}

.text-only a {
    font-size: 24px;
}
