/**
 * Registration Page Presenters Component css
 */
.registrationPage-presenters {
    margin-top: 36px;
}

.registrationPage-presenters__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    color: #999999;
}

.registrationPage-presenters-hosts {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.registrationPage-presenters-hosts__presenter {
    margin-top: 32px;
    text-align: center;
    width: 30%;
}

.registrationPage-presenters-hosts__presenter--0 {
    width: 25%;
}

@media (max-width: 1024px) {
    .registrationPage-presenters-hosts__presenter,
    .registrationPage-presenters-hosts__presenter--0 {
        width: 50%;
    }
}

@media (max-width: 480px) {
    .registrationPage-presenters-hosts__presenter,
    .registrationPage-presenters-hosts__presenter--0 {
        width: 100%;
    }
}

.registrationPage-presenters-hosts__presenter-name {
    font-size: 16px;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.registrationPage-presenters-hosts__presenter-title {
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.registrationPage-presenters-hosts__presenter-image,
.registrationPage-presenters-hosts__presenter-image.center {
    height: 64px;
    width: 64px;
}

.registrationPage-presenters__more {
    text-align: center;
    font-size: 15px;
    margin-top: 24px;
}
