/**
 *  kaltura player (v2 + v7) css
 */

.kaltura-player {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}
