.page-components-item__popover-body {
    background-color: #333333;
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 4px;
    max-width: 250px;
}

.registration-help-icon__button {
    padding: 0;
    margin-left: 6px;
    line-height: 1;
}
