.language-menu {
    margin: 0;
}
.language-menu .react-dropdown-toggle__button {
    padding: 0;
}
.react-dropdown .btn.dropdown-toggle.language-menu--button,
.react-dropdown .btn.dropdown-toggle.language-menu--button:active,
.react-dropdown .btn.dropdown-toggle.language-menu--button:focus {
    padding-left: 0;
    padding-right: 8px;
    border-color: transparent !important;
    background-color: transparent;
    box-shadow: none;
    font-weight: normal;
}
.language-menu--arrow {
    width: 20px;
    vertical-align: middle;
    border-left: none;
}
.navbar .language-menu--arrow i {
    font-size: 16px;
}
.language-menu .dropdown-menu {
    right: 0;
    left: auto;
    top: 42px;
}
.language-menu .dropdown-menu .language-menu--item:focus,
.language-menu .dropdown-menu .language-menu--item:hover {
    border-top: solid 1px #f4f4f4;
    border-bottom: solid 1px #f4f4f4;
    font-weight: normal;
}
.language-menu .dropdown-menu .language-menu--item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.language-menu
    .dropdown-menu
    .language-menu--item
    .language-menu--item--check-icon {
    margin-right: 0;
    margin-left: 8px;
}
