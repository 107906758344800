/* modal selecyion Field css */

.row-fluid .span8.form-field-modalselection {
    margin-left: 0;
}

.siteRegistration-modalselection_values {
    margin-top: 16px;
}

.siteRegistration-modalselection_value {
    display: inline-block;
}

.siteRegistration-modal h4 {
    margin-top: 8px;
}

.siteRegistration-text {
    margin: 40px 0 -20px 0;
}

.siteRegistration-modal.bootbox.fade {
    transform: translate(0, 0) scale(0.9);
}
.siteRegistration-modal.bootbox.fade.in {
    transform: translate(0, 0) scale(0.9);
}
.btn.siteRegistration-modalselection__button:focus {
    background-color: transparent;
}

.siteRegistration-modal--close {
    text-align: right;
}
button.btn.siteRegistration-modal--close-button {
    font-size: 24px;
    padding: 0;
}
