/**
 * css for the Header Search Form
 */

.headerSearchForm__wrapper {
    background-color: white;
    border-radius: 4px;
    text-align: left;
    margin-top: 2px;
    padding: 7px 8px 1px 8px;
}

.headerSearchForm__wrapper .searchForm {
    border-bottom: none;
}

.eSearch__link .btn-group {
    margin-top: 0;
}
.eSearch__link .btn-group .accordionToggleWrapper {
    margin-top: 5px;
}

.headerSearchForm__searchForm {
    margin-bottom: 0;
}

.headerSearchForm__searchForm .searchForm__prepend {
    margin: 0;
    padding-bottom: 5px;
}

.headerSearchForm__searchForm .searchSuggestions {
    top: -5px;
}
