.event-channel__sections {
    margin-top: 20px;
}
.event-channel__sections__nav {
    margin-bottom: 12px;
    display: flex;
}
.event-channel__section-content {
    overflow: inherit;
    margin-top: 24px;
}
.channel-sections__section {
    white-space: nowrap;
}

#event-channel__section-content.event-channel__section-content.tab-content {
    overflow: visible !important;
}
