.contactus-section {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    text-align: center;
    padding: 24px;
}

.faq-section__line-separator {
    color: #cccccc;
    background-color: #cccccc;
    height: 1px;
    border: none;
    margin: 0;
}

.contactus-section__title {
    width: 100%;
    color: #333333;
}

.contactus-section__additional-info {
    width: 100%;
}

.contactus-section__browser-not-supported {
    margin-top: 20px;
    margin-bottom: 20px;
}
