.search-input {
    display: flex;
    align-items: center;
    border: solid 1px #cccccc;
    border-radius: 4px;
}

.search-input:hover {
    border: solid 1px #888888;
}

.search-input--focused,
.search-input--focused:hover {
    border: solid 2px #006efa;
}

.search-input--disabled {
    background-color: white;
}

.search-input__input {
    border: none !important;
    outline: 0 !important;
    box-shadow: none !important;
    margin-bottom: 0 !important;
    color: #333333 !important;
    width: 100%;
    /* For IE11 */
    flex-basis: 1px;
    flex-grow: 1;
    /* For Firefox */
    overflow: hidden;
}

.search-input__input::-moz-placeholder {
    color: #888888 !important;
}

.search-input__input:-ms-input-placeholder {
    color: #888888 !important;
}

.search-input__input::placeholder {
    color: #888888 !important;
}

.search-input__input:disabled::-moz-placeholder {
    color: #cccccc !important;
    background-color: transparent;
}

.search-input__input:disabled:-ms-input-placeholder {
    color: #cccccc !important;
    background-color: transparent;
}

.search-input__input:disabled,
.search-input__input:disabled::placeholder {
    color: #cccccc !important;
    background-color: transparent;
}

.search-input__icon {
    color: #999999;
    font-size: 24px;
    padding: 3px 8px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 7px;
    padding-right: 2px;
}

.search-input__icon-small {
    color: #999999;
    font-size: 20px;
}

.search-input--disabled .search-input__icon {
    color: #cccccc !important;
}

button.search-input__button {
    padding-top: 2px;
}
