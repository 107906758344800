/**
 *  Registration Page Scheduling css 
 */
.registrationPage-scheduling__data + .registrationPage-scheduling-timer {
    margin-top: 30px;
}

.registrationPage-scheduling__data__time {
    margin-top: 12px;
}
