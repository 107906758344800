/* ==================== loading animation ========================== */
@-webkit-keyframes kaltura-spinner {
    0% {
        transform: rotate(0deg) scale(0.7);
        opacity: 1;
    }
    70% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
    82% {
        transform: rotate(360deg) scale(0);
        opacity: 0;
    }
    87% {
        transform: rotate(360deg) scale(0.9);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
}
@-moz-keyframes kaltura-spinner {
    0% {
        transform: rotate(0deg) scale(0.7);
        opacity: 1;
    }
    70% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
    82% {
        transform: rotate(360deg) scale(0);
        opacity: 0;
    }
    87% {
        transform: rotate(360deg) scale(0.9);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
}
@-o-keyframes kaltura-spinner {
    0% {
        transform: rotate(0deg) scale(0.7);
        opacity: 1;
    }
    70% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
    82% {
        transform: rotate(360deg) scale(0);
        opacity: 0;
    }
    87% {
        transform: rotate(360deg) scale(0.9);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
}
@keyframes kaltura-spinner {
    0% {
        transform: rotate(0deg) scale(0.7);
        opacity: 1;
    }
    70% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
    82% {
        transform: rotate(360deg) scale(0);
        opacity: 0;
    }
    87% {
        transform: rotate(360deg) scale(0.9);
        opacity: 1;
    }
    100% {
        transform: rotate(360deg) scale(0.7);
        opacity: 1;
    }
}

.loading-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.3);
}
.spinner-container {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50px, -50px, 0);
}

.spinner {
    width: 100px;
    height: 100px;
    position: relative;
    animation: kaltura-spinner 2.5s infinite;
}
.spinner span.spinner__part {
    width: 8px; /* $spinner-circle-radius *2; */
    height: 8px; /* $spinner-circle-radius *2; */
    background-color: #fff;
    display: block;
    border-radius: 8px; /* $spinner-circle-radius *2; */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -4px; /* -$spinner-circle-radius; */
    margin-left: -4px; /* -$spinner-circle-radius; */
}

.spinner span:nth-child(1) {
    transform: rotate(45deg) translateX(-25px) translateY(-25px);
    background-color: rgb(218, 31, 38);
}
.spinner span:nth-child(2) {
    transform: rotate(90deg) translateX(-25px) translateY(-25px);
    background-color: rgb(6, 168, 133);
}
.spinner span:nth-child(3) {
    transform: rotate(135deg) translateX(-25px) translateY(-25px);
    background-color: rgb(0, 147, 68);
}
.spinner span:nth-child(4) {
    transform: rotate(180deg) translateX(-25px) translateY(-25px);
    background-color: rgb(248, 166, 26);
}
.spinner span:nth-child(5) {
    transform: rotate(225deg) translateX(-25px) translateY(-25px);
    background-color: rgb(27, 74, 151);
}
.spinner span:nth-child(6) {
    transform: rotate(270deg) translateX(-25px) translateY(-25px);
    background-color: rgb(218, 31, 38);
}
.spinner span:nth-child(7) {
    transform: rotate(315deg) translateX(-25px) translateY(-25px);
    background-color: rgb(177, 210, 56);
}
.spinner span:nth-child(8) {
    transform: rotate(360deg) translateX(-25px) translateY(-25px);
    background-color: rgb(252, 210, 3);
}
