/**
 *  Registration  component css
 */

.registration--fullPage {
    position: absolute;
    width: 100%;
    left: 0;
    background-color: white;
    z-index: 2000;
}

@media (min-width: 768px) {
    .registration-modal {
        margin-top: -22px;
    }
}

.registration-modal.modal {
    position: absolute;
    margin-top: 20px;
    margin-left: 0;
    left: 50%;
    transform: translateX(-50%);
    border: none;
}

@media (min-width: 1024px) {
    .registration-modal.modal {
        width: 840px;
    }
}

@media (min-width: 1200px) {
    .registration-modal.modal {
        width: 970px;
    }
}

@media (min-width: 1336px) {
    .registration-modal.modal {
        width: 1000px;
    }
}

@media (min-width: 1600px) {
    .registration-modal.modal {
        width: 1200px;
    }
}

.registration .registration-modal.modal .registration-modal__body.modal-body,
.registration .registration-modal .registration-modal__body.modal-body {
    padding: 0;
    max-height: none;
}
