.editable {
    position: relative;
    display: inline-block;
}

.editable .edit-icon {
    font-size: 14px;
    margin-left: 16px;
    max-height: 0;
    position: relative;
    top: -3px;
}

.editable-label {
    padding: 5px 0;
}

.edit-box {
    margin-left: 0;
}

.edit-actions {
    position: absolute;
    top: 12%;
    right: 2px;
}

input.edit-box__input {
    width: inherit;
    height: inherit;
    font-weight: bold;
    padding-left: 32px;
    box-sizing: border-box;
    margin-bottom: 0;
    padding-right: 56px;
}

.edit-box__form {
    margin: 0;
}
