/**
 * css for the entry metadata Entry Id component
 */

.metadata_entryId__item {
    margin-right: 5px;
    padding-right: 5px;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    border-right: 1px solid #666666;
    cursor: pointer;
}

.metadata_entryId__item:last-child {
    border-right: none;
}
