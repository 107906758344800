.user-avatar {
    display: inline-block;
    line-height: 24px;
    text-align: center;
    width: 24px;
}

.user-avatar--initials {
    border: 1px solid black;
    background-color: #008297;
    color: white;
    border-radius: 50%;
    font-size: 11px;
    height: 24px;
    text-transform: uppercase;
}
