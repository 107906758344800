.playlist__side-bar {
    width: calc(50% - 25px);
    display: inline-block;
    padding-right: 25px;
}

.playlist-icon {
    font-size: 20px;
    color: #008297;
    top: 2px;
    cursor: pointer;
    position: relative;
}
.sidebar__playlist-search-results {
    max-height: 800px;
    overflow: auto;
}

.playlist__side-bar-title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: 0.57px;
    color: #008297;
}
.playlist-entry-container__entry-item {
    border-bottom: solid 1px #eaeaea;
    padding: 17px 24px 17px 0;
}

.sidebar__playlist-no-results-message {
    max-width: calc(100% - 12px);
}

.playlist-side-bar-title__container {
    display: flex;
    justify-content: space-between;
}
.playlist__search-container {
    padding-bottom: 10px;
    max-height: 33px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
}
.playlist-context-search-form {
    display: inline-block;
    width: 50%;
    max-width: 240px;
}

.sidebar__playlist-loading {
    font-size: 50px;
    overflow: hidden;
    margin-top: 122px;
}
.sidebar__playlist-loading__spinner {
    margin-left: 45%;
}
.sidebar__playlist-loading__spinner.v2ui-Spinner-icon .path2::before {
    color: #008297;
    opacity: 0.9;
}
.playlist-entry-item__icon--right--sidebar {
    margin-top: -28px;
}
.playlist-entry-item__icon__add-remove-button {
    padding: 2px 4px;
}
