.dept-filter__unit {
    margin-right: 20px;
    height: 35px;
    display: table-cell;
    vertical-align: middle;
}
.dept-filter__control {
    margin-left: 20px;
}

.dashboard-profiles {
    display: table;
    margin-bottom: 12px;
}

.dashboard-wrap {
    padding: 32px 32px 16px 32px;
    background-color: #f1f1f1;
}

.requests-wrap {
    padding-top: 32px;
    margin-bottom: 16px;
}

.dashboard-row:before,
.dashboard-row:after {
    display: table;
    line-height: 0;
    content: "";
}

.dashboard-row:after {
    clear: both;
}

.dashboard-row:before,
.dashboard-row:after {
    display: table;
    line-height: 0;
    content: "";
}
.dashboard-show-details {
    margin-top: 16px;
    color: #00b4d1;
}

.dashboard-requests-title {
    padding-left: 32px;
    padding-top: 5px;
    float: left;
    color: #4a4a4a;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.38px;
    line-height: 24px;
    text-transform: uppercase;
}

.dashboard-container {
    margin-right: auto;
    margin-left: auto;
    width: 940px;
}

.dashboard-container:before,
.dashboard-container:after {
    display: table;
    line-height: 0;
    content: "";
}
.dashboard-filter-container {
    padding-top: 32px;
}
.dashboard-container:after {
    clear: both;
}

@media (min-width: 1200px) {
    .dashboard-container {
        width: 1170px;
    }
}
@media (max-width: 768px) {
    .dashboard-container {
        width: 724px;
    }
}

.requests-table-cell.channel-rules-link.button--transparent {
    line-height: normal;
    margin-top: 0;
}
