.bar-chart {
    position: relative;
    margin: 0;
    width: 100%;
    float: left;
}
.bar-chart-progress:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}
.bar-chart-progress:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
.bar-chart-progress {
    float: left;
    width: 0;
    height: 20px;
    font-size: 12px;
    line-height: 20px;
    color: black;
    text-align: center;
    background-color: #d8d8d8;
    transition: width 0.6s ease;
    position: relative;
}
.bar-chart-progress__label-item {
    position: absolute;
    right: 0;
    display: none;
}
.bar-chart-progress-with-labels {
    margin-bottom: 20px;
    margin-top: 20px;
}

.bar-chart-progress-with-labels.top:first-child:before {
    bottom: 100%;
}
.bar-chart-progress-with-labels.bottom:first-child:before {
    top: 100%;
}
.bar-chart-progress-with-labels .bar-chart-progress__label-item {
    display: block;
}
.bar-chart-progress-with-labels.top .bar-chart-progress__label-item {
    bottom: 100%;
}
.bar-chart-progress-with-labels.bottom .bar-chart-progress__label-item {
    top: 100%;
}
.bar-chart-unlimited {
    background-image: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 40px 40px;
}
