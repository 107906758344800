.filter-rangepicker__label {
    margin-left: 11px;
    margin-bottom: 1px;
    top: -3px;
    cursor: pointer;
    float: left;
    position: relative;
}

.filter-rangepicker {
    width: 90%;
    display: table;
    height: 100px;
}

.filter-rangepicker .form .input-range {
    width: 90%;
    margin-left: auto;
    top: -5px;
}

.input-range__track.input-range__track--background .input-range__track {
    height: 2px;
    margin-bottom: 2px;
}

.input-range__track.input-range__track--background {
    height: 2px;
}

.rangepicker-checkbox {
    margin-bottom: 45px;
}

.filter-checkbox__icon {
    cursor: pointer;
    float: left;
    text-decoration: none;
}

.filter-rangepicker.selected .form .input-range__slider {
    border: 0px;
}

div.input-range__slider {
    border-radius: 100%;
    border: 0px !important;
    display: block;
    height: 9px;
    margin-left: -0.52rem;
    margin-top: -0.45rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: -webkit-transform 0.3s ease-out,
        -webkit-box-shadow 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out,
        -webkit-box-shadow 0.3s ease-out;
    -o-transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
        -webkit-transform 0.3s ease-out, -webkit-box-shadow 0.3s ease-out;
    width: 9px;
}

.input-range__slider-container
    .input-range__label
    .input-range__label-container {
    padding: 3px;
    border-radius: 3px;
    bottom: 7px;
    font-size: 10px;
}

.input-range__slider-container .input-range__label--value {
    top: 46px;
}

/* LEFT tooltip */
.input-range__slider-container:first-of-type
    .input-range__label
    .input-range__label-container {
    margin-right: 20px;
}
.input-range__slider-container:first-of-type
    .input-range__label
    .input-range__label-container:after {
    left: 85%;
}
/* RIGHT tooltip */
.input-range__slider-container:last-of-type .input-range__label {
    margin-left: 4px;
}
.input-range__slider-container:last-of-type
    .input-range__label
    .input-range__label-container:after {
    left: 20%;
}
.filter-rangepicker .form {
}
.filter-rangepicker.selected .form {
    display: table;
    width: 85%;
    margin-left: 10px;
}
.filter-rangepicker .form {
    display: none;
}

.filter-rangepicker.selected:hover .input-range__label-container {
    opacity: 1;
}
.input-range__label-container {
    opacity: 0;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    top: -40px;
}

.form .input-range__label--min,
.form .input-range__label--max {
    bottom: -1.5rem;
}
