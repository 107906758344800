.no-results__container {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    outline: 0;
}

.no-results__no-results-image {
    text-align: center;
    background-color: white;
    border-radius: 100%;
    min-width: 418px;
    padding: 50px;
    margin-top: -50px;
}

.no-results__image {
    margin-bottom: 24px;
}

.no-results__title {
    font-size: 24px;
    line-height: 27px;
    margin: 16px 0;
}

.no-results__description {
    font-size: 16px;
    line-height: 24px;
}

.no-results__body {
    margin-top: 24px;
}

@media (max-width: 768px) {
    .no-results__container {
        margin-top: 47px;
    }
}

@media (max-width: 667px) {
    .no-results__container {
        margin-top: 31px;
    }
    .no-results__image {
        width: 80%;
        max-width: 319px;
    }
    .no-results__title {
        font-size: 18px;
    }
    .no-results__description {
        font-size: 13px;
    }
}
