.addMeetingInvite span.react-dropdown-toggle__arrow {
    border-left: none;
}
.addMeetingInvite,
.addMeetingInvite button,
.addMeetingInvite button span {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.webcastMeetingsDropdown,
.webcastDnl-link {
    font-size: 14px;
    padding-bottom: 12px;
    display: inline-block;
}

.webcastDnl-link {
    white-space: nowrap;
}

.webcastEditInfo-launchWebcast {
    padding-top: 12px;
    padding-bottom: 12px;
}

.webcastEditInfo-launchWebcast button {
    height: 36px;
    font-size: 16px;
    white-space: nowrap;
}

.webcastMeetingsDropdown-link {
    padding: 0 !important;
}

.webcastMeetingsDropdown-link .react-dropdown-toggle__arrow {
    width: 20px;
}
