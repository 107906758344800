input[type="text"].registration_entry {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    margin-bottom: 0;
    width: 100%;
    height: 36px;
}

input[type="text"].registration_entry.invalid {
    border: 1px solid #dc430d;
}
