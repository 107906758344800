/**
 *  Groups table css
 */

.no-groups {
    padding-top: 24px;
    margin-bottom: 20px;
    border-top: 1px solid #eaeaea;
}

.no-groups-image {
    margin-top: 56px;
}

.no-groups-text {
    margin-top: 32px;
    font-size: 22px;
}

.no-groups-subtext {
    margin-top: 16px;
    font-size: 16px;
}

.no-groups-add-new-button {
    margin-top: 24px;
}

.group-action__link {
    padding-left: 7px;
    padding-right: 7px;
}
.group-action__link:hover {
    text-decoration: none;
}

.groups-management-table--processing {
    color: #008297;
}
