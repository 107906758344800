.textarea {
    resize: none;
    width: 100%;
    height: 100%;
    border: 1px solid #cccccc;
    padding: 10px 15px;
}
.textarea__char-count {
    float: right;
    margin-top: -10px;
}
