.hero-unit-component {
    display: block;
    position: relative;
    width: 100%;
    height: 620px;
    color: #fff !important;
}

@media (max-width: 1199px) {
    .hero-unit-component {
        height: 580px;
    }
}

@media (max-width: 979px) {
    .hero-unit-component {
        height: 480px;
    }
}

.hero-unit_title-wrap {
    position: absolute;
    left: 0;
    right: 0;
    top: 88px;
}

.eventsplatform .hero-unit_title,
.hero-unit_title {
    color: #ffffff;
}
@media (max-width: 480px) {
    .eventsplatform .hero-unit_title,
    .hero-unit_title {
        font-size: 32px;
    }
}

.hero-unit-component__backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, transparent 0%, #000);
}

.hero-unit-component__content {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
}

.btn.btn-borderless-eventplatform.hero-unit-component__content__live-label {
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.2);
    color: inherit;
    border-radius: 4px;
    padding: 3px 6px;
    font-size: 14px;
    line-height: 17px;
    font-weight: bold;
    letter-spacing: 1px;
}

.hero-unit-component__content__title,
.hero-unit-component__content__description,
.hero-unit-component__content__presenters {
    max-width: 600px;
}

.hero-unit-component__content__title.hero-unit-component__content__title.hero-unit-component__content__title {
    line-height: normal;
    margin-top: 19px;
    color: #fff !important;
}

.hero-unit-component__content__description {
    margin-top: 16px;
    font-size: 16px;
}

.hero-unit-component__content__presenters {
    margin-top: 16px;
}

.hero-unit-component__content__presenters .event-presenter__name {
    color: #fff;
}

.hero-unit-component__content__presenters .event-presenter__title {
    color: #ccc;
}

button.btn.hero-unit-component__content__join-button,
button.btn.hero-unit-component__content__toggle-play-button {
    margin-top: 24px;
}

button.btn.hero-unit-component__content__join-button {
    min-width: 209px;
    font-size: 16px;
}

.eventsplatform
    button.btn.hero-unit-component__content__join-button[type="button"]:hover {
    color: #ffffff;
}

button.btn.hero-unit-component__content__toggle-play-button {
    background: none !important;
    border: none !important;
    float: right;
    color: #fff;
    font-size: 14px;
}

.hero-unit-component__lines {
    position: absolute;
    bottom: 16px;
    left: 0;
    right: 0;
    min-width: 220px;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* Duplicate the class to override the global re-invent styles */
button.hero-unit-component__lines__line.hero-unit-component__lines__line.hero-unit-component__lines__line.hero-unit-component__lines__line {
    position: relative;
    width: 100%;
    border-radius: 2px;
    margin: 0;
    padding: 4px 5px;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.hero-unit-component__lines__line__inner {
    position: relative;
    height: 4px;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.3);
}

.hero-unit-component__lines__line:hover
    .hero-unit-component__lines__line__inner,
.hero-unit-component__lines__line:active
    .hero-unit-component__lines__line__inner,
.hero-unit-component__lines__line:focus
    .hero-unit-component__lines__line__inner {
    background: rgba(255, 255, 255, 0.5);
}

.hero-unit-component__lines__line__progress {
    height: 100%;
    border-radius: inherit;
    background: #fff;
    transition: width 500ms linear;
}

@media (max-width: 979px) {
    .hero-unit-component__content__title.hero-unit-component__content__title.hero-unit-component__content__title {
        font-size: 32px;
    }
}

@media (max-width: 480px) {
    button.btn.hero-unit-component__content__join-button {
        min-width: 171px;
    }
}
