.draggable-playlist-entry-container {
    display: flex;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    margin: 8px 0 8px 1px;
}
.draggable-playlist-entry-container__entry-item {
    padding: 17px 24px 17px 8px;
}

.draggable-playlist-entry-container.dragging {
    background-color: #f1f1f1;
}

.draggable-playlist-entry-container--handle {
    font-size: 24px;
}
