.event-presenters-list {
    display: flex;
}
.event-presenters-list__popup {
    background-color: white;
    width: 260px;
    padding: 16px 16px 8px;
    border-radius: 4px;
}
.event-presenters-list__popup .in-popover {
    max-width: initial;
    padding: 0 0 8px;
}
