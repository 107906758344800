/**
 *  BrodcastDetails component css
 */

.broadcastDetailsTable {
    margin-top: 24px;
    margin-bottom: 48px;
}

.table.broadcastDetailsTable tr td {
    font-size: 14px;
}

.broadcastDetails-copy-text__input[readonly] {
    background-color: transparent;
    border: none;
    line-height: normal;
    vertical-align: baseline;
    vertical-align: initial;
    color: #666666;
    width: 100%;
}

.broadcastDetails-button.btn {
    padding: 0;
    text-decoration: underline;
}

.broadcastDetails-tooltip-button.btn.btn-link,
.broadcastDetails-tooltip-button {
    padding: 0;
    color: #666666;
}

.broadcastDetails-tooltip-button.btn.btn-link:hover,
.broadcastDetails-tooltip-button:hover {
    text-decoration: none;
    color: #000000;
}

.broadcastDetails-icon {
    font-size: 16px;
    padding-right: 5px;
}

.broadcastDetails-copy-icon {
    font-size: 18px;
    position: relative;
    top: 3px;
}
.broadcastDetails__description {
    font-size: 14px;
    color: #999999;
    margin-top: 3px;
}
