/**
 * event playform cognito login css
 */

.event-platform-cognito-login-wrapper {
    width: 100%;
}

.event-platform-cognito-videofile {
    display: block;
    position: absolute;
    right: 0;
    width: 64%;
}

.event-platform-cognito-videofile video {
    height: auto;
    width: 100%;
}

.event-platform-cognito-login-box-wrapper {
    padding-top: 153px;
    position: relative;
}

.event-platform-cognito-login--logo {
    text-align: center;
    margin-bottom: 24px;
}
.event-platform-cognito-login--title {
    text-align: center;
    font-weight: 300;
    font-size: 24px;
}

.event-platform-cognito-login-form-wrapper {
    margin: 0 auto;
    max-width: 480px;
    padding: 48px 80px 80px 80px;
    background-color: #f4f4f4;
    border: 1px solid #e3e3e3;
}

.event-platform-cognito-login--link-wrapper {
    text-align: center;
}

a.event-platform-cognito-login--link {
    font-weight: bold;
    font-size: 14px;
    margin-top: 4px;
    padding-right: 14px;
    padding-left: 14px;
}

a.event-platform-cognito-login--link-signup {
    border-right-style: solid;
    border-right-width: 1px;
}

.event-platform-cognito-login--top-text {
    text-align: center;
    margin: 16px 0 32px;
    font-size: 14px;
}

/* phone variant */
@media (max-width: 480px) {
    .event-platform-cognito-videofile {
        display: none;
    }

    .event-platform-cognito-login-form-wrapper {
        padding: 100px 16px 0 16px;
        border: none;
    }

    .event-platform-cognito-login-box-wrapper {
        padding-top: 0;
    }

    .event-platform-cognito-login--title {
        margin-bottom: 40px;
    }
}
