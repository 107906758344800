.dropdown-indicator__icon,
.dropdown-option__icon {
    font-size: 16px;
}

.dropdown-eventplatform__menu .dropdown-option__text {
    white-space: nowrap;
}

.dropdown-eventplatform__menu.dropdown-menu--auto-width {
    width: auto !important;
    min-width: 220px;
}

.dropdown-menu--multiline-items .dropdown-option__text {
    white-space: normal;
}

/* Align the drop-down item text to the left when the item has a checkbox */
.dropdown-menu--multiline-items
    .dropdown-option--with-checkbox
    .dropdown-option__text {
    position: relative;
    padding-left: 30px;
}
.dropdown-menu--multiline-items .checkbox-custom-wrapper {
    position: absolute;
    left: 0;
}

/* react-select dropdown styles, for multiple select in eventsplatform */
.value-container__custom-placeholder
    + .dropdown-eventplatform__indicators
    .dropdown-eventplatform__clear-indicator {
    display: none;
}

/*!* react-select dropdown styles, for multiple select in eventsplatform *!*/
.value-container__custom-placeholder .dropdown-eventplatform__multi-value {
    display: none;
}

/*!* react-select dropdown styles, for multiple select in eventsplatform *!*/
.value-container__custom-placeholder .dropdown-eventplatform__single-value {
    display: none;
}

/*!* react-select dropdown styles, for multiple select in eventsplatform *!*/
.value-container__custom-placeholder .dropdown-eventplatform__placeholder {
    display: none;
}

.eventplatform-checkbox {
    width: 18px;
    height: 18px;
    margin-top: 2px;
    text-align: center;
    display: inline-block;
}

.eventplatform-checkbox--checked {
    border: none;
}

/* hover/focus state */
.dropdown-option.dropdown-eventplatform__option:hover,
.dropdown-option.dropdown-eventplatform__option:focus {
    background-color: #f4f4f4;
}
