/**
 *  css for the TextMulti entry metadata component
 */

.metadata__item__textMulti {
    line-height: 18px;
    margin-bottom: 18px;
    padding-left: 12px;
    border-left: 2px solid #cccccc;
}

.metadata__item__textMulti:last-child {
    margin-bottom: 0;
}

.textMulti__more {
    min-width: 70px;
    text-align: right;
    font-size: 12px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .textMulti__more {
        vertical-align: top;
        padding-top: 20px;
    }
}
