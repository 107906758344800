.table.reach-table {
    border: none;
    font-size: 14px;
    padding-bottom: 12px;
}

/* hide last columns border */
.table.reach-table .ReactTable.-column-separators .rt-thead .rt-th:first-child,
.table.reach-table
    .ReactTable.-column-separators
    .rt-thead
    .rt-th:nth-last-child(2) {
    border-right: none;
}
/* entry table - show first, hide last columns border */
.table.reach-table.reach-table-entry-requests
    .ReactTable.-column-separators
    .rt-thead
    .rt-th:first-child {
    border-right: 1px solid #eaeaea;
}
.table.reach-table.reach-table-entry-requests
    .ReactTable.-column-separators
    .rt-thead
    .rt-th:nth-last-child(3) {
    border-right: none;
}

.ReactTable.-striped .rt-tr.-even + .task-details {
    background: #ffffff;
}

.requests-table-cell {
    text-transform: capitalize;
    line-height: normal;
    margin-left: 5px;
}
.requests-table-cell__arrow-cell {
    padding: 20px;
    line-height: 22px;
}

.requests-table-cell__inline-value {
    display: inline;
}
.requests-table-cell__inline-value .enum-renderer__text {
    margin: 0;
}

.table.reach-table .ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc,
.table.reach-table .ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
    box-shadow: none;
}
@media (max-width: 767px) {
    .table.reach-table .ReactTable .rt-thead {
        display: none;
    }
}

.table.reach-table .rt-thead.-header .requests-table-cell {
    padding-top: 15px;
}
