.reset-password__success-message {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}
.reset-password__button,
.reset-password__success-message {
    margin-top: 40px;
}
.reset-password__button .btn {
    height: 48px;
    font-size: 20px;
    box-sizing: border-box;
    line-height: 42px;
}
