.event-presenters-list-no-count {
    display: flex;
    flex-direction: column;
}

.event-presenters-list-no-count .event-presenter {
    margin-bottom: 8px;
    padding-right: 0;
}

.event-presenters-list-no-count .event-presenter__details {
    width: 100%;
}

@media (min-width: 481px) {
    .event-presenter {
        max-width: 100%;
    }
}
