.legend {
    height: 16px;
    color: #666666;
    font-family: Lato;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}
.legend__clickable {
    cursor: pointer;
}

.legend-label {
    margin-right: 16px;
}
.legend-value {
    font-weight: bold;
}
.legend-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 16px;
}
.legends-inline .legend {
    text-align: center;
    display: inline-block;
}
.legends-inline .legend:last-child {
    text-align: right;
}
.legends-inline .legend:first-child {
    text-align: left;
}
.legends-list {
    float: left;
    width: 100%;
}
.legends-list .legend {
    margin-bottom: 18px;
}
.legends-list .legend .legend-value {
    float: right;
    font-size: 20px;
}

@media (max-width: 1199px) {
    .legend {
        font-size: 14px;
    }
    .legends-list .legend .legend-value {
        font-size: 18px;
    }
    .legend-label {
        margin-right: 10px;
    }
}
@media (max-width: 991px) {
    .legend-bullet {
        margin-right: 5px;
    }
    .legend-label {
        margin-right: 10px;
    }
}
