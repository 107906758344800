/**
 * event playform cognito login form css
 */

form:not(.searchForm):not(.navbar-search)
    input[type="text"].login-cognito__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="text"]:not(.tags-input-field).login-cognito__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="email"].login-cognito__field-input,
form:not(.searchForm):not(.navbar-search)
    input[type="password"].login-cognito__field-input {
    width: 100%;
    max-width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: 50px;
    padding-right: 16px;
    padding-left: 16px;
    margin-bottom: 8px;
}

/* reverse chrome autofill style */
input.login-cognito__field-input:-internal-autofill-selected,
input.login-cognito__field-input:-webkit-autofill {
    -webkit-transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s,
        color 5000s ease-in-out 0s;
}

.login-cognito__label {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 12px;
    padding-top: 0;
    display: inline-block;
}

.login-cognito__error-message {
    margin-top: 40px;
    margin-bottom: 24px;
}

.login-cognito-info {
    font-size: 16px;
    text-align: center;
}

.login-cognito-error {
    font-size: 14px;
    font-weight: bold;
}

.form-control.login-cognito__form-container {
    margin-top: 16px;
}

.login-cognito__buttons-container {
    margin-top: 30px;
}

/* make sure the button is always 100% width */
.eventsplatform.reinvent
    .login-cognito__buttons-container
    button.loading.login-cognito__submit-button,
button.btn.login-cognito__submit-button {
    width: 100%;
    box-sizing: border-box;
}

.login-cognito-password-validation__icon {
    font-size: 16px;
    position: relative;
    top: 2px;
    margin-right: 8px;
}

.form-control-tnc {
    font-size: 13px;
    text-align: center;
}

.form-control-tnc a {
    font-weight: 900;
}
