.ReactTable.email--notifications--table .rt-thead .rt-th {
    font-weight: bold;
    letter-spacing: 0.43px;
}

.ReactTable.email--notifications--table .rt-tbody .rt-tr {
    height: 100px;
}

.ReactTable.email--notifications--table .rt-tbody .rt-td {
    /*padding-top: 32px;*/
    /*padding-bottom: 32px;*/
    align-self: center;
    white-space: normal;
    line-height: 16px;
}

.email--notifications--table .email--notification--cell--line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 100%;
}

.email--notifications--table .email--notification--name {
    font-size: 15px;
    font-weight: bold;
}

.email--notifications--table .email--notification--subject,
.email--notifications--table .email--notification--sender--name {
    font-weight: bold;
}

.email--notifications--table .email--notification--sender--email a {
    text-decoration: underline;
    cursor: default;
}
