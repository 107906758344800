.cognito-admin-user-search-form {
    text-align: center;
}

.cognito-admin-user-search-form input,
.cognito-admin-user-search-form .add-on {
    height: 36px;
    font-size: 16px;
}

.cognito-admin-user-search-form input {
    width: 70%;
}

.cognito-admin-user-search-form .add-on i {
    margin-left: 0;
}
