.SearchFormWrapper {
    position: relative;
}

/* this removes a blank line on FF */
.SearchFormWrapper--text-search-container.row-fluid::before {
    content: none;
}

.SearchFormWrapper .accordion-body.collapse .modal-body {
    padding-top: 0;
    padding-bottom: 0;
}

.search-modal-body .modal-body {
    padding-left: 1px;
}
