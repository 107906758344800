.task-details-inline {
    display: inline;
}
.task-details {
    float: left;
    width: 100%;
    background-color: #f8f8f8;
    box-sizing: border-box;
    padding: 32px 0;
}
.task-details-block {
    float: left;
    width: 45%;
    box-sizing: border-box;
    padding: 0 24px;
}
.task-details-block__info {
    margin-bottom: 10px;
}

.task-details-block__info-feature {
    display: inline-block;
    text-transform: capitalize;
}
.task-details-block__info-label {
    font-weight: bold;
}
@media (max-width: 767px) {
    .task-details-block {
        width: 100%;
    }
    .task-details-block__status-data {
        display: none;
    }
    .task-details {
        padding: 24px 0;
    }
}
