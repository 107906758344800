.event-presenters-list-no-image {
    display: flex;
    margin-bottom: 12px;
}
.event-presenter-no-image__popover {
    background-color: white;
    width: 260px;
    padding: 16px 16px 8px;
    border-radius: 4px;
    border: solid 1px #d9d9d9;
}
.event-presenter-no-image__popover .in-popover {
    max-width: none;
    max-width: initial;
    padding: 0 0 8px;
}
.event-presenter-no-image__popover .Popover-tip {
    display: none;
}
.event-presenters-list__more {
    font-weight: bold;
    max-width: 20%;
    cursor: pointer;
    flex-shrink: 0;
}
@media (max-width: 767px) {
    .event-presenters-list-no-image {
        display: block;
    }
}
