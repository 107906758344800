.enum-renderer__text {
    margin-right: 10px;
}
.enum-renderer__bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: grey;
    margin-right: 10px;
}
