/**
 *  EntryNameAndDescription css
 */

.results-entry__icon {
    font-size: 20px;
    margin-right: 10px;
}

.results-entry__name {
    font-size: 20px;
    line-height: 22px;
    word-break: inherit;
    word-break: break-word;
    word-wrap: break-word;
    display: inline-block;
}

.entry-statistics--mobile {
    margin-left: 16px;
}
.results-entry__basic-scheduling {
    padding-top: 8px;
}

.results-entry__status {
    font-size: 11px;
    font-weight: bold;
    line-height: 13px;
    border-radius: 4px;
    display: inline-block;
    margin-left: 8px;
    padding: 1px 3px;
}
.results-entry__status--live {
    color: #ffffff;
    background-color: #fa374b;
}
.results-entry__status--scheduled {
    color: #999999;
    border-radius: 4px;
    border: solid 1px #999999;
}
