.entryStatistics .entryStatistics__stat__icon {
    margin-left: 0;
    font-size: 14px;
    margin-right: 12px;
    position: relative;
    top: 2px;
}

.entryStatistics__stat {
    margin-right: 20px;
    font-size: 12px;
    position: relative;
    line-height: normal;
    display: inline-block; /* keep stat icon and number together */
    color: #999999;
}

.entryStatistics__link:hover {
    color: #666666;
}
