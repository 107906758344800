.webcast-homepage-carousel-entry-info {
    line-height: normal;
}

.webcast-homepage-carousel-status-text {
    font-weight: bold;
    text-transform: uppercase;
}

.webcast-homepage-carousel-entry-name {
    font-weight: bold;
}

/* tablet portrait / landscape / desktop / desktop large */
.webcast-homepage-carousel-entry-info {
    font-size: 16px;
}

.webcast-homepage-carousel-entry-name {
    font-size: 48px;
}

.webcast-homepage-carousel-entry-name,
.webcast-homepage-carousel-presenters {
    margin-top: 16px;
}

.webcast-homepage-carousel-entry-description {
    line-height: 21px;
    margin-top: 12px;
}

/* phone portrait / landscape */
@media (max-width: 767px) {
    .webcast-homepage-carousel-entry-info {
        font-size: 13px;
    }

    .webcast-homepage-carousel-entry-name {
        font-size: 32px;
    }

    .webcast-homepage-carousel-entry-name,
    .webcast-homepage-carousel-presenters {
        margin-top: 8px;
    }
}
